<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-xl" :class="isTab === $constant.tab.first ? 'modal-dialog-scrollable' : ''" role="document">
            <div class="modal-content">
                <div class="modal-header py-1 bg-light">
                    <h6 v-if="isEdit" class="modal-title py-2 px-4">
                        {{$t('message.common.updatePromotion')}}
                    </h6>
                    <h6 v-else class="modal-title py-2 px-4">
                        {{$t('message.common.addPromotion')}}
                    </h6>
                    <button type="button" class="close" aria-label="Close" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-header custom__list-tab bg-light px-3 pt-3 pb-0 text-center">
                    <div :class="[ isTab === $constant.tab.first ? 'tab__active' : '', 'tab__item px-4 pb-2']" @click="choseTab($constant.tab.first)">
                        {{$t('message.common.promotionInformation')}}
                    </div>
                    <div :class="[ isTab === $constant.tab.second ? 'tab__active' : '', 'tab__item px-4 pb-2']" @click="choseTab($constant.tab.second)">
                        {{$t('message.VoucherIndex.modal.create_or_update.apply_time')}}
                    </div>
                    <div :class="[ isTab === $constant.tab.third ? 'tab__active' : '', 'tab__item px-4 pb-2']" @click="choseTab($constant.tab.third)">
                        {{$t('message.VoucherIndex.modal.create_or_update.apply_area')}}
                    </div>
                </div>
    
                <div class="modal-body py-0 px-3">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="homeInfoCustomer" role="tabpanel" aria-labelledby="home-tab" v-if="isTab === $constant.tab.first">
                            <div class="row mx-4 mt-4">
                                <div class="col-12 pl-0 text-bold">
                                    {{$t('message.ProductIndex.info')}}
                                </div>
                            </div>
                            <div class="row mx-4 my-4 pb-4 mt-1 border-bottom-dotted-2">
                                <div class="col-6 pl-0">
                                    <div class="row mb-3">
                                        <div class="col-4 d-flex align-items-center text-bold">
                                            {{$t('message.noticeDetail.tables.promotion.code')}}
                                        </div>
                                        <div class="col-8 pl-0">
                                            <div class="form-group mb-0">
                                                <input type="text" maxlength="100" v-model.trim="promotionEntry.code"  :placeholder="$t('message.VoucherIndex.modal.create_or_update.placeholder.code')" class="form-control input-custom pl-0" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-4 d-flex align-items-center text-bold">{{$t('message.CouponIndex.name')}} <label class="text-danger px-0">*</label></div>
                                        <div class="col-8 pl-0">
                                            <div class="form-group mb-0">
                                                <input type="text" maxlength="100" v-model.trim="promotionEntry.name"  :placeholder="$t('message.CouponIndex.modal.create_or_update.placeholder.name')" class="form-control pl-0 input-custom" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 pr-0">
                                    <div class="row mb-3">
                                        <div class="col-4 d-flex align-items-center text-bold mt-2">{{$t('message.common.status')}}</div>
                                        <div class="col-3 d-flex align-items-center pl-0 mt-2">
                                            <label class="container-radio m-0 flex-1 min-w-100">{{$t('message.CouponIndex.modal.create_or_update.status_active')}}
                                                <input type="radio" name="promotionStatus" v-model="promotionEntry.status" :value="this.$constant.statusActive" class="mr-2">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div class="col-5 d-flex align-items-center pl-0 mt-2">
                                            <label class="container-radio m-0 flex-1 min-w-100">{{$t('message.CouponIndex.modal.create_or_update.status_inactive')}}
                                                <input type="radio" name="promotionStatus" v-model="promotionEntry.status" :value="this.$constant.status_draft" class="mr-2">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row mb-3 pt-1">
                                        <div class="col-12 d-flex align-items-center position-relative">
                                            <i class="fa fa-pencil-alt ml-2 text-secondary"></i>
                                            <input type="text" name="description" id="description" v-model.trim="promotionEntry.description" class="form-control input-custom pl-4" autocomplete="off" :placeholder="$t('message.CouponIndex.note')">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mx-4 mt-4">
                                <div class="col-12 pl-0 text-bold">
                                    {{$t('message.common.formOfPromotion')}}
                                </div>
                            </div>
                            <div class="row mx-4 mt-3">
                                <div class="col-7 pl-0">
                                    <div class="row mb-3">
                                        <div class="col-6 text-bold">
                                            <div class="row mb-3 d-flex align-items-center m-0">
                                                <div class="col-5 text-bold p-0">{{$t('message.common.promotionBy')}}</div>
                                                <div class="col-7 p-0">
                                                    <SelectSearch :placeholder="$t('message.common.selectPromotionType')" :readonly="true" :disabled="this.notEdit" :modelValue="filterQuery.promotionById" :defaultOption="optionPromotions" @update:modelValue="doFilterSearch" :label="'promotionById'"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 p-0">
                                            <div class="row mb-3 d-flex align-items-center m-0">
                                                <div class="col-4 text-bold p-0 container-checkbox d-flex mb-0">
                                                    {{$t('message.common.form')}} 
                                                    <div class="container-circle tooltip-left-center">
                                                        <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                                        <div class="content-circle font-weight-normal">
                                                            <span v-html="this.$constant.promotion_invoice_discount_description" 
                                                                v-if="this.filterQuery.promotionFromId == this.$constant.promotion_invoice_discount" class="position-relative">
                                                            </span>
                                                            <span v-html="this.$constant.promotion_invoice_donate_product_description" 
                                                                v-if="this.filterQuery.promotionFromId == this.$constant.promotion_invoice_donate_product" class="position-relative">
                                                            </span>
                                                            <span v-html="this.$constant.promotion_invoice_discount_on_product_description" 
                                                                v-if="this.filterQuery.promotionFromId == this.$constant.promotion_invoice_discount_on_product" class="position-relative">
                                                            </span>
                                                            <span v-html="this.$constant.promotion_product_discount_description" 
                                                                v-if="this.filterQuery.promotionFromId == this.$constant.promotion_product_discount" class="position-relative">
                                                            </span>
                                                            <span v-html="this.$constant.promotion_product_donate_product_description" 
                                                                v-if="this.filterQuery.promotionFromId == this.$constant.promotion_product_donate_product" class="position-relative">
                                                            </span>
                                                            <span v-html="this.$constant.promotion_product_allow_quantity_description" 
                                                                v-if="this.filterQuery.promotionFromId == this.$constant.promotion_product_allow_quantity" class="position-relative">
                                                            </span>
                                                            <span v-html="this.$constant.promotion_all_invoice_discount_description" 
                                                                v-if="this.filterQuery.promotionFromId == this.$constant.promotion_all_invoice_discount" class="position-relative">
                                                            </span>
                                                            <span v-html="this.$constant.promotion_all_donate_product_description" 
                                                                v-if="this.filterQuery.promotionFromId == this.$constant.promotion_all_donate_product" class="position-relative">
                                                            </span>
                                                            <span v-html="this.$constant.promotion_all_product_discount_description" 
                                                                v-if="this.filterQuery.promotionFromId == this.$constant.promotion_all_product_discount" class="position-relative">
                                                            </span>
                                                        </div>
                                                    </div>    
                                                </div>
                                                <div class="col-8 p-0">
                                                    <SelectSearch :placeholder="$t('message.CouponIndex.modal.create_or_update.table.placeholder.choose_method')" :readonly="true" :disabled="this.notEdit" :modelValue="filterQuery.promotionFromId" :defaultOption="optionFormPromotion" @update:modelValue="doFilterSearch" :label="'promotionFromId'"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-5 pr-0" :class="this.notEdit ? 'cursor-ban' : ''">
                                    <label class="container-checkbox d-flex m-0" v-if="this.filterQuery.promotionFromId === this.$constant.promotion_product_discount || this.filterQuery.promotionFromId === this.$constant.promotion_product_donate_product" :class="this.notEdit ? 'cursor-ban' : ''">
                                        {{$t('message.common.productByQuantityBuy')}} 
                                        <div class="container-circle tooltip-right-center">
                                            <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                            <div class="content-circle font-weight-normal">
                                                {{$t('message.common.productByQuantityBuyNote')}} <br/>
                                                - {{$t('message.common.productByQuantityBuyNote2')}} <br/>
                                                - {{$t('message.common.productByQuantityBuyNote3')}}
                                            </div>
                                        </div>
                                        <input type="checkbox" class="chkType" v-model="promotionEntry.duplicateProduct" :disabled="this.notEdit" :checked="!promotionEntry.duplicateProduct ? false : true">
                                        <span class="checkmark-checkbox"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="row mx-0 p-0">
                                <div class="col-12 p-0">
                                    <table class="w-100">
                                        <thead>
                                            <tr class="backgroup__detail table-header border-1" v-if="this.filterQuery.promotionFromId === this.$constant.promotion_invoice_discount">
                                                <th scope="col" class="pl-2 cell-status-coupon">{{$t('message.OrderSupplierIndex.total_origin')}}</th>
                                                <th scope="col" class="pl-3 cell-code-coupon">{{$t('message.common.promotionValue')}}</th>
                                                <th scope="col" class="pl-4 cell-auto"></th>
                                            </tr>
                                            <tr class="backgroup__detail table-header border-1" v-if="this.filterQuery.promotionFromId === this.$constant.promotion_invoice_donate_product">
                                                <th scope="col" class="pl-2 cell-status-coupon">{{$t('message.OrderSupplierIndex.total_origin')}}</th>
                                                <th scope="col" class="pl-4 cell-status-coupon">{{$t('message.common.totalGift')}}</th>
                                                <th scope="col" class="pl-3 cell-code-coupon">{{$t('message.common.giftGroup')}}</th>
                                                <th scope="col" class="pl-3 cell-code-coupon">{{$t('message.common.giftProduct')}}</th>
                                                <th scope="col" class="pl-4 cell-auto"></th>
                                            </tr>
                                            <tr class="backgroup__detail table-header border-1" v-if="this.filterQuery.promotionFromId === this.$constant.promotion_invoice_discount_on_product">
                                                <th scope="col" class="pl-2 cell-status-coupon">{{$t('message.OrderSupplierIndex.total_origin')}}</th>
                                                <th scope="col" class="pl-3 cell-code-coupon">{{$t('message.common.promotionValue')}}</th>
                                                <th scope="col" class="pl-4 cell-check text-right">{{$t('message.common.shortQuantity')}}</th>
                                                <th scope="col" class="pl-3 cell-code-coupon">{{$t('message.common.categoriesAreDiscounted')}}</th>
                                                <th scope="col" class="pl-3 cell-code-coupon">{{$t('message.common.productsAreDiscounted')}}</th>
                                                <th scope="col" class="pl-4 cell-check"></th>
                                            </tr>
                                            <tr class="backgroup__detail table-header border-1" v-if="this.filterQuery.promotionFromId === this.$constant.promotion_product_discount">
                                                <th scope="col" class="pl-4 cell-check text-right">{{$t('message.common.shortQuantity')}}</th>
                                                <th scope="col" class="pl-3 cell-code-coupon">{{$t('message.common.purchaseProductCategories')}}</th>
                                                <th scope="col" class="pl-3 cell-code-coupon">{{$t('message.common.promotionValue')}}</th>
                                                <th scope="col" class="pl-4 cell-check text-right">{{$t('message.common.shortQuantity')}}</th>
                                                <th scope="col" class="pl-3 cell-code-coupon">{{$t('message.common.discountedProductCategories')}}</th>
                                                <th scope="col" class="pl-4 cell-check"></th>
                                            </tr>
                                            <tr class="backgroup__detail table-header border-1" v-if="this.filterQuery.promotionFromId === this.$constant.promotion_product_donate_product">
                                                <th scope="col" class="pl-4 cell-check text-right">{{$t('message.common.shortQuantity')}}</th>
                                                <th scope="col" class="pl-3 cell-code-coupon">{{$t('message.CouponIndex.categories')}}</th>
                                                <th scope="col" class="pl-4 cell-code-auto">{{$t('message.CouponIndex.product')}}</th>
                                                <th scope="col" class="pl-4 cell-check text-right">{{$t('message.common.shortQuantity')}}</th>
                                                <th scope="col" class="pl-3 cell-code-coupon">{{$t('message.common.giftGroup')}}</th>
                                                <th scope="col" class="pl-4 cell-code-auto">{{$t('message.common.giftProduct')}}</th>
                                                <th scope="col" class="pl-4 cell-check"></th>
                                            </tr>
                                            <tr class="backgroup__detail table-header border-1" v-if="this.filterQuery.promotionFromId === this.$constant.promotion_all_invoice_discount">
                                                <th scope="col" class="pl-2 cell-status-coupon">{{$t('message.OrderSupplierIndex.total_origin')}}</th>
                                                <th scope="col" class="pl-4 cell-check text-right">{{$t('message.common.shortQuantity')}}</th>
                                                <th scope="col" class="pl-3 cell-code-coupon">{{$t('message.common.withGiftCategories')}}</th>
                                                <th scope="col" class="pl-3 cell-code-coupon">{{$t('message.common.withGiftProduct')}}</th>
                                                <th scope="col" class="pl-3 cell-code-coupon">{{$t('message.InvoiceIndex.discount')}}</th>
                                                <th scope="col" class="pl-4 cell-check"></th>
                                            </tr>
                                            <tr class="backgroup__detail table-header border-1" v-if="this.filterQuery.promotionFromId === this.$constant.promotion_all_donate_product">
                                                <th scope="col" class="pl-2 cell-status-coupon">{{$t('message.OrderSupplierIndex.total_origin')}}</th>
                                                <th scope="col" class="pl-4 cell-check text-right">{{$t('message.common.shortQuantity')}}</th>
                                                <th scope="col" class="pl-3 cell-code-coupon">{{$t('message.common.withPurchaseProductCategories')}}</th>
                                                <th scope="col" class="pl-4 cell-check text-right">{{$t('message.common.shortQuantity')}}</th>
                                                <th scope="col" class="pl-3 cell-code-coupon">{{$t('message.common.giftProductCategories')}}</th>
                                                <th scope="col" class="pl-4 cell-check"></th>
                                            </tr>
                                            <tr class="backgroup__detail table-header border-1" v-if="this.filterQuery.promotionFromId === this.$constant.promotion_all_product_discount">
                                                <th scope="col" class="pl-2 cell-status-coupon">{{$t('message.OrderSupplierIndex.total_origin')}}</th>
                                                <th scope="col" class="pl-4 cell-check text-right">{{$t('message.common.shortQuantity')}}</th>
                                                <th scope="col" class="pl-3 cell-code-coupon">{{$t('message.common.withPurchaseProductCategories')}}</th>
                                                <th scope="col" class="pl-4 cell-auto">{{$t('message.common.promotionValue')}}</th>
                                                <th scope="col" class="pl-4 cell-check text-right">{{$t('message.common.shortQuantity')}}</th>
                                                <th scope="col" class="pl-3 cell-code-coupon">{{$t('message.common.discountedProductCategories')}}</th>
                                                <th scope="col" class="pl-4 cell-check"></th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                            <div class="row mx-0 p-0 mt-0">
                                <div class="col-12 p-0">
                                    <table class="w-100">
                                        <tbody>
                                            <template v-if="this.optionsPromotionInvoiceDiscount?.length > 0 && this.filterQuery.promotionFromId === this.$constant.promotion_invoice_discount">
                                                <tr class="table-header border" v-for="(option, index) in optionsPromotionInvoiceDiscount" :key="option.id" :class="index % 2 ? 'bg-light' : ''">
                                                    <th scope="col" class="pl-2 cell-status-coupon">
                                                        <div class="row m-0">
                                                            <div class="col-2 d-flex align-items-center p-0 font-weight-normal">{{$t('message.TransporterIndex.fee_from')}}</div>
                                                            <div class="col-10 p-0">
                                                                <div class="form-group mb-0">
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.totalAmount"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-3 cell-code-coupon">
                                                        <div class="row m-0">
                                                            <div class="col-6 p-0">
                                                                <div class="form-group mb-0" v-if="option.discountType != this.$constant.discountTypePercent">
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.discountValue"/>
                                                                </div>
                                                                <div class="form-group mb-0" v-else>
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.discountRatio" :max="100"/>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 d-flex align-items-center p-0 font-weight-normal">
                                                                <button type="button" class="btn btn-default ml-2 text-white" 
                                                                v-for="opt in this.$constant.discountOptions" :key="opt.id" :class="option.discountType == opt.id ? 'btn btn-save' : ''"
                                                                @click="!this.notEdit ? changeDiscountType(opt.id, index) : null">{{opt.name}}</button>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-auto flex-end align-items-center pr-3">
                                                        <div class="flex-end align-items-center">
                                                            <i class="fa fa-trash-alt p-1" @click="!this.notEdit ? removeOption(this.optionsPromotionInvoiceDiscount, index) : null"></i>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </template>

                                            <template v-if="this.optionsPromotionInvoiceDonateProduct?.length > 0 && this.filterQuery.promotionFromId === this.$constant.promotion_invoice_donate_product">
                                                <tr class="table-header border" v-for="(option, index) in optionsPromotionInvoiceDonateProduct" :key="option.id" :class="index % 2 ? 'bg-light' : ''">
                                                    <th scope="col" class="pl-2 cell-status-coupon">
                                                        <div class="row m-0">
                                                            <div class="col-2 d-flex align-items-center p-0 font-weight-normal">{{$t('message.TransporterIndex.fee_from')}}</div>
                                                            <div class="col-10 p-0">
                                                                <div class="form-group mb-0">
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.totalAmount"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-status-coupon">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0">
                                                                <div class="form-group mb-0">
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.totalGift"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-3 cell-code-coupon py-2">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0">
                                                                <div class="row prd-search-box1 mx-0" :class="this.notEdit ? 'cursor-ban' : ''">
                                                                    <template v-if="option.receiveCategories?.length == 0 && !option.receiveSelectAllCategories">
                                                                        <div class="col-10 area_obj_selected d-flex align-items-center pl-0 text-gray font-weight-normal">{{$t('message.common.selectGiftCategories')}}</div>
                                                                    </template>
                                                                    <template v-else>
                                                                        <div class="col-10 area_obj_selected p-0" v-if="!option.receiveSelectAllCategories">
                                                                            <template v-for="o in option.receiveCategories" :key="o.id">
                                                                                <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.group')}}: {{ o.name }}<i class="fa fa-times ml-2 text-gray" @click="!this.notEdit ? removeCategory(option.receiveCategories, o.id) : null"></i></div>
                                                                            </template>
                                                                        </div>
                                                                        <div class="col-10 area_obj_selected p-0" v-else>
                                                                            <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.all_categories')}}<i class="fa fa-times ml-2 text-gray" @click="!this.notEdit ? removeAllCategory(option, 'receiveCategories', option.receiveCategories, option.receiveSelectAllCategories) : null"></i></div>
                                                                        </div>
                                                                    </template>
                                                                    <div class="col-2 p-0 d-flex align-items-center justify-content-center">
                                                                        <button class="listIcon text-gray border-none" @click="!this.notEdit ? showSelectCategories('receiveCategories' + index, option.receiveCategories, option.receiveSelectAllCategories) : null">
                                                                            <i class="fa fa-list"></i>
                                                                        </button>
                                                                        <div class="container-circle tooltip-top-center">
                                                                            <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                                                            <div class="content-circle font-weight-normal">
                                                                                <span v-html="$t('message.common.noteSelectCategories')"></span>
                                                                            </div>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-3 cell-code-coupon py-2">
                                                        <div class="row m-0 h-100">
                                                            <div class="col-12 p-0 h-100">
                                                                <ProductUnitsSearch :readonly="this.notEdit" @selectProductUnit="onSelectProductUnit" :label="'receiveProduct' + index" :normal="true" :placeholder="$t('message.common.selectGiftProduct')" :defaultValue="option.receiveProducts" :ref="'receiveProduct' + index"></ProductUnitsSearch>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-auto flex-end align-items-center pr-3">
                                                        <div class="flex-end align-items-center">
                                                            <i class="fa fa-trash-alt p-1" @click="!this.notEdit ? removeOption(this.optionsPromotionInvoiceDonateProduct, index) : null"></i>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </template>

                                            <template v-if="this.optionsPromotionInvoiceDiscountOnProduct?.length > 0 && this.filterQuery.promotionFromId === this.$constant.promotion_invoice_discount_on_product">
                                                <tr class="table-header border" v-for="(option, index) in optionsPromotionInvoiceDiscountOnProduct" :key="option.id" :class="index % 2 ? 'bg-light' : ''">
                                                    <th scope="col" class="pl-2 cell-status-coupon">
                                                        <div class="row m-0">
                                                            <div class="col-2 d-flex align-items-center p-0 font-weight-normal">{{$t('message.TransporterIndex.fee_from')}}</div>
                                                            <div class="col-10 p-0">
                                                                <div class="form-group mb-0">
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.totalAmount"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-3 cell-code-coupon">
                                                        <div class="row m-0">
                                                            <div class="col-6 p-0">
                                                                <div class="form-group mb-0" v-if="option.discountType != this.$constant.discountTypePercent">
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.discountValue"/>
                                                                </div>
                                                                <div class="form-group mb-0" v-else>
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.discountRatio" :max="100"/>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 d-flex align-items-center p-0 font-weight-normal">
                                                                <button type="button" class="btn btn-default ml-2 text-white" 
                                                                v-for="opt in this.$constant.discountOptions" :key="opt.id" :class="option.discountType == opt.id ? 'btn btn-save' : ''"
                                                                @click="!this.notEdit ? changeDiscountType(opt.id, index) : null">{{opt.name}}</button>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-check">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0">
                                                                <div class="form-group mb-0">
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.totalQuantityDiscount"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    
                                                    <th scope="col" class="pl-3 cell-code-coupon py-2">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0">
                                                                <div class="row prd-search-box1 mx-0" :class="this.notEdit ? 'cursor-ban' : ''">
                                                                    <template v-if="option.receiveCategories?.length == 0 && !option.receiveSelectAllCategories">
                                                                        <div class="col-10 area_obj_selected d-flex align-items-center pl-0 text-gray font-weight-normal">{{$t('message.common.selectDiscountCategories')}}</div>
                                                                    </template>
                                                                    <template v-else>
                                                                        <div class="col-10 area_obj_selected p-0" v-if="!option.receiveSelectAllCategories">
                                                                            <template v-for="o in option.receiveCategories" :key="o.id">
                                                                                <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.group')}}: {{ o.name }}<i class="fa fa-times ml-2 text-gray" @click="!this.notEdit ? removeCategory(option.receiveCategories, o.id) : null"></i></div>
                                                                            </template>
                                                                        </div>
                                                                        <div class="col-10 area_obj_selected p-0" v-else>
                                                                            <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.all_categories')}}<i class="fa fa-times ml-2 text-gray" @click="!this.notEdit ? removeAllCategory(option, 'receiveCategories',option.receiveCategories, option.receiveSelectAllCategories) : null"></i></div>
                                                                        </div>
                                                                    </template>
                                                                    <div class="col-2 p-0 d-flex align-items-center justify-content-center">
                                                                        <button class="listIcon text-gray border-none" @click="!this.notEdit ? showSelectCategories('receiveCategories' + index, option.receiveCategories, option.receiveSelectAllCategories) : null">
                                                                            <i class="fa fa-list"></i>
                                                                        </button>
                                                                        <div class="container-circle tooltip-top-center">
                                                                            <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                                                            <div class="content-circle font-weight-normal">
                                                                                <span v-html="$t('message.common.noteSelectCategories')"></span>
                                                                            </div>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-3 cell-code-coupon py-2">
                                                        <div class="row m-0 h-100">
                                                            <div class="col-12 p-0 h-100">
                                                                <ProductUnitsSearch :readonly="this.notEdit" @selectProductUnit="onSelectProductUnit" :label="'receiveProduct' + index" :normal="true" :placeholder="$t('message.common.selectProductDiscount')" :defaultValue="option.receiveProducts" :ref="'receiveProduct' + index"></ProductUnitsSearch>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-check pr-3">
                                                        <div class="flex-end align-items-center">
                                                            <i class="fa fa-trash-alt p-1" @click="!this.notEdit ? removeOption(this.optionsPromotionInvoiceDiscountOnProduct, index) : null"></i>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </template>

                                            <template v-if="this.optionsPromotionProductDiscount?.length > 0 && this.filterQuery.promotionFromId === this.$constant.promotion_product_discount">
                                                <tr class="table-header border" v-for="(option, index) in optionsPromotionProductDiscount" :key="option.id" :class="index % 2 ? 'bg-light' : ''">
                                                    <th scope="col" class="pl-4 cell-check">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0">
                                                                <div class="form-group mb-0">
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.totalBuyQuantity"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-3 cell-code-coupon py-2">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0">
                                                                <div class="row prd-search-box1 mx-0" :class="this.notEdit ? 'cursor-ban' : ''">
                                                                    <template v-if="option.conditionCategories?.length == 0 && !option.conditionSelectAllCategories">
                                                                        <div class="col-10 area_obj_selected d-flex align-items-center pl-0 text-gray font-weight-normal">{{$t('message.CouponIndex.modal.create_or_update.choose_categories')}}</div>
                                                                    </template>
                                                                    <template v-else>
                                                                        <div class="col-10 area_obj_selected p-0" v-if="!option.conditionSelectAllCategories">
                                                                            <template v-for="o in option.conditionCategories" :key="o.id">
                                                                                <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.group')}}: {{ o.name }}<i class="fa fa-times ml-2 text-gray" @click="!this.notEdit ? removeCategory(option.conditionCategories, o.id) : null"></i></div>
                                                                            </template>
                                                                        </div>
                                                                        <div class="col-10 area_obj_selected p-0" v-else>
                                                                            <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.all_categories')}}<i class="fa fa-times ml-2 text-gray" @click="!this.notEdit ? removeAllCategory(option, 'conditionCategories', option.conditionCategories, option.conditionSelectAllCategories) : null"></i></div>
                                                                        </div>
                                                                    </template>
                                                                    <div class="col-2 p-0 d-flex align-items-center justify-content-center">
                                                                        <button class="listIcon text-gray border-none" @click="!this.notEdit ? showSelectCategories('conditionCategories' + index, option.conditionCategories, option.conditionSelectAllCategories) : null">
                                                                            <i class="fa fa-list"></i>
                                                                        </button>
                                                                        <div class="container-circle tooltip-top-center">
                                                                            <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                                                            <div class="content-circle font-weight-normal">
                                                                                <span v-html="$t('message.common.noteSelectCategories')"></span>
                                                                            </div>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row m-0 mt-3">
                                                            <div class="col-12 p-0 h-100">
                                                                <ProductUnitsSearch :readonly="this.notEdit" @selectProductUnit="onSelectProductUnit" :label="'conditionProduct' + index" :normal="true" :placeholder="$t('message.CouponIndex.modal.create_or_update.placeholder.products')" :defaultValue="option.conditionProducts" :ref="'conditionProduct' + index"></ProductUnitsSearch>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-3 cell-code-coupon">
                                                        <div class="row m-0">
                                                            <div class="col-6 p-0">
                                                                <div class="form-group mb-0" v-if="option.discountType != this.$constant.discountTypePercent">
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.discountValue"/>
                                                                </div>
                                                                <div class="form-group mb-0" v-else>
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.discountRatio" :max="100"/>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 d-flex align-items-center p-0 font-weight-normal">
                                                                <button type="button" class="btn btn-default ml-2 text-white" 
                                                                v-for="opt in this.$constant.discountOptions" :key="opt.id" :class="option.discountType == opt.id ? 'btn btn-save' : ''"
                                                                @click="!this.notEdit ? changeDiscountType(opt.id, index) : null">{{opt.name}}</button>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-check">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0">
                                                                <div class="form-group mb-0">
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.totalQuantityDiscount"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-3 cell-code-coupon py-2">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0">
                                                                <div class="row prd-search-box1 mx-0" :class="this.notEdit ? 'cursor-ban' : ''">
                                                                    <template v-if="option.receiveCategories?.length == 0 && !option.receiveSelectAllCategories">
                                                                        <div class="col-10 area_obj_selected d-flex align-items-center pl-0 text-gray font-weight-normal">{{$t('message.common.selectDiscountCategories')}}</div>
                                                                    </template>
                                                                    <template v-else>
                                                                        <div class="col-10 area_obj_selected p-0" v-if="!option.receiveSelectAllCategories">
                                                                            <template v-for="o in option.receiveCategories" :key="o.id">
                                                                                <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.group')}}: {{ o.name }}<i class="fa fa-times ml-2 text-gray" @click="!this.notEdit ? removeCategory(option.receiveCategories, o.id) : null"></i></div>
                                                                            </template>
                                                                        </div>
                                                                        <div class="col-10 area_obj_selected p-0" v-else>
                                                                            <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.all_categories')}}<i class="fa fa-times ml-2 text-gray" @click="!this.notEdit ? removeAllCategory(option, 'receiveCategories', option.receiveCategories, option.receiveSelectAllCategories) : null"></i></div>
                                                                        </div>
                                                                    </template>
                                                                    <div class="col-2 p-0 d-flex align-items-center justify-content-center">
                                                                        <button class="listIcon text-gray border-none" @click="!this.notEdit ? showSelectCategories('receiveCategories' + index, option.receiveCategories, option.receiveSelectAllCategories) : null">
                                                                            <i class="fa fa-list"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row m-0 mt-3">
                                                            <div class="col-12 p-0 h-100">
                                                                <ProductUnitsSearch :readonly="this.notEdit" @selectProductUnit="onSelectProductUnit" :label="'receiveProduct' + index" :normal="true" :placeholder="$t('message.common.selectProductDiscount')" :defaultValue="option.receiveProducts" :ref="'receiveProduct' + index"></ProductUnitsSearch>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-check pr-3">
                                                        <div class="flex-end align-items-center">
                                                            <i class="fa fa-trash-alt p-1" @click="!this.notEdit ? removeOption(this.optionsPromotionProductDiscount, index) : null"></i>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </template>

                                            <template v-if="this.optionsPromotionProductDonateProduct?.length > 0 && this.filterQuery.promotionFromId === this.$constant.promotion_product_donate_product">
                                                <tr class="table-header border" v-for="(option, index) in optionsPromotionProductDonateProduct" :key="option.id" :class="index % 2 ? 'bg-light' : ''">
                                                    <th scope="col" class="pl-4 cell-check"> 
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0">
                                                                <div class="form-group mb-0">
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.totalBuyQuantity"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-3 cell-code-coupon py-2">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0">
                                                                <div class="row prd-search-box1 mx-0" :class="this.notEdit ? 'cursor-ban' : ''">
                                                                    <template v-if="option.conditionCategories?.length == 0 && !option.conditionSelectAllCategories">
                                                                        <div class="col-10 area_obj_selected d-flex align-items-center pl-0 text-gray font-weight-normal">{{$t('message.CouponIndex.modal.create_or_update.choose_categories')}}</div>
                                                                    </template>
                                                                    <template v-else>
                                                                        <div class="col-10 area_obj_selected p-0" v-if="!option.conditionSelectAllCategories">
                                                                            <template v-for="o in option.conditionCategories" :key="o.id">
                                                                                <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.group')}}: {{ o.name }}<i class="fa fa-times ml-2 text-gray" @click="!this.notEdit ? removeCategory(option.conditionCategories, o.id) : null"></i></div>
                                                                            </template>
                                                                        </div>
                                                                        <div class="col-10 area_obj_selected p-0" v-else>
                                                                            <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.all_categories')}}<i class="fa fa-times ml-2 text-gray" @click="!this.notEdit ? removeAllCategory(option, 'conditionCategories', option.conditionCategories, option.conditionSelectAllCategories) : null"></i></div>
                                                                        </div>
                                                                    </template>
                                                                    <div class="col-2 p-0 d-flex align-items-center justify-content-center">
                                                                        <button class="listIcon text-gray border-none" @click="!this.notEdit ? showSelectCategories('conditionCategories' + index, option.conditionCategories, option.conditionSelectAllCategories) : null">
                                                                            <i class="fa fa-list"></i>
                                                                        </button>
                                                                        <div class="container-circle tooltip-top-center">
                                                                            <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                                                            <div class="content-circle font-weight-normal">
                                                                                <span v-html="$t('message.common.noteSelectCategories')"></span>
                                                                            </div>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-auto py-2">
                                                        <div class="row m-0 h-100">
                                                            <div class="col-12 p-0 h-100">
                                                                <ProductUnitsSearch :readonly="this.notEdit" @selectProductUnit="onSelectProductUnit" :label="'conditionProduct' + index" :normal="true" :placeholder="$t('message.CouponIndex.modal.create_or_update.placeholder.products')" :defaultValue="option.conditionProducts" :ref="'conditionProduct' + index"></ProductUnitsSearch>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-check">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0">
                                                                <div class="form-group mb-0">
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.totalGift"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    
                                                    <th scope="col" class="pl-3 cell-code-coupon py-2">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0">
                                                                <div class="row prd-search-box1 mx-0" :class="this.notEdit ? 'cursor-ban' : ''">
                                                                    <template v-if="option.receiveCategories?.length == 0 && !option.receiveSelectAllCategories">
                                                                        <div class="col-10 area_obj_selected d-flex align-items-center pl-0 text-gray font-weight-normal">{{$t('message.common.selectGiftCategories')}}</div>
                                                                    </template>
                                                                    <template v-else>
                                                                        <div class="col-10 area_obj_selected p-0" v-if="!option.receiveSelectAllCategories">
                                                                            <template v-for="o in option.receiveCategories" :key="o.id">
                                                                                <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.group')}}: {{ o.name }}<i class="fa fa-times ml-2 text-gray" @click="!this.notEdit ? removeCategory(option.receiveCategories, o.id) : null"></i></div>
                                                                            </template>
                                                                        </div>
                                                                        <div class="col-10 area_obj_selected p-0" v-else>
                                                                            <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.all_categories')}}<i class="fa fa-times ml-2 text-gray" @click="!this.notEdit ? removeAllCategory(option, 'receiveCategories', option.receiveCategories, option.receiveSelectAllCategories) : null"></i></div>
                                                                        </div>
                                                                    </template>
                                                                    <div class="col-2 p-0 d-flex align-items-center justify-content-center">
                                                                        <button class="listIcon text-gray border-none" @click="!this.notEdit ? showSelectCategories('receiveCategories' + index, option.receiveCategories, option.receiveSelectAllCategories) : null">
                                                                            <i class="fa fa-list"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-auto py-2">
                                                        <div class="row m-0 h-100">
                                                            <div class="col-12 p-0 h-100">
                                                                <ProductUnitsSearch :readonly="this.notEdit" @selectProductUnit="onSelectProductUnit" :label="'receiveProduct' + index" :normal="true" :placeholder="$t('message.common.selectGiftProduct')" :defaultValue="option.receiveProducts" :ref="'receiveProduct' + index"></ProductUnitsSearch>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-check pr-3">
                                                        <div class="flex-end align-items-center">
                                                            <i class="fa fa-trash-alt p-1" @click="!this.notEdit ? removeOption(this.optionsPromotionProductDonateProduct, index) : null"></i>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </template>

                                            <template v-if="this.optionsPromotionAllInvoiceDiscount?.length > 0 && this.filterQuery.promotionFromId === this.$constant.promotion_all_invoice_discount">
                                                <tr class="table-header border" v-for="(option, index) in optionsPromotionAllInvoiceDiscount" :key="option.id" :class="index % 2 ? 'bg-light' : ''">
                                                    <th scope="col" class="pl-2 cell-status-coupon">
                                                        <div class="row m-0">
                                                            <div class="col-2 d-flex align-items-center p-0 font-weight-normal">{{$t('message.TransporterIndex.fee_from')}}</div>
                                                            <div class="col-10 p-0">
                                                                <div class="form-group mb-0">
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.totalAmount"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-check">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0">
                                                                <div class="form-group mb-0">
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.attachQuantity"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-3 cell-code-coupon py-2">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0">
                                                                <div class="row prd-search-box1 mx-0" :class="this.notEdit ? 'cursor-ban' : ''">
                                                                    <template v-if="option.conditionCategories?.length == 0 && !option.conditionSelectAllCategories">
                                                                        <div class="col-10 area_obj_selected d-flex align-items-center pl-0 text-gray font-weight-normal">{{$t('message.CouponIndex.modal.create_or_update.choose_categories')}}</div>
                                                                    </template>
                                                                    <template v-else>
                                                                        <div class="col-10 area_obj_selected p-0" v-if="!option.conditionSelectAllCategories">
                                                                            <template v-for="o in option.conditionCategories" :key="o.id">
                                                                                <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.group')}}: {{ o.name }}<i class="fa fa-times ml-2 text-gray" @click="!this.notEdit ? removeCategory(option.conditionCategories, o.id) : null"></i></div>
                                                                            </template>
                                                                        </div>
                                                                        <div class="col-10 area_obj_selected p-0" v-else>
                                                                            <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.all_categories')}}<i class="fa fa-times ml-2 text-gray" @click="!this.notEdit ? removeAllCategory(option, 'conditionCategories', option.conditionCategories, option.conditionSelectAllCategories) : null"></i></div>
                                                                        </div>
                                                                    </template>
                                                                    <div class="col-2 p-0 d-flex align-items-center justify-content-center">
                                                                        <button class="listIcon text-gray border-none" @click="!this.notEdit ? showSelectCategories('conditionCategories' + index, option.conditionCategories, option.conditionSelectAllCategories) : null">
                                                                            <i class="fa fa-list"></i>
                                                                        </button>
                                                                        <div class="container-circle tooltip-top-center">
                                                                            <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                                                            <div class="content-circle font-weight-normal">
                                                                                <span v-html="$t('message.common.noteSelectCategories')"></span>
                                                                            </div>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-3 cell-code-coupon py-2">
                                                        <div class="row m-0 h-100">
                                                            <div class="col-12 p-0 h-100">
                                                                <ProductUnitsSearch :readonly="this.notEdit" @selectProductUnit="onSelectProductUnit" :label="'conditionProduct' + index" :normal="true" :placeholder="$t('message.CouponIndex.modal.create_or_update.placeholder.products')" :defaultValue="option.conditionProducts" :ref="'conditionProduct' + index"></ProductUnitsSearch>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-3 cell-code-coupon">
                                                        <div class="row m-0">
                                                            <div class="col-6 p-0">
                                                                <div class="form-group mb-0" v-if="option.discountType != this.$constant.discountTypePercent">
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.discountValue"/>
                                                                </div>
                                                                <div class="form-group mb-0" v-else>
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.discountRatio" :max="100"/>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 d-flex align-items-center p-0 font-weight-normal">
                                                                <button type="button" class="btn btn-default ml-2 text-white" 
                                                                v-for="opt in this.$constant.discountOptions" :key="opt.id" :class="option.discountType == opt.id ? 'btn btn-save' : ''"
                                                                @click="!this.notEdit ? changeDiscountType(opt.id, index) : null">{{opt.name}}</button>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-check pr-3">
                                                        <div class="flex-end align-items-center">
                                                            <i class="fa fa-trash-alt p-1" @click="!this.notEdit ? removeOption(this.optionsPromotionAllInvoiceDiscount, index) : null"></i>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </template>

                                            <template v-if="this.optionsPromotionAllDonateProduct?.length > 0 && this.filterQuery.promotionFromId === this.$constant.promotion_all_donate_product">
                                                <tr class="table-header border" v-for="(option, index) in optionsPromotionAllDonateProduct" :key="option.id" :class="index % 2 ? 'bg-light' : ''">
                                                    <th scope="col" class="pl-2 cell-status-coupon">
                                                        <div class="row m-0">
                                                            <div class="col-2 d-flex align-items-center p-0 font-weight-normal">{{$t('message.TransporterIndex.fee_from')}}</div>
                                                            <div class="col-10 p-0">
                                                                <div class="form-group mb-0">
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.totalAmount"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-check">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0">
                                                                <div class="form-group mb-0">
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.attachQuantity"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-3 cell-code-coupon py-2">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0">
                                                                <div class="row prd-search-box1 mx-0" :class="this.notEdit ? 'cursor-ban' : ''">
                                                                    <template v-if="option.conditionCategories?.length == 0 && !option.conditionSelectAllCategories">
                                                                        <div class="col-10 area_obj_selected d-flex align-items-center pl-0 text-gray font-weight-normal">{{$t('message.CouponIndex.modal.create_or_update.choose_categories')}}</div>
                                                                    </template>
                                                                    <template v-else>
                                                                        <div class="col-10 area_obj_selected p-0" v-if="!option.conditionSelectAllCategories">
                                                                            <template v-for="o in option.conditionCategories" :key="o.id">
                                                                                <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.group')}}: {{ o.name }}<i class="fa fa-times ml-2 text-gray" @click="!this.notEdit ? removeCategory(option.conditionCategories, o.id) : null"></i></div>
                                                                            </template>
                                                                        </div>
                                                                        <div class="col-10 area_obj_selected p-0" v-else>
                                                                            <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.all_categories')}}<i class="fa fa-times ml-2 text-gray" @click="!this.notEdit ? removeAllCategory(option, 'conditionCategories', option.conditionCategories, option.conditionSelectAllCategories) : null"></i></div>
                                                                        </div>
                                                                    </template>
                                                                    <div class="col-2 p-0 d-flex align-items-center justify-content-center">
                                                                        <button class="listIcon text-gray border-none" @click="!this.notEdit ? showSelectCategories('conditionCategories' + index, option.conditionCategories, option.conditionSelectAllCategories) : null">
                                                                            <i class="fa fa-list"></i>
                                                                        </button>
                                                                        <div class="container-circle tooltip-top-center">
                                                                            <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                                                            <div class="content-circle font-weight-normal">
                                                                                <span v-html="$t('message.common.noteSelectCategories')"></span>
                                                                            </div>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row m-0 mt-3">
                                                            <div class="col-12 p-0 h-100">
                                                                <ProductUnitsSearch :readonly="this.notEdit" @selectProductUnit="onSelectProductUnit" :label="'conditionProduct' + index" :normal="true" :placeholder="$t('message.CouponIndex.modal.create_or_update.placeholder.products')" :defaultValue="option.conditionProducts" :ref="'conditionProduct' + index"></ProductUnitsSearch>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-check">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0">
                                                                <div class="form-group mb-0">
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.totalGift"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-3 cell-code-coupon py-2">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0">
                                                                <div class="row prd-search-box1 mx-0" :class="this.notEdit ? 'cursor-ban' : ''">
                                                                    <template v-if="option.receiveCategories?.length == 0 && !option.receiveSelectAllCategories">
                                                                        <div class="col-10 area_obj_selected d-flex align-items-center pl-0 text-gray font-weight-normal">{{$t('message.common.selectGiftCategories')}}</div>
                                                                    </template>
                                                                    <template v-else>
                                                                        <div class="col-10 area_obj_selected p-0" v-if="!option.receiveSelectAllCategories">
                                                                            <template v-for="o in option.receiveCategories" :key="o.id">
                                                                                <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.group')}}: {{ o.name }}<i class="fa fa-times ml-2 text-gray" @click="!this.notEdit ? removeCategory(option.receiveCategories, o.id) : null"></i></div>
                                                                            </template>
                                                                        </div>
                                                                        <div class="col-10 area_obj_selected p-0" v-else>
                                                                            <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.all_categories')}}<i class="fa fa-times ml-2 text-gray" @click="!this.notEdit ? removeAllCategory(option, 'receiveCategories', option.receiveCategories, option.receiveSelectAllCategories) : null"></i></div>
                                                                        </div>
                                                                    </template>
                                                                    <div class="col-2 p-0 d-flex align-items-center justify-content-center">
                                                                        <button class="listIcon text-gray border-none" @click="!this.notEdit ? showSelectCategories('receiveCategories' + index, option.receiveCategories, option.receiveSelectAllCategories) : null">
                                                                            <i class="fa fa-list"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row m-0 mt-3">
                                                            <div class="col-12 p-0 h-100">
                                                                <ProductUnitsSearch :readonly="this.notEdit" @selectProductUnit="onSelectProductUnit" :label="'receiveProduct' + index" :normal="true" :placeholder="$t('message.common.selectGiftProduct')" :defaultValue="option.receiveProducts" :ref="'receiveProduct' + index"></ProductUnitsSearch>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-check pr-3">
                                                        <div class="flex-end align-items-center">
                                                            <i class="fa fa-trash-alt p-1" @click="!this.notEdit ? removeOption(this.optionsPromotionAllDonateProduct, index) : null"></i>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </template>

                                            <template v-if="this.optionsPromotionAllProductDiscount?.length > 0 && this.filterQuery.promotionFromId === this.$constant.promotion_all_product_discount">
                                                <tr class="table-header border" v-for="(option, index) in optionsPromotionAllProductDiscount" :key="option.id" :class="index % 2 ? 'bg-light' : ''">
                                                    <th scope="col" class="pl-2 cell-status-coupon">
                                                        <div class="row m-0">
                                                            <div class="col-2 d-flex align-items-center p-0 font-weight-normal">{{$t('message.TransporterIndex.fee_from')}}</div>
                                                            <div class="col-10 p-0">
                                                                <div class="form-group mb-0">
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.totalAmount"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-check">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0">
                                                                <div class="form-group mb-0">
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.attachQuantity"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-3 cell-code-coupon py-2">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0">
                                                                <div class="row prd-search-box1 mx-0" :class="this.notEdit ? 'cursor-ban' : ''">
                                                                    <template v-if="option.conditionCategories?.length == 0 && !option.conditionSelectAllCategories">
                                                                        <div class="col-10 area_obj_selected d-flex align-items-center pl-0 text-gray font-weight-normal">{{$t('message.CouponIndex.modal.create_or_update.choose_categories')}}</div>
                                                                    </template>
                                                                    <template v-else>
                                                                        <div class="col-10 area_obj_selected p-0" v-if="!option.conditionSelectAllCategories">
                                                                            <template v-for="o in option.conditionCategories" :key="o.id">
                                                                                <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.group')}}: {{ o.name }}<i class="fa fa-times ml-2 text-gray" @click="!this.notEdit ? removeCategory(option.conditionCategories, o.id) : null"></i></div>
                                                                            </template>
                                                                        </div>
                                                                        <div class="col-10 area_obj_selected p-0" v-else>
                                                                            <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.all_categories')}}<i class="fa fa-times ml-2 text-gray" @click="!this.notEdit ? removeAllCategory(option, 'conditionCategories', option.conditionCategories, option.conditionSelectAllCategories) : null"></i></div>
                                                                        </div>
                                                                    </template>
                                                                    <div class="col-2 p-0 d-flex align-items-center justify-content-center">
                                                                        <button class="listIcon text-gray border-none" @click="!this.notEdit ? showSelectCategories('conditionCategories' + index, option.conditionCategories, option.conditionSelectAllCategories) : null">
                                                                            <i class="fa fa-list"></i>
                                                                        </button>
                                                                        <div class="container-circle tooltip-top-center">
                                                                            <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                                                            <div class="content-circle font-weight-normal">
                                                                                <span v-html="$t('message.common.noteSelectCategories')"></span>
                                                                            </div>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row m-0 mt-3">
                                                            <div class="col-12 p-0 h-100">
                                                                <ProductUnitsSearch :readonly="this.notEdit" @selectProductUnit="onSelectProductUnit" :label="'conditionProduct' + index" :normal="true" :placeholder="$t('message.CouponIndex.modal.create_or_update.placeholder.products')" :defaultValue="option.conditionProducts" :ref="'conditionProduct' + index"></ProductUnitsSearch>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-auto">
                                                        <div class="row m-0">
                                                            <div class="col-6 p-0">
                                                                <div class="form-group mb-0" v-if="option.discountType != this.$constant.discountTypePercent">
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.discountValue"/>
                                                                </div>
                                                                <div class="form-group mb-0" v-else>
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.discountRatio" :max="100"/>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 d-flex align-items-center p-0 font-weight-normal">
                                                                <button type="button" class="btn btn-default ml-1 text-white" 
                                                                v-for="opt in this.$constant.discountOptions" :key="opt.id" :class="option.discountType == opt.id ? 'btn btn-save' : ''"
                                                                @click="!this.notEdit ? changeDiscountType(opt.id, index) : null">{{opt.name}}</button>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-check">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0">
                                                                <div class="form-group mb-0">
                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                    v-model="option.totalQuantityDiscount"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-3 cell-code-coupon py-2">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0">
                                                                <div class="row prd-search-box1 mx-0" :class="this.notEdit ? 'cursor-ban' : ''">
                                                                    <template v-if="option.receiveCategories?.length == 0 && !option.receiveSelectAllCategories">
                                                                        <div class="col-10 area_obj_selected d-flex align-items-center pl-0 text-gray font-weight-normal">{{$t('message.common.selectDiscountCategories')}}</div>
                                                                    </template>
                                                                    <template v-else>
                                                                        <div class="col-10 area_obj_selected p-0" v-if="!option.receiveSelectAllCategories">
                                                                            <template v-for="o in option.receiveCategories" :key="o.id">
                                                                                <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.group')}}: {{ o.name }}<i class="fa fa-times ml-2 text-gray" @click="!this.notEdit ? removeCategory(option.receiveCategories, o.id) : null"></i></div>
                                                                            </template>
                                                                        </div>
                                                                        <div class="col-10 area_obj_selected p-0" v-else>
                                                                            <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.all_categories')}}<i class="fa fa-times ml-2 text-gray" @click="!this.notEdit ? removeAllCategory(option, 'receiveCategories', option.receiveCategories, option.receiveSelectAllCategories) : null"></i></div>
                                                                        </div>
                                                                    </template>
                                                                    <div class="col-2 p-0 d-flex align-items-center justify-content-center">
                                                                        <button class="listIcon text-gray border-none" @click="!this.notEdit ? showSelectCategories('receiveCategories' + index, option.receiveCategories, option.receiveSelectAllCategories) : null">
                                                                            <i class="fa fa-list"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row m-0 mt-3">
                                                            <div class="col-12 p-0 h-100">
                                                                <ProductUnitsSearch :readonly="this.notEdit" @selectProductUnit="onSelectProductUnit" :label="'receiveProduct' + index" :normal="true" :placeholder="$t('message.common.selectProductDiscount')" :defaultValue="option.receiveProducts" :ref="'receiveProduct' + index"></ProductUnitsSearch>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-check pr-3">
                                                        <div class="flex-end align-items-center">
                                                            <i class="fa fa-trash-alt p-1" @click="!this.notEdit ? removeOption(this.optionsPromotionAllProductDiscount, index) : null"></i>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </template>

                                            <template v-if="this.optionsPromotionProductAllowQuantity?.length > 0 && this.filterQuery.promotionFromId === this.$constant.promotion_product_allow_quantity">
                                                <tr class="table-header" v-for="(option, index) in optionsPromotionProductAllowQuantity" :key="option.id">
                                                    <div class="form-group-promotion mx-4 mb-4">
                                                        <label class="form-label control-label text-bold">{{$t('message.common.whenBuy')}}</label>
                                                        <div class="form-wrap">
                                                            <div class="parent-row">
                                                                <div class="wrap-autocomplete">
                                                                    <div class="row m-0">
                                                                        <div class="col-6 p-0 py-2 d-flex align-items-end m-0" :class="this.notEdit ? 'cursor-ban' : ''">
                                                                            <div class="row prd-search-box1 mx-0">
                                                                                <template v-if="option.conditionCategories?.length == 0 && !option.conditionSelectAllCategories">
                                                                                    <div class="col-10 area_obj_selected d-flex align-items-center pl-0 text-gray font-weight-normal">{{$t('message.CouponIndex.modal.create_or_update.choose_categories')}}</div>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <div class="col-10 area_obj_selected p-0" v-if="!option.conditionSelectAllCategories">
                                                                                        <template v-for="o in option.conditionCategories" :key="o.id">
                                                                                            <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.group')}}: {{ o.name }}<i class="fa fa-times ml-2 text-gray" @click="!this.notEdit ? removeCategory(option.conditionCategories, o.id) : null"></i></div>
                                                                                        </template>
                                                                                    </div>
                                                                                    <div class="col-10 area_obj_selected p-0" v-else>
                                                                                        <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.all_categories')}}<i class="fa fa-times ml-2 text-gray" @click="!this.notEdit ? removeAllCategory(option, 'conditionCategories', option.conditionCategories, option.conditionSelectAllCategories) : null"></i></div>
                                                                                    </div>
                                                                                </template>
                                                                                <div class="col-2 p-0 d-flex align-items-center justify-content-center">
                                                                                    <button class="listIcon text-gray border-none" @click="!this.notEdit ? showSelectCategories('conditionCategories' + index, option.conditionCategories, option.conditionSelectAllCategories) : null">
                                                                                        <i class="fa fa-list"></i>
                                                                                    </button>
                                                                                    <div class="container-circle tooltip-top-center">
                                                                                        <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                                                                        <div class="content-circle font-weight-normal">
                                                                                            <span v-html="$t('message.common.noteSelectCategories')"></span>
                                                                                        </div>
                                                                                    </div> 
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6 p-2 d-flex align-items-end">
                                                                            <ProductUnitsSearch :readonly="this.notEdit" @selectProductUnit="onSelectProductUnit" :label="'conditionProduct' + index" :normal="true" :placeholder="$t('message.CouponIndex.modal.create_or_update.placeholder.products')" :defaultValue="option.conditionProducts" :ref="'conditionProduct' + index"></ProductUnitsSearch>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row m-0">
                                                                    <div class="col-12 p-0 mt-2">
                                                                        <i class="fa fa-trash-alt p-1" @click="!this.notEdit ? removeOption(this.optionsPromotionProductAllowQuantity, index) : null"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row-adv border-bottom-dotted-2 pb-3">
                                                                <div class="row m-0 mt-3" v-for="(optBuy, i) in option.whenBuying" :key="optBuy.id">
                                                                    <div class="col-3 p-0 d-flex m-0 align-items-center">
                                                                        <label class="text-bold">{{$t('message.common.fromQuantity')}}</label>
                                                                        <div class="input-location">
                                                                            <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" v-model="optBuy.quantity" class="input-custom min-w-100 mb-0 bg-transparent"/>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-2 p-0 d-flex m-0 align-items-end">
                                                                        <SelectSearch :modelValue="optBuy.discountBy" :disabled="this.notEdit" :readonly="true" :defaultOption="this.$constant.optionFormPromotionPrice" @update:modelValue="selectDiscountBy" :label="'discountBy' + index + '' +i"/>
                                                                    </div>
                                                                    <div class="col-4 py-0 d-flex m-0 align-items-center">
                                                                        <div class="row m-0">
                                                                            <div class="col-6 p-0">
                                                                                <div class="form-group mb-0" v-if="optBuy.discountType != this.$constant.discountTypePercent">
                                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                                    v-model="optBuy.discountValue"/>
                                                                                </div>
                                                                                <div class="form-group mb-0" v-else>
                                                                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                                                    v-model="optBuy.discountRatio" :max="100"/>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-6 d-flex align-items-center p-0 font-weight-normal" v-if="optBuy.discountBy != this.$constant.promotionByPriceId">
                                                                                <button type="button" class="btn btn-default ml-2 text-white" 
                                                                                v-for="opt in this.$constant.discountOptions" :key="opt.id" :class="optBuy.discountType == opt.id ? 'btn btn-save' : ''"
                                                                                @click="!this.notEdit ? changeDiscountType(opt.id, index, i) : null">{{opt.name}}</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-3 flex-end align-items-center p-0">
                                                                        <a class="remove-btn" @click="!this.notEdit ? removeOption(option.whenBuying, i) : null">
                                                                            <i class="fa fa-minus text-danger"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row-adv pb-3">
                                                                <div class="row m-0 mt-3">
                                                                    <div class="col-12 p-0 d-flex m-0 align-items-center text-primary font-weight-normal">
                                                                        <a href="javascript:void(0);" @click="!this.notEdit ? addChildConditional(option) : null" class="text-decoration-none">
                                                                            <i class="fa fa-plus mr-1"></i><span>
                                                                                {{$t('message.LocationIndex.addRow')}}
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade show active" id="homeTime" role="tabpanel" aria-labelledby="time-tab" v-if="isTab === $constant.tab.second">
                            <div class="row mx-4 my-4 pb-4 mt-1">
                                <div class="col-6 pl-0">
                                    <div class="row mb-3">
                                        <div class="col-3 d-flex align-items-center text-bold py-2">{{$t('message.common.time')}}</div>
                                        <div class="col-4 px-0 border-bottom-1 d-flex align-items-center">
                                            <SelectDateTimePicker :readonly="this.notEdit" :modelValue="promotionEntry.startDate" :placeholder="$t('message.HistoryIndex.from_date')" @update:modelValue="changeStartDate"/>
                                        </div>
                                        <div class="col-1 d-flex align-items-center justify-content-center p-0">{{$t('message.CouponIndex.modal.create_or_update.to')}}</div>
                                        <div class="col-4 px-0 border-bottom-1 d-flex align-items-center position-relative">
                                            <SelectDateTimePicker :readonly="this.notEdit" :modelValue="promotionEntry.endDate" :placeholder="$t('message.HistoryIndex.to_date')" @update:modelValue="changeEndDate"/>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-3 d-flex align-items-center text-bold">{{$t('message.DateRangePicker.byMonth')}}</div>
                                        <div class="col-9 p-0">
                                            <SelectLocationTime :readonly="this.notEdit" :modelValue="promotionEntry.monthly" :defaultOption="this.$constant.monthly" :placeholder="$t('message.DateRangePicker.byMonth')" :label="'monthly'" @update:modelValue="updateLocationTime"/>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-3 d-flex align-items-center text-bold">{{$t('message.DateRangePicker.byDay')}}</div>
                                        <div class="col-9 p-0">
                                            <SelectLocationTime :readonly="this.notEdit" :modelValue="promotionEntry.byDate" :defaultOption="this.$constant.byDate" :placeholder="$t('message.DateRangePicker.byDay')" :label="'byDate'" @update:modelValue="updateLocationTime"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 pr-0">
                                    <div class="row mb-3">
                                        <div class="col-3 d-flex align-items-center text-bold">{{$t('message.RevenueOriginal.dayOfWeek')}}</div>
                                        <div class="col-9 pl-0">
                                            <SelectLocationTime :readonly="this.notEdit" :modelValue="promotionEntry.dayOfWeek" :defaultOption="this.$constant.dayOfWeeks" :placeholder="$t('message.RevenueOriginal.dayOfWeek')" :label="'dayOfWeek'" @update:modelValue="updateLocationTime"/>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-3 d-flex align-items-center text-bold">{{$t('message.RevenueOriginal.hour')}}</div>
                                        <div class="col-9 pl-0">
                                            <SelectLocationTime :readonly="this.notEdit" :modelValue="promotionEntry.hours" :defaultOption="this.$constant.hours" :placeholder="$t('message.RevenueOriginal.hour')" :label="'hours'" @update:modelValue="updateLocationTime"/>
                                        </div>
                                    </div>
                                    <div class="row mb-3 pt-2">
                                        <div class="col-3 d-flex align-items-center text-bold">{{$t('message.common.otherOption')}}</div>
                                        <div class="col-9 pl-0 d-flex align-items-center">
                                            <label class="container-checkbox d-flex m-0" :class="this.notEdit ? 'cursor-ban' : ''">
                                                {{$t('message.common.warningWhenUsedPromotion')}}
                                                <div class="container-circle tooltip-right-center">
                                                    <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                                    <div class="content-circle font-weight-normal">
                                                        {{$t('message.common.warningWhenUsedPromotionNote')}}
                                                    </div>
                                                </div>
                                                <input type="checkbox" class="chkType" v-model="promotionEntry.other" :disabled="this.notEdit" :checked="!promotionEntry.other ? false : true">
                                                <span class="checkmark-checkbox"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade show active" id="homeLocation" role="tabpanel" aria-labelledby="location-tab" v-if="isTab === $constant.tab.third">
                            <div class="row mx-4 my-4 pb-2 mt-1">
                                <div class="col-12 pl-0">
                                    <div class="row mb-3">
                                        <div class="col-6">
                                            <div class="row mt-2">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <label class="container-radio m-0 flex-1 min-w-100 text-bold">{{$t('message.ModalFileUploadProducts.titleAllBranch')}}
                                                                <input type="radio" :disabled="this.notEdit" v-model="promotionEntry.isFullBranch" :value="$constant.statusCheckAll" @click="clearDataBranches()">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div class="col-8"></div>
                                                    </div>
                                                </div>
                                                <div class="col-12 mt-3">
                                                    <div class="row">
                                                        <div class="col-4 d-flex align-items-center">
                                                            <label class="container-radio m-0 flex-1 min-w-100 text-bold">{{$t('message.common.branch')}}
                                                                <input type="radio" :disabled="this.notEdit" v-model="promotionEntry.isFullBranch" :value="$constant.statusCheckAPart">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div class="col-8">
                                                            <q-select v-model="promotionEntry.listBranch" :selectAll="true" :readonly="promotionEntry.isFullBranch != $constant.statusCheckAPart  || this.notEdit" :defaultOptions="branches" :multiple="true" :placeholder="$t('message.ModalFileUploadProducts.titlePlaceHolderSelectBranch')" :cardSettingPrice="true"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="row mt-2">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <label class="container-radio m-0 flex-1 min-w-100 text-bold">{{$t('message.PriceListsIndex.modal.allCustomer')}}
                                                                <input type="radio" :disabled="this.notEdit" v-model="promotionEntry.isFullCustomer" :value="$constant.statusCheckAll" @click="clearDataCustomerGroup()">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div class="col-8"></div>
                                                    </div>
                                                </div>
                                                <div class="col-12 mt-3">
                                                    <div class="row">
                                                        <div class="col-4 d-flex align-items-center">
                                                            <label class="container-radio m-0 flex-1 min-w-100 text-bold pr-0">{{$t('message.PriceListsIndex.modal.customerGroup')}}
                                                                <input type="radio" :disabled="this.notEdit" v-model="promotionEntry.isFullCustomer" :value="$constant.statusCheckAPart">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div class="col-8">
                                                            <q-select v-model="promotionEntry.listCustomer" :selectAll="true" :readonly="promotionEntry.isFullCustomer != $constant.statusCheckAPart  || this.notEdit" :defaultOptions="customerGroups" :multiple="true" :placeholder="$t('message.PriceListsIndex.modal.chooseCustomerGroup')" :cardSettingPrice="true"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-6">
                                            <div class="row mt-2">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <label class="container-radio m-0 flex-1 min-w-100 text-bold">{{$t('message.common.allSeller')}}
                                                                <input type="radio" :disabled="this.notEdit" v-model="promotionEntry.isFullSeller" :value="$constant.statusCheckAll" @click="clearDataUserCreate()">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div class="col-8"></div>
                                                    </div>
                                                </div>
                                                <div class="col-12 mt-3">
                                                    <div class="row">
                                                        <div class="col-4 d-flex align-items-center">
                                                            <label class="container-radio m-0 flex-1 min-w-100 text-bold">{{$t('message.customerIndex.sellerName')}}
                                                                <input type="radio" :disabled="this.notEdit" v-model="promotionEntry.isFullSeller" :value="$constant.statusCheckAPart">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div class="col-8">
                                                            <q-select v-model="promotionEntry.listSeller" :selectAll="true" :readonly="promotionEntry.isFullSeller != $constant.statusCheckAPart  || this.notEdit" :defaultOptions="users" :multiple="true" :placeholder="$t('message.PriceListsIndex.modal.chooseUser')" :cardSettingPrice="true"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer  border-none d-block">
                    <div class="row m-0" v-if="this.isTab === $constant.tab.first">
                        <div class="col-12 flex-start p-0">
                            <button type="button" class="btn btn-white mr-2 border" @click="!this.notEdit ? addConditional() : null">
                                <i class="fa fa-plus mr-1"></i>
                                {{$t('message.common.addConditional')}}
                            </button>
                        </div>
                    </div>
                    <div class="row m-0">
                        <div class="col-12 flex-end p-0">
                            <button type="button" class="btn btn-save mr-2" @click="beforeSubmit">
                                <i class="fa fa-save mr-1"></i>
                                {{$t('message.button.save')}}
                            </button>
                            
                            <button type="button" class="btn btn-secondary text-white mr-2" @click="hide">
                                <i class="fa fa-ban mr-1"></i>
                                {{$t('message.button.skip')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalConfirm ref="ModalConfirm" :title="$t('message.VoucherIndex.modal.create_or_update.title_create')" :content="$t('message.VoucherIndex.modal.create_or_update.alert.content_confirm')" @confirm="confirmCreateVoucher"/>
    <SelectCategories ref="SelectCategories" @update:modelValue="updateListCategories" :label="labelCategories"/>
</template>
<script>
import SelectSearch from "@/components/SelectSearch.vue";
import InputNumberFormat from "@/components/forms/InputNumberFormat";
import {$post, $alert, cloneObject} from "@/lib/utils";
import ModalConfirm from "@/components/ModalConfirm";
import ProductUnitsSearch from "@/components/ProductUnitsSelectSearch";
import SelectCategories from "@/components/SelectCategories";
import moment from 'moment';
import store from "@/store";
import QSelect from "@/components/QSelect";
import SelectLocationTime from "@/components/SelectLocationTime";
import SelectDateTimePicker from "@/components/SelectDateTimePicker";
import InputDateTimePicker from "@/components/forms/InputDateTimePicker";
// import modal
import Pagination from "@/components/Pagination";
export default {
    name: "VoucherCreateUpdateModal",
    components: { SelectSearch, InputNumberFormat, QSelect, ModalConfirm, SelectCategories, 
        ProductUnitsSearch, Pagination, InputDateTimePicker, SelectDateTimePicker, SelectLocationTime},
    data () {
        const globalConfig = store.state.GlobalConfig;
        return {
            isEdit: false,
            isTab: this.$constant.tab.first,

            promotionEntry: {},
            optionPromotions: this.$constant.optionPromotions,
            filterQuery:{
                promotionById: this.$constant.promotionInvoiceId,
                promotionFromId: null,
            },
            optionFormPromotion: [],
            branches: globalConfig.branches,
            customerGroups: globalConfig.cusGroup,
            users: globalConfig.users,
            startDatePicker: null,
            startTimePicker: null,
            endDatePicker: null,
            endTimePicker: null,
            promotionUsageType: null,
            notEdit: false,
            optionsPromotionInvoiceDiscount: [
                {totalAmount: null, discountValue: null, discountRatio: null, discountType: this.$constant.discountTypeMoney}
            ],
            optionsPromotionInvoiceDonateProduct: [
                {totalAmount: null, totalGift: null, receiveCategories: [], receiveProducts: [], receiveSelectAllCategories: false}
            ],
            optionsPromotionInvoiceDiscountOnProduct: [
                {totalAmount: null, discountValue: null, discountRatio: null, discountType: this.$constant.discountTypeMoney, totalQuantityDiscount: null, receiveCategories: [], receiveProducts: [], receiveSelectAllCategories: false}
            ],
            optionsPromotionProductDiscount: [
                {totalBuyQuantity: null, conditionCategories: [], conditionProducts: [], discountValue: null, discountRatio: null, discountType: this.$constant.discountTypeMoney, conditionSelectAllCategories: false,
                    totalQuantityDiscount: null, receiveCategories: [], receiveProducts: [], receiveSelectAllCategories: false
                }
            ],
            optionsPromotionProductDonateProduct: [
                {totalBuyQuantity: null, conditionCategories: [], conditionProducts: [], conditionSelectAllCategories: false,
                    totalGift: null, receiveCategories: [], receiveProducts: [], receiveSelectAllCategories: false
                }
            ],
            optionsPromotionProductAllowQuantity: [
                {conditionCategories: [], conditionProducts: [], conditionSelectAllCategories: false, 
                    whenBuying: [{
                        id: 1,
                        quantity: null, 
                        discountBy: this.$constant.promotionByPriceId,
                        value: null,
                        discountValue: null,
                        discountRatio: null, discountType: this.$constant.discountTypeMoney
                    }]
                }
            ],
            optionsPromotionAllInvoiceDiscount: [
                {totalAmount: null, discountValue: null, discountRatio: null, discountType: this.$constant.discountTypeMoney, attachQuantity: null, conditionCategories: [], conditionProducts: [], conditionSelectAllCategories: false}
            ],
            optionsPromotionAllDonateProduct: [
                {totalAmount: null, attachQuantity: null, conditionCategories: [], conditionProducts: [], conditionSelectAllCategories: false,
                    totalGift: null, receiveCategories: [], receiveProducts: [], receiveSelectAllCategories: false
                }
            ],
            optionsPromotionAllProductDiscount: [
                {totalAmount: null, discountValue: null, discountRatio: null, discountType: this.$constant.discountTypeMoney, attachQuantity: null, conditionCategories: [], conditionProducts: [], conditionSelectAllCategories: false,
                    totalQuantityDiscount: null, receiveCategories: [], receiveProducts: [], receiveSelectAllCategories: false
                }
            ],
            labelCategories: null,
        }
    },
    created() {
    },
    methods: {
        show (data = null, type = null) {
            this.clearData();
            if (data) {
                this.type = type;
                this.isEdit = type === "copy" ? false : true;
                this.promotionEntry = cloneObject(data);
                this.promotionEntry.status = this.promotionEntry.statusOrigin;
                this.promotionEntry.code = !this.isEdit ? null : this.promotionEntry.code;
                this.promotionEntry.id = !this.isEdit ? null : this.promotionEntry.id;
                this.promotionEntry.idUpdate = this.promotionEntry.id ?? null;
                this.promotionEntry.other = this.promotionEntry.other ? true : false;
                this.promotionEntry.duplicateProduct = this.promotionEntry.duplicateProduct ? true : false;
                this.notEdit = (this.promotionEntry.notEdit && this.isEdit) ? true : false;
                this.filterQuery.promotionById = this.promotionEntry.promotionById;
                this.setDataUpdate();
                this.filterQuery.promotionFromId = this.promotionEntry.promotionFromId;
                switch (this.filterQuery.promotionFromId) {
                    case this.$constant.promotion_invoice_discount:
                        this.optionsPromotionInvoiceDiscount = this.setOptionPromotionUpdate(this.promotionEntry.promotion_options, this.optionsPromotionInvoiceDiscount);
                        break;
                    case this.$constant.promotion_invoice_donate_product:
                        this.optionsPromotionInvoiceDonateProduct = this.setOptionPromotionUpdate(this.promotionEntry.promotion_options, this.optionsPromotionInvoiceDonateProduct);
                        break;
                    case this.$constant.promotion_invoice_discount_on_product:
                        this.optionsPromotionInvoiceDiscountOnProduct = this.setOptionPromotionUpdate(this.promotionEntry.promotion_options, this.optionsPromotionInvoiceDiscountOnProduct);
                        break;
                    case this.$constant.promotion_invoice_donate_voucher:
                        console.log(1);
                        break;
                    case this.$constant.promotion_product_discount:
                        this.optionsPromotionProductDiscount = this.setOptionPromotionUpdate(this.promotionEntry.promotion_options, this.optionsPromotionProductDiscount);
                        break;
                    case this.$constant.promotion_product_donate_product:
                        this.optionsPromotionProductDonateProduct = this.setOptionPromotionUpdate(this.promotionEntry.promotion_options, this.optionsPromotionProductDonateProduct);
                        break;
                    case this.$constant.promotion_product_allow_quantity:
                        this.optionsPromotionProductAllowQuantity = this.setOptionPromotionUpdate(this.promotionEntry.promotion_options, this.optionsPromotionProductAllowQuantity);
                        break;
                    case this.$constant.promotion_product_donate_voucher:
                        console.log(2);
                        break;
                    case this.$constant.promotion_all_invoice_discount:
                    this.optionsPromotionAllInvoiceDiscount = this.setOptionPromotionUpdate(this.promotionEntry.promotion_options, this.optionsPromotionAllInvoiceDiscount);
                        break;
                    case this.$constant.promotion_all_donate_product:
                        this.optionsPromotionAllDonateProduct = this.setOptionPromotionUpdate(this.promotionEntry.promotion_options, this.optionsPromotionAllDonateProduct);
                        break;
                    case this.$constant.promotion_all_product_discount:
                        this.optionsPromotionAllProductDiscount = this.setOptionPromotionUpdate(this.promotionEntry.promotion_options, this.optionsPromotionAllProductDiscount);
                        break;
                }
            }
            window.jQuery(this.$refs.modal).modal('show');
        },
        setDataUpdate(){
            switch (this.filterQuery.promotionById) {
                case this.$constant.promotionInvoiceId:
                    this.optionFormPromotion = this.$constant.optionFormPromotionInvoice;
                    break;
                case this.$constant.promotionProductId:
                    this.optionFormPromotion = this.$constant.optionFormPromotionProduct;
                    break;
                case this.$constant.promotionAllId:
                    this.optionFormPromotion = this.$constant.optionFormPromotionAll;
                    break;
                default:
                    this.optionFormPromotion = [];
                    break;
            }
        },
        setOptionPromotionUpdate(options, defaultOption){
            options.forEach((option) => {
                Object.keys(option).forEach(key => {
                    if(key == 'condition_categories'){
                        if(option.condition_categories?.length > 0){
                            option.condition_categories.forEach(cate => {
                                if(cate.categoryId){
                                    cate.id = cate.categoryId;
                                }
                                if(cate.categoryName){
                                    cate.name = cate.categoryName;
                                }
                            })
                        }
                        let conditionCategoriesExist = Object.keys(defaultOption[0]).some(k => k === 'conditionCategories');
                        if(conditionCategoriesExist){
                            option['conditionCategories'] = option.condition_categories;
                        }
                    }
                    else if(key == 'condition_products'){
                        if(option.condition_products?.length > 0){
                            option.condition_products.forEach(prod => {
                                if(prod.product_unit){
                                    prod.productCode = prod.product_unit?.product?.code ?? null;
                                }
                            })
                        }
                        let conditionProductsExist = Object.keys(defaultOption[0]).some(k => k === 'conditionProducts');
                        if(conditionProductsExist){
                            option['conditionProducts'] = option.condition_products;
                        }
                    }
                    else if(key == 'receive_categories'){
                        if(option.receive_categories?.length > 0){
                            option.receive_categories.forEach(cate => {
                                if(cate.categoryId){
                                    cate.id = cate.categoryId;
                                }
                                if(cate.categoryName){
                                    cate.name = cate.categoryName;
                                }
                            })
                        }
                        let receiveCategoriesExist = Object.keys(defaultOption[0]).some(k => k === 'receiveCategories');
                        if(receiveCategoriesExist){
                            option['receiveCategories'] = option.receive_categories;
                        }
                    }
                    else if(key == 'receive_products'){
                        if(option.receive_products?.length > 0){
                            option.receive_products.forEach(prod => {
                                if(prod.product_unit){
                                    prod.productCode = prod.product_unit?.product?.code ?? null;
                                }
                            })
                        }
                        let receiveProductsExist = Object.keys(defaultOption[0]).some(k => k === 'receiveProducts');
                        if(receiveProductsExist){
                            option['receiveProducts'] = option.receive_products;
                        }
                    }
                    else if(key == 'isFullCategories'){
                        option['receiveSelectAllCategories'] = option.isFullCategories ? true : false;
                    }
                    else if(key == 'isFullCategoriesCondition'){
                        option['conditionSelectAllCategories'] = option.isFullCategoriesCondition ? true : false;
                    }
                    else if(key == 'discountValue'){
                        option['discountType'] = option.discountValue ? this.$constant.discountTypeMoney : this.$constant.discountTypePercent;
                    }
                    else if(key == 'discountRatio'){
                        option['discountType'] = !option.discountRatio ? this.$constant.discountTypeMoney : this.$constant.discountTypePercent;
                    }
                    let keyExist = Object.keys(defaultOption[0]).some(k => k === key);
                    if(!keyExist){
                        delete option[key];
                    }
                });
                
            });       
            return options;
        },
        hide () {
            this.clearData();
            window.jQuery(this.$refs.modal).modal('hide');
        },
        async doFilterSearch(v){
            switch (v.label) {
                case 'promotionById':
                    this.filterQuery.promotionById = v.model;
                    this.setFormPromotion(this.filterQuery.promotionById);
                    break;
                case 'promotionFromId':
                    this.filterQuery.promotionFromId = v.model;
                    break;
            }
            this.clearOptions();
        },
        selectDiscountBy(v){
            this.optionsPromotionProductAllowQuantity.forEach( (option, index) => {
                option.whenBuying.forEach( (o, i) => {
                    if(v.label == ('discountBy' + index + '' + i)){
                        o.discountBy = v.model
                    }
                })
            })
        },
        setFormPromotion(val){
            switch (val) {
                case this.$constant.promotionInvoiceId:
                    this.optionFormPromotion = this.$constant.optionFormPromotionInvoice;
                    this.filterQuery.promotionFromId = this.$constant.promotion_invoice_discount;
                    break;
                case this.$constant.promotionProductId:
                    this.optionFormPromotion = this.$constant.optionFormPromotionProduct;
                    this.filterQuery.promotionFromId = this.$constant.promotion_product_discount;
                    break;
                case this.$constant.promotionAllId:
                    this.optionFormPromotion = this.$constant.optionFormPromotionAll;
                    this.filterQuery.promotionFromId = this.$constant.promotion_all_invoice_discount;
                    break;
                default:
                    this.optionFormPromotion = [];
                    this.filterQuery.promotionFromId = null;
                    break;
            }
        },
        clearData(){
            this.promotionEntry = {
                status: this.$constant.statusActive,
                code: null,
                name: null,
                description: null,
                other: null,
                duplicateProduct: null,
                id: null,
                startDate: moment().format('YYYY-MM-DD hh:mm:ss'),
                endDate: moment().format('YYYY-MM-DD hh:mm:ss'),
                isFullBranch: this.$constant.statusCheckAll,
                isFullSeller: this.$constant.statusCheckAll,
                isFullCustomer: this.$constant.statusCheckAll,
                listBranch: [],
                listSeller: [],
                listCustomer: [],
                dayOfWeek: [],
                hours: [],
                monthly: [],
                byDate: [],
                listCondition: [],
                isActiveOfBirthDay: 1,
            };
            this.isTab = this.$constant.tab.first;
            this.filterQuery.promotionFromId = null;
            this.isEdit = false;
            this.notEdit = false;
            this.promotionUsageType = this.$constant.defaultPromotionUsageType;
            this.clearOptions();
            this.filterQuery.promotionById = this.$constant.promotionInvoiceId;
            this.setFormPromotion(this.filterQuery.promotionById);
        },
        choseTab(tab) {
            this.isTab = tab;
        },
        clearDataBranches(){
            this.promotionEntry.listBranch = [];
        },
        clearDataUserCreate(){
            this.promotionEntry.listSeller = [];
        },
        clearDataCustomerGroup(){
            this.promotionEntry.listCustomer = [];
        },
        changeStartDate(date){
            this.promotionEntry.startDate = date;
        },
        changeEndDate(date){
            this.promotionEntry.endDate = date;
        },
        changeLimitPromotionUsageType(o){
            this.promotionUsageType = o.id ?? null;
        },
        addConditional(){
            switch (this.filterQuery.promotionFromId) {
                case this.$constant.promotion_invoice_discount:
                    this.optionsPromotionInvoiceDiscount.push({totalAmount: null, discountValue: null, discountRatio: null, discountType: this.$constant.discountTypeMoney});
                    break;
                case this.$constant.promotion_invoice_donate_product:
                    this.optionsPromotionInvoiceDonateProduct.push({totalAmount: null, totalGift: null, receiveCategories: [], receiveProducts: [], receiveSelectAllCategories: false});
                    break;
                case this.$constant.promotion_invoice_discount_on_product:
                    this.optionsPromotionInvoiceDiscountOnProduct.push({totalAmount: null, discountValue: null, discountRatio: null, discountType: this.$constant.discountTypeMoney, totalQuantityDiscount: null, receiveCategories: [], receiveProducts: [], receiveSelectAllCategories: false});
                    break;
                case this.$constant.promotion_invoice_donate_voucher:                    
                    break;
                case this.$constant.promotion_product_discount:
                    this.optionsPromotionProductDiscount.push(
                        {totalBuyQuantity: null, conditionCategories: [], conditionProducts: [], discountValue: null, discountRatio: null, discountType: this.$constant.discountTypeMoney, conditionSelectAllCategories: false,
                            totalQuantityDiscount: null, receiveCategories: [], receiveProducts: [], receiveSelectAllCategories: false
                        }
                    );
                    break;
                case this.$constant.promotion_product_donate_product:
                    this.optionsPromotionProductDonateProduct.push(
                        {totalBuyQuantity: null, conditionCategories: [], conditionProducts: [], conditionSelectAllCategories: false,
                            totalGift: null, receiveCategories: [], receiveProducts: [], receiveSelectAllCategories: false
                        }
                    );
                    break;
                case this.$constant.promotion_product_allow_quantity:
                    this.optionsPromotionProductAllowQuantity.push(
                        {conditionCategories: [], conditionProducts: [], conditionSelectAllCategories: false, 
                            whenBuying: [{
                                id: 1,
                                quantity: null, 
                                discountBy: this.$constant.promotionByPriceId,
                                value: null,
                                discountValue: null,
                                discountRatio: null, discountType: this.$constant.discountTypeMoney
                            }]
                        }
                    );
                    break;
                case this.$constant.promotion_product_donate_voucher:                    
                    break;
                case this.$constant.promotion_all_invoice_discount:
                    this.optionsPromotionAllInvoiceDiscount.push(
                        {totalAmount: null, discountValue: null, discountRatio: null, discountType: this.$constant.discountTypeMoney, attachQuantity: null, conditionCategories: [], conditionProducts: [], conditionSelectAllCategories: false}
                    );
                    break;
                case this.$constant.promotion_all_donate_product:
                    this.optionsPromotionAllDonateProduct.push(
                        {totalAmount: null, attachQuantity: null, conditionCategories: [], conditionProducts: [], conditionSelectAllCategories: false,
                            totalGift: null, receiveCategories: [], receiveProducts: [], receiveSelectAllCategories: false
                        }
                    );
                    break;
                case this.$constant.promotion_all_product_discount:
                    this.optionsPromotionAllProductDiscount.push(
                        {totalAmount: null, discountValue: null, discountRatio: null, discountType: this.$constant.discountTypeMoney, attachQuantity: null, conditionCategories: [], conditionProducts: [], conditionSelectAllCategories: false,
                            totalQuantityDiscount: null, receiveCategories: [], receiveProducts: [], receiveSelectAllCategories: false
                        }
                    );
                    break;
            }
            
        },
        addChildConditional(option){
            switch (this.filterQuery.promotionFromId) {
                case this.$constant.promotion_product_allow_quantity:
                    var maxId = option.whenBuying.reduce((acc, value) => {
                        return (acc = acc > value.id ? acc : value.id);
                    }, 0);
                    option.whenBuying.push(
                        {
                            id: maxId + 1,
                            quantity: null, 
                            discountBy: this.$constant.promotionByPriceId,
                            value: null,
                            discountValue: null,
                            discountRatio: null, discountType: this.$constant.discountTypeMoney
                        }
                    );
                    break;
            }
        },
        clearOptions(){
            // Hóa đơn được giảm giá hóa đơn
            this.optionsPromotionInvoiceDiscount = this.filterQuery.promotionFromId != this.$constant.promotion_invoice_discount ? [{totalAmount: null, discountValue: null, discountRatio: null, discountType: this.$constant.discountTypeMoney}] : this.optionsPromotionInvoiceDiscount;
            // Hóa đơn được tặng hàng
            this.optionsPromotionInvoiceDonateProduct = this.filterQuery.promotionFromId != this.$constant.promotion_invoice_donate_product ? [{totalAmount: null, totalGift: null, receiveCategories: [], receiveProducts: [], receiveSelectAllCategories: false}] : this.optionsPromotionInvoiceDonateProduct;
            // Hóa đơn được giảm giá hàng
            this.optionsPromotionInvoiceDiscountOnProduct = this.filterQuery.promotionFromId != this.$constant.promotion_invoice_discount_on_product ? [{totalAmount: null, discountValue: null, discountRatio: null, discountType: this.$constant.discountTypeMoney, totalQuantityDiscount: null, receiveCategories: [], receiveProducts: [], receiveSelectAllCategories: false}] : this.optionsPromotionInvoiceDiscountOnProduct;
            // Khi mua hàng được giảm giá hàng
            this.optionsPromotionProductDiscount = this.filterQuery.promotionFromId != this.$constant.promotion_product_discount ? [{totalBuyQuantity: null, conditionCategories: [], conditionProducts: [], discountValue: null, discountRatio: null, discountType: this.$constant.discountTypeMoney, conditionSelectAllCategories: false, totalQuantityDiscount: null, receiveCategories: [], receiveProducts: [], receiveSelectAllCategories: false}] : this.optionsPromotionProductDiscount;
            // Khi mua hàng được tặng hàng
            this.optionsPromotionProductDonateProduct = this.filterQuery.promotionFromId != this.$constant.promotion_product_donate_product ? [{totalBuyQuantity: null, conditionCategories: [], conditionProducts: [], conditionSelectAllCategories: false, totalGift: null, receiveCategories: [], receiveProducts: [], receiveSelectAllCategories: false}] : this.optionsPromotionProductDonateProduct;
            // Khi mua hàng giảm giá theo số lượng mua
            this.optionsPromotionProductAllowQuantity = this.filterQuery.promotionFromId != this.$constant.promotion_product_allow_quantity ? [{conditionCategories: [], conditionProducts: [], conditionSelectAllCategories: false, whenBuying: [{id: 1, quantity: null, discountBy: this.$constant.promotionByPriceId, value: null, discountValue: null, discountRatio: null, discountType: this.$constant.discountTypeMoney }] }] : this.optionsPromotionProductAllowQuantity;
            // Hóa đơn và hàng hóa: giảm giá hóa đơn
            this.optionsPromotionAllInvoiceDiscount = this.filterQuery.promotionFromId != this.$constant.promotion_all_invoice_discount ? [{totalAmount: null, discountValue: null, discountRatio: null, discountType: this.$constant.discountTypeMoney, attachQuantity: null, conditionCategories: [], conditionProducts: [], conditionSelectAllCategories: false}] : this.optionsPromotionAllInvoiceDiscount;
            // Hóa đơn và hàng hóa: được tặng hàng
            this.optionsPromotionAllDonateProduct = this.filterQuery.promotionFromId != this.$constant.promotion_all_donate_product ? [{totalAmount: null, attachQuantity: null, conditionCategories: [], conditionProducts: [], conditionSelectAllCategories: false, totalGift: null, receiveCategories: [], receiveProducts: [], receiveSelectAllCategories: false}] : this.optionsPromotionAllDonateProduct;
            // Hóa đơn và hàng hóa: được giảm giá hàng
            this.optionsPromotionAllProductDiscount = this.filterQuery.promotionFromId != this.$constant.promotion_all_product_discount ? [{totalAmount: null, discountValue: null, discountRatio: null, discountType: this.$constant.discountTypeMoney, attachQuantity: null, conditionCategories: [], conditionProducts: [], conditionSelectAllCategories: false, totalQuantityDiscount: null, receiveCategories: [], receiveProducts: [], receiveSelectAllCategories: false}] : this.optionsPromotionAllProductDiscount;
        },
        changeDiscountType(id, index, childIndex){
            switch (this.filterQuery.promotionFromId) {
                case this.$constant.promotion_invoice_discount:
                    this.optionsPromotionInvoiceDiscount.forEach((o, i) => {
                        if(i == index){
                            o.discountType = id;
                            o.discountValue = o.discountRatio = 0;
                            return;
                        }
                    });
                    break;
                case this.$constant.promotion_invoice_discount_on_product:
                    this.optionsPromotionInvoiceDiscountOnProduct.forEach((o, i) => {
                        if(i == index){
                            o.discountType = id;
                            o.discountValue = o.discountRatio = 0;
                            return;
                        }
                    });
                    break;
                case this.$constant.promotion_product_discount:
                    this.optionsPromotionProductDiscount.forEach((o, i) => {
                        if(i == index){
                            o.discountType = id;
                            o.discountValue = o.discountRatio = 0;
                            return;
                        }
                    });
                    break;
                case this.$constant.promotion_all_invoice_discount:
                    this.optionsPromotionAllInvoiceDiscount.forEach((o, i) => {
                        if(i == index){
                            o.discountType = id;
                            o.discountValue = o.discountRatio = 0;
                            return;
                        }
                    });
                    break;
                case this.$constant.promotion_all_product_discount:
                    this.optionsPromotionAllProductDiscount.forEach((o, i) => {
                        if(i == index){
                            o.discountType = id;
                            o.discountValue = o.discountRatio = 0;
                            return;
                        }
                    });
                    break;
                case this.$constant.promotion_product_allow_quantity:
                    this.optionsPromotionProductAllowQuantity.forEach((option, ind) => {
                        if(ind == index){
                            option.whenBuying.forEach((o, i) => {
                                if(i == childIndex){
                                    o.discountType = id;
                                    o.discountValue = o.discountRatio = 0;
                                    return;
                                }
                            });
                        }
                    });
                    break;
            }
        },
        removeOption(options, index){
            options.splice(index, 1);
        },
        showSelectCategories(label, data = [], statusCheckedAll) {
            this.labelCategories = label;
            let arrayData = [];
            if(Array.isArray(data)){
                data.forEach(cate => {
                    if(cate.id){
                        arrayData.push(cate.id);
                    }else{
                        arrayData.push(cate);
                    }
                });
            }
            this.$refs.SelectCategories.show(arrayData, statusCheckedAll);
        },
        updateListCategories(value){
            switch (this.filterQuery.promotionFromId) {
                case this.$constant.promotion_invoice_donate_product:
                    this.setValueCategories(this.optionsPromotionInvoiceDonateProduct, value);
                    break;
                case this.$constant.promotion_invoice_discount_on_product:
                    this.setValueCategories(this.optionsPromotionInvoiceDiscountOnProduct, value);
                    break;
                case this.$constant.promotion_product_discount:
                    this.setValueCategories(this.optionsPromotionProductDiscount, value);
                    break;
                case this.$constant.promotion_product_donate_product:
                    this.setValueCategories(this.optionsPromotionProductDonateProduct, value);
                    break;
                case this.$constant.promotion_product_allow_quantity:
                    this.setValueCategories(this.optionsPromotionProductAllowQuantity, value);
                    break;
                case this.$constant.promotion_all_invoice_discount:
                    this.setValueCategories(this.optionsPromotionAllInvoiceDiscount, value);
                    break;
                case this.$constant.promotion_all_donate_product:
                    this.setValueCategories(this.optionsPromotionAllDonateProduct, value);
                    break;
                case this.$constant.promotion_all_product_discount:
                    this.setValueCategories(this.optionsPromotionAllProductDiscount, value);
                    break;
            }
        },
        removeCategory(option, id){
            option.splice(option.findIndex(v => v.id == id), 1);
        },
        removeAllCategory(option, label, o, selectAll){
            if(label == 'conditionCategories'){
                option.conditionCategories = [];
                option.conditionSelectAllCategories = false; 
            }else if(label == 'receiveCategories'){
                option.receiveCategories = [];
                option.receiveSelectAllCategories = false;
            }
        },
        setValueCategories(options, value){
            options.forEach( (option, index) => {
                if(value.label && option.receiveCategories && value.label == ('receiveCategories' + index)){
                    option.receiveCategories = value.data;
                    option.receiveSelectAllCategories = value.selectAll;
                }
                if(value.label && option.conditionCategories && value.label == ('conditionCategories' + index)){
                    option.conditionCategories = value.data
                    option.conditionSelectAllCategories = value.selectAll;
                }
            })
        },
        async onSelectProductUnit(value){
            
        },
        updateLocationTime(v){
            if(v.label && v.data){
                switch (v.label) {
                    case 'hours':
                        this.promotionEntry.hours = v.data;
                        break;
                    case 'dayOfWeek':
                        this.promotionEntry.dayOfWeek = v.data;
                        break;
                    case 'byDate':
                        this.promotionEntry.byDate = v.data;
                        break;
                    case 'monthly':
                        this.promotionEntry.monthly = v.data;
                        break;
                }
            }
        },
        beforeSubmit(){
            switch (this.filterQuery.promotionFromId) {
                case this.$constant.promotion_invoice_discount:
                    this.promotionEntry.listCondition = this.optionsPromotionInvoiceDiscount;
                    this.submit();
                    break;
                case this.$constant.promotion_invoice_donate_product:
                    this.promotionEntry.listCondition = this.optionsPromotionInvoiceDonateProduct;
                    this.submit();
                    break;
                case this.$constant.promotion_invoice_discount_on_product:
                    this.promotionEntry.listCondition = this.optionsPromotionInvoiceDiscountOnProduct;
                    this.submit();
                    break;
                case this.$constant.promotion_invoice_donate_voucher:
                    console.log(0);
                    break;
                case this.$constant.promotion_product_discount:
                    this.promotionEntry.listCondition = this.optionsPromotionProductDiscount;
                    this.submit();
                    break;
                case this.$constant.promotion_product_donate_product:
                    this.promotionEntry.listCondition = this.optionsPromotionProductDonateProduct;
                    this.submit();
                    break;
                case this.$constant.promotion_product_allow_quantity:
                    this.promotionEntry.listCondition = this.optionsPromotionProductAllowQuantity;
                    this.submit();
                    break;
                case this.$constant.promotion_product_donate_voucher:
                    console.log(0);
                    break;
                case this.$constant.promotion_all_invoice_discount:
                    this.promotionEntry.listCondition = this.optionsPromotionAllInvoiceDiscount;
                    this.submit();
                    break;
                case this.$constant.promotion_all_donate_product:
                    this.promotionEntry.listCondition = this.optionsPromotionAllDonateProduct;
                    this.submit();
                    break;
                case this.$constant.promotion_all_product_discount:
                    this.promotionEntry.listCondition = this.optionsPromotionAllProductDiscount;
                    this.submit();
                    break;
            }
        },
        async submit(){
            this.promotionEntry.promotionById = this.filterQuery.promotionById;
            this.promotionEntry.promotionFromId = this.filterQuery.promotionFromId;
            if(this.promotionEntry.listCondition?.length <= 0){
                $alert({code:422, message: this.$t('message.common.emptyConditionPromotion')});
                return;
            }
            // Tách hàm do phân quyền hai chức năng thêm mới và update khác nhau
            let strSave = '';
            if(this.promotionEntry.id) {
                strSave = '/v1/promotions/update'
            }
            else {
                strSave = '/v1/promotions/store'
            }
            let res = await $post(strSave, {...this.promotionEntry});
            if(res.status && res.status.code == 200){
                $alert({code:200, message: !this.isEdit ? this.$t('message.common.addPromotionSuccess') : this.$t('message.common.updatePromotionSuccess')});
                this.$emit('update');
                this.hide();
            }else if (res.code === 422) {
                let errors = res.messages.errors;
                Object.keys(errors).forEach(function(key) {
                    $alert({code: res.code, message: errors[key][0]});
                });
            }
        }
    },
}
</script>
<style scoped lang="scss">
@import "../../assets/css/vars.scss";
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}

@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

.table thead th #modalChecked .modal-header {
    border-bottom: 0 !important;
}
.modal-header {
    border-bottom: none !important;
}

.custom__list-tab {
    justify-content: flex-start;
    align-items: end;
    padding-bottom: 8px;
}
.tab__active {
    border-bottom: 2px solid $base-color !important;
}
.tab__item {
    cursor: pointer;
    padding: 0 15px;
    border-bottom: 2px solid #f8f9fa;
}

.input:focus-visible {
    border: none !important;
}

.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.fa-pencil-alt{
    position: absolute;
    left: 10px;
    bottom: 10px;
}
.currencyUnit{
    position: absolute;
    right: 0;
    top: 20%;
}
.fa-search{
    position: absolute;
    right: 0;
    top: 40%;
}
.table-header th{
    height: 40px !important;
}
.table-header-search{
    height: 50px !important;
    background-color: #FEFCED;
}
.cell-check{
    width: 100px;
    min-width: 100px;
    max-width: 100px;
}
.cell-code-coupon{
    width: 232px;
    min-width: 232px;
    max-width: 232px;
}
.cell-release-coupon{
    width: 190px;
    min-width: 190px;
    max-width: 190px;
}
.cell-auto{
    min-width: 160px;
}
.cell-status-coupon{
    width: 150px;
    min-width: 150px;
    max-width: 150px;
}

.listIcon{
    outline: none !important;
    background: none !important;
    height: 20px;
    width: 25px;
}
.listIcon:hover{
    background-color: #e5e5e5 !important;
    outline: 0;
    box-shadow: none;
    border-radius: 50%;
}
.cate_selected{
    background: #e5e5e5 !important;
    min-height: 30px !important;
    width:fit-content !important;
    font-weight: 400!important;
}
.area_obj_selected{
    max-height: 100px !important;
    min-height: 35px !important;
    overflow-y: auto;
}
.area_obj_selected::-webkit-scrollbar {
  display: none;
}

.area_obj_selected {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.dropdown-menu-content{
    width: 100vh;
    max-height: 20rem;
    max-width: 12rem;
    overflow: auto;
    z-index: 1000;
    transform: translate3d(15px, 0px, 0px) !important;
    background: white;
    position: absolute;
}
.fa-times:hover{
    background-color: #e5e5e5;
    box-shadow: none;
    border-radius: 999px;
}
.dropdown-toggle::after {
    position: absolute;
    right: 15px;
    top: 50% !important;
}
.container-circle {
  position: relative;
}

.icon-circle-custom {
  cursor: pointer;
}

.content-circle {
  display: none;
  position: absolute;
  font-size: 13px;
  width: 310px;
  border: 1px solid $base-color;
  padding: 5px;
  background-color: #ffffff;
  top: -7px;
  left: 40px;
  z-index: 1;
  border-radius: 5px;
}

.content-circle:before {
  content: "";
  border-style: solid;
  border-width: 10px 15px 10px 0;
  border-color: transparent $base-color transparent transparent;
  position: absolute;
  left: -16px;
}

/* .icon-circle-custom:hover+.content-circle {
  display: block;
} */
.inputDate_picker, .inputDate_picker:focus, .inputDate_picker:active{
    width: 90%;
    border: none;
    position: absolute;
    outline: none;
}
.time_picker{
    position: absolute;
    right: 0px;
}
input[type="date"]:focus, input[type="date"]:active{
    outline: none;
    border: none;
}
.inputText_picker{
    width: 85% !important;
    z-index: 1;
}
.fa-trash-alt:hover{
    background-color: #ccc;
    border-radius: 999px ;
}
.form-group-promotion {
    min-height: 30px;
    padding: 10px 20px 10px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #d7d7d7;
    -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.15);
    -moz-box-shadow: 0 5px 10px rgba(0,0,0,.15);
    box-shadow: 0 5px 10px rgba(0,0,0,.15);
    margin-bottom: 0;
}
.form-group-promotion .form-label {
    width: 130px;
    float: left;
    cursor: default;
    padding: 0 0.5rem 0;
    line-height: 1.8rem;
    min-height: 1.8rem;
    word-break: break-word;
}
.form-group-promotion .form-wrap {
    margin-left: 15rem;
}
.form-group-promotion .form-wrap .parent-row {
    position: relative;
    display: flex;
    justify-content: space-between;
}
.wrap-autocomplete{
    min-width: 450px;
}
.remove-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}
.remove-btn:hover{
    background-color: #e47885;
    border-color: #e47885;
}
.remove-btn:hover i{
    color: #fff !important;
}
.input-location{
    width: 50%;
    max-width: 50%;
    min-width: 50%;
}
.prd-search-box1:hover, .prd-search-box1:active {
    border-bottom: 1px solid #ccc !important;
}
.dropdown-toggle-limitPromotion:after{
    display: none;
}
</style>