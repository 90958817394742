<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog" role="document" style="max-width: 1140px">
            <div class="modal-content">
                <div class="modal-header px-4 border-bottom-0 pb-0">
                    <h6 class="modal-title" id="exampleModalLabel">
                        {{$t('message.common.listPromotion')}}
                    </h6>
                    <button type="button" class="close" @click="hide()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body px-4 pt-0">
                    <div class="mapping-grid-container">
                        <div class="setting-mapping-grid">
                            <div class="table-content">
                                <div class="table-content-head">
                                    <table class="table mb-0" >
                                        <thead>
                                            <tr>
                                                <th scope="col" class="cell-check"></th>
                                                <th scope="col" class="cell-name">
                                                    {{$t('message.common.promotions')}}
                                                </th>
                                                <th scope="col" class="cell-name">
                                                    {{$t('message.common.formOfPromotion')}}
                                                </th>
                                                <th scope="col" class="cell-name text-right">
                                                    {{$t('message.DetailOrdersCard.promotion')}}
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                <div class="table-content-body">
                                    <table class="table mb-0" >
                                        <tbody v-if="promotions && promotions.length">
                                            <template v-for="(promotion, index) in promotions" :key="promotion.id">
                                                <tr v-if="!promotion.promotion_options.whenBuying">
                                                    <td class="cell-check pt-0"> 
                                                        <label class="container-radio m-0 flex-1 min-w-100">
                                                            <input class="form-check-input" type="radio" v-model="promotionOptionId" @click="changeCheckPromotionOptionId(promotion.promotionOptionId)" :value="promotion.promotionOptionId" name="flexRadioDefault">
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </td>
                                                    <td class="cell-name">{{ promotion.name }}</td>
                                                    <td class="cell-name">{{ getNameByLocale(promotion.promotion_from) }}</td>
                                                    
                                                    <td class="cell-name text-right" v-if="promotion.promotion_options" :key="index">
                                                        <span v-if="(promotion.promotion_options.receiveCategoriesIds && promotion.promotion_options.receiveCategoriesIds.length) || promotion.promotion_options.isFullCategories == $constant.full || (promotion.promotion_options.receiveProductUnitIds && promotion.promotion_options.receiveProductUnitIds.length)">
                                                            <button class="btn btn-sm btn-outline-secondary p-1" :disabled="promotion.promotionOptionId != promotionOptionId" @click="getProductsInPromotion(promotion.promotion_options.receiveProductUnitIds, promotion.promotion_options.receiveCategoriesIds, promotion.promotion_options.isFullCategories, promotion)">
                                                                {{$t('message.common.selectPromotionItems')}}
                                                            </button>
                                                        </span>
                                                        <span v-else-if="promotion.promotion_options.discountRatio"> {{ promotion.promotion_options.discountRatio }} {{$t('message.common.percent')}}</span>
                                                        <span v-else-if="promotion.promotion_options.discountValue && promotion.promotion_options.discountValue != $constant.priceZero"> {{ $filters.formatPrice(promotion.promotion_options.discountValue)}} {{$t('message.common.vnd')}}</span>
                                                    </td>
                                                </tr>

                                                <tr v-if="promotion.promotion_options.whenBuying && promotion.promotion_options.whenBuying.discountValue">
                                                    <td class="cell-check pt-0"> 
                                                        <label class="container-radio m-0 flex-1 min-w-100">

                                                            <input class="form-check-input" type="radio" v-model="promotionOptionId" @click="changeCheckPromotionOptionId(promotion.promotionOptionId)" :value="promotion.promotionOptionId" name="flexRadioDefault">
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </td>
                                                    <td class="cell-name">{{ promotion.name }}</td>
                                                    <td class="cell-name">{{ getNameByLocale(promotion.promotion_from) }}</td>
                                                    
                                                    <td class="cell-name text-right" v-if="promotion.promotion_options" :key="index">
                                                        <span>
                                                            {{$t('message.common.buy')}} 
                                                            {{ n(promotion.promotion_options.whenBuying.quantity) }}  
                                                            {{ promotion.promotion_options.whenBuying.discountBy == this.$constant.promotionByPriceId ? $t('message.ProductUnitSearch.price') : $t('message.DetailOrdersCard.prodDiscount') }} 
                                                            {{ n(promotion.promotion_options.whenBuying.discountValue) }}
                                                       </span>
                                                    </td>
                                                </tr>

                                                <tr v-if="promotion.promotion_options.whenBuying && promotion.promotion_options.whenBuying.discountRatio">
                                                    <td class="cell-check pt-0"> 
                                                        <label class="container-radio m-0 flex-1 min-w-100">
                                                            <input class="form-check-input" type="radio" v-model="promotionOptionId" @click="changeCheckPromotionOptionId(promotion.promotionOptionId)" :value="promotion.promotionOptionId" name="flexRadioDefault">
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </td>
                                                    <td class="cell-name">{{ promotion.name }}</td>
                                                    <td class="cell-name">{{ getNameByLocale(promotion.promotion_from) }}</td>
                                                    
                                                    <td class="cell-name text-right" v-if="promotion.promotion_options" :key="index">
                                                        <span>
                                                            {{$t('message.common.buy')}} 
                                                            {{ n(promotion.promotion_options.whenBuying.quantity) }}  
                                                            {{$t('message.DetailOrdersCard.prodDiscount')}} 
                                                            {{ n(promotion.promotion_options.whenBuying.discountRatio) }} 
                                                            {{$t('message.common.percent')}}
                                                       </span>
                                                    </td>
                                                </tr>

                                                <template v-if="promotion.receiveProductUnit">
                                                    <tr v-for="productUnit in promotion.receiveProductUnit" :key="productUnit.id" class="bg-light">
                                                        <td class="cell-check pt-0"> 
                                                        </td>
                                                        <td class="cell-name" :colspan="2">
                                                            <i class="fa fa-trash-alt text-gray mr-4" @click="removeReceiveProductUnit(productUnit.id, promotion.receiveProductUnit)"></i>
                                                            <span>
                                                                {{ productUnit.product?.code + ' - ' + productUnit.product?.name + ' - ' + productUnit.unitName}}
                                                            </span>
                                                        </td>
                                                        <td class="cell-name text-right">
                                                            <InputNumberFormat v-model="productUnit.quantity" :max="productUnit.maxQuantity" @update:modelValue="changeMaxQuantityReceiveProduct(promotion)" class="sale-input-number mb-0" :showAdjust="true" :bgTransparent="true" :min="0"></InputNumberFormat>
                                                        </td>
                                                    </tr>
                                                </template>                
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-top-0 pb-4 px-4">
                    <button type="button" class="btn btn-white border-1 px-4 py-2" @click="hide()">
                        <span class="text-omi">
                            {{$t('message.button.skip')}}
                        </span>
                    </button>
                    <button type="button" class="btn btn-primary px-4 py-2" @click="save()">
                        <span>
                            {{$t('message.PriceListsIndex.modal.apply')}}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <ModalShowProductList ref="ModalShowProductList" @onSelectProductActive="onSelectProductActive"/>
</template>

<script>
    import TabInfo from "../../views/customers/tabs/TabInfo";
    import {$post, $alert} from "../../lib/utils";
    import ModalShowProductList from "./ModalShowProductList.vue";
    import InputNumberFormat from "@/components/forms/InputNumberFormat";

    export default {
        name: "ModalPromotionInvoiceList",
        emits: ['created', 'choosePromotion'],
        components: {ModalShowProductList, InputNumberFormat},
        data() {
            return {
                promotions: [],
                promotionActive: null,
                productActive: null,
                promotionOptionId: null,
                noCheckExistPromotion: true,
            }
        },
        watch: {
            promotionOptionId(newValue) {
                if (!newValue) {
                    return;
                }
                this.promotionActive = this.promotions.find(promotion => promotion.promotionOptionId == newValue);
            },
        },
        methods: {
            changeCheckPromotionOptionId(promotionOptionId) {
                if (promotionOptionId && promotionOptionId == this.promotionOptionId) {
                    this.promotionActive = null;
                    this.productActive = null;
                    this.promotionOptionId = null;
                }
            },
            show(promotions, promotionOptionId = null, noCheckExistPromotion = true) {
                this.clearData();
                if (promotions) {
                    this.promotions = promotions;
                }
                if (promotionOptionId) {
                    this.promotionOptionId = promotionOptionId;
                    this.promotionActive = this.promotions.find(promotion => promotion.promotionOptionId == this.promotionOptionId);
                }
                this.noCheckExistPromotion = noCheckExistPromotion;
                window.jQuery(this.$refs.modal).modal('show');
            },
            hide(){
                window.jQuery(this.$refs.modal).modal('hide');
            },
            clearData(){
                this.promotions = [];
                this.promotionActive = {
                    receiveProductUnit: null,
                };
                this.productActive = null;
                this.promotionOptionId = null;
                this.noCheckExistPromotion = true;
            },
            save() {
                if(!this.noCheckExistPromotion){
                    if(this.promotionOptionId){
                        $alert({code: 500, message: this.$t('message.common.cannotMergePromotion')});
                        return;
                    }
                    window.jQuery(this.$refs.modal).modal('hide');
                    return;
                }
                this.promotionActive = this.promotions.find(promotion => promotion.promotionOptionId == this.promotionOptionId);
                if (this.promotionActive && this.promotionActive.promotionFromId && this.$constant.promotionOptionIdsNeedProduct.includes(this.promotionActive.promotionFromId) && 
                (!this.promotionActive.receiveProductUnit || !this.promotionActive.receiveProductUnit.length)) 
                {
                    $alert({code: 500, message: this.$t('message.common.haveNotSelectProduct')});
                    return;
                }
                if (this.promotionActive && this.promotionActive.promotionFromId && this.$constant.promotionOptionIdsNeedProduct.includes(this.promotionActive.promotionFromId)) {
                    let notExistQuantity = false;
                    for (let index = 0; index < this.promotionActive.receiveProductUnit.length; index++) {
                        const product = this.promotionActive.receiveProductUnit[index];
                        if (product.quantity) {
                            notExistQuantity = true;
                        }
                    }
                    if (!notExistQuantity) {
                        $alert({code: 500, message: this.$t('message.common.selectProductQuantity')});
                        return;
                    }
                }
                this.$emit('choosePromotion', this.promotionActive);
                window.jQuery(this.$refs.modal).modal('hide');
            },
            async getProductsInPromotion(productUnitIds, categoriesIds, isFullCategories) {
                this.$refs.ModalShowProductList.show(productUnitIds, categoriesIds, isFullCategories);
            },
            async onSelectProductActive(productUnits) {
                this.promotionActive.receiveProductUnit = productUnits;
            },
            changePromotion(id){
                if(this.promotionActive && this.promotionOptionId == id){
                    this.promotionActive = null;
                    this.promotionOptionId = null;
                }else{
                    this.promotionOptionId = id
                }
            },
            removeReceiveProductUnit(id, arrays){
                arrays.splice(arrays.findIndex(v => v.id == id), 1);
            },
            changeMaxQuantityReceiveProduct(promotion){
                let totalQuantity = 0;
                promotion.receiveProductUnit.forEach(prm => {
                    promotion.receiveProductUnit.forEach(prm1 => {
                        if(prm.id != prm1.id){
                            totalQuantity += (prm1.quantity ? +prm1.quantity : 0);
                        }
                    })

                    if (promotion.duplicateProduct) {
                        if(promotion.promotion_options.totalGift){
                            let curr = Math.floor((promotion.quantityOrder / promotion.promotion_options.totalBuyQuantity)) * promotion.promotion_options.totalGift;
                            prm.maxQuantity = (+curr - +totalQuantity) < 0 ? 0 : ((+curr - +totalQuantity) > curr ? +curr : +curr - +totalQuantity) ;
                        }else if(promotion.promotion_options.totalQuantityDiscount){
                            let curr =  Math.floor((promotion.quantityOrder / promotion.promotion_options.totalBuyQuantity)) * promotion.promotion_options.totalQuantityDiscount;
                            prm.maxQuantity = (+curr - +totalQuantity) < 0 ? 0 : ((+curr - +totalQuantity) > curr ? +curr : +curr - +totalQuantity) ;
                        }
                        totalQuantity = 0;
                    }else {
                        if(promotion.promotion_options.totalGift){
                            prm.maxQuantity = (+promotion.promotion_options.totalGift - +totalQuantity) < 0 ? 0 : ((+promotion.promotion_options.totalGift - +totalQuantity) > promotion.promotion_options.totalGift ? +promotion.promotion_options.totalGift : +promotion.promotion_options.totalGift - +totalQuantity) ;
                        }else if(promotion.promotion_options.totalQuantityDiscount){
                            prm.maxQuantity = (+promotion.promotion_options.totalQuantityDiscount - +totalQuantity) < 0 ? 0 : ((+promotion.promotion_options.totalQuantityDiscount - +totalQuantity) > promotion.promotion_options.totalQuantityDiscount ? +promotion.promotion_options.totalQuantityDiscount : +promotion.promotion_options.totalQuantityDiscount - +totalQuantity) ;
                        }
                        totalQuantity = 0;
                    }
                });
            },
        },
        beforeUnmount() {
            this.hide();
        },
        unmounted() {
            this.hide();
        }
    }
</script>

<style scoped>
.mapping-grid-container {
    position: relative;
}

.setting-mapping-grid {
    flex: 1;
    margin-top: 1rem;
}
.table-content-head{
    background-color: #2fa1a1;
    border: 1px solid #d7d9de;
    border-bottom: none !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.table-content-body{
    background-color: #fff;
    border: 1px solid #d7d9de;
    border-top: none !important;
    max-height: 400px;
    overflow: auto;
}
.table thead tr th{
    color: #fff !important;
}
.table thead tr th, tbody tr td{
    border-top: none !important;
    border-bottom: none !important;
}
.table tbody tr td{
    border-top: 1px solid #d7d9de !important;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.cell-check{
    width: 30px;
    min-width: 30px !important;
    max-width: 30px !important;
}
.cell-name{
    width: 30%;
    min-width: 30% !important;
    max-width: 30% !important;
}
.btn-minus, .btn-add{
    background-color: #ccc !important;
    width: 25px;
    height: 25px;
    border-radius: 50% !important;
    border: none;
    outline: none;
}
</style>
