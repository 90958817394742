const messages = {
    ja: {
        message: {
            // Header + common
            headers: {
                branch: "支店",
                setupShop: "店舗設置",
                print_template_manage: "印刷物の管理",
                branch_manage: "支店の管理",
                promotion_manage: "プロモーションの管理",
                coupon_manage: "クーポンの管理",
                voucher_manage: "バウチャーの管理",
                role_manage: "役割管理",
                permission_manage: "権限管理",
                history: "操作履歴",
                email_template_manage: "メールテンプレートの管理",
                logout: "ログアウト",
                dashboard: "概観",
                list: "カテゴリー",
                products: "商品",
                product_list: "商品リスト",
                categories: "商品グループ",
                units: "商品単位",
                customers: "顧客",
                customer_list: "顧客リスト",
                customer_group: "顧客グループ",
                suppliers: "サプライヤー",
                supplier_list: "サプライヤーリスト",
                supplier_group: "サプライヤーグループ",
                employees: "スタッフ",
                employee_list: "スタッフリスト",
                departments: "部門",
                transporters: "運送業者",
                sale_channels: "販売チャネル",
                price_list: "価格表",
                setting_price: "価格設定",
                price_list_detail: "価格表の詳細",
                location_list: "場所のディレクトリ",
                transaction: "引取",
                purchase_products: "商品を購入",
                plan_purchase_order: "入荷予測",
                inventory_plan: "予測の詳細",
                plan_purchase: "購入予測",
                order_suppliers: "仕入注文",
                purchase_orders: "購入領収書",
                return_purchase_orders: "返品",
                settlement_advances: "仮払い決済",
                sales: "販売",
                buy: "購入",
                orders: "注文",
                invoices: "請求書",
                returns: "返品",
                request_refund: "商品返品依頼",
                warehouse_transfer: "転送",
                transfers: "発送",
                requests: "リクエスト",
                locations: "場所",
                ticket_import_location: "商品を位置に置く",
                ticket_export_location: "商品を位置から取り除く",
                ticket_transfer_location: "位置を移動する",
                cash_book: "総勘定元帳",
                cash_book_money: "総勘定現金",
                bank_book: "総勘定銀行",
                reports: "報告",
                sell_online: "オンライン販売する",
                delivery: "配送",
                ReportSaleDetails: "詳細な売上元帳",
                ReportSaleChannel: "販売チャネル別売上報告書",
                ReportSaleEmployee: "営業担当者別売上報告書",
                ReportSaleBranch: "営業支店別売上報告書",
                ReportRevenueProduct: "時間経過に伴う製品/製品グループの売上詳細分析",
                ReportRevenueInBranch: "支店別製品/製品グループ売上詳細分析",
                ReportRevenuePriceList: "価格リストに基づく製品/製品グループの売上詳細分析",
                ReportRevenueCustomerGroup: "顧客グループ別製品/製品グループの売上詳細分析",
                ReportPurchaseDetail: "詳細な仕入れ元帳",
                ReportStockDetail: "商品詳細元帳",
                ReportInventories: "在庫報告書",
                ReportInventoriesDetail: "在庫詳細報告書",
                ReportValueBranch: "在庫評価報告書",
                ReportConsignment: "賞味期限切れ在庫報告書",
                ReportStockLocation: "在庫場所報告書",
                ReportStockLocationConsignment: "在庫ロットと場所の報告書",
                manage: "管理"
            },
            button: {
                confirm: "同意",
                skip: "スキップ",
                cancel: "キャンセル",
                save: "保存",
                remove: "削除",
                removeFilter: "更新",
                action: "オプション",
                import:{
                    name: "インポート",                    
                    selectFile:"データファイルを選択",
                    action:"アップロード",
                },
                export: {
                    name: "エクスポート",
                },
                copy: {
                    name: "コピー",
    
                },
                print: {
                    name: "印刷",
                },
                sendMailText: "メールを送る",                
                export_all: "概要リストファイルをエクスポート",
                export_detail: "詳細リストファイルをエクスポート",
                file_all: "概要ファイル",
                file_detail: "詳細ファイル",
                invoiceAdd: "請求書を作成",
                close: "閉じる",
                choose: "選択",
                saveDraft: "ドラフト保存",
                addInvoice: "新しい請求書を追加",
                addOrder: "新規注文を追加",
                selectInvoiceForReturn: "請求書を選択",
                returnFast: "迅速な返品",
                finished: "終了",
                detail: "詳細",
            },
            common: {
                quantityVoucherNoti: "バウチャーの数量: ",
                quantityPromotionNoti: "プロモーションプログラムの数量: ",
                quantityCouponNoti: "クーポンの数量: ",
                quantityProductNoti: "商品の数量: ",
                notFound: "検索結果が存在しません",
                notData: "データなし",
                branch: "支店",
                numberRecord: "記録",
                placeholder: {
                    branch: "支店を選択"
                },
                time: "時間",
                status: "状態",
                created_by: "クリエイター",
                male: "男性",
                female: "女性",
                inValidFormatFile: "アップロードされたファイルは正しいxlsx、xls形式である必要があります",
                inValidFormatTime: "時間の形式が正しくありません",
                vnd: "ドン",
                percent: "%",
                choose: "選択",
                chooseImage: "画像選択",
                upload: "アップロード",
                download: "ダウンロード",
                up: "アップ",
                uploadFromUrl: "URLから画像をアップロード",
                chooseFile: "ライブラリからファイルを選択",
                manageFile: "ファイル管理",
                enterUrl: "URL入力",
                chooseUrl: "URL選択してください",
                maxThreeImage: "画像を3枚まで選択してください",
                chooseImagePlease: "画像を選択してください",
                maxNumberImage: "画像を{number}まで選択してください",
                import: "輸入",
                export: "輸出",
                date: "日",
                downloadDefaultFile: "サンプルファイルをダウンロード",
                excelFile: "Excelファイル",
                have: "あり",
                selected: "選択された",
                image: "画像",
                errorServer: "サーバーエラー",
                confirm: "確認",
                confirmCancelChange: "変更をキャンセルしますか?",
                product: "商品",
                applyFormulaForAll: "価格表にすでに価格がある製品に数式を適用しますか?",
                draft: "下書き",
                planPurchase: "在庫予測",
                planDetail: "予測",
                existProduct: "商品は既に存在します！",
                existPlanDetail: "詳細見積書が存在します！",
                searchProduct: "製品コードまたは商品名で検索",
                searchPlanDetail: "詳細見積もりコードまたは名前で検索",
                service: "サービス",
                combo: "コンボ",
                productValue: "商品の価値",
                all: "すべて",
                active: "活性",
                inActive: "非活性",
                province: "省/市",
                district: "区/郡",
                ward: "区/町",
                effect: "有効期限",
                expire: "有効期限が切れる",
                createdAt: "作成日",
                statusCancel: "キャンセル済",
                statusReturned: "返品済",
                unpaid: "未払い",
                paid: "支払いを完了",
                partialPayment: "一部支払い",
                ticket: "phiếu",
                STT: "順番",
                searchSupplier: "サプライヤーを検索",
                autocompleteCode: "自動伝票コード",
                cash: "現金",
                card: "カード",
                transferPayment: "振込",
                none: "なし",
                received: "受領済",
                notReceived: "未受領",
                choosePrintTemplate: "印刷テンプレートを選択",
                receiverDefault: "デフォルト受取人",
                sendToGroup: "グループに",
                chooseZaloTemplate: "Zaloテンプレートを選択",
                sendZNS: "ZNS送信",
                cc: "CC",
                searchOrderSupplier: "注文書を検索",
                createPurchaseOrderSuccess: "新しい購入受領書を作成した",
                updatePurchaseOrderSuccess: "新しい購入受領書を更新した",
                emptyBatch: "商品{name}はロットを選択していません",
                invalidPurchaseQuantity: "商品{name}の数量は注文数量を超えることはできません",
                invalidQuantity: "商品{name}の数量はゼロ以下にすることはできません",
                isEmptyPO: "購入受領書は空",
                havePODraft: "1つの発注書には1つの一時入荷書しか存在できません",
                haveReturnPODraft: "購入領収書には一時的な下書き返品が一つしかできません",
                confirmChangePo: "この注文書は保存されていません。別のものに変更しますか？",
                searchPO: "購入受領書コードで検索",
                invalidReturnPOQuantity: "商品{name}の返品数量は入荷数量を超えることはできません。",
                createReturnPOSuccess: "新しい返品を正常に作成した",
                updateReturnPOSuccess: "新しい返品を正常に更新した",
                saleModeFast: "即売",
                saleModeTransfer: "配送販売",
                returned: "請求書は支払済",
                confirmEndOrder: "注文コード {code} から請求書を作成しました。未回収の製品を処理せずに注文を終了しますか？",
                processOrder: "処理中",
                orderNote: "注文メモに従って",
                confirmCloseTab: "{tab} の情報は保存されません。本当に閉じますか？",
                stamp: "ラベル",
                returnQuantity: "返品数量",
                shortQuantity: "数量",
                orderQuantity: "注文数量",
                displayHSCode: "HSコードを表示",
                displayTax: "税率を表示",
                promotionInvoice: "請求書プロモーション",
                discountProduct: "商品の割引",
                noProduct: "商品がなし",
                businessMan: "営業スタッフ",
                selectPriceBook: "価格表を選択",
                totalCostPrice: "購入商品の原価合計",
                KM: "プロモーション",
                couponCode: "クーポンコード",
                voucherCode: "バウチャーコード",
                returnCustomer: "全額返金",
                customerPays: "顧客が支払う",
                ExcessMoneyPaidByCustomer: "顧客への余剰金",
                ExcessMoneyReturnedToCustomers: "顧客への余剰金",
                RemainingUnpaid: "未払い",
                ExpectedDelivery: "納期",
                changeInforInvoice: "電子請求書情報の変更",
                soldByName: "購入者の氏名",
                enterSoldByName: "購入者の氏名を入力",
                enterUnitName: "会社名を入力",
                noteForDriver: "ドライバーへの注意事項",
                collection: "代金引換",
                cm: "cm",
                searchTransporter: "配送パートナーを検索",
                normal: "通常",
                fast: "速達",
                duringTheDay: "即日",
                linkToGHN: "速達配送とリンクする必要があります",
                linkToAhaMove: "Ahamoveとリンクする必要があります",
                linkToViettelPost: "ViettelPostとリンクする必要があります",
                loginNow: "今すぐログイン",
                deliveryPromotion: "配送プロモーション",
                theSenderPaysTheFee: "発送者が料金を支払います",
                paymentViaApp: "アプリを通じた支払い",
                heavyThings: "大型商品",
                declarePrice: "商品の価格を申告",
                maximumDeclaredValue: "申告できる最大価値",
                million: "百万",
                sendSMS: "受取人にSMSを送信",
                fragile: "壊れやすい商品",
                atThePostOffice: "郵便局で商品を送る",
                cod: "COD",
                cannotBeSmaller: "以下にすることはできません",
                cannotBeLarger: "以上にすることはできません",
                descriptionDetail: "詳細な説明",
                canSell: "販売可能",
                totalCustomerOrder: "顧客注文合計",
                listPromotion: "プロモーションリスト",
                promotions: "プロモーションプログラム",
                formOfPromotion: "プロモーションの形式",
                selectPromotionItems: "プロモーション商品/ギフトを選択",
                buy: "購入",
                selectProductPromotion: "プロモーション商品を選択",
                searchProductPromotion: "プロモーション商品を検索",
                haveNotSelectProduct: "商品が選択していません",
                cannotMergePromotion: "プロモーションの併用はできませんので、これ以上選択しないでください",
                selectProductQuantity: "商品の数量を選択してください",
                appliedPromotionOfCustomer: "この顧客には以下のプロモーションプログラムが適用されています。",
                confirmApplyContinue: "そのプロモーションを引き続き適用しますか？",
                loginAhamove: "Ahamove ログイン",
                vietnam: "VN",
                apiKey: "API Key",
                enterApiKey: "APIコードを入力",
                newAddress: "新しい住所",
                showProduct: "商品の確認を許可",
                notShowProduct: "商品の確認を不許可",
                notEnoughInventory: "製品{name}の在庫が不足",
                emptyOrder: "注文が空",
                cannotApplyVoucher: "クーポンコードが存在しないか、適用できる条件を満たしていません",
                usedVoucher: "クーポンが使用済",
                cannotApplyVouchers: "1回の発行で複数のクーポンコードを適用しないでください",
                cannotApplyCoupon: "クーポンコードが存在しないか、適用できる条件を満たしていません",
                confirmPayment: "顧客情報が入力されていません。システムは小売顧客との借金を追跡しません。支払いを行いたいですか？",
                notEnoughMoney: "支払いが不十分",
                emptyInvoice: "請求書が空",
                selectWeightProduct: "重量の単位を選択してください",
                notExceed30Kg: "重量は30kgを超えことはできません",
                notExceed10Million: "代引きは1,000万を超えることはできません",
                cannotLessThan0: "代引きは0以下にすることはできません。",
                notExceed5Million: "注文金額は500万を超えることはできません",
                notExceed150Cm: "サイズは150cmを超えることはできません",
                addInvoiceSuccess: "請求書を正常に作成しました",
                emptyReturn: "返品票が空",
                addOrderSuccess: "注文を正常に作成しました",
                updateOrderSuccess: "注文を正常に更新しました",
                updateInvoiceSuccess: "請求書を正常に更新しました",
                confirmReturn: "顧客情報が入力されていません。システムは小売顧客との借金を追跡していません。商品を返品しますか？",
                quantityReturnGreaterThan0: "返品する商品の数量は0以上である必要があります",
                addReturnSuccess: "返品を正常に作成しました",
                notEnoughInventorySystem: "販売数量が在庫を超えています！",
                first: "最初",
                last: "最後",
                netRevenue: "純収益に基づいて",
                accordingToQuantity: "数量に基づいて",
                selectCoupon: "クーポンを選択",
                selectCouponCode: "クーポンコードを選択",
                usedQuantity: "使用数量",
                remainingQuantity: "残りの数量",
                fromQuantity: "数量の範囲",
                quantityStartFrom1: "数値は1から始まる必要があります",
                inValidQuantity: "無効な数量",
                orderCode: "注文コード",
                orderDate: "注文日",
                addOrderWhenNotEnoughInventory: "在庫切れの場合でも注文が許可されます",
                sellWhenOrdered: "商品が注文されると販売・転送",
                loginMisaTitle: "電子請求書の接続を設定",
                loginMisa: "MISA ログイン",
                inValidInforLogin: "ログイン情報が正しくありません",
                loginMisaSuccess: "MISAにログインしました",
                existMisaAccount: "システムにはすでにMISAアカウントがあります",
                level: "制限",
                maxDebt: "顧客が負債できる最大金額",
                warningWhenTransaction: "顧客が指定金額を超えて負債がある場合、システムは警告または取引をブロックします。",
                numberOfDebtDate: "支払期日を過ぎた日数",
                maxNumberOfDebtDate: "顧客が負債できる最大日数",
                warningWhenTransaction2: "顧客が指定された期間を超えて負債がある場合、システムは警告または取引をブロックします",
                dayFrom: "からの日数",
                addDebt: "顧客が制限を超えてもっと多くの負債を持つことを許可する",
                addDebtTooltip: "取引により顧客の負債が制限を超える場合（例：請求書の作成、支払いクーポンの作成など）、システムはさらなる取引をブロックします。",
                addDebtNote: "注意：システムはオフライン取引の同期、電子商取引プラットフォームからの取引の同期、代金引換の請求書、請求書のキャンセル、支払い伝票のキャンセル時にブロックしません。",
                updatePromotion: "プロモーションプログラムを更新",
                addPromotion: "プロモーションプログラムを追加",
                addConditional: "条件を追加",
                back: "戻る",
                cancelChange: "変更をキャンセル",
                enterBatch: "ロットと賞味期限を入力",
                addBatch: "ロットと賞味期限を更新",
                selectBatch: "ロット選択",
                addBatchFor: "ロットを追加",
                batch: "ロット",
                selectBatchExpire: "ロットと賞味期限を選択",
                searchBatch: "バッチと賞味期限を検索",
                notFoundBatch: "一致するロットが見つかりません",
                quantityNotGreaterThan: "Số lượng không được lớn hơn",
                emptyProductBatch: "ロットをまだ入力していません",
                inValidExpire: "無効な賞味期限",
                exprieNotLessThanToday: "賞味期限は現在の日付より前にすることはできません",
                existBatch: "このロットはすでに存在します",
                uploadDate: "アップロード日",
                fileName: "ファイル名",
                fileExtension: "形式",
                purchaseDate: "購入日",
                searchInventoryPlan: "商品名または詳細見積もりコードで商品を検索する",
                searchInvoiceCode: "請求書コードを検索する",
                selectBranches: "支店を選択してください",
                addAttribute: "バリエーションを作成する",
                attributeName: "属性名",
                addAttributeSuccess: "属性が正常に作成されました",
                addDeliverySuccess: "配送注文が正常に作成されました",
                bank: "銀行",
                totalRecords: "レコードの合計",
                numberNewRecords: "新しいレコード",
                numberUpdatedRecords: "更新されたレコード",
                numberErrorRecords: "エラーレコード",
                noPermission: "このページにアクセスできません",
                backToHome: "ホームページに戻る",
                needPermission: "アクセスするための許可が必要",
                selectCategories: "商品グループを選択する",
                productQuantities: "商品の数量",
                action: "操作",
                select: "オプション",
                addNewGroup: "新しいグループを作成する",
                searchReturnPO: "インポートされた返品を検索する",
                searchBAS: "BAS検索",
                inValidEmail: "メールの形式が正しくありません",
                exist: "既に存在します",
                max20Attribute: "最大値は20の属性",
                removeAllSelected: "すべて選択を削除する",
                major: "操作",
                setting: "設定",
                printTemplate: "印刷テンプレート",
                atBranch: "支店で",
                cancelCouponError: "未発行のクーポンのみをキャンセルできます。次のクーポンは無効",
                confirmCancelCoupon: "選択したクーポンリストを解放してもよろしいですか？",
                cancelCouponSuccess: "クーポンのキャンセルが成功しました",
                cancelCouponFailed: "クーポンのキャンセルが失敗しました",
                addCoupons: "クーポンリストを追加する",
                quantityCoupon: "クーポン数量",
                quantityCouponWillCreate: "生成されたクーポンコードの数",
                couponCodeLength: "コードの長さ",
                couponCodeLengthNote: "クーポンの長さは8文字未満または15文字を超えることはできません",
                reduce: "減少",
                forInvoice: "請求書用",
                removeCoupon: "クーポン発行を削除",
                confirmRemoveCoupon: "システムはこのクーポン発行を完全に削除します。実行しますか？",
                removeCouponSuccess: "クーポン発行が正常に削除されました",
                removeCouponFailed: "クーポンが発行されたか使用されています。削除することはできません",
                releaseCouponError: "未発行のクーポンのみ発行できます。次のクーポンは無効です",
                confirmReleaseCoupon: "選択したクーポンリストを発行してもよろしいですか？",
                releaseCouponSuccess: "クーポンが正常に発行されました",
                releaseCouponFailed: "クーポンの発行に失敗しました",
                fullname: "フルネーム",
                addressDetail: "詳細な住所",
                home: "自宅",
                enterGroupName: "グループ名を入力",
                processingUpload: "ファイルのアップロード処理",
                uploadFile: "ファイルをアップロード",
                row: "行",
                uploadFileSuccess: "アップロードが成功しました",
                cancelDeliverySuccess: "配送伝票のキャンセルが成功しました",
                cancelDeliveryFailed: "注文の状態はキャンセルできません",
                updateBASSuccess: "BAS {code} が正常に更新されました",
                updateBASFailed: "BAS {code} の更新に失敗しました。",
                cancelBASFailed: "BASのキャンセルに失敗しました",
                removeBASSuccess: "BASが正常に削除されました",
                removeBASFailed: "BASの削除に失敗しました",
                confirmRemoveBAS: "BAS {code} を削除してもよろしいですか？",
                confirmCancelBAS: "BAS {code} をキャンセルしてもよろしいですか？",
                notFoundTicket: "見つかりません",
                totalQuantityOrder: "合計数量",
                reportCode: "レポートコード",
                confirmCancelInvoice: "請求書 {code} をキャンセルしてもよろしいですか？",
                cancelInvoiceSuccess: "請求書{code}が正常にキャンセルされました",
                cancelInvoiceFailed: "請求書{code}のキャンセルに失敗しました",
                uploadFileInvoice: "データファイルから請求書を作成する",
                delivery: "配送",
                firstCharacterInvoice: "請求書コードは常に 'HD' で始まります。入力しない場合、システムが自動的に追加します。これにより上記の原則が確実に守られます。",
                maxLengthInvoiceUpload: "システムでは一度に最大1,000行のインポートが可能",
                ensureInventoryWhenUpdload: "商品の在庫が依然として十分であることを確認してください",
                completeOrder: "注文を完了する",
                confirmCompleteOrder: "注文 {code} を完了しますか？",
                completeOrderSuccess: "「注文が正常に完了しました",
                updateInvoiceFailed: "請求書の更新に失敗しました",
                confirmCancelOrderSupplier: "購入注文 {code} をキャンセルしてもよろしいですか？",
                cancelOrderSupplierSuccess: "購入注文{code}が正常にキャンセルされました",
                cancelOrderSupplierFailed: "購入注文 {code} のキャンセルに失敗しました",
                closeOrderSupplier: "まだ未完了の在庫がある場合でも、購入注文の処理を継続することはできません。購入注文を終了してもよろしいですか？",
                closeOrderSupplierSuccess: "購入注文 {code} を正常に完了しました",
                closeOrderSupplierFailed: "購入注文 {code} の完了に失敗しました。",
                emptyUploadFile: "アップロードされたファイルにレコードがありません",
                selectMethodDiscount: "割引方法を選択",
                priceDiscount: "割引",
                methodDiscount: "割引方法",
                confirmCancelOrder: "注文 {code} をキャンセルしてもよろしいですか？",
                cancelOrderSuccess: "注文{code}が正常にキャンセルされました",
                cancelOrderFailed: "注文{code}のキャンセルに失敗しました",
                selectStatus: "状態を選択",
                maxLengthProcessOrder: "システムでは最大で10件の注文の処理しか許可されていません", 
                inValidExpectedDateOfTransfer: "見積もり配送日は今日より前にすることはできません",
                updateOrderFailed: "注文書 {code} の更新に失敗しました",
                updateOrderSuccessfully: "注文書 {code} の更新が成功しました",
                roleName: "役割名",
                confirmCancelUpdateRole: "役割の更新をキャンセルしますか？",
                confirmCancelAddRole: "新しい役割の追加をキャンセルしますか？",
                emptyPermission: "権限を選択していません",
                permission: "分権化",
                role: "役割",
                searchEmployee: "コード、従業員名、ログイン名によって",
                perrmissionUser: "ユーザーに権限を割り当てる",
                roles: "役割リスト",
                addRole: "新しい役割を追加する",
                searchRole: "役割を検索",
                searchBranch: "支店を検索",
                assignRole: "追加の役割を割り当てる",
                confirmLogoutUser: "ユーザー {name} がシステムを使用している場合、役割を更新するために即座にログアウトされます。更新を続行しますか？",
                emptyBranchRole: "権限のために支店と役割を選択してください",
                duplicateBranchRole: "役割と支店データが重複しています",
                addPermissionSuccess: "新しい権限を正常に追加しました",
                addPermissionFailed: "新しい権限の追加に失敗しました",
                updatePermissionSuccess: "権限の更新に成功しました",
                updatePermissionFailed: "権限の更新に失敗しました",
                selectBranchRole: "行 {row}：役割と支店を選択してください",
                selectBranchPermission: "行 {row}：支店を選択してください",
                selectRolePermission: "行 {row}：役割を選択してください",
                confirmCopyProduct: "この商品をコピーしてもよろしいですか？",
                copyProductSuccess: "商品のコピーが成功しました",
                emptyProductSelected: "まだ商品が選択されていません",
                paper3: "3ラベルロール紙のサンプル（ラベル用紙サイズ 104x22mm / 4.2 x 0.9インチ）",
                paper12: "12ラベルの紙サンプル（Tomy 103ラベル印刷用紙サイズ 202x162mm）",
                paper2: "2ラベルの紙ロールサンプル（ラベル印刷用紙サイズ 72x22mm）",
                paper65: "65ラベルの紙サンプル（A4サイズ Tomy 145ラベル印刷用紙サイズ）",
                paper2_1: "2ラベルの紙ロールサンプル（ラベル印刷用紙サイズ 74x22mm）",
                paperJewelry: "ジュエリーラベルのサンプル（ラベル用紙サイズ 75x10mm / 2.75インチ x 0.4インチ）",
                printNote: "注意：印刷されたバーコードが読み取れない場合は、より大きな用紙フォーマットを使用するか、商品コードを短くしてください。",
                noPrintPrice: "価格の印刷なし",
                priceWithVND: "価格にはドンが含まれています",
                priceNonVND: "価格にはドンが含まれていません",
                priceWithUnit: "価格には単位が含まれています",
                priceNonUnit: "価格には単位が含まれていません",
                printShopName: "店舗名を印刷する",
                noPrintShopName: "店舗名を印刷していません",
                inventorySystem: "システム在庫",
                inventory: "実際の在庫",
                existAttribute: "属性は既に存在します",
                removeVarriant: "属性を削除する",
                removeUnit: "単位を削除する",
                productInformation: "商品情報",
                quantityTransfer: "転送数量",
                quantityReceive: "受け取る数量",
                productSelected: "選択された商品",
                inActiveProduct: "商品コード {code} の取引を停止しますか？",
                reActiveProduct: "商品コード {code} の取引を許可しますか？",
                keepInforationInventory: "在庫情報と取引履歴は引き続き保持しています",
                discontinued: "関連する変換された商品は廃止されます",
                customer: "顧客ターゲット",
                applyBranch: "適用可能な支店",
                allBraches: "すべての支店",
                promotionBy: "プロモーションに従って",
                selectPromotionType: "プロモーションタイプを選択してください",
                invoiceWithProduct: "請求書と商品",
                giveProduct: "商品を寄付する",
                giveVoucher: "クーポン券を配布する",
                buyProductDiscountProduct: "割引商品を購入する",
                buyProductGiveProduct: "購入して無料の商品を手に入れる",
                priceByQuantity: "購入数量に応じた価格",
                buyProductGiveVoucher: "購入してクーポンを受け取る",
                removePromotion: "プロモーションを削除する",
                startTime: "開始時間",
                endTime: "終了時間",
                promotionInformation: "プロモーション情報",
                form: "フォーム",
                productByQuantityBuy: "購入数量に応じて贈り物が増えます",
                productByQuantityBuyNote: "例えば、2枚の男性用Tシャツを購入すると、靴下1足が無料で付いてきます",
                productByQuantityBuyNote2: "購入数量に応じて倍にしない：たとえば、4枚のTシャツを購入しても、靴下1足しか無料ではありません。",
                productByQuantityBuyNote3: "購入数量に応じて倍にします：たとえば、4枚のTシャツを購入すると、靴下が2足無料で付いてきます。",
                promotionValue: "プロモーションの価値",
                totalGift: "合計寄付額",
                giftGroup: "ギフトグループ",
                giftProduct: "ギフト商品",
                categoriesAreDiscounted: "割引商品グループ",
                productsAreDiscounted: "商品が割引されています",
                purchaseProductCategories: "購入された商品/商品グループ",
                discountedProductCategories: "商品/商品グループが割引されています",
                withGiftCategories: "購入した製品グループに添付されています",
                withGiftProduct: "購入に含まれる",
                withPurchaseProductCategories: "購入した商品/商品グループを含む",
                giftProductCategories: "ギフト商品/商品グループ",
                selectGiftCategories: "ギフトグループを選択",
                noteSelectCategories: "商品コードリストをコピーして貼り付けて商品リストを追加できます。商品コードは 'Enter' もしくは ',' で区切ってください。<br>例： <br>SP000123<br>SP000125<br>または: SP000123, SP000125",
                selectGiftProduct: "ギフトアイテムを選択",
                selectDiscountCategories: "割引商品グループを選択",
                otherOption: "その他のオプション",
                warningWhenUsedPromotion: "このプロモーションを利用した顧客に警告します",
                warningWhenUsedPromotionNote: "自動的にプロモーションは適用されません。もし顧客が以前にプロモーションを利用した場合、次回そのプロモーションが適用される際には、販売画面に警告が表示されます",
                allSeller: "すべての販売者",
                emptyConditionPromotion: "プロモーションタイプ：プロモーション条件が追加されていません",
                addPromotionSuccess: "プロモーションを追加するのが成功した",
                updatePromotionSuccess: "プロモーションが正常に更新されました",
                removePromotions: "プロモーションを削除した",
                confirmRemovePromotion: "{code} プロモーションを削除してもよろしいですか？",
                searchPromotion: "コード名、プログラム名",
                historyPromotionOrder: "プロモーション履歴 - 注文",
                historyPromotionInvoice: "プロモーション履歴 - 請求書",
                totalAmountFrom: "商品の総コスト",
                quantityBuy: "購入数量",
                quantityDiscount: "減少数量",
                quantityGift: "寄付数量",
                pricePromotion: "プロモーション価格",
                confirmCancelPO: "商品受領票{code}をキャンセルしてもよろしいですか？",
                cancelPOSuccess: "商品受領票{code}のキャンセルに成功しました",
                cancelPOFailed: "注文受領{code} のキャンセルに失敗しました",
                confirmCancelRetunrPO: "返品伝票 {code} をキャンセルしてもよろしいですか？",
                cancelReturnPOSuccess: "返品 {code} のキャンセルに成功しました",
                cancelReturnPOFailed: "返品 {code} のキャンセルに失敗しました",
                receiveInvoice: "請求書を受け取る",
                invoiceDate: "請求日",
                invoiceAmount: "請求書の金額",
                nonInvoice: "請求書なし",
                hasInvoice: "請求書あり",
                enterInvoice: "請求書を入力",
                enterInvoiceDate: "請求書の日付を入力",
                enterAmount: "金額を入力",
                addInvoiceForPOSuccess: "商品受領のための請求書を作成しました",
                updatePOSuccess: "商品受領 {code} が正常に更新されました",
                pdf: "PDF",
                excel: "Excel",
                mix: "総合",
                confirmCancelTicket: "このチケットを処理してもよろしいですか？",
                approveRequestRefund: "返品依頼書を承認する",
                confirmApproveRequestRefund: "このリクエスト{code}を承認してもよろしいですか ？",
                refuseRequestRefund: "返品依頼の承認を拒否する",
                confirmRefuseRequestRefund: "このリクエスト {code}の承認を拒否してもよろしいですか？",
                approveSuccess: "リクエストが正常に承認されました",
                approveFailed: "リクエストの承認に失敗しました",
                refuseSuccess: "リクエストが正常に拒否されました",
                refuseFailed: "リクエストの拒否が失敗しました",
                cancelRequestRefund: "返品リクエストをキャンセルする",
                confirmCancelRequestRefund: "返品リクエスト {code} をキャンセルしてもよろしいですか？",
                cancelRequestRefundSuccess: "返品リクエスト {code} のキャンセルが成功しました",
                cancelRequestRefundFailed: "返品リクエスト {code} のキャンセルが失敗しました",
                searchInvoice: "請求書による",
                requestRefundCode: "返品リクエストコード",
                approveTime: "閲覧時間",
                updateRequestRefundSuccess: "返品リクエスト {code} が正常に更新されました",
                updateRequestRefundFailed: "返品リクエスト {code} の更新に失敗しました",
                addNewRole: "新しい役割として保存",
                removeRole: "役割を削除",
                confirmRemoveRole: "役割を削除してもよろしいですか？",
                lastUpdateDay: "最終更新日",
                emailTemplate: "メールテンプレート",
                selectTemplate: "サンプリングを選択",
                templateKeys: "印刷可能なサンプルトークンリスト",
                importPosition: "位置を入力",
                exportPosition: "位置をエクスポート",
                transferPosition: " 位置を変更",
                bas: "BAS",
                selectOrderSync: "注文を同期するために選択",
                onlySyncOrder: "注文のみが同期できます",
                from: "から",
                confirmConnectNote: "注意: これらの注文が同期されると、対応する注文と請求書が作成され、関連商品の注文数と在庫が変更されます。",
                understood: "了解",
                fifteenDay: "過去15日間",
                afterSetting: "設定後",
                create: "作成",
                createOrUpdate: "作成または更新",
                selectBranchBeforeSave: "保存する前に支店を選択してください",
                disconnect: "接続を削除",
                confirmDisconnect: "ブースの接続を削除してもよろしいですか？",
                confirmCancelRequests: "注文 {code} をキャンセルしてもよろしいですか？",
                cancelRequestsSuccess: "注文 {code} のキャンセルが成功しました",
                cancelRequestsFailed: "注文キャンセルリクエスト {code} が失敗しました",
                confirmCancelTransfer: "配送ノート {code} をキャンセルしてもよろしいですか？",
                cancelTransferSuccess: "配送ノート {code} のキャンセルが成功しました",
                cancelTransferFailed: "配送ノート {code} のキャンセルが失敗しました",
                selectBranchRequest: "支店を選択してください",
                joint: "一致",
                noJoint: "不一致",
                deviated: " 相違",
                notGiven: "まだ与えられていない",
                requestQuantity: "リクエスト数量",
                transferQuantity: "提供された数量",
                requestPrice: "リクエスト価格",
                waitForConfirmation: "確認を待つ",
                totalRequestQuantity: "合計リクエスト数量",
                totalTransferQuantity: "合計提供数量",
                createTransfer: "移動伝票を作成",
                emptyRequests: "注文フォームが空",
                inValidRequestBranch: "無効な呼び出し支店",
                inValidTransferQuantityConsignment: "商品名が{consignment}のバッチの移動数量は在庫を超えることはできません",
                inValidTransferQuantityProduct: "商品名{name}の移動数量は在庫を超えることはできません",
                inValidTransferQuantityProductForRequest: "商品{name}の移動数量は注文数量を超えることはできません",
                updateRequestSuccess: "注文フォームが正常に更新されました",
                updateConfirmRequestSuccess: "注文確認書が正常に更新されました",
                confirmRequestSuccess: "注文の確認が成功しました！対応する配送ノートが作成されました",
                addRequestSuccess: "注文が正常に作成されました",
                inValidRequestedBranch: "無効な支店が呼び出されました！",
                maxLengthRequestBranchNote: "呼び出し支店のメモは255文字を超えることはできません",
                maxLengthRequestedBranchNote: "呼び出される支店のメモは255文字を超えることはできません",
                transferPrice: "移動価格",
                moving: "移動中",
                transferBranch: "配送元支店",
                totalSendQuantity: "移動総数量",
                totalReceiveQuantity: "受け取った総数量",
                receive: "受け取る",
                outOfStock: "商品は在庫切れ",
                emptyTransfer: "配送伝票が空",
                inValidReceiveBranch: "受け取り支店が無効",
                emptyNote: "メモを空白にすることはできません",
                emptyConsignment: "商品{name}には空のバッチが存在できません",
                inValidReceiveQuantity: "商品{name}の受け取った数量は、出荷数量を超えることはできません",
                updateTransferSuccess: "配送ノートが正常に更新されました",
                confirmTransferSuccess: "配送確認が正常に更新されました",
                receiveSuccess: "商品を受け取りました！在庫の数量が更新されます",
                addTransferSuccess: "配送ノートが正常に作成されました",
                maxLengthTransferBranchNote: "移動支店のメモは255文字を超えることはできません",
                maxLengthReceiveBranchNote: "受け取り支店のメモは255文字を超えることはできません",
                removeDebtAdjustmentSuccess: "負債調整が正常に削除されました",
                debtAdjustmentSuccess: "負債が正常に調整されました",
                debtAdjustmentFailed: "負債調整が失敗しました",
                updateDeliveryInforSuccess: "配送情報を更新する",
                sellTime: "販売時間",
                updateHistoryDeliverySuccess: "配送履歴が正常に更新されました",
                addSubmitter: "提出者を追加",
                emptyName: "名前が入力されていません",
                emptyPhone: "電話番号が入力されていません",
                addPartnerSuccess: "パートナーを追加しました",
                uploadVoucher: "データファイルからクーポンをインポート",
                maxLengthVoucher: "システムでは、1度に最大5,000枚のクーポンをファイルから受け取ることができます",
                cancelVoucherError: "システムでは、未発行のクーポンのみをキャンセルすることができます。以下のクーポンは無効",
                confirmCancelVoucher: "これらのクーポンをキャンセルしてもよろしいですか？",
                cancelVoucherSuccess: "クーポンは正常にキャンセルされました",
                cancelVoucherFailed: "クーポンのキャンセルに失敗しました",
                releaseVoucherError: "未発行のクーポンのみを発行できます。以下のクーポンは無効",
                give: "寄付",
                releaseVoucherSuccess: "クーポンが正常に発行されました",
                releaseVoucherFailed: "クーポンの発行が失敗しました",
                confirmReleaseVoucher: "購入者／受取人名が入力されていません。発行してもよろしいですか？",
                inValidVoucherValue: "クーポンの価値が無効",
                confirmAddReceiptsForVoucher: "システムは上記のクーポンに対して領収書を生成します。発行してもよろしいですか？",
                returnVoucher: "クーポンを返却",
                returnVoucherError: "システムでは、発行されたクーポンのみを返却することができます。以下のクーポンは無効",
                returnDate: "返却日",
                returnVoucherSuccess: "クーポンが正常に返却されました",
                returnVoucherFailed: "クーポンの返却に失敗しました",
                inValidReturnBy: "返却の名前が入力されていません。返却を行いますか？",
                confirmReturnVoucher: "システムは上記のクーポンに対して領収書を生成します。返却を行いますか？",
                enterBAScode: "クーポンコードBASを入力",
                searchSuppliers: "コードと名前によるサプライヤーの検索",
                confirmCancelInforSaved: "作成した情報をキャンセルしますか？",
                notFoundRecord: "このレコードは見つかりません",
                existSupplier: "サプライヤーはすでに存在しています",
                deliveryDetail: "配送ノートの詳細",
                long: "長さ",
                width: "幅",
                height: "高さ",
                pickUpAtTheBranch: "支店で商品を受け取る",
                approve: "承認",
                refuse: "拒否",
                emptyRequestRefund: "返品リクエストフォームが空",
                addRequestRefundSuccess: "返品リクエストが成功裏に作成されました",
                updatedRequestRefundSuccess: "返品リクエストフォームが成功裏に更新されました",
                intoStorage: "入庫",
                takeOutStorage: "出庫",
                existDocumentOrigin: "元の文書はすでに存在しています",
                poWithReturnPOCode: "輸入注文コード / 輸入返品",
                statusPO: "クーポンの状態",
                totalAdvance: "全額の前払い",
                totalSettlement: "決済金額",
                totalDebtInvoice: "",
                updateTicketSuccess: "チケットが正常に更新されました",
                addTicketSuccess: "チケットが正常に作成されました",
                searchImportPosition: "場所入力クーポンコードを見つける",
                searchExportPosition: "場所エクスポートチケットコードを検索",
                confirmSaveInforSaved: "更新された情報を保存しますか？",
                outputLocation: "出力場所",
                entryLocation: "入力場所",
                locationCode: "場所コード",
                totalQuantityProducts: "商品の総数",
                integrateSalesChannels: "販売チャネルを統合",
                linkToECommerce: "ECプラットフォームへのリンク",
                linkToProduct: "商品のリンク",
                addStall: "ブースを追加",
                stallName: "ブース名",
                syncOrder: "注文を同期",
                syncQuantity: "販売数量を同期",
                syncPrice: "販売価格を同期",
                noLinkStall: "まだリンクされたショップはありません",
                linkToStallSuccess: "ブースが正常に接続されました",
                retry: "エラーが発生しました。もう一度お試しください",
                existStall: "ブースが接続されました。カスタマーブースをお試しください。",
                linkToStall: "ECプラットフォーム上のブース接続",
                linkProductToOmipos: "ECプラットフォームの商品のOmipos商品との連携",
                settingSync: "同期機能を設定",
                loginStallToOmpos: "ログインして、{stall}のストアをOmiposに接続し、Omiposで商品の注文情報を同期",
                noAccount: "アカウントがありません",
                register: "登録",
                syncError: "同期エラー",
                enterCodeOrName: "コードと商品名を入力",
                enterIDOrSKU: "アイテムID、SKUコード、またはアイテム名を入力",
                stall: "ブース",
                productOnStall: "ECプラットで商品",
                id: "ID",
                sku: "SKU",
                branchSync: "同期支店",
                branchSyncDesc: "注文情報、在庫、販売価格を同期する支店を選択してください",
                syncOrderDesc: "ECプラットフォームからOmiposに注文を自動同期して、売上と利益を集中管理",
                onlySyncOrderWhen: "注文を同期するのは",
                syncOrderNote: "注意：これらの注文が同期されると、注文が作成され、対応する請求書が商品の注文数量と在庫を変更",
                productNoLink: "注文に未リンクの商品がある場合",
                confirmDelivery: "返品配送伝票を確認",
                confirmDeliveryDesc: "店舗の返品配送伝票を確認して、「返金完了」の状態に移行する必要",
                syncSaleQuantity: "販売数量を同期",
                autoSyncInventory: "Omiposから在庫を自動的に同期",
                formulaSyncQuantity: "販売数量を同期するための式",
                setValueQuantity: "上記の販売数量の値を設定",
                fromFormuala: "数式に従って",
                distributionOfSaleQuantity: "複数のリンクされた製品の販売数量の分配",
                ecommerceLevel: "プラットフォーム",
                error: "エラー",
                syncAll: "すべて同期",
                products: "商品",
                removeSyncError: "エラーリストから削除",
                sync: "同期",
                productSync: "商品を同期",
                syncContent: "同期内容",
                inValidInventoryProduct: "顧客が注文した商品の在庫が、上記のプロモーションプログラムに参加する合計数量よりも少ない",
                reSync: "再度同期中…",
                syncSuccess: "同期が成功しました",
                currentPrice: "現在の価格",
                lastPurchasePrice: "前回の入力価格",
                month1: "1月",
                month2: "2月",
                month3: "3月",
                month4: "4月",
                month5: "5月",
                month6: "6月",
                month7: "7月",
                month8: "8月",
                month9: "9月",
                month10: "10月",
                month11: "11月",
                month12: "12月",
                day1: "1日",
                day2: "2日",
                day3: "3日",
                day4: "4日",
                day5: "5日",
                day6: "6日",
                day7: "7日",
                day8: "8日",
                day9: "9日",
                day10: "10日",
                day11: "11日",
                day12: "12日",
                day13: "13日",
                day14: "14日",
                day15: "15日",
                day16: "16日",
                day17: "17日",
                day18: "18日",
                day19: "19日",
                day20: "20日",
                day21: "21日",
                day22: "22日",
                day23: "23日",
                day24: "24日",
                day25: "25日",
                day26: "26日",
                day27: "27日",
                day28: "28日",
                day29: "29日",
                day30: "30日",
                day31: "31日",
                confirmationTime: "確認時間",
                manualLink: "手動リンクを待つ",
                inventoryMinusOrdered: "在庫 - 注文",
                productOnLazada: "Lazadaの商品",
                productOnShopee: "Shopeeの商品",
                productOnTiki: "Tikiの商品",
                productOnTiktok: "Tiktokの商品",
                productOnSendo: "Sendoの商品",
                searchOrderCode: "注文コードによる",
                enterOrderCode: "注文コードを入力",
                searchCustomerInfor: "コード、名前、顧客の電話番号で",
                enterCustomerCodeNamePhone: "顧客のコード、名前、電話番号を入力",
                searchProductCodeName: "コード、商品名で",
                enterProductCodeName: "コードと商品名を入力",
                productOnECommerce: "プラットフォーム上の商品",
                notRelease: "未発行",
                released: "発行済",
                used: "使用済",
                cancelled: "キャンセル済",
                promotionInvoiceDiscountDesc: "例えば：<br/> ‐合計価格が500,000以上の請求書には5 % の割引が適用されます < br /> ‐合計価格が1,000,000以上の請求書には10 % の割引が適用されます",
                promotionInvoiceDonateProductDesc: "例えば：<br/> ‐合計商品金額が500,000以上の請求書には靴下1足をプレゼントします < br /> ‐合計商品金額が1,000,000以上の請求書にはアクセサリーグループの商品1点を無料でプレゼントします < br /> ",
                promotionInvoiceDiscountProductDesc: "例えば：<br/> ‐Tシャツの合計金額が100,000以上の請求書にはTシャツに対して5 % の割引が適用されます < br /> ‐Tシャツの合計金額が200,000以上の請求書にはTシャツに対して10 % の割引が適用されます < br /> ",
                promotionProductDiscount: "定数量以上の購入で、顧客は次回の購入に割引を受けることができます",
                promotionProductDonateProductDesc: "定数量以上の購入で、顧客は追加の商品を受け取ることができます",
                promotionProductAllowProductDesc: "例えば：<br/> ‐Tシャツを3枚以上購入すると、1枚あたり250,000ドンの割引が適用されます < br /> ‐Tシャツを5枚以上購入すると、1枚あたり50,000ドンの割引が適用されます",
                promotionAllInvoiceDiscountDesc: "例えば：<br /> ‐合計商品金額が2,000,000ドン以上で、少なくとも1つのMango Bag製品を購入すると、5 % の割引が適用されます。<br /> ‐Mango Bag製品を少なくとも3つ購入すると、5 % の割引が適用されます。<br />",
                promotionAllDonateProductDesc: "例えば：<br /> ‐合計購入金額が2,000,000ドン以上で、少なくとも1つのMango Bag製品を購入すると、レディースアクセサリーの商品が2点無料でプレゼントされます。<br /> ‐Mango Bag製品を少なくとも3つ購入すると、レディースアクセサリーの商品が2点無料でプレゼントされます。<br />",
                promotionAllProductDiscountDesc: "例えば：<br /> ‐合計商品金額が2,000,000ドン以上で、少なくとも1つのMango Bag製品を購入すると、レディースアクセサリーの商品が2点につき50 % の割引が適用されます。<br /> ‐Mango Bag製品を少なくとも3つ購入すると、レディースアクセサリーの商品2点につき50,000ドンの割引が適用されます。",
                warning: "警告",
                ban: "ブロック",
                stocking: "在庫あり",
                productOutOfStock: "在庫切れ",
                zeroPercent: "免税（0％）",
                fivePercent: "5%",
                eightPercent: "8%",
                tenPercent: "10%",
                removed: "削除済み",
                delivering: "配送中",
                waitingForProgressing: "進行を待つ",
                selectTypePaper: "コードを印刷するための紙の種類を選択する",
                previewTemplate: "印刷を見る",
                multipleOptions: "多くの選択肢",
                receiver: "受信者",
                accountant: "会計",
                selectProductDiscount: "割引対象商品を選択",
                whenBuy: "購入時",
                readyToShip: "発送準備完了",
                process: "処理中",
                transport: "配送",
                inCancel: "キャンセル中",
                waitingInvoice: "請求書待ち",
                onHold: "一時停止",
                waitingShip: "配送待ち",
                waitingCollection: "受取待ち",
                partiallyShipping: "部分配送",
                delivered: "配送済み",
                queueing: "出品者確認待ち",
                successfulDelivery: "配送成功",
                processing: "処理中",
                waitingPayment: "支払い待ち",
                handoverToPartner: "注文は配送パートナーに渡されました",
                closed: "注文完了",
                packaging: "注文は梱包中",
                picking: "商品受取中",
                holded: "問題の対処のために注文が保留",
                finishedPacking: "注文が梱包済",
                printTemplateName: "印刷テンプレート名",
                suggestPrintTemplate: "推奨印刷テンプレート",
                previewPrintTemplate: "印刷テンプレートをプレビュー",
                titleAddPrintTemplate: "新しい印刷テンプレートを追加",
                titleUpdatePrintTemplate: "印刷テンプレートを更新",
                addPrintTemplateSuccess: "印刷テンプレートを更新しました",
                addPrintTemplateFailed: "新しい印刷テンプレートが失敗",
                emptyPrintTemplateName: "サンプル名を入力",
                maxPrintTemplates: "この印刷を追加できません！システムは最大3つの印刷テンプレートのみをサポートしています",
                updatePaper: "印刷用紙サイズを編集",
                addPaper: "新しい用紙サイズを追加",
                enterPrintTemplateName: "印刷テンプレート名を入力してください",
                emptyPaper: "用紙サイズの名前を入力してください",
                removePrintTemplate: "印刷テンプレートを削除",
                confirmRemovePrintTemplate: "この印刷を削除しますか？",
                branchRequest: "支店呼び出し",
                voucherSelected: "クーポンが選択",
                paymentSchedule: "支払いスケジュール",
                noSetup: "まだ設定されていません",
                connected: "接続済み",
                removeLink: "リンクを削除する",
                uploadImage: "{type} をアップロード",
                sendZaloSuccess: "Zalo への送信に成功",
                sendZaloFailed: "Zalo への送信に失敗",
                numberCouponSelected: "選択されたクーポンが {number} 件あり",
                numberVoucherSelected: "選択されたバウチャーが {number} 件あり",
                numberRecordSelected: "選択されたレコードが {number} 件あり",
                discountCouponValue: "クーポンコード {code}: {value} の割引（請求書に対する {percent}% の割引）",
                monday: "月",
                tuesday: "火",
                wednesday: "水",
                thursday: "木",
                friday: "金",
                saturday: "土",
                sunday: "日",
                searchDeliveryStatus: "配送状態を検索",
                transactionOccurred: "取引が発生",
                noData: 'データなし',
                selectCreatorApplied: '適用する作成者を選択',
                messageImportSuccess: "ファイルのインポートに成功しました。",

            },
            permissions: {   
                titleUpdate: "通知",
                contentUpdate:"ユーザー{name}がシステムを使用している場合、役割を更新するためにすぐにログアウトされます。更新を続行しますか？",
                messageSuccess: "役割の削除が成功しました",
                messageError: "役割の削除が失敗しました",
                modal: {
                    titleEditRole: "役割の編集",
                    save: "保存",
                    cancel: "スキップ",
                    delete: "削除",
                }
            },
            InputQuickSearch:{
                placeholder: {
                    request_refund_code: "返品リクエストコードによる",
                    settlement_advance_code: "事前リクエストフォームコードによる",
                    transfer_position_code: "移動コードによる",
                    import_position_code: "場所インポートコードによる",
                    export_position_code: "場所削除コードによる",
                    original_documents_code: "原本による",
                    original_documents_type: "文書タイプによる",
                    coupon_voucher_code_name: "コード、リリース名による",
                    coupon_code: "クーポンコードによる",
                    voucher_code: "バウチャーコードによる",
                    returns_code_return_search: "返品コードによる",
                    returns_code_refund_search: "返品伝票コードによる",
                    order_code: "注文コードによる",
                    code_transfer: "移動コードによる",
                    code_request: "リクエストコードによって",
                    product_code: "商品コードによる",
                    product_name: "商品名による",
                    code_name_phone: "顧客のコード、名、電話番号による",
                    username: "ユーザー名による",
                    phone: "電話番号による",
                    email: "メールによる",
                    return_purchase_order: "バウチャーコードによる",
                    order_supplier: "注文コードによる",
                    purchase_order: "商品の受領コードによる",
                    product_code_name: "コード、商品名による",
                    supplier_code_name: "コード、サプライヤー名による",
                    code_invoice: "請求書コードによる",
                    delivery_code: "配送コードによる",
                    note: "メモによる",
                    note_product: "商品メモによる",
                    note_description: "メモ、商品の説明による",
                    invoice_supplier_code: "請求書番号による",
                    product_omipos: "商品コードおよび名前を入力（OmiPOS）",
                    product_medicine: "薬品コードおよび名前を入力（薬局部門）",
                    control_history: "調整コードによる",
                    note_delivery: "納品書による",
                    inventory_plan_code: "名前、詳細見積もりコードによる",
                    plan_purchase_code: "在庫予測コード、名前による",
                    discount_bas_code: "BASコードによる",
                },
                button: {
                    extend: "拡張",
                    collapse: "折りたたみ",
                    search: "検索"
                },
                title: {
                    code_transfer: "移転コード",
                    code_request: "リクエストコード",
                    product_name: "商品名",
                    product_code: "商品コード",
                    order_supplier: "発注書コード",
                    purchase_order: "購入受領コード",
                    return_purchase_order: "返品コード",
                    product_code_name: "商品",
                    supplier_code_name: "サプライヤー",
                    note: "メモ",
                    note_product: "商品メモ",
                    username: "ユーザー",
                    phone: "電話番号",
                    email: "メール",
                    note_description: "メモ　‐　説明",
                    code_name_phone: "顧客",
                    code_invoice: "請求書コード",
                    order_code: "注文コード",
                    delivery_code: "配送券コード",
                    returns_code: "返品コード",
                    coupon_voucher_code_name: "出荷バッチ",
                    coupon_code: "クーポンコード",
                    voucher_code: "バウチャーコード",
                    keyword: "コード",
                    import_position_code: "場所の取り込みコード",
                    export_position_code: "場所の削除コード",
                    original_documents_code: "元の文書",
                    original_documents_type: "文書タイプ",
                    transfer_position_code: "移動コード",
                    request_refund_code: "返品リクエストコード",
                    settlement_advance_code: "事前リクエストフォームコード",
                    invoice_supplier_code: "請求書コード",
                    cash_book_code: "現金コード",
                    bank_book_code: "銀行コード",
                    medicine_name: "薬品名",
                    medicine_number: "登録番号",
                    medicine_code: "薬品コード",
                    medicine_content: "内容",
                    medicine_country: "NSX",
                    product_omipos: "商品名",
                    product_medicine: "薬コード",
                    transporter_code: "パートナーコード",
                    control_history: "調整コード",
                    note_delivery: "メモ",
                    inventory_plan_code: "詳細見積もりコード",
                    plan_purchase_code: "購入予定のコード",
                    discount_bas_code: "BASコード"
                }
            },
            LoginScreen: {
                title: "OmiPos ログイン",
                header: "ログイン",
                remember: "記憶",
                submit: "ログイン",
                password: "パスワード",
                username: "ユーザー名"
            },
            Pagination: {
                show: "表示",
                total: "合計",
                record: "レコード",
            },
            SelectDateTime: {
                otherOption: "他の選択",
            },
            DateRangePicker: {
                byDay: "日々に",
                byWeek: "週ごとに",
                byMonth: "月次に",
                byQuarter: "四半期ごとに",
                byYear: "年ごとに",
                today: "今日",
                yesterday: "昨日",
                thisWeek: "今週",
                lastWeek: "先週",
                twoDay: "過去2日間",
                threeDay: "過去3日間",
                sevenDay: "過去7日間",
                month: "今月",
                lastMonth: "先月",
                thirtyDay: "過去30日間",
                quarter: "今四半期",
                lastQuarter: "前四半期",
                year: "今年",
                lastYear: "去年",
                all: "すべての期間"
            },
            ImportExcel: {
                title: "Excelファイルから商品を追加する",
                defaultFile: "サンプルファイルをダウンロードする",
                file: "詳細ファイル",
                chooseFile: "データファイルを選択",
                action: "実行",
                orderSupplierFile: "注文ファイル",
                purchaseOrderFile: "インポートファイル",
            },
            ProductUnitSearch: {
                orderFromCustomer: "顧客の注文",
                orderSupplier: "注文数量",
                price: "価格",
                inventory: "在庫",
            },

        /* Dashboard */

            DateRangerDashboardPicker: {
                today: '今日',
                last_day: '昨日',
                seven_day: '過去7日間',
                month: '今月',
                last_month: '先月',
            },
            RevenueFromBranch: {
                title: '支店別の純売上高 {label}',
            },
            RevenueOriginal: {
                title: '純売上高',
                dayOfWeek: '日別',
                day: '日付別',
                hour: '時間別',
            },
            StatisticalInvoice: {
                title: '今日の売上結果',
                revenue: '売上高',
                invoice: '請求書',
                promissory: '請求書',
                return: '返品',
                compared_to_yesterday: '昨日との比較',
                compared_same_period_last_month: '前月同期との比較',
            },
            notice: {
                title_notice: "通知",
                title_action: "最近の活動",
                action_by_user: "注文を作成",
                value: "価値",
                content_transfer: ' {fromBranch}から {toBranch}まで',
                have: "あり",
                expired: "まもなく期限切れ",
                three_month: "3月中に",
                seven_day: "今週中に",
                product: "商品",
                voucher: "バウチャー発行",
                coupon: "バウチャー発行",
                promotion: "プロモーションプログラム",
                noticeForProduct: '<span class="text-primary">{number} 点の商品</span>が3ヶ月以内に期限切れです。',
                noticeForCoupon: '<span class="text-primary">{number} 枚のクーポン発行</span>が期限切れ間近です',
                noticeForVoucher: '<span class="text-primary">{number} 枚のバウチャー発行</span>が期限切れ間近です',
                noticeForPromotion: '<span class="text-primary">{number} 件のプロモーションプログラム</span>が期限切れ間近です',
                noticeActionInvoice: '<a class="text-primary text-decoration-none" target="_blank" href="{href}">{employee}</a> 新たに作成 <a class="text-primary text-decoration-none" target="_blank" href="{hrefAction}">{action}</a> <span class="{showBranch}">から {branchNameSend} まで {branchNameReceive}</span> <span class="{showAmount}">価格で <span class="text-bold">{amount}</span></span>',
            },
            noticeDetail: {
                time: " 日付 {from} から日付 {to} まで",
                branch: "支店: {branch}",
                title_product: "次の3ヶ月以内に期限切れの商品リスト",
                title_voucher: "次の7日間で期限切れのバウチャーリスト",
                title_coupon: "次の7日間で期限切れのクーポンリスト",
                title_promotion: "次の7日間で期限切れのプロモーションプログラムリスト",
                max_value: "最大",
                tables: {
                    product: {
                        code: '商品コード',
                        name: '商品名',
                        consignment: 'ロット',
                        expired: '有効期限',
                        daysLeft: '残り日数',
                    },
                    voucher: {
                        code: 'バウチャーコード',
                        release_code: 'リリースコード',
                        amount: '金額',
                        release_date: 'リリース日',
                        expired_date: '有効期限日',
                    },
                    coupon: {
                        code: 'クーポンコード',
                        release_code: 'リリースコード',
                        amount: 'クーポンの価値',
                        release_date: 'リリース日',
                        expired_date: '有効期限日',
                    },
                    promotion: {
                        code: 'プログラムコード',
                        form: 'フォーム',
                        release_date: 'リリース日',
                        expired_date: '有効期限日',
                    },
                }
            },
            ProductStatistical: {
                title: 'トップ10の売れ筋商品',
            },

        /* Hết dashboard */


        /* Danh mục */

            // Danh sách hàng hóa
            ProductIndex: {
                title: "商品",
                document_title: "商品| OmiPos",
                product_type: "カテゴリー",
                categories: "商品グループ",
                all: "すべて",
                created_at: "作成日",
                choose_show: "表示オプション",
                placeholder: {
                    categories: "商品グループ検索",
                    search: "商品名、コード",
                    branch: "支店検索",
                    batch: "バッチと有効期限日によって検索"
                },
                info: "情報",
                maxInventory: "在庫基準",
                stockCard: "倉庫カード",
                inventory: "在庫",
                batch: "ロット日",
                combo: "成分",
                code: "商品コード",
                price: "価格",
                weight: "重量（グラム）",
                location: "場所",
                manufacturer: "製造業者",
                country: "製造国",
                unit: "包装",
                description: "説明",
                note: "メモ",
                supplier: "サプライヤー",
                status: "状態",
                quantity: "数量",
                no_field: "表示フィールドがありません",
                branch: "支店",
                component_product: "構成品",
                name: "商品名",
                reason: "原因",
                active: "アクティブな商品",
                inActive: "取引停止商品",
                table: {
                    stockCard: {
                        code: "書類",
                        time: "時間",
                        type: "トランザクションタイプ",
                        price: "トランザクション価格",
                        price_tooltip_1: "取引価格とは、取引（請求書、返品、輸入、返品）時の商品の価格です。取引価格を表示するには、商品の輸入価格と原価を表示する権限が必要",
                        price_tooltip_2: "取引に関連するコストや割引が発生した場合、取引価格は再割り当てされます",
                        last_inventory: "期末在庫",
                        transporter: "パトナー"
                    },
                    maxInventory: {
                        on_show_quantity: "陳列数",
                        min_inventory: "在庫最小値",
                        max_inventory: "在庫最大値",
                        value: "価値",
                    },
                    inventory: {
                        quantity_order: "注文数量",
                        customer_order: "顧客予約数量",
                        expected_out_of_stock: "在庫切れの予定",
                    },
                    batch: {
                        batch_number: "ロット番号",
                        expired: "有効期限",
                    },
                    combo: {
                        STT: "順番",
                        component_row_name: "コンポーネント行名"
                    }
                },
                button: {
                    save: "保存",
                    export_excel: "Excelファイルをエクスポート",
                    export_file: "ファイルをエクスポート",
                    add: "追加",
                    add_product: "手動でさらに商品を追加",
                    upload: "ファイルをアップロード",
                    option: "オプション",
                    update: "更新",
                    copy: "コーピー",
                    print: "コードスタンプを印刷",
                    in_active: "取引停止",
                    active: "取引許可",
                    remove: "削除",
                    skip: "スキップ",
                    fix: "更新",
                    purchase_product: "購入受領",
                    order_supplier: "発注書",
                    add_order: "注文を追加",
                    add_invoice: "請求書を追加",
                    inventory_plan: "詳細見積もり",
                    confirm: "確認",
                    close: "閉じる",
                    agree: "同意"
                },
                alerts: {
                    valid_max_inventory: "最大在庫は最小在庫以下にすることはできません",
                },
                modal: {
                    title_create: "商品を追加",
                    title_update: "商品を更新",
                    title_update_multiple: "商品の一括更新",
                    title_create_combo: "商品の属性を追加",
                    title_remove: "商品を削除",
                    title_remove_multiple: "複数の商品を削除",
                    content_remove_multiple: "ショッピングリストから{number}個の商品を削除してもよろしいですか？",
                    content_remove: "システムは{branch}全体で{name}個の商品を完全に削除",
                    content_remove_confirm: "削除しもよろしいますか？",
                    product_name: "商品 {name}",
                    have_transaction: "業務が発生した",
                    note_remove: "注意：システムはまだ取引が行われていない商品のみを削除",
                    product_manage: "商品管理：",
                    search: "検索",
                    button: {
                        save: "保存",
                        skip: "スキップ",
                    },
                    confirm_remove_attribute: "属性を削除してもよろしですか？",
                    least_one_unit: "少なくとも1単位の商品を追加",
                    choose_enough_unit: "十分な単位を選択してください",
                    choose_enter_unit: "十分な単位を入力してください",
                    enter_price: "商品の販売価格を入力してください",
                    duplicate_unit: "{name} 単位が重複しています",
                    max_length: "長さ制限は255文字です。再入力してください。",
                    confirm_remove_product: "商品を削除： {name}？",
                    processed_quantity: "処理されたカテゴリ数：",
                    removed_quantity: "正常に削除されたカテゴリ数：",
                    not_remove_quantity: "削除されなかったカテゴリ数：",
                    categories_have_transaction: "発生した業務のリスト",
                    remove_success: "選択されたすべての商品が削除",
                    not_remove: "．商品を削除することはできません！",
                    reason: "システムはまだ業務が発生していない商品のみを削除"
                }
            },
            ModalFileUploadProducts: {
                title: "データファイルから商品をインポート",
                titleTemplate: "サンプルファイルをダウンロードする：",
                titleExcel: "Excelファイル",
                notifyRow1: "システムは一度に最大5000商品のインポートを許可",
                notifyRow2: "商品コードには特殊文字（@、#、$、*、/など）やアクセント付きの文字は含まれていません",
                titleActionExistCode: "重複する商品コードの処理",
                titleErrorStop: "商品コードが重複する場合、エラーを報告し、インポートを停止",
                titleCreateProductNew: "新しい商品を追加",
                titleUpdateProduct: "商品情報を更新",
                updateCreateProduct: "更新、新しい商品を追加",
                titleUpdateInventory: "在庫の最新",
                titleUpdateInventoryYes: "あり",
                titleUpdateInventoryNo: "なし",
                titleStatusAppliesAbove: "上記のビジネスステータスが適用",
                notifyRow3: "インポートファイルのビジネスステータスは、選択された支店にのみ適用",
                notifyRow4: "新しい商品を追加する際には、選択されていない支店のステータスが反対になることに注意してください",
                titleAllBranch: "システム全体",
                titleSelectBranch: "支店",
                titlePlaceHolderSelectBranch: "該当する支店を選択",
            },
            ModalProductFormTabMain: {
                code: "商品コード",
                name: "商品名",
                category: "商品グループ",
                type: "商品種類",
                hs_code: "HSコード",
                tax_rate: "税率",
                image: "商品写真",
                batch: "ロット、有効期限",
                medicine: "薬品",
                medicine_name: "薬品名",
                medicine_code: "薬品コード",
                medicine_short_name: "略語",
                medicine_road_for_use: "使用方",
                ingredient: "有効成分",
                manufacturer: "製造業者",
                country: "製造国",
                packing: "包装",
                description: "説明",
                branch: "業務倉庫",
                register_number: "登録番号",
                content: "含有量",
                unit: "商品に単位",
                auto_price: "変換された値に基づいて価格を自動計算",
                attribute: '属性',
                listVariant: '同種商品のリスト',
                lifeTime: '使用日数',
                table: {
                    stt: "順番",
                    unit_name: "単位名",
                    conversion_value: "変換値",
                    conversion_calculation: "変換計算",
                    price: "価格",
                    unit_main: "主単位",
                    unit_conversion: "変換単位",
                    placeholder: {
                        unit_name: "単位選択",
                        price: "価格を入力してください",
                    }
                },
                button: {
                    remove: "削除",
                    add_unit: "単位を追加",
                    multiplication: "乗算",
                    division: "除算",
                    attribute_plus: '属性を追加',
                },
                placeholder: {
                    code: "自動商品コード",
                    name: "商品名を入力",
                    category: "商品グループ",
                    type: "商品タイプを選択",
                    hs_code: "HSコードを入力",
                    tax_rate: "商品の税率を選択",
                    image: "商品写真",
                    batch: "ロット、有効期限",
                    medicine: "薬品",
                    medicine_name: "薬品名を入力",
                    medicine_code: "薬品コード",
                    medicine_short_name: "略名を入力",
                    medicine_road_for_use: "使用方法を選択",
                    ingredient: "商品の有効成分を入力",
                    manufacturer: "商品の製造業者を入力",
                    country: "商品の製造国を入力",
                    packing: "商品の包装仕様を入力",
                    description: "商品の説明を入力",
                    branch: "業務倉庫を選択",
                    attribute: "属性を選択",
                    lifeTime: '商品の使用日数を入力',
                }
            },
            ModalCreateRouteOfUse: {
                title_create: "使用方法を追加",
                title_update: "使用方法を更新",
                name: "使用方法名",
                placeholder: {
                    name: "使用方法名を入力",
                },
                button: {
                    create: "追加",
                    update: "更新",
                    skip: "スキップ",
                    remove: "削除"
                },
                alert: {
                    delete_success: "削除に成功しました",
                    delete_error: "削除に成功しました",
    
                    create_success: "追加に成功しました",
                    create_error: "更新追加に失敗しました",
                    update_success: "更新に成功しました",
                    update_error: "更新に失敗しました"
                }
            },

            // Nhóm hàng hóa
            CategoriesIndex: {
                title: "商品グループ",
                document_title: "商品リスト | OmiPos",
                placeholder: {
                    search: '検索',
                },
                button: {
                    add: "追加",
                },
                modal: {
                    title_create: "商品グループを追加",
                    title_update: "商品グループを編集",
                    title_remove: "商品グループを削除",
                    name: "グループ名",
                    parent_category: "親グループ",
                    content_remove: "このグループを削除してもよろしいですか？",
                    contentCancelUpdate: "商品グループの更新をキャンセルしますか？",
                    contentCancelCreate: "商品グループの追加をキャンセルしますか？",
                    placeholder: {
                        name: "商品グループ名を入力",
                        parent_category: "親グループを選択",
                    },
                    button: {
                        add: "追加",
                        update: "更新",
                        skip: "スキップ",
                        remove: "削除",
                        confirm: "同意"
                    }
                }
            },
            ModalFileUploadCategories: {
                title: "データファイルから商品グループを追加",
                titleTemplate: "サンプルファイルをダウンロード:",
                titleExcel: "Excelファイル",
                notifyRow1: "システムは一度に最大5000件のレコードのインポートを許可",
                notifyRow2: "商品コードには特殊文字（@、#、$、*、/ など）やアクセント付きの文字を含めないでください",
                titleActionExistCode: "商品グループの処理",
                titleErrorStop: "新しい商品グループや重複する商品グループがある場合、エラーを報告してインポートを停止する",
                titleCreateCategoryNew: "新しい商品グループを追加",
                titleUpdateProduct: "商品情報を更新",
                updateCreateProduct: "商品情報を更新、追加",
                titleUpdateInventory: "在庫を更新",
                titleUpdateInventoryYes: "あり",
                titleUpdateInventoryNo: "なし",
                titleStatusAppliesAbove: "営業状況は上記に該当",
                notifyRow3: "Tインポートファイルのビジネスステータスは、選択された支店にのみ適用",
                notifyRow4: "新しい商品を追加する際には、選択されていない支店のステータスが反対になることに注意",
                titleAllBranch: "システム全体",
                titleSelectBranch: "支店",
                titlePlaceHolderSelectBranch: "適用支店を選択",
            },

            // Đơn vị hàng hóa
            UnitIndex: {
                title: "商品単位",
                document_title: "商品単位| OmiPos",
                placeholder: {
                    search: '検索',
                },
                button: {
                    add: "追加",
                },
                modal: {
                    title_remove: "商品単位を削除",
                    content_remove: "この単位を削除してもよろしいですか？",
                    button: {
                        skip: "スキップ",
                        confirm: "同意"
                    }
                }
            },
            modalCreateUpdateUnit: {
                title_create: "商品単位を追加",
                title_update: "商品単位を編集",
                title_confirm: "確認",
                unit_name: "単位名",
                message_confirm_create: "単位の追加をキャンセルしますか？",
                message_confirm_update: "単位の更新をキャンセルしますか？",
                placeholder: {
                    name: "単位名"
                },
            },

            // Hàng hóa liên kết
            medicineProductMapping: {
                title: "連携商品",
                title_document: "連携商品| OmiPos",
                placeholder: {
                    search: "薬品名による",
                    chooseCategories: "商品グループを選択"
                },
                filter: {
                    title: {
                        category: "商品グループ",
                        status: "状態"
                    }
                },
                message_connect_success: "薬の連携に成功しました",
                message_connect_error: "連携商品が見つかりませんでした",
                message_disconnect_success: "切断に成功しました",
                err: "サーバーエラー",
                all: "全体",
                linked: "連携済み",
                noLink: "連携していない",
                productOmipos: "Omiposで商品",
                medicineCode: "国内薬品コード",
                removeLinked: "切断",
            },

            // Danh mục thuốc
            medicine: {
                title: "薬品リスト",
                title_index: "薬剤リストへのリンク",
                title_document: "薬品リスト | OmiPos",
                import: "輸入",
                export: "輸出ファイル",
                placeholder: {
                    search: "薬品名による",
                    number: "登録番号による",
                    code: "薬品名コードによる",
                    content: "有効成分と含有量による",
                    country: "製造業者と製造国による"
                },
                modal: {
                    title: "国内医薬品リストをデータファイルからインポートする",
                    button: "データファイルを選択"
                }
            },
            MedicineSearch: {
                placeholder: {
                    name: "薬品名を入力"
                },
                table: {
                    name: "薬品名",
                    registerNumber: "登録番号",
                    ingredient: "有効成分",
                    content: "含有量",
                    packing: "包装",
                    manufacturer: "製造業者",
                }
            },

            // Danh sách khách hàng
            customerIndex: {
                title: "顧客",
                documentTitle: "顧客管理　| OMIPOS",
                customerGroup: "顧客グループ",
                createdAt: "作成日",
                createdBy: "作成者",
                birthDate: "誕生日",
                lastTransactionDate: "最終取引日",
                totalSale: "総売上",
                type: "顧客タイプ",
                all: "全体",
                individual: "個人",
                company: "会社",
                gender: "性別",
                male: "男性",
                female: "女性",
                deliveryArea: "配送エリア",
                status: "状態",
                active: "活動中",
                inActive: "非活動中",
                reActive: "活動中",
                quantity: "数量",
                discount: "割引",
                note: "メモ",
                code: "顧客コード",
                name: "顧客名",
                birthDay: "生年月日",
                companyName: "会社名",
                taxCode: "税率コード",
                email: "メール",
                facebook: "フェスブック",
                accountNumber: "口座番号",
                accountOwner: "口座名義人",
                bankName: "銀行名",
                bankAddress: "銀行支店",
                phone: "電話番号",
                address: "住所",
                ward: "区",
                debtDate: "負債期間",
                employee: "担当社員",
                addressName: "住所名",
                receiverName: "受取人名",
                receiverPhone: "受取電話番号",
                receiverAddress: "受取住所",
                orderCode: "注文コード",
                time: "時間",
                sellerName: "販売者",
                branch: "支店",
                total: "合計",
                invoiceCode: "請求書コード",
                confirm: "確認",
                contentInActive: "この顧客を無効にしてもよろしいですか？この顧客の取引情報は保持されます",
                contentReActive: "この顧客を再度活性化させてもよろしいですか？",
                contentConfirmSkip: "変更をキャンセルしてもよろしいですか？",
                contentConfirm: "編集を確実にキャンセルしますか？",
                change: "変更",
                branchActive: "活動支店",
                button: {
                    add: "追加",
                    addCustomer: "顧客",
                    file: "ファイル",
                    import: "輸入",
                    export: "輸出ファイル",
                    update: "更新",
                    remove: "削除",
                    new: "追加",
                    agree: "同意",
                    skip: "スキップ",
                    action: "実施",
                },
                placeholder: {
                    search: "検索",
                    searchCustomer: "コード、名、電話番号による",
                    chooseArea: "都道府県/市区町村 - 区"
                },
                tabs: {
                    info: "情報",
                    address: "受取住所",
                    historyOrder: "注文歴史",
                    historySale: "売上/返品履歴"
                },
                values: {
                    info: "info",
                    address: "addressGetOrder",
                    historyOrder: "historyOrder",
                    historySale: "historySale"
                },
                modal: {
                    titleCreate: "新顧客を追加",
                    titleUpdate: "顧客を更新",
                    branch: "作成支店",
                    customerCode: "顧客コード",
                    customerName: "顧客名",
                    customerPhoneNumber: "電話番号",
                    customerBirthDate: "生年月日",
                    customerGender: "性別",
                    customerAddress: "住所",
                    customerAccountNumber: "口座番号",
                    customerAccountName: "口座名義人",
                    customerBankName: "銀行名",
                    customerBankAddress: "銀行支店",
                    customerType: "顧客タイプ",
                    personal: "個人",
                    company: "会社",
                    customerCompanyName: "会社名",
                    customerTaxCode: "税率コード",
                    customerメール: "メール",
                    customerフェスブック: "フェスブック",
                    customerGroup: "グループ",
                    customerNote: "メモ",
                    customerDebtDate: "債務期間",
                    user: "担当者",
                    paymentTerms: "支払条件",
                    maxDebtNumber: "最大債務額",
                    isSupplier: "供給者も同様に",
                    button: {
                        save: "保存",
                        skip: "スキップ"
                    },
                    defaultCode: "デフォルトコード",
                    enterCustomerName: "顧客名を入力",
                    enterPhone1: "電話番号1を入力",
                    enterPhone2: "電話番号2を入力",
                    enterAddress: "住所を入力",
                    enterAccountNumber: "口座番号を入力",
                    enterAccountOwner: "口座名義人を入力",
                    enterBankName: "銀行名を入力",
                    enterBankAddress: "銀行支店を入力",
                    enterCompanyName: "会社名を入力",
                    enterTaxCode: "税率コードを入力",
                    enterEmail: "メールを入力",
                    enterFacebook: "フェスブックを入力",
                    chooseCustomerGroup: "顧客グループを選択",
                    enterNote: "メモー",
                    enterDebtDate: "支払い遅延日数を入力",
                    choosePaymentDate: "繰り返し支払いの日付を選択",
                    enterEmployee: "担当者",
                    enterPaymentCondition: "支払条件を入力",
                    enterMaxDebt: "最大債務額を入力",
                    notFoundInfoTax: "税務部門のデータが見つかりません",
                    titleImport: "データファイルから顧客をインポート",
                    downloadDefaultFile: "サンプルファイルをダウンロード",
                    excelFile: "Excel ファイル",
                    allowUpdateWhenDuplicateCode: "顧客コードがシステムに既に存在する場合、情報の更新を許可",
                    allowDuplicateEmail: "顧客がメールを重複して登録することを許可",
                    chooseFile: "データファイルを選択",
                    inValidFormatFile: "アップロードされたファイルは正しいxlsx、xls形式でなければなりません",
                    titleRemove: "顧客を削除",
                    contentRemove: "システムは顧客{name}を完全に削除しますが、過去の取引情報は保持されます（もしあれば）。本当に削除してもよろしいですか？",
                    alertRemoveSuccess: "顧客{name}の削除に成功しました."
                }
            },

            // Nhóm khách hàng
            customerGroupIndex: {
                title: "顧客グループ",
                documentTitle: "顧客グループリスト",
                quantity: "数量",
                discount: "割引",
                note: "メモ",
                button: {
                    add: "追加",
                    save: "保存",
                    skip: "スキップ",
                    remove: "削除",
                    agree: "同意",
                },
                placeholder: {
                    search: "検索",
                },
                titleCreate: "顧客グループを選択",
                titleUpdate: "顧客グループを編集",
                info: "情報",
                advanceSetting: "詳細設定",
                name: "グループ名",
                enterName: "顧客グループ名を入力",
                chooseDiscount: "割引を選択",
                vnd: "ドン",
                percent: "%",
                settingConditional: "グループに顧客を追加する条件を設定",
                add: "条件グループに顧客を追加",
                update: "条件に従ってリストを更新",
                notUpdate: "顧客リストを更新しないでください",
                confirm: "確認",
                confirmContent: "新しい顧客グループの追加をキャンセルしますか？",
                contentSkip: "この顧客グループですか？",
                alertSuccess: "新しい顧客グループを追加しました",
                alertCreateSuccess: "正常に追加されました",
                alertUpdateSuccess: "顧客グループを更新しました。",
                titleRemove: "顧客グループを削除",
                contentRemove: "この顧客グループを削除してもよろしいですか？",
                description: "説明",
            },

            // Danh sách nhà cung cấp
            supplierIndex: {
                title: "サプライヤー",
                documentTitle: "サプライヤー管理 | OMIPOS",
                quantity: "数量",
                discount: "割引",
                note: "メモ",
                supplierGroup: "サプライヤーグループ",
                allGroup: "グループ全体",
                totalBuy: "総購入量",
                status: "状態",
                all: "全体",
                active: "活動",
                inActive: "非活動",
                reActive: "活動可能",
                searchSupplier: "コード、名、電話番号による",
                code: "サプライヤーコード",
                name: "サプライヤー名",
                address: "住所",
                deliveryArea: "配送エリア",
                ward: "地区",
                phone: "電話番号",
                email: "メール",
                accountNumber: "口座番号",
                accountOwner: "口座名義人",
                bankName: "銀行名",
                bankAddress: "銀行支店",
                company: "会社",
                taxCode: "税率コード",
                createdBy: "作成者",
                createdAt: "作成日",
                debtDate: "債務条件",
                employee: "担当者",
                numberSKU: "サプライヤーが入力したSKU番号",
                amountImport: "輸入販売",
                openingFee: "サプライヤーコードを開設する手数料",
                paymentDiscount: "支払い割引",
                confirm: "確認",
                contentConfirmCancelCreate: "追加のサプライヤーをキャンセルしますか？",
                contentConfirmCancelUpdate: "サプライヤーの更新をキャンセルしますか？",
                button: {
                    add: "追加",
                    addSupplier: "サプライヤー",
                    update: "更新",
                    remove: "削除",
                },
                placeholder: {
                    search: "検索",
                },
                tabs: {
                    info: "情報",
                    paymentForSupplier: "サプライヤーに支払うべき債務",
                    historyOrder: "注文履歴",
                    historyPurchaseReturn: "輸入/返品履歴"
                },
                values: {
                    info: "info",
                    address: "addressGetOrder",
                    historyOrder: "historyOrder",
                },
                table: {
                    code: "フォームコード",
                    time: "時間",
                    createBy: "作成者",
                    branch: "支店",
                    total: "総合",
                    status: "状態"
                },
                modal: {
                    titleCreate: "サプライヤー追加",
                    titleUpdate: "サプライヤーを編集",
                    branch: "支店",
                    supplierCode: "サプライヤーコード",
                    supplierName: "サプライヤー名",
                    supplierPhoneNumber: "電話番号",
                    supplierBirthDate: "生年月日",
                    supplierGender: "性別",
                    supplierAddress: "住所",
                    supplierProvince: "省/市",
                    supplierDistrict: "区",
                    supplierWard: "区/町",
                    supplierAccountNumber: "口座番号",
                    supplierAccountName: "口座名義人",
                    supplierBankName: "銀行名",
                    supplierBankAddress: "銀行支店",
                    supplierType: "顧客タイプ",
                    personal: "個人",
                    company: "会社",
                    supplierCompanyName: "会社名",
                    supplierTaxCode: "税率コード",
                    supplierメール: "メール",
                    supplierフェイスブック: "フェイスブック",
                    supplierGroup: "サプライヤーグループ",
                    supplierNote: "メモ",
                    supplierDebtDate: "債務条件",
                    user: "担当者",
                    paymentTerms: "支払条件",
                    maxDebtNumber: "最大債務額",
                    isCustomer: "顧客も同様に",
                    openingFee: "サプライヤーコードを開設する手数料",
                    paymentDiscount: "支払い割引",
                    enterName: "サプライヤー名を入力",
                    enterPhone: "電話番号を入力",
                    enterAddress: "住所を入力",
                    chooseProvince: "省/市を選択",
                    chooseDistrict: "区を選択",
                    chooseWard: "区/町を選択",
                    enterAccountNumber: "口座番号を選択",
                    enterAccountOwner: "口座名義人名を選択",
                    enterBankName: "銀行名を入力",
                    enterBankAddress: "銀行支店を入力",
                    enterEmail: "メールを入力",
                    enterCompanyName: "会社を入力",
                    enterTaxCode: "税率コードを入力",
                    chooseSupplierGroup: "サプライヤーグループを選択",
                    enterNote: "メモ入力",
                    enterDebtDate: "支払い遅延日数を入力",
                    choosePaymentDate: "繰り返し支払いの日付を選択",
                    enterPaymentTerm: "支払条件を入力",
                    enterMaxDebt: "最大債務額を入力",
                    tooltip1: "顧客が既に存在する場合、システムは自動的に確認してリンクします",
                    tooltip2: "顧客が存在しない場合、システムは自動的に新しい顧客を作成します",
                    titleUpload: "データファイル提供者を入力",
                    replaceAndUpdate: "サプライヤーコードが重複する場合、情報を置き換えて更新",
                    notReplaceAndUpdate: "サプライヤーコードが重複する場合、情報の置き換えおよび更新は許可されません",
                    chooseFile: "データファイルを選択",
                    titleRemove: "サプライヤーを削除",
                    contentRemove: "システムはサプライヤーを完全に削除しますが、過去の取引情報は保持されます（もしあれば）。本当に削除してもよろしいですか？",
                    button: {
                        save: "保存",
                        skip: "スキップ",
                        action: "実施"
                    }
                }
            },

            // Nhóm nhà cung cấp
            SupplierGroupIndex: {
                title: "サプライヤーグループ",
                documentTitle: "サプライヤーグループリスト| OMIPOS",
                search: "検索",
                add: "追加",
                titleCreate: "サプライヤーグループを追加",
                name: "グループ名",
                enterName: "サプライヤーグループ名を入力",
                note: "メモ",
                confirm: "確認",
                confirmCancelChange: "追加のサプライヤーグループをキャンセルしますか？",
                titleCancelEdit: "サプライヤーグループの更新をキャンセル",
                contentCancelEdit: "サプライヤーグループの更新を取り消しますか？",
                titleUpdate: "サプライヤーグループを編集",
                titleRemove: "サプライヤーグループを削除",
                contentRemove: "このサプライヤーグループを削除してもよろしいですか？",
                contentInActive: "このサプライヤーを停止してもよろしいですか？このサプライヤーの取引情報は保持されます。",
                contentReActive: "このサプライヤーが再び運営できるようにしてもよろしいですか？",
                quantity: "数量",
            },

            // Danh sách nhân viên
            employeeIndex: {
                title: "スタッフリスト",
                employee_code: "スタッフコード",
                employee_name: "スタッフ名前",
                employee_birthday: "生年月日",
                employee_gender: "性別",
                employee_department: "部署",
                employee_job_title: "役職",
                employee_branch_work: "勤務支店",
                employee_user_name: "ユーザー名",
                employee_phone: "電話番号",
                employee_email: "メール",
                employee_status: "状態",
                employee_created_at: "作成時間",
                note: "メモ",
                chooseBranch: "支店を選択",
                chooseJobTitle: "役職を選択",
                chooseDepartment: "部署を選択",
                chooseRole: "役割を選択",
                tab: {
                    info: "情報"
                },
                button: {
                    update: "更新",
                    inActive: "非活動",
                    active: "活動を許可する",
                    remove: "スタッフを削除",
                    add: "スタッフ",
                    file: "ファイル",
                    import: "輸入",
                    export: "輸出ファイル",
                    skip: "スキップ",
                    agree: "同意",
                },
                input: {
                    filter: "コード、名、電話番号による",
                },
                titlRemove: "スタッフを削除",
                contentRemove: "システムはこのスタッフを完全に削除しますが、給与明細は保持されます（もしあれば）。本当に削除してもよろしいですか？",
                alertRemoveSuccess: "スタッフ{name}の削除に成功しました。",
                alertRemoveError: "スタッフ{name}の削除に失敗しました。",
                confirmReActive: "このスタッフを再び働かせますか？",
                confirmInActive: "このスタッフを停止してもよろしいですか？",
                alertUpdateSuccess: "ユーザーの更新に成功しました",
            },
            modalCreateEmployee: {
                title_create: "新しいスタッフを追加",
                title_update: "スタッフを更新",
                code: "スタッフコード",
                name: "スタッフ名前",
                birth_date: "生年月日",
                gender: "性別",
                male: "男性",
                female: "女性",
                phone_number: "電話番号",
                email: "メール",
                department: "部署",
                job_title: "役職",
                can_login: "omiposで活動しているスタッフ",
                branch: "勤務支店",
                username: "ユーザー名",
                password: "パスワード",
                re_password: "パスワードを入力",
                note: "メモ",
                placeholder: {
                    code: "スタッフコードを選択",
                    name: "スタッフ名を選択",
                    birth_date: "生年月日を入力",
                    phone_number: "電話番号を入力",
                    email: "メールを入力",
                    department: "部署を選択",
                    job_title: "役職を選択",
                    branch: "支店を選択",
                    username: "ログイン名を入力",
                    password: "パスワードを入力",
                    note: "メモを入力"
                },
                button: {
                    save: "保存",
                    skip: "スキップ",
                },
                confirmCancelChange: "変更をキャンセルしてもよろしいですか？",
                confirm: "確認",
                emptyName: "スタッフ名まだ選択していません",
                emptyDepartment: "部署まだ選択していません",
                emptyJobTitle: "役職まだ選択していません",
                emptyWorkingBranch: "支店まだ選択していません",
                emptyPassword: "パスワードをまだ入力していません",
                emptyRePassword: "パスワードをまだ再入力していません",
                regexPassword: "パスワードは8文字以上で、大文字、小文字、数字、特殊文字を含める必要",
                invalidPassword: "2つのパスワードが一致しません",
                alertCreateSuccess: "新しいスタッフの追加に成功しました",
                alertUpdateSuccess: "スタッフの更新に成功しました",
                alertCreateError: "スタッフの更新に失敗しましたg"
            },
            ModalImportEmployees: {
                headerTitle: "データファイルからスタッフを入力",
                templateTitle: "サンプルファイルをダウンロード",
                urlTitle: "Excel ファイル",
                contentTitle: "重複したスタッフコードと異なるスタッフ情報の処理：",
                errStopTitle: "エラーを報告してインポートを停止",
                changeUpdateTitle: "旧従業員情報を新従業員情報に更新する"
            },

            // Phòng ban
            DepartmentIndex: {
                title: "部署",
                document_title: "部署 | OmiPos",
                placeholder: {
                    search: "検索",
                },
                button: {
                    create: "追加",
                },
                table: {
                    name: "部署名",
                    quantity: "数量",
                    description: "説明",
                    status: "状態",
                    code: "部署コード"
                },
                status: {
                    active: "活動",
                    in_active: "非活動",
                },
                modal: {
                    createOrUpdate: {
                        title_create: "部署を追加",
                        title_update: "部署を更新",
                        name: "部署名",
                        description: "説明",
                        status: "状態",
                        active: "活動",
                        in_active: "非活動",
                        placeholder: {
                            name: "部署名を入力",
                            code: "部署コードを入力してください",
                        },
                        button: {
                            save: "保存",
                            skip: "スキップ",
                            remove: "削除"
                        },
                        title_confirm: "確認",
                        content_confirm_create: "追加の部署をキャンセルしますか？",
                        content_confirm_update: "この部署ですか？",
                        alerts: {
                            create_success: "新しい部署の追加に成功しました",
                            update_success: "部署の更新に成功しました",
                        }
                    },
                    modalRemove: {
                        title: "確認",
                        content: "システムはこの部署を完全に削除します。実行しますか？",
                        button: {
                            confirm: "同意",
                            skip: "スキップ"
                        }
                    }
                }
            },

            // Chức danh
            JobTitle: {
                titleCreate: "新役職を追加",
                titleUpdate: "新役職を更新",
                name: "役職名",
                description: "説明",
                status: "状態",
                active: "活動",
                inActive: "非活動",
                save: "保存",
                skip: "スキップ",
                remove: "削除",
                confirm: "確認",
                agree: "同意",
                cancelUpdate: "職名の更新をキャンセルしますか？",
                cancelCreate: "新しい職種の追加をキャンセルしますか？",
                contentRemove: "システムはこの職種を完全に削除します。削除してもよろしいですか？",
            },

            // Đối tác giao hàng
            TransporterIndex: {
                personal: "個人",
                company: "会社",
                title: "配送パトナー",
                title_document: "配送パトナー | OmiPos",
                integration: "統合",
                other: "その他",
                transporter_group: "配送パートナーグループ",
                total_delivery_fee: "配送手数料合計",
                fee_from: "から",
                fee_to: "まで",
                current_debt: "現在の債務",
                select_show: "表示選択",
                type: "パトナータイプ",
                code: "パトナーコード",
                name: "パトナー名",
                address: "住所",
                phone: "電話番号",
                email: "メール",
                area_delivery: "配送アリア",
                ward: "区",
                transporter_group_name: "パトナーグループ",
                note: "メモ",
                feeAdjustments: "調整",
                invoice_code: "コード請求書",
                delivery_code: "配送証券コード",
                time: "時間",
                invoice_value: "請求書価格",
                cod: "代金引換（COD）としてまだ集金が必要",
                fee: "配送パートナーへの支払手数料",
                status: "状態",
                tiket_code: "フォームコード",
                type_fee: "タイプ",
                value: "価値",
                debt: "パートナーに支払うべき債務",
                transporterCode: "配送パートナーコード",
                placeholder: {
                    search: "コード、名、電話番号による",
                    transporter_group: "パートナーグループを選択",
                    value: "価値",
                    code: "デフォルトコード",
                    name: "パートナー名を入力",
                    phone: "電話番号を入力",
                    email: "メールを入力",
                    group: "パトナーグループを選択",
                    address: "住所を入力",
                    groupName: "配送パートナーグループ名を入力"
    
                },
                button: {
                    feeAdjustments: "調整",
                    create: "配送パトナー",
                    import: "輸入",
                    export: "輸出ファイル",
                    update: "更新",
                    in_active: "非活動",
                    remove: "削除",
                    active: "活動",
                    update_delivery: "配送の更新",
                    payment: "支払い",
                },
                tabs: {
                    info: "情報",
                    history: "配送歴史",
                    return_fee: "取引の手数料を支払う必要",
                    control_history: "取引の手数料を支払う必要",
                },
                modal: {
                    // Modal xóa transport
                    modalRemove: {
                        title: "確認",
                        content: "システムは相手先の検出されていない取引のみ削除できます。本当に削除してもよろしいですか？",
                        button: {
                            confirm: "同意",
                            skip: "スキップ"
                        }
                    },
                    // Modal ngừng hoạt động
                    modalStop: {
                        title: "確認",
                        content: "この配送パートナーを無効にしてもよろしいですか？",
                        button: {
                            confirm: "同意",
                            skip: "スキップ"
                        }
                    },
                    modalAddTransporters: {
                        name: "グループ名",
                        namePlaceholder: "商品グループ名を入力",
                        note: "メモ",
                        notePlaceholder: "メモ",
                        buttonAddUpdate: {
                            confirm: "保存",
                            skip: "スキップ"
                        },
                        buttonRemove: "削除",
                        add: {
                            title: "新しい配送パトナーを更新",
                            button: {
                                confirm: "保存",
                                skip: "スキップ"
                            },
                            msgSuccess: "新しい配送パートナーを追加しました",
                            msgError: "新しい配送パートナーの追加に失敗しました",
                        },
                        update: {
                            title: "配送パトナーを更新",
                            button: {
                                confirm: "保存",
                                skip: "スキップ"
                            },
                            msgSuccess: "新しい配送パートナーを更新しました",
                            msgError: "新しい配送パートナーの更新に失敗しました",
                        },
                        remove: {
                            title: "確認",
                            content: "この{name}配送パートナーグループを削除してもよろしいですか？",
                            button: {
                                confirm: "同意",
                                skip: "スキップ"
                            },
                            msgSuccess: "新しい配送パートナーグループを削除しました",
                            msgError: "新しい配送パートナーグループの削除に失敗しました",
                        },
                    },
                    modalAddTransporterGroups: {
                        name: "グループ名",
                        namePlaceholder: "商品グループ名を入力",
                        note: "メモ",
                        notePlaceholder: "メモ",
                        buttonAddUpdate: {
                            confirm: "保存",
                            skip: "スキップ"
                        },
                        buttonRemove: "削除",
                        add: {
                            title: "パトナーグループを追加",
                            button: {
                                confirm: "保存",
                                skip: "スキップ"
                            },
                            msgSuccess: "新しい配送パートナーグループを追加しました",
                            msgError: "新しい配送パートナーグループの追加に失敗しました",
                        },
                        update: {
                            title: "配送パートナーグループを更新",
                            button: {
                                confirm: "保存",
                                skip: "スキップ"
                            },
                            msgSuccess: "新しい配送パートナーグループを追加しました",
                            msgError: "新しい配送パートナーグループの追加に失敗しました",
                        },
                        remove: {
                            title: "確認",
                            content: "この{name}配送パートナーグループを削除してもよろしいですか？",
                            button: {
                                confirm: "同意",
                                skip: "スキップ"
                            },
                            msgSuccess: "新しい配送パートナーグループを削除しました",
                            msgError: "新しい配送パートナーグループの削除に失敗しました",
                        },
                    },
                    modelSelectInvoicePayment: {
                        title: "配送パートナーとの支払い",
                        search: "検索",
                        placeholder: {
                            invoice: "請求書コードによる",
                            delivery: "配送コードによる",
                            control: "調整コードによる",
                        },
                        branch: "支店",
                        status_delivery: "配送状態",
                        time: "時間",
                        start_date: "日から",
                        end_date: "日まで",
                        invoice_code: "請求書コード",
                        delivery_code: "配送コード",
                        control_code: "調整コード",
                        customer: "顧客",
                        transporter: "配送パートナー",
                        invoice_value: "請求書価格",
                        cod: "集金が必要",
                        receive_cod: "代金引換（COD）",
                        fee_payment: "配送パートナーへの手数料支払い",
                        debt_fee_payment: "支払うべき配送料",
                        cod_fee_payment: " COD - 配送パートナーへの手数料",
                        button: {
                            payment: "支払い",
                            skip: "スキップ",
                        }
                    }
                }
            },

            // Kênh bán
            SaleChannelIndex: {
                title: "販売チャンネル",
                status: "状態",
                search: "検索",
                add: "追加",
                documentTitle: "販売チャンネルリスト | OMIPOS",
                all: "全体",
                active: "活動",
                inActive: "非活動",
                titleCreate: "販売チャンネルを追加",
                titleUpdate: "販売チャンネルを編集",
                name: "販売チャンネル名",
                description: "説明",
                confirm: "確認",
                contentConfirmCancelCreate: "タイトルの追加をキャンセルしますか？",
                contentConfirmCancelUpdate: "タイトルの更新をキャンセルしますか？",
                titleRemove: "販売チャネルを削除する",
                contentRemove: "取引がない場合のみ営業チャネルが削除されます。チャネル{name}を削除してもよろしいですか？",
                alertRemoveSuccess: "販売チャネル{name}を削除しました",
            },

            // Danh mục bảng giá
            PriceListsIndex: {
                title: "一般価格表",
                document_title: "一般価格表| OmiPos",
                time: "時間",
                branch: "支店",
                status: "状態",
                effect: "効果",
                placeholder: {
                    search: "価格表の名前を入力",
                    branch: "支店を選択",
                },
                button: {
                    create: "価格表を追加",
                    file: "ファイル",
                    import: "輸入",
                    export: "輸出ファイル",
                },
                modal: {
                    title: "Excelファイルから価格表を選択",
                    title_import_detail: "Excelファイルから製品リストを選択",
                    button: "データファイルを選択",
                    download_file: "サンプルファイルをダウンロード",
                    button_action: "実施",
                    alert_error_validate: "アップロードされるファイルは正しいxlsx、xls形式である必要",
                    alert_error_status: "エラーを報告してインポートを停止",
                    update_status: "価格表情報を置き換えて更新",
                    titleCreate: "価格表を追加",
                    titleUpdate: "価格表を編集",
                    info: "情報",
                    applyArea: "適用範囲",
                    name: "価格表名",
                    startDate: "有効開始日",
                    to: "まで",
                    apply: "適用",
                    notApply: "適用しない",
                    allBranch: "システム全体",
                    branch: "支店",
                    chooseBranch: "適用する支店を選択",
                    allUser: "トレーダー全体",
                    createdBy: "取引作成者",
                    chooseUser: "適用する取引先を選択",
                    allCustomer: "顧客全体",
                    customerGroup: "顧客グループ",
                    chooseCustomerGroup: "適用する顧客グループを選択",
                    guest: "小売顧客",
                    chooseCustomer: "適用する顧客を選択",
                    price: "価格",
                    chooseProduct: "売上画面で商品を選択",
                    chooseProductDifferentPrice: "価格表以外の商品を選択することができる",
                    chooseProductAndWarning: "警告付きで価格表以外の商品を選択することができる",
                    notChooseProductDifferentPrice: "価格表以外の商品を選択することはできません",
                    confirm: "確認",
                    contentCancelCreate: "新しい価格表の追加をキャンセルしますか？",
                    contentCancelUpdate: "価格表の更新をキャンセルしますか？",
                    alertCreateSuccess: "新しい価格表を追加しました",
                    alertUpdateSuccess: "価格表を更新しました",
                    choosePriceList: "--価格表を選択--",
                    contentRemove: "システムは完全に {name} の価格表を削除します。削除してもよろしいですか？",
                    alertRemoveSuccess: "{name} の価格表を削除しました",
                    alertRemoveError: "{name} の価格表の削除に失敗しました",
                    time: "有効時間"
                },
                details: {
                    code: 'コード',
                    time: '時間',
                    seller: '販売者',
                    customer: '顧客',
                    sum: '合計',
                    revenue: '収益',
                    recipientPays: '受取人払い',
                    returnedGuests: '顧客に返金済み',
                }
            },

            // Chi tiết bảng giá
            PriceListDetailIndex: {
                title: "価格表の詳細",
                priceList: "価格表",
                choosePriceList: "価格表を選択する",
                categories: "商品グループ",
                searchCategories: "商品グループを検索する",
                all: "全体",
                search: "商品コードまたは商品名を入力",
                documentTitle: "価格設定 | OMIPOS",
                productCode: "商品コード",
                productName: "商品名",
                unitName: "単位",
                inventories: "在庫",
                lastEntryPrice: "最終入力価格",
                price: "普通価格",
                comparePrice: "-- 比較価格 --",
                costPrice: "本社価格",
                alertUpdateSuccess: "更新が成功しました",
                contentConfirmClone: "残りの製品を{name}の価格表に追加しますか？",
                alertCreateSuccess: "価格表の追加に成功しました",
                alertCreateError: "価格表の追加に失敗しました",
                salePrice: "価格",
    
            },

            // Danh mục vị trí
            LocationIndex: {
                title: " 商品の場所",
                documentTitle: "場所のカテゴリー | OMIPOS",
                title_location: "場所のリスト",
                title_confirm: "確認",
                content_confirm: "場所のカテゴリーを削除してもよろしいですか？",
                document_title: "場所のカテゴリー | OMIPOS",
                button: {
                    filter: "フィルター",
                    create: "更新",
                    create_location_category: "カテゴリー追加",
                    create_fast_location_category: "迅速に場所を追加",
                    action: "操作",
                    export: "excel　輸出",
                },
                table_location_category: {
                    code: "コード",
                    name: "名前",
                    number_location: "",
                    created_at: "作成日",
                    button: {
                        update: "編集",
                        add_location: "場所を追加",
                        remove: "削除",
                    }
                },
                table_location: {
                    barcode: "バーコード",
                    name: "名前",
                    code: "コード",
                    created_at: "作成日",
                    button: {
                        update: "編集",
                        remove: "削除"
                    }
                },
                titleCreate: "カテゴリー追加",
                info: "情報",
                branch: "支店",
                chooseBranch: "- 支店選択 -",
                parentCategory: "親カテゴリー",
                categories: "カテゴリー",
                categoriesName: "カテゴリーリスト",
                enterCategoriesName: "カテゴリー名を入力",
                categoriesCode: "カテゴリーコード",
                enterCategoriesCode: "カテゴリーコードを入力",
                addRow: "行を追加",
                confirmCancelCreate: "新しい場所カテゴリの追加をキャンセルしてもよろしいですか？",
                maxRow: "最大で20行までしか許可されていません",
                existCategoriesName: "場所カテゴリ「{name}」はすでに存在",
                existCategoriesCode: "場所カテゴリコード「{name}」はすでに存在",
                emptyCategoriesName: "場所カテゴリ名を入力",
                emptyCategoriesCode: "場所カテゴリコードを入力",
                titleQuickAdd: "場所カテゴリコードを追加",
                categoriesLevel1: "レベル1カテゴリー",
                examLevel1: "例：1階",
                categoriesLevel2: "レベル2カテゴリー",
                examLevel2: "例：[1-3]アリア",
                categoriesLevel3: "レベル3カテゴリー",
                examLevel3: "例：[1-3]棚",
                categoriesLevel4: "レベル4カテゴリー",
                examLevel4: "例：[1-3]階",
                locations: "位置",
                examLocations: "例：ボックス[1-4]、書類ボックス",
                confirmCancelQuickAdd: "位置カテゴリの追加をすぐにキャンセルしてもよろしいですか？",
                emptyCategoriesLevel1: "レベル1カテゴリ名を入力",
                titleCreateLocation: "在庫に新しい位置を追加",
                addInfo: "情報追加",
                note: "注意",
                contentNote: "結合位置バーコード [カテゴリコード1] - [カテゴリコード2] .... - [位置コード]",
                addLocationName: "位置名を追加",
                addLocationCode: "位置コードを追加",
                addBarcode: "バーコードを追加",
                addMaxQuantity: "最大数量を追加",
                enterLocationName: "位置名を入力",
                enterLocationCode: "位置コードを入力",
                enterBarcode: "バーコードを入力",
                enterMaxQuantity: "商品の最大数",
                locationInFolder: "エリア内の位置",
                barcode: "バーコード",
                name: "名前",
                code: "コード",
                totalProducts: "商品数",
                totalQuantity: "商品数量",
                maxTotalQuantity: "商品が収容できる数量",
                confirmCancelCreateLocation: "追加の位置リストをキャンセルしてもよろしいですか？",
                existLocationName: "位置名は既に存在します",
                duplicateLocationName: "位置名が重複しています",
                existLocationCode: "商品位置コードは既に存在します",
                duplicateLocationCode: "商品位置コードが重複しています",
                row: "行",
                emptyLocationName: "位置名を入力",
                emptyLocationCode: "商品位置コードを入力",
                emptyLocationBarCode: "バーコードを入力",
                emptyData: "データを入力",
                titleChooseParentCate: "親カテゴリを選択",
                titleUpdateLocation: "位置を編集",
                total: "総合",
                confirmCancelUpdateLocation: "本当に位置の更新をキャンセルしますか？",
                alertUpdateSuccess: "位置が正常に更新されました",
                alertUpdateError: "位置の更新に失敗しました",
                confirmRemoveLocation: "本当に位置を削除しますか？",
                noteRemoveLocation: "注: すでに商品が含まれている位置は削除できません",
                alertRemoveSuccess: "位置が正常に削除されました",
                alertRemoveError: "この位置には現在、商品が含まれているロケーションがあります",
                titleUpdateLocationCategories: "位置カテゴリを編集",
                cancelUpdateLocationCategories: "本当に位置カテゴリの更新をキャンセルしますか？?"
            },

        /* Hết danh mục */
        
        /* Giao dịch */
            // Dự trù chi tiết
            PlanIndex: {
                title: "詳細な予測",
                record: "記録番号",
                branch: "支店",
                time: "時間",
                createdBy: "作成者",
                status: "状態",
                plansAdd: "新しい見積もり",
                total_products: "推定された商品コードの総数",
                total_quantity_products: "予想される商品の総数",
                chooseBranch: "支店お選択",
                chooseCreatedBy: "作成者を選択",
                search: "コードによると、予想されるレポート名",
                documentTitle: "詳細な計画 | OMIPOS",
                note: "メモ",
                autoCreateCode: "自動報告コード",
                name: "レポートを選択",
                chooseUnit: "単位を選択",
                detail: {
                    code: "予測コード",
                    name: "予測名",
                    branch: "予測支店",
                    time: "平均販売量を計算する時間（日）",
                    estimate: "売れるまでの推定日数",
                    status: "状態",
                    createdBy: "作成者",
                    description: "メモ",
                    plan_purchase: "購入予測",
                },
                button: {
                    save: "保存",
                    open: "投票を開始",
                    process: "投票を処理中",
                    print: "印刷",
                    export: "ファイルを輸出",
                    delete: "削除",
                    cancel: "キャンセル",
                    create: "在庫予測を作成",
                    exportOverview: "概要ファイルを輸出",
                    exportDetail: "詳細ファイルを輸出",
                    fileOverview: "概要ファイル",
                    fileDetails: "詳細ファイル",
                    new: "新しい予測i"
                },
                modal: {
                    title: "確認",
                    content: "本当に予測チケットをキャンセルしますか？",
                    content_multiple: "システムは、下書き状態で購入計画表にないもののみキャンセルを許可します。本当に詳細見積もりをキャンセルしますか？",
                    content_delete_multiple: "システムは、下書き状態で購入計画表にないもののみ削除を許可します。本当に詳細見積もりをキャンセルしますか？",
                    content_delete: "本当に見積もりを削除しますか？",
                    nextStep: "スキップ",
                    confirm: "同意",
                    success: "データのキャンセルに成功しました",
                    success_delete: "データの削除に成功しました"
                },
                alerts: {
                    update_error: "データの更新に失敗しました",
                    update_success: "データの更新に成功しました",
                }
            },

            // Dự trù nhập mua
            PlanPurchaseIndex: {
                title: "購入予測",
                record: "記録番号",
                filter_time: "時間",
                filter_user: "作成者",
                total_products: "予測商品コードの総数",
                total_quantity_products: "予測商品の総数",
                total_supplier: "サプライヤーの総数",
                chooseBranch: "支店を選択",
                chooseCreatedBy: "作成者を選択",
                search: "コード、推定レポート名による",
                documentTitle: "購入予測　| OMIPOS",
                detail: {
                    branchCreate: "予測支店",
                    branch: "予測された支店",
                    plan: "詳細な予測",
                },
                modal: {
                    content_cancel: "在庫予測をキャンセルしてもよろしいですか？",
                    content_delete: "システムは下書きのみ削除を許可します。在庫予測を削除してもよろしいですか？",
                    content_cancel_multiple: "複数の在庫予測をキャンセルしてもよろしいですか？",
                    content_delete_multiple: "システムは下書きのみ削除を許可します。在庫予測を削除してもよろしいですか？"
                },
                alerts: {
                    error: "在庫予測を空白にすることはできません。",
                    create_success: "在庫予測を成功裏に作成しました",
                    create_error: "在庫予測の作成に失敗しました",
                    update_success: "在庫予測を成功裏に更新しました",
                    update_error: "在庫予測の更新に失敗しました",
                    create_order: "発注書を成功裏に作成しました",
                },
                button: {
                    process: "処理中",
                    create_po: "発注書を作成",
                }
            },
            planCreateUpdate: {
                title: "詳細見積もり",
                title_plan_purchase: "在庫予測",
                table: {
                    stt: "循環",
                    code: "商品コード",
                    name: "商品名",
                    unit: "単位",
                    min_inventory: "最低在庫レベル",
                    max_inventory: "最大在庫レベル",
                    estimate_number: "予測変動の数",
                    avg_number_of_sale: "1日あたりの平均販売数",
                    sys_estimate_number: "予測システムの販売",
                    available_inventory: "販売可能在庫",
                    total_quantity_plan: "見積もり数量",
                    total_quantity_order: "合計見積もり数量",
                    total_quantity_real_order: "実際の発注数量",
                    price: "価格表",
                    supplier: "サプライヤー",
                },
                created_by: "作成者",
                plan_code: "コード",
                plan_name: "名前",
                plan_branch: "見積もり支店",
                plan_time: "平均販売数量/日の計算時間",
                to: "まで",
                plan_estimate: "販売までの見積日数",
                plan_status: "状態",
                note: "メモ",
                total_product: "合計見積もり商品コード",
                total_quantity_product: "合計予定商品数",
                total_supplier: "合計仕入先数",
                button: {
                    success: "完了",
                    draft: "下書き",
                    create_order: "発注書を作成",
                },
                status_draft: "下書き",
                alerts: {
                    error: "在庫予測を空白にすることはできません",
                    create_success: "詳細な見積もりを成功裏に作成しました",
                    update_success: "詳細な見積もりを成功裏に作成しました",
                }
            },

            // Đặt hàng nhập
            OrderSupplierIndex: {
                title: "購入注文",
                document_title: "購入注文 | OmiPOS",
                filter: {
                    branch: '支店',
                    time: '時間',
                    status: '状態',
                    status_payment: '支払い状態',
                    created_by: '作成者',
                    order_by: '注文者',
                    placeholder: {
                        branch: '支店を選択',
                        created_by: '作成者を選択',
                        order_by: 'インストール受取人を選択',
                        search: 'コード入力フォームによる',
                    }
                },
                code: "発注コード",
                time: "時間",
                supplier: "サプライヤー",
                customer: "顧客",
                price_list: "価格表",
                purchase_code: "購入受領コード",
                status: "状態",
                status_complete: "完了",
                status_cancel: "削除した",
                status_purchase_a_part: "部分的にインポート済み",
                status_draft: "一時的",
                statusConfirmed: "サプライヤー確認済み",
                branch: "支店",
                employee: "ビジネスマン",
                created_by: "作成者",
                order_by: "注文者",
                sale_channel: "販売チャネル",
                note: "メモ",
                method: "支払い方法",
                total_quantity: "合計",
                total_products: "商品の総数",
                total_origin: "商品の総コスト",
                discount: "割引",
                total: "合計プラス",
                payment_supplier: "サプライヤーに支払う必要",
                total_payment: "サプライヤー支払済み",
                expected_date: "予定のインポート日",
                tabs: {
                    info: "情報",
                    history_purchase: "輸入歴史",
                    history_payment: "支払い歴史",
                    history_delivery: "配送歴史",
                    table: {
                        history_payment: {
                            code: "投票コード",
                            time: "時間",
                            created_by: "作成者",
                            method: "方法",
                            status: "状態",
                            total_price: " 費用",
                        },
                        history_purchase: {
                            code: "コード",
                            time: "時間",
                            create_by: "作成者",
                            status: "状態",
                        }
                    }
                },
                button: {
                    add: "発注書",
                    action: "作成",
                    export: "輸出ファイル",
                    export_all: "概要リストファイルの輸出",
                    export_detail: "詳細リストファイルの輸出",
                    import: "輸入ファイル",
                    file: "ファイル",
                    file_all: "概要ファイル",
                    file_detail: "詳細ファイル",
                    cancel: "キャンセル",
                    print: "印刷",
                    print_barcode: "コードスタンプを印刷",
                    show: "オープン",
                    save: "保存",
                    update: "更新",
                    create_purchase: "購入受領書を作成",
                    copy: "コーピー",
                    import_invoice_supplier: "サプライヤーの請求書を受け取る",
                    process_ticket: "処理",
                    close: "終了",
                },
                titleCreate: "発注書",
                paymentForSupplier: "サプライヤーに支払済み",
                needToPaySupplier: "サプライヤーに支払う必要",
                paidSupplier: "サプライヤーへの支払い",
                supplierReturn: "サプライヤーの返品",
                estimatedImportDate: "予定納品日",
                emptyOrder: "注文が空",
                invalidETImportDate: "予定の輸入日は作成日より後でなければなりません",
                alertCreateSuccess: "発注書が正常に作成されました",
                alertUpdateSuccess: "発注書が正常に更新されました",
            },
            DetailSupplierCard: {
                title: "発注書",
                orderCode: " 発注書コード",
                orderDate: "時間",
                supllierName: "サプライヤー",
                createdBy: "作成者",
                statusName: "状態",
                branchName: "支店",
                orderBy: "注文者",
                expectedDateOfImport: "予定の輸入日",
                note: "メモ",
                prodCode: "商品コード",
                prodName: "商品名",
                prodQuantity: "数量",
                prodPrice: "価格表",
                prodDiscount: "割引",
                prodImportPrice: "輸入価格",
                totalPrice: "合計金額",
                totalQuantity: " 総数量",
                totalProduct: "総商品数",
                total: "商品の総コスト",
                discount: "割引",
                sumTotal: "合計",
                paymentForSupplier: "サプライヤーに支払った金額"
            },
            ReportPayToDebt: {
                placeholder: {
                    order_import: "発注書コードを入力",
                    import: "購入コードを入力",
                    supplier: "サプライヤー名、コードによる"
                },
                title: {
                    supplier: "サプライヤー",
                    order_import: "発注番号",
                    import: "購入コードを入力"
                }
            },

            // Nhập hàng
            PurchaseOrderIndex: {
                title: "購入領収書",
                document_title: "請求書 | OmiPos",
                filter: {
                    branch: '支店',
                    time: '時間',
                    status: '状態',
                    status_invoice: '請求書の状態',
                    created_by: '作成者',
                    import_by: '作成者',
                    priceList: '価格表',
                    placeholder: {
                        branch: '支店お選択',
                        created_by: '作成者',
                        import_by: '輸入者を選択',
                        priceList: '価格表',
                        search: '購入コードによる',
                        sold_by: '売り手を選択',
                    }
                },
                code: "購入コード",
                time: "時間",
                supplier: "サプライヤー",
                customer: "顧客",
                price_list: "価格表",
                order_code: "注文コードが入力",
                status: "状態",
                statusPurchased: "完了",
                branch: "支店",
                employee: "ビジネスマン",
                created_by: "作成者",
                import_by: "購入者",
                sale_channel: "販売チャンネル",
                note: "メモ",
                method: "支払い方法",
                total_quantity: "総合料",
                total_products: "商品の総数",
                total_origin: "商品の総コスト",
                discount: "割引",
                total: "合計",
                total_payment: "サプライヤーに支払った金額",
                tabs: {
                    info: "情報",
                    history_return: "返品履歴",
                    history_payment: "支払い履歴",
                    table: {
                        history_payment: {
                            code: "フォームコード",
                            time: "時間",
                            created_by: "作成者",
                            method: "方法",
                            status: "情報",
                            total_price: "支出",
                        },
                        history_return: {
                            code: "返品コード",
                            time: "時間",
                            receive_by: "返品者",
                            total_price: "合計",
                            status: "状態",
                        }
                    }
                },
                button: {
                    add: "商品の輸入",
                    action: "操作",
                    export: "輸出ファイル",
                    export_all: " 概要リストファイルの輸出",
                    export_detail: "詳細リストファイルの輸出",
                    import: "輸入ファイル",
                    file: "ファイル",
                    file_all: "概要ファイル",
                    file_detail: "詳細ファイル",
                    cancel: "キャンセル",
                    print: "印刷",
                    print_barcode: "コードスタンプを印刷",
                    show: "オープン",
                    save: "保存",
                    update: "更新",
                    return: "返品",
                    copy: "コーピー",
                    import_invoice_supplier: "サプライヤーの請求書を受け取る",
                    process_ticket: "処理",
                    sendZalo: "zaloで送る"
                },
            },

            // Trả hàng nhập (0)
            ReturnPurchaseOrder: {
                title: "返品",
                selectReturnBy: "支払いを選択",
                totalAmountReturn: "支払った総額",
                supplierNeedToPay: "サプライヤーに支払う必要",
                supplierPaid: "サプライヤーが支払済",
                returnPrice: "返品価格",
                returnDiscount: "返品割引",
                updateSuccess: "{code} の返品受領書が正常に更新",
                includedInDebt: "債務に含まれる",
                advanceRefund: "前払金を返金",
                amountSupplierPaid: "サプライヤーによる支払い",
                amountReturnSupplier: "サプライヤーに返金"
            },

            // Tạm ứng quyết toán
            SettlementAdvanceIndex: {
                title: "前払いの決済",
                document_title: "前払いの申請",
                branch: "支店",
                time: "時間",
                created_by: "作成者",
                status: "状態",
                code: "前払いの決済コード",
                created_at: "作成日",
                note: "メモ",
                po_code: "発注書コード",
                po_status: "発注書の状態",
                payment_date: "契約に基づく支払いスケジュール",
                supplier_code: "サプライヤーコード",
                supplier_name: "サプライヤー名",
                payment_method: "支払い方法",
                payment_info: "請求情報",
                cash: "金額",
                advance_info: "決済情報",
                account_number: "口座番号",
                account_owner: "口座名義人名",
                bank_address: "銀行＋支店",
                settlement: "前払い",
                advances: "決済",
                compare: "差額",
                import_number: "入力された票数",
                invoice_number: "請求書の番号",
                invoice_info: "請求書の情報",
                invoice_cash: "請求書の金額",
                invoice_status: "請求書の状態",
                placeholder: {
                    branch: "支店を選択",
                    created_by: "作成者を選択",
                    search: "前払いの申請コードによる"
                },
                button: {
                    add: "更新",
                    export: "輸出ファイル",
                    print: "印刷",
                    save: "保存",
                    update: "更新",
                    cancel: "キャンセル"
                },
                tabs: {
                    info: "情報"
                },
                alertUpdateSuccess: "前払いの決済 {code} を正常に更新しました",
                alertUpdateError: "前払いの決済 {code} の更新に失敗しました。",
                alertCancelSuccess: "投票が正常にキャンセルされました。",
                confirmCancel: "前払いの決済 {code} をキャンセルしてもよろしいですか？",
            },

            // BAS
            DiscountsBAS: {
                title: "BAS後の割引",
                document_title: "BAS後の割引 | OmiPos",
                branch: "支店",
                time: "作成時間",
                created_by: "作成者",
                status: "状態",
                code: "フォームコード",
                supplierCode: "サプライヤーコード",
                supplierName: "サプライヤー名",
                created_at: "作成日",
                updated_at: "更新期間",
                note: "メモ",
                totalPrice: "合計金額",
                button: {
                    add: "更新",
                    export: "輸出ファイル",
                    file_all: "概要ファイル",
                    file_detail: " 詳細ファイル",
                    export_all: "概要ファイル",
                    export_detail: "詳細ファイル",
                    print: "印刷",
                    save: "保存",
                    update: "更新",
                    open: "オープン",
                    cancel: "キャンセル",
                    delete: "削除",
                    success: "完了",
                    draft: "一時保存",
                    action: "操作",
                },
                alerts: {
                    error: "サプライヤーを選択",
                    errorMount: "割引額を入力",
                    create_success: "在庫予測の作成に成功",
                    update_discount_bas_error: 'BASの更新に失敗しました',
                    update_discount_bas_success: "BASを更新しました",
                    create_discount_bas_error: '新しいBASの追加に失敗しました',
                    create_discount_bas_success: '新しいBASを追加しました'
        
                },
                tabs: {
                    info: "情報"
                },
                table: {
                    STT: "順番",
                    supplierId: "サプライヤーコード",
                    supplierName: "サプライヤー名",
                    content: "内容",
                    amount: "金額",
                    deductionLimit: "相殺期間",
                },
                placeholder: {
                    search: "BASコードによる",
                    created_by: "作成者を選択",
                    content: "内容を入力",
                    deductionLimit: "相殺期間を入力",
                    branch: "支店を選択"
                }
            },

            // Đặt hàng bán
            OrderIndex: {
                title: "注文",
                document_title: "注文 | OmiPos",
                filter: {
                    branch: '支店',
                    time: '時間',
                    status: '状態',
                    created_by: '作成者',
                    ordered_by: '営業スタッフ',
                    saleChannel: '販売チャンネル',
                    placeholder: {
                        branch: '支店を選択',
                        created_by: '作成者を選択',
                        ordered_by: '営業スタッフを選択',
                        saleChannel: '販売チャンネルを選択',
                        search: '注文コードを選択',
                    }
                },
                code: "注文コード",
                time: "時間",
                customer: "顧客",
                price_list: "価格表",
                expected_date_of_transfer: "お届け予定日",
                invoice_code: "請求書コード",
                status: "状態",
                branch: "支店",
                created_by: '作成者',
                ordered_by: '営業社員',
                status_on_ecommerce: "プラットフォーム上のステータス",
                sale_channel: "販売チャンネル",
                note: "メモ",
                total_quantity: "小計",
                total_origin: "合計",
                discount: "割引",
                surcharge: "追加料金",
                total: "合計",
                total_payment: "顧客が支払済",
                transaction_fee: "取引手数料",
                shipping_fee_collected_by_customer: "実際の配送料（顧客から）",
                fees_paid_to_delivery_partners: "配送パートナーへの支払い料金",
                tabs: {
                    info: "情報",
                    history_invoice: "請求書履歴",
                    history_payment: "支払い履歴",
                    table: {
                        history_payment: {
                            code: "コード",
                            time: "時間",
                            created_by: "作成日",
                            method: "方法",
                            status: "状態",
                            total_price: "収益",
                        },
                        history_invoice: {
                            code: "請求書コード",
                            time: "時間",
                            created_by: "作成者",
                            value: "価値",
                            status: "状態",
                        },
                    },
                },
                button: {
                    order: "注文",
                    export: "輸出ファイル",
                    file_all: "概要ファイル",
                    file_detail: "詳細ファイル",
                    end: "完了",
                    save: "保存",
                    process_order: "注文処理",
                    print: "印刷",
                    copy: "コーピー",
                    cancel: "キャンセル",
                },
            },
            modalEndOrder: {
                title: "注文フォームを完了",
                content: "商品がまだ配送されていなくても注文を処理できなくなります。注文を完了して注文状態を変更してもよろしいですか？",
                messageSuccess: "注文が正常に完了しました",
                messageError: "注文の完了に失敗しました"
            },
            ModalCreateInvoiceMultiple: {
                status_draft: "下書き",
                status_confirm: "確認済",
                content: "システムは、ステータスがある販売注文のみ処理します",
                add_success: "追加に成功しました:",
                add_errors: "追加に失敗しました:",
                continue: "続行",
                inogre: "スキップ",
            },
            DetailOrdersCard: {
                title: "注文",
                orderCode: "注文コード",
                orderDate: "時間",
                customerName: "顧客",
                priceLists: "価格表",
                createdBy: "作成者",
                statusName: "状態",
                branchName: "処理支店",
                branchNameCreate: "作成支店",
                orderBy: "営業スタッフ",
                expectedDateOfImport: "お届け予定日 ",
                note: "メモ",
                prodCode: "商品コード",
                prodName: "商品名",
                prodQuantity: "数量",
                prodUnit: "単位",
                prodPrice: "単価",
                prodDiscount: "割引",
                prodImportPrice: "輸入価格",
                totalPrice: "小計",
                totalQuantity: "総数量",
                totalProduct: "総商品数",
                total: "合計",
                discount: "割引",
                sumTotal: "最終合計",
                paymentForSupplier: "支払済み",
                SaleChannelName: "販売チャネル",
                notFound: "一致する結果はありません",
                btnOpen: "注文処理",
                promotion: "プロモーション",
                codeDiscount: "割引コード",
                attribute: "属性",
                searchProductCode: "商品コードを選択",
                searchProductName: "商品名を選択",
                dvt: "単位",
                chooseUnit: "単位を選択",
                chooseAttribute: "属性を選択",
            },

            // Hóa đơn
            InvoiceIndex: {
                title: "請求書",
                document_title: "請求書 | OmiPos",
                filter: {
                    branch: '支店',
                    time: '時間',
                    status: '状態',
                    saleChannel: '販売チャンネル',
                    method: '方法',
                    priceList: '価格表',
                    placeholder: {
                        branch: '支店を選択',
                        saleChannel: '販売チャネルを選択',
                        method: '支払い方法を選択',
                        priceList: '価格リストを選択',
                        search: '請求書コードによる',
                        sold_by: '売り手を選択',
                    }
                },
                code: "請求書コード",
                time: "時間",
                customer: "顧客",
                price_list: "価格表",
                order_code: "注文コード",
                status: "状態",
                branch: "支店",
                employee: "営業スタッフ",
                created_by: "作成者",
                sale_channel: "販売チャンネル",
                note: "メモ",
                method: "支払い方法",
                total_quantity: "総数量 ",
                total_origin: "合計",
                discount: "割引",
                surcharge: "追加料金",
                total: "合計",
                total_payment: "支払済み ",
                shipping_fee_collected_by_customer: "実際の配送料（顧客から）",
                fees_paid_to_delivery_partners: "配送パートナーへの支払い料金",
                tabs: {
                    info: "情報",
                    history_return: "返品履歴",
                    history_payment: "支払い履歴",
                    history_delivery: "配送履歴",
                    detail_delivery: "出荷詳細",
                    table: {
                        history_payment: {
                            code: "コード",
                            time: "時間",
                            created_by: "作成者",
                            method: "方法",
                            status: "状態",
                            total_price: "収益／支出",
                            value: "金額",
                            type_payment: "タイプ"
                        },
                        history_return: {
                            code: "返品コード",
                            time: "時間",
                            receive_by: "受取人払い",
                            total_price: "合計 ",
                            status: "状態",
                        }
                    },
                    delivery: {
                        title: "出荷詳細",
                        address_receive: "受け取り住所",
                        receive_by: "受取人",
                        paid_by: "送信者",
                        contact_number: "電話番号",
                        address: "住所",
                        area: "エリア",
                        ward: "区 ",
                        note: "メモ",
                        delivery_code: "配送書類コード",
                        weight: "重量",
                        size: "サイズ",
                        price_declaration: "申告価格",
                        exchange: "換算",
                        conversion_exchange: "換算レート",
                        take_to_office: "郵便局に持って行く",
                        pay_fee_by: "支払いの当事者 ",
                        service: "サビース",
                        delivery_by: "配送者",
                        using_price_cod: "代金引換",
                        using_price_cod_still: "代引き残高",
                        price: "配送パートナーへの手数料",
                        price_still: " 残り",
                        delivery_time: "配送時間",
                        delivery_status: "配送状況",
                        note_cancel: 'キャンセル理由',
                        note_delivery: "メモ",
                        discount_code: "割引コード",
                        estimate_delivery: "予定配達日",
                        control_code: "調整コードによる",
                        total_fee: "合計",
                        total_receive: "受取人の総収益",
                        titleCreate: '別の配送書類を作成',
                        paid_by_receiver_value: "受取人が料金を支払う",
                    }
                },
                button: {
                    invoice: "請求書",
                    add: "更新",
                    action: "操作",
                    export: "輸出ファイル",
                    export_all: "概要ファイルを輸出",
                    export_detail: "詳細ファイルを輸出",
                    import: "輸入ファイル",
                    file: "ファイル",
                    file_all: "概要ファイル",
                    file_detail: "詳細ファイル",
                    cancel: "キャンセル",
                    print: "印刷",
                    save: "保存",
                    update: "更新",
                    refund: "返品リクエスト",
                    return: "返品",
                    copy: "コーピー",
                    print_delivery: "出荷情報を印刷",
                    cancel_delivery: " 輸送書類をキャンセル",
                    create_delivery: "輸送書類を作成",
                    payment_with_transporter: "配送パートナーとの支払い",
                },
            },

            // Trả hàng bán (0)
            ReturnIndex: {
                selectReturnBy: "受取人を選択",
                returnTotal: "合計",
                returnDiscount: "割引",
                returnFee: "配送料",
                returnCustomer: "総返品",
                totalPayment: "顧客に返金済み",
                reImportPrice: "再輸入価格",
                alertUpdateSuccess: "返品 {code} が正常に更新されました",
                alertUpdateError: "返品受領書 {code} の更新に失敗しました",
            },

            // Đề nghị hoàn hàng (0)

            // Chuyển hàng đi
            TransferIndex: {
                title: "配送",
                record: "記録",
                filter_time: "時間",
                transfer_date: "配送日",
                receive_date: "受取日",
                filter_user: "作成日",
                type: "タイプ",
                type_transfer: "移動",
                type_receive: "輸入",
                from_branch: "支店まで",
                to_branch: "支店まで",
                status: "状態",
                status_receive: "配送状況",
                placeholder: {
                    search: "振替コードによる"
                },
                button: {
                    action: "操作",
                    export_all: "概要ファイルを輸出",
                    export_detail: "詳細ファイルを輸出",
                    create: "出荷",
                    export: "輸出ファイル",
                    file_all: "概要ファイル",
                    file_detail: "詳細ファイル",
                    save: "保存",
                    open: "オープン",
                    process: "処理",
                    print: "印刷",
                    print_barcode: "コードを印刷",
                    copy: "コーピー",
                    cancel: "キャンセル"
                },
                tabs: {
                    info: "状態",
                    detail_receive: "配送詳細"
                },
                transfer_code: "出荷コード",
                request_code: "注文コード",
                from_branch_note: "メモ（振替支店）",
                to_branch_note: "メモ（受け取り支店）",
                total_products: "総商品数",
                total_send_quantity: "総振替数",
                total_send_price: "金額",
                total_receive_quantity: "総受取数量",
                total_receive_price: "総受取金額",
            },

            // Gọi hàng về
            RequestIndex: {
                title: "注文リクエスト",
                record: "記録番号",
                filter_time: "時間",
                request_date: "リクエスト日",
                receive_date: "受け取る日",
                filter_user: "作成者",
                type: "タイプ",
                type_transfer: "移動",
                type_receive: "輸入",
                status: "状態",
                status_confirm: "確認状態",
                placeholder: {
                    search: "注文コードによる",
                    from_branch: "支店から",
                    to_branch: "支店まで",
                },
                button: {
                    action: "操作",
                    export_all: "概要ファイルを輸出",
                    export_detail: "詳細ファイルを輸出",
                    create: "商品をリクエスト",
                    export: "輸出ファイル",
                    file_all: "概要ファイル",
                    file_detail: "詳細ファイル",
                    save: "保存",
                    open: "オープン",
                    process: "処理",
                    print: "印刷",
                    print_barcode: "コードを印刷",
                    copy: "コーピー",
                    cancel: "キャンセル"
                },
                tabs: {
                    info: "情報",
                    detail: "詳細リクエスト"
                },
                request_code: "注文コード",
                from_branch: "支店を作成",
                to_branch: "リクエストされた支店 ",
                transfer_code: "取引コード",
                from_branch_note: "メモ（リクエスト支店）",
                to_branch_note: "メモ（支店がリクエストを受け取りました） ",
                total_products: "総商品数",
                total_request_quantity: "総数量（リクエスト合計）",
                total_request_price: "金額（リクエスト合計）",
                total_transfer_quantity: "総振替数量:",
                total_confirm_quantity: "総確認数量:",
                total_transfer_price: "金額 :",
                total_confirm_price: "確認された金額:",
                sendQuantity: "承認された数量",
                sendAmount: "承認された合計",
                requestPriceOrSendPrice: "価格",
            },

            // Xếp sản phẩm vào vị trí
            ImportPositionIndex: {
                title: "商品を配置",
                title_confirm: "確認",
                content_confirm: "倉庫受領書 {code} をキャンセルしますか？",
                document_title: "商品を配置 | OmiPos",
                time: "時間",
                status: "状態",
                code: "位置インポートコード",
                created_at: "作成時間",
                created_by: "作成者",
                original_document: "原本書類",
                note: "メモ",
                totalProducts: "総数量",
                totalTypeProducts: "全品目数",
                status_draft: "下書き",
                status_confirm: "確認済",
                placeholder: {
                    search: "位置輸入コードによって",
                },
                tabs: {
                    info: "情報",
                },
                button: {
                    save: "保存",
                    process: "処理",
                    update: "更新",
                    print: "印刷",
                    cancel: "キャンセル",
                },
                table: {
                    code: "商品コード",
                    name: "商品名",
                    unit: "単位",
                    attribute: "属性",
                    quantity: "数量",
                    import_position: "輸入位置"
                },
                alerts: {
                    validate_note: "メモは255文字を超えることはできません",
                    update_success: "{code} が正常に更新されました ",
                    update_error: "{code} の更新に失敗しました",
                    cancel_success: "正常にキャンセルされました",
                    error_server: "サーバーエラー"
                }
            },

            // Lấy sản phẩm khỏi vị trí
            ExportPositionIndex: {
                title: "位置から製品を取り除く",
                title_confirm: "確認",
                content_confirm: "納品書 {code} をキャンセルしてもよろしいですか？",
                document_title: "位置から製品を取り除く | OmiPos",
                time: "時間",
                status: "状態",
                code: "位置コードの輸出",
                created_at: "作成時間",
                created_by: "作成者",
                original_document: "原本書類",
                note: "メモ",
                totalProducts: "総数量",
                totalTypeProducts: "全品目数",
                status_draft: "下書き",
                status_confirm: "確認済",
                placeholder: {
                    search: "エクスポート位置コードによる",
                },
                tabs: {
                    info: "情報",
                },
                button: {
                    save: "保存",
                    process: "処理",
                    update: "更新",
                    print: "印刷",
                    cancel: "キャンセル",
                },
                table: {
                    code: "商品コード",
                    name: "商品名",
                    unit: "単位",
                    quantity: "数量",
                    attribute: "属性",
                    export_position: "輸出位置"
                },
                alerts: {
                    validate_note: "メモは255文字を超えることはできません",
                    update_success: "{code} が正常に更新されました ",
                    update_error: "{code} の更新に失敗しました",
                    cancel_success: "正常にキャンセルされました",
                    error_server: "サーバーエラー"
                }
            },
            ExportPositionProduct: {
                button: {
                    export: "輸出ファイル"
                }
            },

            // Điều chuyển vị trí
            TransferPositionIndex: {
                title: "位置移動 ",
                title_confirm: "確認",
                content_confirm: "{code} の移動をキャンセルしてもよろしいですか？",
                document_title: " 位置移動 | OmiPos",
                time: "時間",
                status: "状態",
                code: "位置移動コード",
                created_at: "作成時間",
                created_by: "作成者",
                note: "メモ",
                totalProducts: "総数量",
                totalTypeProducts: "総数",
                status_draft: "下書き",
                status_confirm: "確認済",
                placeholder: {
                    search: "位置移動コードによる",
                },
                tabs: {
                    info: "情報",
                },
                button: {
                    save: "保存",
                    open: "オープン",
                    create: "追加",
                    update: "更新",
                    print: "印刷",
                    cancel: "キャンセル",
                    copy: "コーピー"
                },
                table: {
                    code: "商品コード",
                    name: "商品名",
                    unit: "単位",
                    quantity: "数量",
                    from_location: "から",
                    attribute: "属性",
                    to_location: "まで"
                },
                alerts: {
                    validate_note: "メモは255文字を超えることはできません",
                    update_success: "{code} が正常に更新されました ",
                    update_error: "{code} の更新に失敗しました",
                    cancel_success: "正常にキャンセルされました",
                    error_server: "サーバーエラー"
                }
            },
            print_template_convert: {
                ticket_import: {
                    title: "商品の位置入力"
                },
                ticket_export: {
                    title: "商品の位置輸出"
                },
                ticket_transfer: {
                    title: "商品の位置移動"
                }
            },

            // Sổ quỹ tiền mặt
            CashBookIndex: {
                title: "般会計現金",
                document_title: "総勘定元帳現金| OmiPos",
                filter: {
                    branch: '支店',
                    time: '時間',
                    document_type: '文書タイプ',
                    payment_receipt_type: 'タイプ',
                    status: '状態',
                    created_by: '作成者',
                    user: 'スタッフ',
                    object_payment_receipt: '対象',
                    placeholder: {
                        all: '全体',
                        user: 'スタッフを選択',
                        created_by: '作成者',
                        payment_receipt_type: 'タイプ',
                        branch: '支店を選択',
                        name: ' 送信者の名前とコード',
                        phone: '電話番号',
                    }
                },
                tooltip: {
                    totalAmountReserve: "期末残高 = 期首残高 + 借方 - 貸方"
                },
                code: "コード",
                earlyTermFund: "期首残高",
                totalAmountRevenue: " 借方",
                totalAmountSpending: "貸方",
                totalAmountReserve: "期末残高",
                created_at: "作成時間",
                value: "価値",
                receipt_by: "受取人",
                payment_by: "提出者",
                phone_number: "電話番号",
                address: "住所",
                branch: "支店",
                type_receipt_payment: "タイプ",
                status: "状態",
                created_by: "作成者",
                user: "スタッフ",
                object_payment: "提出オブジェクト",
                object_receipt: "受取オブジェクト",
                supplier: "サプライヤー",
                customer: "顧客",
                guest: "小売顧客",
                note: "メモ",
                method: "方法",
                cash: "現金",
                transporter: "配送パトナー",
                tabs: {
                    info: "情報",
                },
                button: {
                    add_receipt_ticket: "領収書を追加",
                    add_payment_ticket: "支払いを追加",
                    action: "操作",
                    export: "ファイルを輸出",
                    show: "オープン",
                    cancel: "キャンセル",
                    print: "印刷",
                    save: "保存",
                    update: "更新"
                },
                placeholder: {
                    search: "コードによる",
                },
                alerts: {
                    update_success: "{code} の更新しました",
                    update_error: "{code} の更新に失敗しました",
                    validate_error_note: "メモは255文字を超えることはできません",
                },
                modal: {
                    showDetail: {
                        title_receipt: "領収書",
                        title_payment: "支払い",
                        code_receipt: "領収書コード",
                        code_payment: "支払いコード",
                        table: {
                            order_code: "注文コード",
                            invoice_code: "請求書コード",
                            return_code: " 返品コード",
                            order_supplier_code: "発注書コード",
                            purchase_code: "購入領収書コード",
                            return_purchase_code: "返品コード",
                            time: "時間",
                            value: "価格",
                            receipted: "事前に領収された",
                            paymented: "前払い",
                            amount_receipt_payment: "支払い金額",
                            status: "状態",
                        }
                    }
                },
                toLink: {
                    invoice_receipt: "請求書に関連付けて自動的に領収書が作成",
                    order_receipt: "注文に関連付けて自動的に領収書が作成",
                    return_receipt: "返品に関連付けて自動的に領収書が作成",
                    order_for_supplier_receipt: "発注書に関連付けて自動的に領収書が作成",
                    import_for_supplier_receipt: "製品の受領書に関連付けて自動的に領収書が作成",
                    return_import_for_supplier_receipt: "輸入製品の返品に関連付けて自動的に領収書が作成",
                    delivery_receipt: "提証に関連付けて自動的に領収書が作成",
                    invoice_payment: "請求書に関連付けて自動的に支払いが作成",
                    order_payment: "受注伝票に関連付けて自動的に支払いが作成",
                    return_payment: "返品伝票に関連付けて自動的に支払いが作成",
                    order_for_supplier_payment: "入庫伝票に関連付けて自動的に支払いが作成",
                    import_for_supplier_payment: "受入れ伝票に関連付けて自動的に支払いが作成",
                    return_import_for_supplier_payment: "輸入製品の返品に関連付けて自動的に支払いが作成",
                    delivery_payment: "提証に関連付けて自動的に支払いが作成",
                }
            },
            modalCreateTicketForCash: {
                title_create_receipt: "領収書を追加",
                title_update_receipt: "領収書を更新",
                title_create_payment: "支払いを追加",
                title_update_payment: "支払いを更新",
                title_confirm_close: "確認",
                content_confirm_close_receipt: "新しい領収書をキャンセルしてもよろしですか？",
                content_confirm_close_update_receipt: "領収書の更新をキャンセルしてもよろしですか？",
                content_confirm_close_payment: "新しい支払いをキャンセルしてもよろしですか？",
                content_confirm_close_update_payment: "支払いの更新をキャンセルしてもよろしですか？",
                info_common: '一般情報',
                document: '文書',
                objects: '対象',
                amount: '価格',
                reason_receipt: '領収書の理由',
                reason_payment: '支払いの理由',
                user_receipt: '支払いを行ったスタッフ',
                user_payment: 'スタッフ',
                note: 'メモ',
                planning_day: '会計日',
                document_number: '文書コード',
                placeholder: {
                    amount_receipt: "領収書の金額を入力",
                    amount_payment: "支払いの金額を入力",
                    reason: "理由を入力",
                    note: "メモを入力",
                    document_number: "文書コードを入力",
                    user: "スキップを選択",
                    objects: "対象を選択",
                },
                button: {
                    save: "保存",
                    saveAndPrint: "保存と印刷",
                    skip: "スキップ",
                    cancel: "キャンセル",
                },
                alert: {
                    create_receipt_success: "新しい領収書を追加しました",
                    update_receipt_success: "領収書を更新しました",
                    create_payment_success: "新しい支払いを追加しました",
                    update_payment_success: "支払いを更新しました",
    
                    create_receipt_error: "新しい領収書の追加に失敗しました",
                    update_receipt_error: "領収書の更新に失敗しました",
                    create_payment_error: "新しい支払いの追加に失敗しました",
                    update_payment_error: "更新に失敗しました",
                }
            },
            modalCashCancel: {
                title: "キャンセル",
                content: "{code} をキャンセルしてもよろしいですか？",
                btnSubmit: "同意",
                btnAbort: "キャンセル",
                messageReceiveSuccess: "領収書 {code} はキャンセルされました",
                messagePaymentSuccess: "支払い {code} はキャンセルされました",
                messageReceiveError: "領収書 {code} のキャンセルに失敗しました",
                messagePaymentError: "領収書 {code} のキャンセルに失敗しました"
            },

            // Sổ quỹ ngân hàng
            BankBookIndex: {
                title: "総勘定元帳銀行",
                document_title: "総勘定元帳銀行 | OmiPos",
                numberRecord: " 記録",
                note: "メモ",
                totalAmountRevenue: "総収益",
                supplier: "サプライヤー",
                customer: "顧客",
                transporter: "配送パトナー",
                filter: {
                    payment_type: "支払い方法",
                    branch: "支店",
                    time: "時間",
                    created_by: "作成者",
                    user: 'スタッフ',
                    object_payment_receive: '受け取る対象',
                    placeholder: {
                        all: '全体',
                        user: 'スタッフを選択',
                        created_by: '作成者を選択',
                        payment_receipt_type: 'タイプ',
                        branch: '支店を選択',
                        name: '名前とコード/受信者',
                        phone: '電話番号',
                    }
                },
                placeholder: {
                    search: "コードによる",
                },
                method: {
                    title: "方法",
                    value: {
                        card: "カード",
                        banking: "振り込む"
                    }
                },
                button: {
                    clearFilter: "フィルターを削除",
                    operation: "オプション",
                    receipts: "領収書を追加",
                    export: "ファイルを輸出",
                    save: "保存",
                },
                detail: {
                    code: "コード",
                    time: "時間",
                    value: "価格",
                    method: "方法",
                    payer: "提出者",
                    phone: "電話番号",
                    address: "住所",
                    branch: "支店",
                    created_by: "作成者",
                    user: "スタッフ",
                    object_payer: "主題"
                },
                alerts: {
                    update_success: "領収書 {code} が更新されました",
                    update_error: "領収書 {code} の更新に失敗しました",
                    validate_error_note: "メモは255文字を超えることはできません",
                },
                toLink: {
                    invoice_receipt: "領収書は自動的に請求書に関連付けて作成",
                    order_receipt: "領収書は自動的に注文に関連付けて作成",
                    return_import_for_supplier_receipt: "輸入製品の返品に関連付けて自動的に領収書が作成",
                    delivery_receipt: "提証に関連付けて自動的に領収書が作成",
                }
            },
            modalBankBook: {
                title: "領収書を追加:",
                titleContentLeft: "一般情報:",
                titleContentRight: "文書:",
                applicableObject: "対象",
                amount: "価格",
                reasonForPayment: "提出理由:",
                employee: "スタッフ",
                method: "方法",
                note: "メモ:",
                planningDay: "提出理由:",
                documentNumber: "文書番号:",
                planning: "業績への会計",
                //placeholder
                placeholder: {
                    object: "オブジェクトを選択",
                    amount: "値を入力",
                    reason: "提出理由を選択",
                    employee: "スタッフを選択",
                    method: "提出方法を選択",
                    description: "メモを入力",
                    planningDay: "会計日を入力",
                    documentNumber: "文書番号を入力"
                }
            },

            // Vận đơn
            DeliveryIndex: {
                title: "提証書",
                title_document: " 提証書 | OmiPos",
                created_at: "作成時刻",
                completed_at: "完成時間",
                status: "配送状態",
                transporter: "配送パトナー",
                area_delivery: "配送アリア",
                cod: "代金引換（COD）",
                receiver_info: "受取人情報",
                invoice_info: "請求書情報",
                delivery_info: "配送情報",
                receiver_name: "受取人",
                receiver_phone: "電話番号",
                receiver_address: "住所",
                receiver_area: "アリア",
                receiver_ward: "区",
                invoice_code: "請求書コード",
                branch: "支店",
                customer_name: "顧客",
                total_products: "商品数量",
                values: "価格",
                note: "メモ",
                delivery_code: "提証書コード",
                created_by: "作成者",
                weight: "重量",
                size: "サイズ",
                delivery_by: "配送者",
                service: "サビース",
                delivery_time: "配送時間",
                cod_still: "まだ集金が必要（代金引換）",
                price: "配送パートナーへの手数料支払い",
                price_still: "配送パートナーへの残額",
                column_status: "状態",
                control_code: "調整コード",
                conversion_rate: "換算レート",
                expected_delivery: "予定配送日",
                price_declaration: "宣言価格",
                take_to_post_office: "自分で郵便局に持参する",
                paying_by: "支払いを行う当事者",
                attention_when_delivering: "メモ",
                discount_code: "割引コード",
                total_fare: "総運賃",
                receipt_paid_the_fee: "受取人が料金を支払い",
                total_revenue_of_the_recipient: "受取人の総収益",
                infoTitle: "支払い（集金と配送パートナー経由の手数料）のリストを表示",
                position: "位置",
                infoUpdate: "情報更新",
                placeholder: {
                    search: "提証書コードによる",
                    transporter: " 配達者を選択する",
                    area_delivery: " 県/市 - 区を選択する",
                    value: "価格",
                },
                button: {
                    create: "配送パトナー",
                    import: "輸入",
                    export: "ファイル輸出",
                    update: "更新",
                    in_active: "非活動",
                    remove: "削除",
                    print: "印刷",
                    payment: "支払い",
                    save: "保存",
                    support: "サポートリクエストを送信",
                    cancel: "キャンセル"
                },
                tabs: {
                    info: "情報",
                    history: "配達履歴",
                    paymentTrans: "配送パートナー支払い履歴"
                },
                modal: {
                    modalCancel: {
                        title: "提証書をキャンセル",
                        content: "この提証書をキャンセルしてもよろしいですか？",
                        button: {
                            confirm: "同意",
                            skip: "スキップ"
                        }
                    },
                    modelPaymentAndCreateRefund: {
                        title: "配送パートナーとの支払い",
                        invoice_code: "請求書コード",
                        delivery_code: "提証書コード",
                        transporter: "配送パートナー",
                        total_fee: "合計（代金引換 - 配送パートナーへの手数料支払い）",
                        cod: "代金引換（COD）",
                        fee_ship: "配送パートナーへの手数料",
                        time: "時間",
                        method: "方法",
                        button: {
                            type_receipt: '領収書を作成する',
                            type_payment: '支払いを作成する',
                            type_all: '領収書と支払いを作成',
                            skip: "スキップ"
                        },
                        message_success: "請求書 {code} の支払いが成功",
                        message_create_multiple_success: "請求書の支払いが成功",
                        message_error: "請求書 {code} の支払いが失敗",
                        message_validate_time_error: "伝票の時間が現在の時間を超えています",
                        message_validate_created_at: "伝票の時間が請求書の時間 {code} を超えています",
                    }
                },
                alert: {
                    update_success: "データが正常に更新されました",
                    payment_error: "提証書が支払われた、払わない"
                }
            },

        /* Hết giao dịch */

        /* Báo cáo */
            // Báo cáo chi tiết bán hàng
            ReportSaleDetails: {
                title: "販売",
                title_document: "販売レポート | OmiPos",
                time: "時間",
                product: "商品",
                category: "商品グループ",
                customer: "顧客",
                customer_group: "顧客グループ",
                sale_channel: "販売チャンネル",
                branch: "支店",
                created_date: "作成日",
                from_date: "から",
                to_date: "まで",
                price_list: "価格表",
                type_currency: "通貨",
                invoice: "請求書コード",
                placeholder: {
                    product: "コード、製品名で検索",
                    category: "製品グループを検索",
                    customer: "コード、顧客名で検索",
                    customer_group: "顧客グループを検索",
                    sale_channel: "販売チャンネルを選択",
                    branch: "支店を選択",
                    price_list: "価格表を選択",
                    invoice: "請求書コードによる"
                },
                button: {
                    export: "ファイル輸出"
                }
            },

            // Báo cáo bán hàng theo kênh bán
            ReportBySaleChannel: {
                title: "販売",
                title_document: "販売レポート | OmiPos",
                time: "時間",
                product: "商品",
                category: "商品グループ",
                customer: "顧客",
                customer_group: "顧客グループ",
                sale_channel: "販売チャンネル",
                branch: "支店",
                created_date: "作成日",
                from_date: "から",
                to_date: "まで",
                price_list: "価格表",
                placeholder: {
                    product: "コード、製品名による",
                    category: "顧客グループを検索",
                    customer: "コード、顧客名による",
                    customer_group: "顧客グループを検索",
                    sale_channel: "販売チャンネルを選択",
                    branch: "支店を選択",
                },
                button: {
                    export: "ファイル輸出"
                }
            },

            // Báo cáo bán hàng theo nhân viên
            SaleReportByEmployee: {
                title: "販売",
                title_document: "販売レポート | OmiPos",
                time: "時間",
                product: "商品",
                category: "商品グループ",
                customer: "顧客",
                customer_group: "顧客グループ",
                sale_channel: "販売チャンネル",
                branch: "支店",
                created_date: "作成日",
                from_date: "から",
                to_date: "まで",
                price_list: "価格表",
                placeholder: {
                    product: "コード、製品名による",
                    category: "顧客グループを検索",
                    customer: "コード、顧客名による",
                    customer_group: "顧客グループを検索",
                    sale_channel: "販売チャンネルを選択",
                    branch: "支店を選択",
                },
                button: {
                    export: "ファイル輸出"
                }
            },

            // Báo cáo bán hàng theo chi nhánh
            SaleReportByBranch: {
                title: "販売",
                title_document: "販売レポート | OmiPos",
                time: "時間",
                product: "商品",
                category: "商品グループ",
                customer: "顧客",
                customer_group: "顧客グループ",
                sale_channel: "販売チャンネル",
                branch: "支店",
                created_date: "作成日",
                from_date: "から",
                to_date: "まで",
                price_list: "価格表",
                placeholder: {
                    product: "コード、製品名による",
                    category: "顧客グループを検索",
                    customer: "コード、顧客名による",
                    customer_group: "顧客グループを検索",
                    sale_channel: "販売チャンネルを選択",
                    branch: "支店を選択",
                },
                button: {
                    export: "ファイル輸出"
                }
            },

            // Phân tích chi tiết danh thu theo thời gian
            ReportRevenuesTime: {
                title: '販売',
                title_document: '時間経過に伴う収益を分析する'
            },

            // Phân tích chi tiết danh thu theo chi nhánh
            ReportRevenuesBranch: {
                title: '販売',
                title_document: '支店に伴う収益を分析する'
            },

            // Phân tích chi tiết danh thu theo bảng giá
            SaleReportRevenuePriceList: {
                title: "販売",
                title_document: "販売レポート | OmiPos",
                time: "時間",
                options: "表示選択",
                product: "商品",
                category: "商品グループ",
                customer: "顧客",
                customer_group: "顧客グループ",
                sale_channel: "販売チャンネル",
                branch: "支店",
                priceList: "価格表",
                productUnit: "単位: 主要単位",
                created_date: "作成日",
                from_date: "から",
                to_date: "まで",
                price_list: "価格表",
                placeholder: {
                    product: "コード、製品名による",
                    category: "顧客グループを検索",
                    customer: "コード、顧客名による",
                    customer_group: "顧客グループを検索",
                    sale_channel: "販売チャンネルを選択",
                    branch: "支店を選択",
                },
                button: {
                    export: "ファイル輸出"
                }
            },

            // Phân tích chi tiết danh thu theo nhóm khách hàng
            SaleReportRevenueCustomerGroup: {
                title: "販売",
                title_document: "販売レポート | OmiPos",
                time: "時間",
                product: "商品",
                category: "商品グループ",
                customer: "顧客",
                customer_group: "顧客グループ",
                sale_channel: "販売チャンネル",
                productUnit: "単位",
                branch: "支店",
                created_date: "作成日",
                from_date: "から",
                to_date: "まで",
                price_list: "価格表",
                placeholder: {
                    product: "コード、製品名による",
                    category: "顧客グループを検索",
                    customer: "コード、顧客名による",
                    customer_group: "顧客グループを検索",
                    sale_channel: "販売チャンネルを選択",
                    branch: "支店を選択",
                },
                button: {
                    export: "ファイル輸出"
                },
                table: {
                    STT: "順番",
                    productCode: "商品コード",
                    productName: "商品名",
                    productUnit: "単位",
                    regionNorth: "北部",
                    regionCenter: "中部",
                    regionSouth: "南部",
                    quantity: "数量",
                    price: "価格",
                    total: "合計"
                }
            },

            // Sổ mua hàng chi tiết
            ReportPurchaseDetails: {
                title: "購入",
                title_document: "購入レポート | OmiPos",
                time: "時間",
                purchase_order: "購入領収書",
                product: "商品",
                category: "商品グループ",
                customer: "顧客",
                customer_group: "顧客グループ",
                supplier_group: "サプライヤーグループ",
                supplier: "サプライヤー",
                sale_channel: "販売チャンネル",
                branch: "支店",
                created_date: "作成日",
                from_date: "から",
                to_date: "まで",
                price_list: "価格表",
                type_currency: "通貨",
                invoice: "請求書コード",
                placeholder: {
                    purchase_order: "入力された票数による",
                    product: "コード、商品名による",
                    category: "商品グループを検索",
                    customer: "コード、顧客名で検索",
                    customer_group: "顧客グループを検索",
                    supplier_group: "サプライヤーグループを検索",
                    supplier: "サプライヤーを検索",
                    sale_channel: "販売チャンネルを選択",
                    branch: "支店を選択",
                    price_list: "価格表を選択",
                    invoice: "請求書コードで検索"
                },
                button: {
                    export: "ファイル輸出"
                }
            },

            // Sổ chi tiết hàng hóa
            ReportStockDetails: {
                title: "販売",
                title_document: "販売レポート | OmiPos",
                time: "時間",
                product: "商品",
                category: "商品グループ",
                customer: "顧客",
                customer_group: "顧客グループ",
                sale_channel: "販売チャンネル",
                branch: "支店",
                created_date: "作成日",
                from_date: "から",
                to_date: "まで",
                price_list: "価格表",
                invoice: "請求書コード",
                placeholder: {
                    product: "コード、商品名による",
                    category: "商品グループを検索",
                    customer: "コード、顧客名による",
                    customer_group: "顧客グループを検索",
                    sale_channel: "販売チャンネルを選択",
                    branch: "支店を選択",
                    price_list: "価格表を選択",
                    invoice: "請求書コードによる"
                },
                button: {
                    export: "ファイル輸出"
                }
            },

            // Báo cáo nhập xuất tồn
            ReportInventoriesIndex: {
                titleHeader: '商品レポート',
                title: '輸入と輸出のレポート | OmiPos'
            },

            // Báo cáo nhập xuất tồn chi tiết
            ReportInventoriesIndexDetail: {
                titleHeader: '商品レポート',
                title: '詳細な輸入と輸出のレポート | OmiPos'
            },

            // Báo cáo giá trị kho
            ReportValueBranch: {
                title: "商品",
                title_document: "販売レポート | OmiPos",
                time: "時間",
                product: "商品",
                category: "商品グループ",
                customer: "顧客",
                customer_group: "顧客グループ",
                sale_channel: "販売チャンネル",
                branch: "支店",
                created_date: "作成日",
                from_date: "から",
                to_date: "まで",
                price_list: "価格表",
                placeholder: {
                    product: "コード、商品名による",
                    category: "商品グループを検索",
                    customer: "コード、顧客名による",
                    customer_group: "顧客グループを検索",
                    sale_channel: "販売チャンネルを選択",
                    branch: "支店を選択",
                },
                button: {
                    export: "ファイル輸出"
                }
            },

            // Báo cáo tồn kho theo hạn sử dụng
            SaleReportByConsignment: {
                title: "商品",
                title_document: "販売レポート | OmiPos",
                time: "時間",
                product: "商品",
                category: "商品グループ",
                customer: "顧客",
                customer_group: "顧客グループ",
                sale_channel: "販売チャンネル",
                branch: "支店",
                created_date: "作成日",
                from_date: "から",
                to_date: "まで",
                price_list: "価格表",
                placeholder: {
                    product: "コード、商品名による",
                    category: "商品グループを検索",
                    customer: "コード、顧客名による",
                    customer_group: "顧客グループを検索",
                    sale_channel: "販売チャンネルを選択",
                    branch: "支店を選択",
                },
                button: {
                    export: "ファイル輸出"
                }
            },

            // Báo cáo tồn kho theo vị trí
            ReportByLocation: {
                title: "商品",
                title_document: "販売レポート | OmiPos",
                time: "時間",
                product: "商品",
                category: "商品グループ",
                customer: "顧客",
                customer_group: "顧客グループ",
                sale_channel: "販売チャンネル",
                branch: "支店",
                created_date: "作成日",
                from_date: "から",
                to_date: "まで",
                price_list: "価格表",
                placeholder: {
                    product: "コード、商品名による",
                    category: "商品グループを検索",
                    customer: "コード、顧客名による",
                    customer_group: "顧客グループを検索",
                    sale_channel: "販売チャンネルを選択",
                    branch: "支店を選択",
                },
                button: {
                    export: "ファイル輸出"
                }
            },

            // Báo cáo tồn kho theo lô date và vị trí

        /* Hết báo cáo */

        /* Thiết lập */
            // Thiết lập cửa hàng
            SettingPage: {
                title_left_top: '管理設定',
                title_left_bot: '店舗設定',
                success: '設定が正常に更新されました',
                product: {
                    title: '商品',
                },
                partner: {
                    title: 'パトナー',
                    modal: {
                        debt: {
                            title: '顧客の債務アラートを設定'
                        },
                        transporter: {
                            title: '配送パートナーと連携する'
                        }
                    }
        
                },
                transaction: {
                    title: '取引',
                    modal: {
                        setting_time_return: {
                            title: '配送時間を設定',
                            title_value_time_return: '購入後に返品',
                            date: '日',
                            note_value_time_return: 'システムは購入日から設定された日数以内の返品のみを許可',
                            process_option: '期限切れの返品の処理:',
                            note_warning: '返品期限が過ぎた場合、システムは警告を表示しますが、それでもユーザーが製品を返品できるようにします',
                            button_save: '保存',
                            button_skip: 'スキップ',
                        },
                    }
                },
                store: {
                    title: '店舗情報'
                },
                security: {
                    title: 'セキュリティ設定'
                },
                webhook: {
                    title: 'Kiotviet 同期'
                }
            },

            // Quản lý mẫu in
            

            // Quản lý chi nhánh
            branchIndex: {
                title: "支店",
                branch_status: "状態",
                branch_name: "支店名",
                branch_phone: "電話番号",
                branch_email: "メール",
                branch_work_day: "勤務日",
                branch_address: "住所",
                branch_area_delivery: "配送アリア",
                branch_ward: "区",
                tabs: {
                    info: "情報",
                    user: "ユーザー",
                    address_get_order: "受取住所",
                },
                button: {
                    add: "支店",
                    update: "更新",
                    inActive: "非活動",
                    active: "活動",
                    remove: "削除",
                },
                status: {
                    all: "全体",
                    active: "活動",
                    inActive: "非活動",
                },
                placeholder: {
                    search: "支店名",
                    province: "県/市を選択する",
                    district: "区を選択する",
                    ward: "町/村を選択する",
                    address: "住所を入力",
                    email: "メールを入力",
                    phone: "電話番号を入力",
                    name: "支店を入力",
                },
                modal: {
                    title_create: "新しい支店を追加",
                    title_update: "支店更新",
                    title_confirm: "確認",
                    branch_province: "県/市",
                    branch_district: "区",
                    branch_ward: "町/村",
                    button: {
                        save: "保存",
                        skip: "スキップ",
                        confirm: "同意",
                    },
                    alerts: {
                        confirm_in_active: "この支店の運営を停止してもよろしいですか？",
                        confirm_active: "この支店を再開しますか？",
                        success_create: "新しい支店が正常に追加されました",
                        success_update: "支店の更新が成功しました",
                        confirm_skip_create: "支店の追加をキャンセルしますか？",
                        confirm_skip_update: "支店の更新をキャンセルしますか？",
                        success_remove: "支店 {name} を正常に削除しました"
                    },
                    title_remove: "確認",
                    content_remove: "システムはこの支店を完全に削除",
                }
            },

            // Quản lý khuyến mại

            // Quản lý coupon
            CouponIndex: {
                title: "クーポン",
                document_title: "クーポン | OmiPOS",
                status: "情報",
                code: "発行コード",
                time: "時間",
                categories: "購入グループ",
                group: "グループ",
                all_categories: "購入グループ",
                product: "購入した製品",
                name: "プログラム名",
                value: "クーポン価値",
                max: "最大",
                min_total_price: "最小合計金額",
                note: "メモ",
                placeholder: {
                    search: 'コード、リリース名',
                },
                button: {
                    add: "新しいリリースを追加",
                    update: "更新",
                    copy: "コーピー",
                    remove: "削除",
                },
                tabs: {
                    info: "情報",
                    list_coupon: "クーポンリスト",
                    history: "使用履歴",
                },
                table: {
                    list_coupon: {
                        code: "クーポンコード",
                        release_date: "発行日",
                        use_date: "使用日",
                        number_coupon: "ページに表示されるクーポンの数",
                        placeholder: {
                            search: "クーポンコードを検索"
                        },
                        button: {
                            export: "ファイル輸出"
                        }
                    },
                    history: {
                        code: "コード",
                        time: "時間",
                        sold_by: "販売者",
                        customer: "顧客",
                        branch: "支店",
                        revenue: "収益",
                        value_coupon: "クーポンの価値",
                        placeholder: {
                            search: "クーポンコードを検索"
                        },
                        button: {
                            export: "ファイル輸出"
                        }
                    }
                },
                modal: {
                    create_or_update: {
                        title_create: "クーポンの発行を追加",
                        title_update: "クーポンの発行を更新",
                        code: "発行コード",
                        name: "発行名",
                        value_coupon: "クーポン価格",
                        max: "最大",
                        currency_vnd: "ドン",
                        currency_percent: "%",
                        effect: "効果",
                        to: "まで",
                        status: "状態",
                        status_active: "アクティブ",
                        status_inactive: "未適用",
                        categories: "購入グループ",
                        choose_categories: "購入グループを選択",
                        group: "グループ",
                        all_categories: "商品グループ全体",
                        products: "購入商品",
                        total_price: "商品の総コスト",
                        tooltip: {
                            code: "発行コードは固有の情報",
                            categories: "クーポンを適用する条件として、請求書に特定の商品または商品グループの購入が必要",
                            total_price: " 請求書の合計金額がこの最小金額に等しい場合、クーポンで支払われます",
                        },
                        placeholder: {
                            code: "自動コード",
                            name: "プロモーションプログラム名",
                            products: "購入を選択",
                            note: "メモ",
                        },
                        tabs: {
                            info: "情報",
                            list_coupon: "クーポンリスト",
                        },
                        button: {
                            save: "保存",
                            skip: "スキップ",
                            action: "操作",
                            release_coupon: "クーポン発行",
                            cancel_coupon: "クーポンをキャンセル",
                            add: "クーポンリスト"
                        },
                        table: {
                            code: "クーポンコード",
                            release_date: "発行日",
                            use_date: "使用日",
                            status: "状態",
                            number_coupon: "ページに表示されるクーポンの数",
                            placeholder: {
                                search: "クーポンコードを検索",
                                choose_method: "フォームを選択"
                            },
                            button: {
                                export: "ファイル輸出"
                            }
                        },
                        alert: {
                            title_confirm: "クーポンの発行を追加",
                            content_confirm: "クーポンリストを作成する前にクーポンリリースを保存する必要があります。保存しますか？",
                            create_list_coupon_success: "クーポンリストが正常に追加されました",
                            create_success: "新しいリリースが正常に追加されました",
                            update_success: "発行の更新が成功しました",
                        }
                    }
                }
            },

            // Quản lý voucher
            VoucherIndex: {
                title: "バウチャー",
                document_title: "バウチャー | OmiPOS",
                branch: "支店",
                status: "状態",
                code: "発行コード",
                time: "時間",
                categories: "購入グループ",
                group: "グループ",
                all_categories: "商品グループ全体",
                product: "購入",
                name: "発行名",
                value: "額面",
                min_total_price: "最小合計金額",
                note: "メモ",
                placeholder: {
                    search: 'コード、発行名による',
                    branch: '支店を選択',
                },
                button: {
                    add: "新しい発行を追加",
                    update: "更新",
                    copy: "コーピー",
                    remove: "削除",
                },
                tabs: {
                    info: "情報",
                    list_voucher: "バウチャーリスト",
                    history: "利用履歴",
                },
                table: {
                    list_voucher: {
                        code: "バウチャーコード",
                        release_date: "発行日",
                        end_date: "有効期限",
                        number_voucher: " ページに表示されるバウチャーの数",
                        placeholder: {
                            search: " バウチャーコードを検索r"
                        },
                        button: {
                            export: "ファイル輸出"
                        }
                    },
                    history: {
                        code: "コード",
                        time: "時間",
                        sold_by: "販売者",
                        customer: "顧客",
                        branch: "支店",
                        revenue: "収益",
                        value_voucher: "バウチャー価値",
                        placeholder: {
                            search: " バウチャーコードを検索"
                        },
                        button: {
                            export: "ファイル輸出"
                        }
                    }
                },
                modal: {
                    create_or_update: {
                        title_create: "バウチャーの発行を追加する",
                        title_update: "バウチャーの発行を更新する",
                        code: "発行コード",
                        name: "発行名",
                        value_voucher: "額面",
                        max: "最大",
                        effect: "有効開始",
                        to: "まで",
                        status: "状態",
                        status_active: "アクティブ化",
                        status_inactive: " 非アクティブ化",
                        categories: "購入グループ",
                        choose_categories: "購入グループを選択",
                        group: "グループ",
                        all_categories: "購入グループ全体",
                        products: "購入商品",
                        total_price: "商品の合計コスト",
                        apply_multiple_voucher: "1つの請求書に複数のバウチャーを適用する",
                        apply_time: "適用時間",
                        apply_area: "適用範囲",
                        all_system: "システム全体",
                        branch: "支店",
                        all_customer: "顧客全体",
                        customer_group: "顧客グループ",
                        all_created_by: "作成者全体",
                        created_by: "作成者",
                        tooltip: {
                            code: "発行コードは固有の情報",
                            value: "購入時に顧客が支払う金額",
                            categories: "これらの商品グループを含む請求書はバウチャーで支払われます",
                            products: "これらの商品を含む請求書はバウチャーで支払われます",
                            total_price: "バウチャーで支払うための最小合計金額",
                            apply_multiple_voucher: "1つの請求書に複数のバウチャーを使用することを許可する/禁止する",
                        },
                        placeholder: {
                            code: "自動コード",
                            name: "発行名",
                            products: "商品を選択",
                            note: "メモ",
                        },
                        tabs: {
                            info: "情報",
                            apply: "適用",
                            list_voucher: "バウチャーリスト",
                        },
                        button: {
                            save: "保存",
                            skip: "スキップ",
                            action: "操作",
                            release_voucher: "バウチャーの発行",
                            cancel_voucher: "バウチャーのキャンセル",
                            add: "バウチャーリスト",
                            voucher: "バウチャー",
                            import: "輸入",
                        },
                        table: {
                            code: "バウチャーコード",
                            release_date: "発行日",
                            end_date: "有効期限",
                            use_date: "使用日",
                            status: "状態",
                            number_voucher: "ページに表示されるバウチャーの数",
                            placeholder: {
                                search: "バウチャーコードを検索",
                                choose_method: "フォームを選択"
                            },
                            button: {
                                export: "ファイル輸出"
                            }
                        },
                        alert: {
                            title_confirm: "バウチャーの発行を追加する",
                            content_confirm: "バウチャーリストを作成する前にバウチャーの発行を保存する必要があります。保存しますか？",
                            create_list_voucher_success: "バウチャーリストが正常に追加されました",
                            create_success: "新しい発行が正常に追加されました",
                            update_success: "発行の更新が成功しました",
                        }
                    },
                    create_list_voucher: {
                        title: "バウチャーリストを追加",
                        number_voucher: "バウチャーの数",
                        voucher_code_length: "バウチャーコードの長さ",
                        first_character: "開始文字",
                        last_character: "終了文字",
                        tooltip: {
                            number_voucher: "新しいバウチャーの数が追加",
                            voucher_code_length: "バウチャーコードの長さは、開始文字と終了文字を引いた値が6以上である必要があります。これにより、最小の長さと一意性が確保",
                            first_character: "生成されたバウチャーコードはこれらの文字で始まります",
                            last_character: "生成されたバウチャーコードはこれらの文字で終わります"
                        },
                        button: {
                            save: "保存",
                            skip: "スキップ",
                        },
                        alerts: {
                            validate_number_voucher: "バウチャーの数を未入力",
                            validate_voucher_code_length: "開始文字と終了文字を引いた場合のバウチャーコードの長さは、6以上でなければなりません",
                            success: "新しいバウチャーコードが正常に作成されました"
                        }
                    },
                    create_voucher_code: {
                        title: "バウチャーを更新",
                        voucher_code: "バウチャーコード",
                        button: {
                            add: "バウチャーを更新",
                            save: "保存",
                            skip: "スキップ",
                        },
                        alerts: {
                            validate_voucher_code: "バウチャーコードは空白にできません",
                            validate_voucher_code_is_exist: "バウチャーコード {code} はすでに存在します",
                            success: "新しいバウチャーが正常に追加されました"
                        }
                    },
                    remove: {
                        title: "確認",
                        content: "このバウチャーの発行を完全に削除しますか？",
                        button: {
                            confirm: "同意",
                            skip: "スキップ",
                        },
                        alerts: {
                            success: "バウチャーの発行が正常に削除されました",
                            error: "バウチャーが発行されているか使用されています。削除することはできません！"
                        }
                    }
                }
            },

            // Quản lý vai trò

            // Quản lý phân quyền

            // Lịch sử thao tác
            HistoryIndex: {
                title: "操作履歴",
                user: "スタッフ",
                function: "機能",
                branch: "支店",
                action: "操作",
                time: "時間",
                document_title: "操作履歴 | OmiPos",
                today: "今日",
                week: "今週",
                month: "今月",
                three_month_ago: "3月後",
                other: "他の選択",
                from_date: "日から",
                to_date: "日まで",
                placeholder: {
                    search: "キーワード入力",
                    branch: "支店を選択",
                    user: "スタッフを選択",
                    function: "機能を選択",
                },
                button: {
                    save: "保存",
                    process: "チケット処理",
                    update: "更新",
                    print: "印刷",
                    cancel: "キャンセル",
                    export: "ファイル輸出",
                },
                tabs: {
                    content: "詳細内容"
                }
            },

            // Quản lý mẫu email/zalo
            SendMailZaloIndex: {
                title: "メール/Zaloテンプレート",
                title_document: "メール/Zaloテンプレート | OmiPos",
                modal: {
                    title_send: "メールテンプレートを選択する",
                    title_create: "新しいメール/Zaloテンプレートを追加",
                    title_update: "メール/Zaloテンプレートを更新",
                    title_remove: "メール/Zaloテンプレートを削除",
                    remove_content: "このメール/Zaloテンプレートを削除しますか？",
                    template_name: "メール/Zaloテンプレート名:",
                    file: "添付ファイル:",
                    placeholder: {
                        file: "添付ファイルを選択"
                    },
                    read_before: "メール/Zaloテンプレートをプレビューする",
                    button: {
                        save: "保存",
                        skip: "スキップ",
                        remove: "削除",
                        confirm: "同意",
                        send: "送信",
                    },
                    alerts: {
                        success_create: "新しいメール/Zaloテンプレートが正常に追加",
                        success_update: "メール/Zaloテンプレートが正常に更新",
                        success_remove: "メール/Zaloテンプレートが正常に削除",
                        error_create: "新しいメール/Zaloテンプレートの追加に失敗",
                        error_update: "メール/Zaloテンプレートの更新に失敗",
                        error_remove: "メール/Zaloテンプレートの削除に失敗",
                    }
                }
            },

            // Danh sách đối tác zalo
            zaloGroups: {
                name: "Zaloグループ",
                templateTitle: "Zaloテンプレート",
                zaloTitle: "Zaloパートナーのリスト",
                zalo: "Zaloグループリスト",
                numberRecord: "レコード数:",
                logOut: "ロングアウト",
                placeholder: "グループ名による "
            },
            ModalLoginZalo: {
                title: "ZALO OAにログインする",
                refresh_token: "リフレッシュトークンコード",
                app_id: "アプリケーションID",
                app_secret: "アプリケーションシークレット",
                webhook_secret: "Webhookシークレット",
                placeholder: {
                    refresh_token: "リフレッシュトークンコードを入力",
                    app_id: "アプリケーションIDを入力",
                    app_secret: "管理者からのシークレットコードを入力",
                    webhook_secret: "管理者からのWebhookコードを入力",
                },
                button: {
                    login: "ログイン",
                    skip: "スキップ"
                },
                message: {
                    login_success: "ログアウトが成功",
                    login_failed: "ログアウトに失敗",
                }
            },
            ModalLogoutZalo: {
                title: "確認",
                content: "システムからZalo OAアカウントをログアウトしてもよろしいですか？",
                button: {
                    logout: "ロングアウト",
                    skip: "スキップ"
                },
                message: {
                    logout_success: "ログアウトが成功",
                    logout_failed: "ログアウトに失敗",
                }
            },
            ModalSendZalo: {
                title: 'Zaloに送信するフォームを選択'
        
            },


        /* Hết thiết lập */

        /* Modal */
            modalConfirm: {
                title: "確認",
                titleCancelOrderSupplier: "入力された注文フォームをキャンセル",
                titleCancelOrder: "注文フォームをキャンセル",
                titleCancelInvoice: "請求書をキャンセル",
                titleCancelTicketPayment: "支払い伝票をキャンセル",
                content: "変更をキャンセルしますか？",
                contentCancelHistoryPaymentOrderSupplier: "関連するデポジットをキャンセルしますか？",
                contentCancelTicketPayment: "関連する支払い伝票をキャンセルしますか？",
                button: {
                    confirm: "同意",
                    skip: "スキップ",
                },
            },
            modalPaymentSupplier: {
                title_order_for_supplier: " {code}の注文フォーム入力の前払い",
                title_import_for_supplier: " {code}の商品受領の前払い",
                code: "バウチャーコード",
                created_at: "時間",
                amount: "支払い金額",
                method: "方法",
            },
            modalCustomerPaid: {
                title_order: "注文 {code} の前払い",
                code: "受け取りコード",
                created_at: "時間",
                amount: "収益",
                method: "方法",
            },
            ModalConnectVTP: {
                title: "VIETTEL POSTログイン",
                username: "アカウント",
                password: "パスワード",
                placeholder: {
                    username: "アカウント",
                    password: "パスワード",
                },
                button: {
                    login: "ログイン"
                }
            },
            ModalCreateUpdatePickup: {
                title: "新しい受け取り先住所を追加",
                title_update: "配送先住所を更新",
                address: "受け取り先住所",
                province: "省/市",
                district: "地区",
                ward: "区",
                phone: "電話番号",
                placeholder_address: "家、建物、路地、道路の番号",
                placeholder_province: "省/市を選択",
                placeholder_district: "地区を選択",
                placeholder_ward: "区/コミューンを選択",
                placeholder_phone: "電話番号を入力",
                message_success: "新しい受け取り先住所を正常に作成",
                message_success_update: "受け取り先住所を正常に更新",
                confirm_title: "確認",
                confirm_content: "受け取り先住所を削除してもよろしいですか？",
                message_success_delete: "受け取り先住所を正常に削除しました?"
            },
            ModalCreateUpdateDelivery: {
                title: "新しい受け取り先住所を追加",
                title_update: "受け取り先住所を更新",
                name_address: "住所名",
                name: "受取名前",
                phone: "電話番号",
                address_value: "受取住所",
                provinceId: "省/市",
                district: "地区",
                ward: "区",
                message_success: "新しい配送先住所を正常に作成",
                confirm_title: "確認",
                confirm_content: "配送先住所を削除してもよろしいですか？",
            },
            ModalFeeAdjustment: {
                title: "調整",
                current: "現在の債務を支払う必要",
                updatedAt: "調整日",
                adjustedValue: "調整後の債務額",
                description: "メモ",
                createdBy: "作成者",
                cancel: "キャンセル",
                update: "更新",
                content: "調整を削除してもよろしいですか？"
            },
            ModalcreateDelivery: {
                titleCreate: 'もう一つの運送状を作成',
                description: "メモ",
            },
            
            ModalSelectOptionExport: {
                title: "ファイル輸出オプション",
                content: "ページオプション（合計 {totalNumbers} レコード）",
                option: "レコードを {from} から {to} までエクスポート",
                export: "ファイル輸出",
                skip: "スキップ"
            },
            ModalConnectGHN: {
                title: "ログイン Giao hàng nhanh",
                token: "Tokenコード",
                IDShop: "ショップID",
                name: "ショップ名",
                placeholder: {
                    token: "Tokenコードを入力",
                    IDShop: "店舗IDを入力",
                    name: "店舗名を入力",
                },
                button: {
                    login: "ログイン"
                }
            },

        /* Hết modal */

        /* Bán hàng */
            orderFromTab: {
                message_create_address: "配送先住所を追加して送料を確認",
                service_type: "サービスの種類",
                shipping_fee: "配送料",
                delivery_code: "運送状コード",
                delivery_time: "配送時間",
                delivery_status: "配送状況",
                placeholder: {
                    delivery_code: "運送状コード",
                }

            },

        /* Hết bán hàng */

            sendMail: {
                success: "メール送信成功！",
                failing: "メール送信失敗！"
            },
            ModalCreateTemporaryConfirm: {
                title: "現在の呼び出し数は支店に存在する数を超えています",
                confirm: "この注文伝票を一時保存しますか？"
            },
            ModalImportDepartment: {
                title: "データファイルから部門をインポートする"
            }
        }
    }
};

export default messages;