import i18n from '@/setup/i18n.js';

const constant = {
    statusSuccess: 200,
    cus_individual: 1,
    cus_company: 2,
    productActive:1,
    productInActive:0,
    tab: {
        first: 1,
        second: 2,
        third: 3,
    },
    statusOrderCancelled:4,
    statusPurchaseCancelled:3,
    statusReturnCancelled:2,
    statusCheckAPart: 0,    
    statusCheckAll: 1,
    statusCheckGroup: 0,
    statusCheckFIt: 2,
    // Cho phép xem thông tin giao dịch của nhân viên khác 
    statusActive: 1,
    // Không Cho phép xem thông tin giao dịch của nhân viên khác 
    statusInActive: 0,
    status_draft:0,
    statusLock: 0,
    statusAllow: 0,
    statusAllowAndWarning: 1,
    statusNotAllow: 2,
    statusOptionsTicket: [
        {id: 0, name: i18n.t('message.common.draft')},
        {id: 1, name: i18n.t('message.OrderSupplierIndex.statusConfirmed')},        
    ],
    statusOptionsCreateOrderSupplier: [
        {id: 0, name: i18n.t('message.common.draft')},
        {id: 1, name: i18n.t('message.OrderSupplierIndex.statusConfirmed')},
    ],
    statusOptionsTicketTemporary: 0,
    statusOptionsTicketConfirm: 1,
    paymentChannelCashMoney: 1,
    paymentChannelCardMoney: 2,
    paymentChannelTransferMoney: 3,
    paymentChannelVoucher: 4,
    discountOptions: [
        {id: 0, name: i18n.t('message.common.vnd')},
        {id: 1, name: i18n.t('message.common.percent')},
    ],
    calculatorOptions: [
        {id: 0, name: '+'},
        {id: 1, name: '-'},
    ],
    calculatorTypePlus: 0,
    calculatorTypeMinus: 1,
    
    discountTypeMoney: 0,
    discountTypePercent: 1,
    priceListOptions: [
        {id: 0, name: '--' + i18n.t('message.PriceListDetailIndex.choosePriceList') + '--'},
        {id: 1, name: i18n.t('message.common.currentPrice')},
        {id: 2, name: i18n.t('message.PriceListDetailIndex.costPrice')},
        {id: 3, name: i18n.t('message.common.lastPurchasePrice')},
        {id: 4, name: i18n.t('message.PriceListDetailIndex.price')},
    ],
    return: 0,
    debt: 1,
    status_cancel_import_order: 3,
    status_imported_order: 1,
    listStatusImportOrder:  [
        {id: 0, name: 'message.common.draft'},
        {id: 1, name: 'message.PurchaseOrderIndex.statusPurchased'},
        {id: 3, name: 'message.common.statusCancel'},
    ],
    listStatusInvoice: [
        { "id": 0, "name": "message.common.none" },
        { "id": 1, "name": "message.common.notReceived" },
        { "id": 2, "name": "message.common.received" }
    ],
    status_confirm_supplier: 1,
    status_purchase_one_part: 2,
    status_success: 3,
    status_cancel_order_supplier: 4,
    listStatusOrderSupplier:  [
        {id: 0, name: 'message.common.draft'},
        {id: 1, name: 'message.OrderSupplierIndex.statusConfirmed'},
        {id: 2, name: 'message.OrderSupplierIndex.status_purchase_a_part'},
        {id: 3, name: 'message.OrderSupplierIndex.status_complete'},
        {id: 4, name: 'message.common.statusCancel'},                    
    ], 
    statusNameOrderSupplier:  [
        i18n.t('message.common.draft'),
        i18n.t('message.OrderSupplierIndex.statusConfirmed'),
        i18n.t('message.OrderSupplierIndex.status_purchase_a_part'),
        i18n.t('message.OrderSupplierIndex.status_complete'),
        i18n.t('message.OrderSupplierIndex.status_cancel')
    ],
    statusOrderSupplier: {
        STATUS_DRAFT: 0,
        STATUS_CONFIRMED: 1,
        STATUS_IMPORTED:2,
        STATUS_FINISHED: 3,
        STATUS_CANCELLED: 4
    },
    
    status_returned_order_supplier: 1,
    status_cancel_returned_order_supplier: 2,
    listStatusReturnOrderSupplier:  [
        {id: 0, name: 'message.common.draft'},
        {id: 1, name: 'message.common.statusReturned'},
        {id: 2, name: 'message.common.statusCancel'},                    
    ],
    listStatusPayment:  [
        { "id": 0, "name": "message.common.unpaid" },
        { "id": 1, "name": "message.common.partialPayment" },
        { "id": 2, "name": "message.common.paid" }                    
    ],
    actionLinkImportByOrder: 1,
    actionLinkReturnImport: 1,
    maxNumber: 999999999999,
    notFound: i18n.t('message.common.notFound'),
    dayOfWeeks: [
        {id: 0, name: i18n.t('message.common.monday')},
        {id: 1, name: i18n.t('message.common.tuesday')},
        {id: 2, name: i18n.t('message.common.wednesday')},                    
        {id: 3, name: i18n.t('message.common.thursday')},                    
        {id: 4, name: i18n.t('message.common.friday')},                    
        {id: 5, name: i18n.t('message.common.saturday')},                    
        {id: 6, name: i18n.t('message.common.sunday')},                    
    ],
    hours: [
        {id: 1, name: '0'},
        {id: 2, name: '1'},
        {id: 3, name: '2'},                    
        {id: 4, name: '3'},                    
        {id: 5, name: '4'},                    
        {id: 6, name: '5'},                    
        {id: 7, name: '6'},                    
        {id: 8, name: '7'},                    
        {id: 9, name: '8'},                    
        {id: 10, name: '9'},                    
        {id: 11, name: '10'},                    
        {id: 12, name: '11'},                    
        {id: 13, name: '12'},                    
        {id: 14, name: '13'},                    
        {id: 15, name: '14'},                    
        {id: 16, name: '15'},                    
        {id: 17, name: '16'},                    
        {id: 18, name: '17'},                    
        {id: 19, name: '18'},                    
        {id: 20, name: '19'},                    
        {id: 21, name: '20'},                    
        {id: 22, name: '21'},                    
        {id: 23, name: '22'},                    
        {id: 24, name: '23'},                    
    ],
    monthly: [
        {id: 1, name: i18n.t('message.common.month1')},
        {id: 2, name: i18n.t('message.common.month2')},
        {id: 3, name: i18n.t('message.common.month3')},                    
        {id: 4, name: i18n.t('message.common.month4')},                    
        {id: 5, name: i18n.t('message.common.month5')},                    
        {id: 6, name: i18n.t('message.common.month6')},                    
        {id: 7, name: i18n.t('message.common.month7')},                    
        {id: 8, name: i18n.t('message.common.month8')},                    
        {id: 9, name: i18n.t('message.common.month9')},                    
        {id: 10, name: i18n.t('message.common.month10')},                    
        {id: 11, name: i18n.t('message.common.month11')},                    
        {id: 12, name: i18n.t('message.common.month12')},                    
    ],
    byDate: [
        {id: 1, name: i18n.t('message.common.day1')},
        {id: 2, name: i18n.t('message.common.day2')},
        {id: 3, name: i18n.t('message.common.day3')},                    
        {id: 4, name: i18n.t('message.common.day4')},                    
        {id: 5, name: i18n.t('message.common.day5')},                    
        {id: 6, name: i18n.t('message.common.day6')},                    
        {id: 7, name: i18n.t('message.common.day7')},                    
        {id: 8, name: i18n.t('message.common.day8')},                    
        {id: 9, name: i18n.t('message.common.day9')},                    
        {id: 10, name: i18n.t('message.common.day10')},                    
        {id: 11, name: i18n.t('message.common.day11')},                    
        {id: 12, name: i18n.t('message.common.day12')},                    
        {id: 13, name: i18n.t('message.common.day13')},                    
        {id: 14, name: i18n.t('message.common.day14')},                    
        {id: 15, name: i18n.t('message.common.day15')},                    
        {id: 16, name: i18n.t('message.common.day16')},                    
        {id: 17, name: i18n.t('message.common.day17')},                    
        {id: 18, name: i18n.t('message.common.day18')},                    
        {id: 19, name: i18n.t('message.common.day19')},                    
        {id: 20, name: i18n.t('message.common.day20')},                    
        {id: 21, name: i18n.t('message.common.day21')},                    
        {id: 22, name: i18n.t('message.common.day22')},                    
        {id: 23, name: i18n.t('message.common.day23')},                    
        {id: 24, name: i18n.t('message.common.day24')},                    
        {id: 25, name: i18n.t('message.common.day25')},                    
        {id: 26, name: i18n.t('message.common.day26')},                    
        {id: 27, name: i18n.t('message.common.day27')},                    
        {id: 28, name: i18n.t('message.common.day28')},                    
        {id: 29, name: i18n.t('message.common.day29')},                    
        {id: 30, name: i18n.t('message.common.day30')},                    
        {id: 31, name: i18n.t('message.common.day31')},                    
    ],
    productCommon: 1,
    status_paid: 1,
    productCharateristics: [
        { id: 1, name: i18n.t('message.headers.products')},
        { id: 2, name: i18n.t('message.common.service')},
        { id: 3, name: i18n.t('message.common.combo')}
    ],
    statusBusiness: [
        {id: 1, name: i18n.t('message.ProductIndex.active')},
        {id: 0, name: i18n.t('message.ProductIndex.inActive')},
        {id: null, name: i18n.t('message.common.all')},
    ],
    getPriceOrigin: 'get_price_origin',
    statusTicketTransferTemporary: 1,
    statusTicketTransferTransported: 2,
    statusTicketTransferReceive: 3,
    statusTicketRequestTransferSucceed: 3,
    statusTransferDraft: 1,
    actionConfirmTransfer: 'confirm',
    actionConfirmRequest: 'confirm',
    actionLinkTransfer: 'link',
    statusTransferInProgress: 2,
    statusTransferCancel: 4,
    statusTicketTransferBeg: 2,
    typeRequest: 'request',
    typeTransfer: 'transfer',
    typeAll: 1,
    typeMatch: 2,
    typeDeviated: 3,
    typeNotReceived: 4,
    actionGetPriceList: 'get_price_list',
    priceListIdCommon: 0,
    priceListCommon: {id: 0, name: i18n.t('message.PriceListsIndex.title')},   
    status_draft_order: 1, // orderconfirm
    status_confirm_order: 2,
    status_delivery_order: 3,    
    // add status end order
    status_end_order: 4,
    status_cancel_order:5,
    // end
    typeOrder: 'order',
    typeMultiOrder: 'multiOrder',
    typeReturn: 'typeReturn',
    typeInvoice: 'invoice',
    typeImportForSupplier: 'import',
    typeOrderForSupplier: 'orderForSupplier',
    status_complete_invoice: 1,  
    list_status_can_cancel_invoice: [1,3,5],
    status_cancel_invoice: 2,    
    status_complete_return: 1,
    calculation_multiplication: 0,     
    calculation_division: 1, 
    status_isUpdate: 1,  
    type_channel: {
        shopee: 'shopee',
        lazada: 'lazada',
        tiktok: 'tiktok',
        tiki: 'tiki'
    },
    status_connected: 1,
    status_choose_setting: 1,
    statusSyncOrder: [
        {id: 0, name: i18n.t('message.common.create')},
        // {id: 1, name: 'Tạo hoặc cập nhật'},
    ],
    timeSyncOrder: [
        // {id: 0, name: 'Hôm nay'},
        // {id: 1, name: '7 ngày qua'},
        // {id: 2, name: '15 ngày qua'},
        {id: 0, name: i18n.t('message.common.afterSetting')},
    ],
    syncTimeSale: [
        // {id: 0, name: 'Thời gian đơn tạo trên sàn'},
        {id: 0, name: i18n.t('message.common.confirmationTime')},
    ],
    syncProductLink: [
        // {id: 0, name: 'Tự động tạo trên Omipos và liên kết'},
        {id: 0, name: i18n.t('message.common.manualLink')},
    ],
    syncSaleQuantity:[
        // {id: 0, name: 'Tồn kho'},
        {id: 0, name: i18n.t('message.common.inventoryMinusOrdered')},
        // {id: 2, name: 'Tồn kho + Đặt NCC'},
        // {id: 3, name: 'Tồn kho - Đặt hàng + Đặt NCC'},
    ],
    textSyncOrder: 'syncOrder',
    textSyncOrderQuantity: 'syncOrderQuantity',
    textSyncQuantityAllocation: 'syncQuantityAllocation',
    textSyncPrice: 'syncPrice',
    textAutoCopeProduct: 'autoCopeProduct',
    textAutoMappingProduct: 'autoMappingProduct',
    textRemoveMappingProduct: 'removeMappingProduct',
    textShopee: 'shopee',
    shopeeId: 1,
    textLazada: 'lazada',
    lazadaId: 2,
    textTiki: 'tiki',
    tikiId: 3,
    textTiktok: 'tiktok',
    tiktokId: 5,
    textSendo: 'sendo',
    sendoId: 4,
    omiId: 0,
    textActive: 1,
    textInActive: 0,
    numberRecords:[
        {id: 10, name: '10'},
        {id: 20, name: '20'},
        {id: 30, name: '30'},
        {id: 50, name: '50'},
    ],
    optionSearchProductLazada:[
        {id: 2, name: i18n.t('message.common.productOnLazada')},
        {id: 0, name: i18n.t('message.medicineProductMapping.productOmipos')},
    ],
    optionSearchProductShopee:[
        {id: 1, name: i18n.t('message.common.productOnShopee')},
        {id: 0, name: i18n.t('message.medicineProductMapping.productOmipos')},
    ],
    optionSearchProductTiki:[
        {id: 3, name: i18n.t('message.common.productOnTiki')},
        {id: 0, name: i18n.t('message.medicineProductMapping.productOmipos')},
    ],
    optionSearchProductTiktok:[
        {id: 5, name: i18n.t('message.common.productOnTiktok')},
        {id: 0, name: i18n.t('message.medicineProductMapping.productOmipos')},
    ],
    optionSearchProductSendo:[
        {id: 4, name: i18n.t('message.common.productOnSendo')},
        {id: 0, name: i18n.t('message.medicineProductMapping.productOmipos')},
    ],
    optionSearchSyncFailOrder:[
        {id: 0, name: i18n.t('message.common.searchOrderCode'), placeholder: i18n.t('message.common.enterOrderCode')},
        {id: 2, name: i18n.t('message.common.searchCustomerInfor'), placeholder: i18n.t('message.common.enterCustomerCodeNamePhone')},
        {id: 3, name: i18n.t('message.common.searchProductCodeName'), placeholder: i18n.t('message.common.enterProductCodeName')},
    ],
    searchOrderId: 0,
    searchInvoiceId: 1,
    searchCustomerId: 2,
    searchProductId: 3,
    listSaleChannel:[
        {id: 1, name: 'Shopee'},
        {id: 2, name: 'Lazada'},
        {id: 3, name: 'Tiki'},
        {id: 4, name: 'Sendo'},
        {id: 5, name: 'Tiktok'},
    ],
    listStatusSync: [
        {id: 0, name: i18n.t('message.common.all')},
        {id: 1, name: i18n.t('message.common.syncError')},
    ],
    listTypeChannel: [
        {id: 1, name: i18n.t('message.common.productOnECommerce')},
        {id: 0, name: i18n.t('message.medicineProductMapping.productOmipos')},
    ],
    allMapping: 0,
    falseMapping: 1,
    successMapping: 2,
    syncFailOrders: 'orders',
    syncFailProducts: 'products',
    optionStatusCoupon: [
        {id: null, name: i18n.t('message.common.all')},
        {id: 0, name: i18n.t('message.common.notRelease')},
        {id: 1, name: i18n.t('message.common.released')},
        {id: 2, name: i18n.t('message.common.used')},
        {id: 3, name: i18n.t('message.common.statusCancel')},
    ],
    statusCouponVoucherAll: null,
    statusCouponVoucherReleased: 1,
    fromConfirm: 'fromConfirm',
    optionStatusCouponVoucher: [
        { id: 0, label: i18n.t('message.common.all'), value: '' },
        { id: 1, label: i18n.t('message.CouponIndex.modal.create_or_update.status_active'), value: 1 },
        { id: 2, label: i18n.t('message.CouponIndex.modal.create_or_update.status_inactive'), value: 0 },
    ],
    typeCoupon: 1,
    typeVoucher: 2,
    
    optionUserCreate: [
        {id: 1, name: i18n.t('message.headers.customers')},
        {id: 2, name: i18n.t('message.headers.suppliers')},
        {id: 3, name: i18n.t('message.headers.employees')},
        {id: 4, name: i18n.t('message.headers.transporters')},
        {id: 5, name: i18n.t('message.TransporterIndex.other')},
    ],
    optionUserCreateOther: 5,
    optionMethodPayment: [
        {id: 1, name: i18n.t('message.common.cash')},
        {id: 2, name: i18n.t('message.common.card')},
        {id: 3, name: i18n.t('message.common.transferPayment')},
    ],
    methodPaymentCard: 2,
    methodPaymentBank: 3,
    methodCashId: 1,
    methodCardId: 2,
    methodTransferId: 3,
    statusNotUsedYetVoucherCoupon: 0,
    statusPublishedVoucherCoupon: 1,
    optionPromotions: [
        {id: 1, name: i18n.t('message.InvoiceIndex.title')},
        {id: 2, name: i18n.t('message.headers.products')},
        {id: 3, name: i18n.t('message.common.invoiceWithProduct')},
    ],
    promotionInvoiceId: 1,
    promotionProductId: 2,
    promotionAllId: 3,
    promotion_invoice_discount: 1,
    promotion_invoice_donate_product: 2,
    promotion_invoice_discount_on_product: 3,
    promotion_invoice_donate_voucher: 4,
    promotion_product_discount: 5,
    promotion_product_donate_product: 6,
    promotion_product_allow_quantity: 7,
    promotion_product_donate_voucher: 8,
    promotion_all_invoice_discount: 9,
    promotion_all_donate_product: 10,
    promotion_all_product_discount: 11,
    promotion_invoice_discount_description: i18n.t('message.common.promotionInvoiceDiscountDesc'),
    promotion_invoice_donate_product_description: i18n.t('message.common.promotionInvoiceDonateProductDesc'),
    promotion_invoice_discount_on_product_description: i18n.t('message.common.promotionInvoiceDiscountProductDesc'),
    promotion_product_discount_description: i18n.t('message.common.promotionProductDiscount'),
    promotion_product_donate_product_description: i18n.t('message.common.promotionProductDonateProductDesc'),
    promotion_product_allow_quantity_description: i18n.t('message.common.promotionProductAllowProductDesc'),
    promotion_all_invoice_discount_description: i18n.t('message.common.promotionAllInvoiceDiscountDesc'),
    promotion_all_donate_product_description: i18n.t('message.common.promotionAllDonateProductDesc'),
    promotion_all_product_discount_description: i18n.t('message.common.promotionAllProductDiscountDesc'),
    optionFormPromotionInvoice: [
        {id: 1, name: i18n.t('message.InvoiceIndex.discount')},
        {id: 2, name: i18n.t('message.common.giveProduct')},
        {id: 3, name: i18n.t('message.common.discountProduct')},
        // {id: 4, name: 'Tặng voucher'},
    ],
    optionFormPromotionProduct: [
        {id: 5, name: i18n.t('message.common.buyProductDiscountProduct')},
        {id: 6, name: i18n.t('message.common.buyProductGiveProduct')},
        {id: 7, name: i18n.t('message.common.priceByQuantity')},
        // {id: 8, name: 'Mua hàng tặng voucher'},
    ],
    optionFormPromotionAll: [
        {id: 9, name: i18n.t('message.InvoiceIndex.discount')},
        {id: 10, name: i18n.t('message.common.giveProduct')},
        {id: 11, name: i18n.t('message.common.discountProduct')},
    ],
    optionFormPromotionPrice: [
        {id: 1, name: i18n.t('message.ProductIndex.price')},
        {id: 2, name: i18n.t('message.customerIndex.discount')},
    ],
    promotionByPriceId: 1,
    promotionByDiscountId: 2,
    priceZero: 0.00,
    full: 1,
    promotionOptionIdsNeedProduct: [2, 3, 5, 6, 10, 11],
    limitPromotionUsageType: [
        {id: 0, name: i18n.t('message.common.warning')},
        {id: 1, name: i18n.t('message.common.ban')},
    ],
    defaultPromotionUsageType: 0,
    confirmPromotionWarning: 'confirm', 
    cancelPromotionWarning: 'cancel',
    stockCardInvoice: 'invoice',
    stockCardReturn: 'return',
    stockCardTransfer: 'transfer',
    stockCardImportForSupplier: 'importForSupplier',    
    stockCardReturnForSupplier: 'returnForSupplier',
    stockCardSupplier: 'supplier',
    stockCardOrderForSupplier: 'orderForSupplier',    
    typeProduct: 1,
    typeProductService: 2,
    typeProductCombo: 3,
    optionStatusConsignment: [
        { id: 0, label: i18n.t('message.common.all'), value: ''},
        { id: 1, label: i18n.t('message.common.stocking'), value: 1},
        { id: 2, label: i18n.t('message.common.productOutOfStock'), value: 2},
    ],
    actionUpdateOrder: 'updateOrder',
    actionUpdateInvoice: 'updateInvoice',    
    locationCategoryLevel1: 1,
    locationCategoryLevel2: 2,
    locationCategoryLevel3: 3,
    locationCategoryLevel4: 4,
    locationCategoryLevel5: 5,
    isFirstCate: '0',
    isAdmin: 1,
    taxRate: [
        { id: 5, label: i18n.t('message.common.fivePercent'), value: 5},
        { id: 8, label: i18n.t('message.common.eightPercent'), value: 8},
        { id: 10, label: i18n.t('message.common.tenPercent'), value: 10},
        { id: 0, label: i18n.t('message.common.zeroPercent'), value: 0},
    ],
    discountBasTemplateId: 14,
    taxRateShow: 1,
    hsCodeShow: 1,
    TabInvoices: 'TabInvoices',
    TabOrder: 'TabOrder',
    TabDeliveries: 'TabDeliveries',
    TabReturns: 'TabReturns',
    TabOrderSuppliers: 'TabOrderSuppliers',
    TabImports: 'TabImports',
    TabReturnSuppliers: 'TabReturnSuppliers',
    TabTransfers: 'TabTransfers',
    TabReceipts: 'TabReceipts',
    TabPayment: 'TabPayment',
    TabRefunds: 'TabRefunds',
    TabPlanDetails: 'TabPlanDetails',
    TabPlanImportBuy: 'TabPlanImportBuy',
    TabDiscountBas: 'TabDiscountBas',    
    TypePlanDetails: 'typePlanDetails',
    TypePlanImportBuy: 'typePlanImportBuy',
    TypePrintOrder: 'typePrintOrder',
    TypePrintDeliveries: 'typePrintDeliveries',
    TypeOrderSuppliers: 'typeOrderSuppliers',
    TypePurchaseOrder: 'typePurchaseOrder',
    TypeReturnSuppliers: 'typeReturnSuppliers',
    TypePrintInvoice: 'typePrintInvoice',
    TypePrintTransfer: 'typePrintTransfer',
    TypePrintReturn: 'typePrintReturn',
    TypePrintRefund: 'typePrintRefund',
    TypePrintReceipts: 'TypePrintReceipts',
    TypePrintPayments: 'TypePrintPayments',
    TypePrintSettlementAdvance: 'typePrintSettlementAdvance',        
    TypePrintDiscountBas: 'TypePrintDiscountBas',
    typePrints: [
        'typePrintOrder', 
        'typePrintInvoice',
        'typePrintReturn'
    ],
    TypeDiscountsBas: 'TypeDiscountBas',

    importPositionProduct: 1,
    exportPositionProduct: 2,
    transferPositionProduct: 3,
    // Trạng thái hoạt động của nhân viên
    lstStatusUser: [
        {id:1, name: i18n.t('message.customerIndex.active')},
        {id:2, name: i18n.t('message.customerIndex.inActive')},
        {id:3, name: i18n.t('message.common.removed')}
    ],
    typeUpdate: 'add',
    typePermissionCategory: 1,
    typePermissionMajor: 2,
    typePermissionReport: 3,
    typePermissionPromotion: 4,
    typePermissionSetting: 5,
    listStatusUser: [
        {id: null, name: 'message.common.all'},
        {id: 1, name: 'message.common.active'},
        {id: 2, name: 'message.common.inActive'},
    ],
    temporary: 0,
    completed: 2,
    ticketTransfercompleted: 1,
    ticketcompleted: 1,
    roleRoot:1,
    update: 'update',
    listOptionStamps: [
        {id: 0, name: i18n.t('message.ModalFileUploadProducts.titleUpdateInventoryNo')},
        {id: 1, name: i18n.t('message.ModalFileUploadProducts.titleUpdateInventoryYes')}
    ],
    statusHasStamp: 1,
    statusNosStamp: 0,
    methodPayments: [
        {id: 1, name: i18n.t('message.common.cash')},
        {id: 2, name: i18n.t('message.common.transferPayment')},
        {id: 3, name: i18n.t('message.common.card')},
    ],
    TYPE_SRC_ORDER_SUPPLIER: 1,
    TYPE_SRC_RETURN_IMPORT_SUPPLIER: 2,
    TYPE_SRC_SETTLEMENT_ADVANCE: 3,
    TYPE_SRC_IMPORT_SUPPLIER: 4,
    TYPE_SRC_DISCOUNT_BAS: 6,
    TYPE_SRC_SUPPLIER: 5,
    STATUS_TEMPORARY: 0,
    STATUS_PROCESSING: 1,
    STATUS_COMPLETED: 2,
    STATUS_CANCELLED: 3,
    refund_status_origin: 1,
    refund_approve: 2,
    not_invoice_supplier: i18n.t('message.common.none'),
    has_invoice_supplier: i18n.t('message.common.hasInvoice'),
    status_not_invoice_supplier: 0,
    not_receive_invoice_supplier: i18n.t('message.common.notReceived'),
    status_not_receive_invoice_supplier: 1,
    received_invoice_supplier: i18n.t('message.common.received'),
    status_received_invoice_supplier: 2,
    type_approve: 2,
    type_refuse: 4,
    ticketProcessing: 1,
    type_refund_approve: 'approve',
    type_refund_update: 'update',
    paymentSettlementAdvanceCashMoney: 1,
    paymentSettlementAdvanceCardMoney: 3,
    paymentSettlementAdvanceTransferMoney: 2,
    confim: 'confim',
    //Hiển thị tab
    // ----------------------------------------
    tabHistoryPayment: 'historyPayment',
    tabHistoryReturn: 'historyReturn',
    tabHistorySupplier: 'historySupplier',
    tabHistoryImport: 'historyImport',
    tabHistoryDelivery: 'historyDelivery',
    tabDetailsDelivery: 'detailDelivery',
    tabInformation: 'info',
    //Hiển thị giá trị
    tabPaymentValue: 1,
    tabSupplierValue: 2,
    tabDeliveryValue:3,
    tabInformationValue: 0,
    tabDetailValue: 5,
    // ----------------------------------------
    bankBook:'bank',
    cashBook:'cash',
    ticket_receipt_cash_book: 1,
    type_ticket_receipt_cash_book: 8,
    ticket_payment_cash_book: 2,
    type_ticket_payment_cash_book: 9,
    TabSettlementAdvance: 'TabSettlementAdvance',
    TabTicketImport: 'TabTicketImport',
    TabTicketExport: 'TabTicketExport',
    TabTicketTransfer: 'TabTicketTransfer',
    OrderForSupplierFinished: 3,    
    type_receipts_cash_book: 1,
    type_payment_cash_book: 2,
    status_paid_cash_book: 1,
    BANK_STATUS_CANCEL: 2,
    CASH_STATUS_CANCEL: 2,
    type_bank_book_transfer: 2,
    type_bank_book_card: 1,
    filePermission: [
        
    ],
    orderSupplierPermissionStatus: ['OrderSuppliers.update','OrderSuppliers.updateNotStatus'],

    PriceDetailsFilePermission: [
        'PriceDetails.import', 'PriceDetails.export', 
    ],
    SettingPriceFilePermission: [
        'SettingPrice.import', 'SettingPrice.export', 
    ],
    SettingPricePermission: [
        'SettingPrice', 'PriceList', 'PriceDetails'
    ],
    importSupplierPermission:[
        'ImportProductCategory', 'OrderSuppliers', 'PurchaseOrders', 'ReturnImportForSupplier', 'SettlementAdvance',
        'Plans', 'PlanPurchases'
    ],
    planPermission:[
        'Plans', 'PlanPurchases'
    ],
    // Tạm ứng quyết toán
    settlementAdvance:[
        'SettlementAdvance.create','SettlementAdvance.update'
    ],
    productsPermission:[
        'ProductCategory', 'Categories', 'MappingProduct', 'Units'
    ],
    categoryPermission:[
        'ProductCategory', 'Medicine', 'CustomerCategory', 'SupplierCategory', 'UserCategory', 'SaleChannels', 
        'SettingPrice', 'Locations', 'MappingProduct', 'Transporters'

    ],
    medicinePermission:[
        'Medicine', 'Medicine.data', 'Medicine.import', 'Medicine.export',
    ],
    //Phân quyền cho mục giao dịch
    transactionPermission:[
        'ImportProductCategory', 'SaleCategory', 'TransferWarehouse', 'LocationsProducts','BankCashBook',
        'CashBook','CashBook.data','CashBook.create','CashBook.update','CashBook.cancel','CashBook.export',
        'CashBook.print','BankBook','BankBook.data','BankBook.create','BankBook.update','BankBook.cancel',
        'BankBook.export','BankBook.print', 'SettlementAdvance','SettlementAdvance','SettlementAdvance.data',
        'SettlementAdvance.create','SettlementAdvance.update','SettlementAdvance.cancel','SettlementAdvance.export','SettlementAdvance.print',
        'Deliveries',
        // 'Deliveries.data','Deliveries.quickSave','Deliveries.paymentAndCreateFund','Deliveries.export','Deliveries.remove','Deliveries.save','Deliveries.print',
        'Plans', 'PlanPurchases'
    ],
    planActionPermission:[         
        'Plans.create','Plans.export','Plans.print','Plans.delete','Plans.cancel',
    ],
    planPurchasesActionPermission:[        
        'PlanPurchases.create','PlanPurchases.export','PlanPurchases.print','PlanPurchases.delete','PlanPurchases.cancel',
    ],
    // Phân quyền cho mục Sổ quỹ
    bankCashBookPermission:[
        'BankCashBook','CashBook','CashBook.data','CashBook.create','CashBook.update','CashBook.cancel',
        'CashBook.export','CashBook.print','BankBook','BankBook.data','BankBook.create','BankBook.update',
        'BankBook.cancel','BankBook.export','BankBook.print'
    ],
    // Phân quyền cho mục sổ quỹ tiền mặt
    cashBookPermission:['CashBook','CashBook.data','CashBook.create','CashBook.update','CashBook.cancel','CashBook.export','CashBook.print'],
    // Phân quyền cho mục sổ quỹ ngân hàng
    bankBookPermission:['BankBook','BankBook.data','BankBook.create','BankBook.update','BankBook.cancel','BankBook.export','BankBook.print'],
    // Thiết lập kênh bán trong Tích hợp hệ thống
    saleChanelList: [
        'SaleChannels.data','SaleChannels.create','SaleChannels.update','SaleChannels.remove'
    ],    
    object_other_bank_book: 6,
    object_other_cash_book: 6,
    applicableObject: [
        {id:2, name: i18n.t('message.headers.customers')},
        {id:3, name: i18n.t('message.headers.suppliers')},
        {id:4, name: i18n.t('message.headers.employees')},
        {id:5, name: i18n.t('message.headers.transporters')},
        {id:6, name: i18n.t('message.TransporterIndex.other')},
    ],
    // Customer: Order, Invoice, Returns
    typeSrcCustomer:[1,2,3],
    // Supplier: OrderForSuppler, ImportforSupplier, ReturnImportForSupplier
    typeSrcSupplier:[4,5,6],   
    
    status_all : 1,    
    createNewCategory: 1, // Thêm mới nhóm hàng hóa khi import
    is_all_branch: 1,
    status_alert_error_import: 1,
    status_update_value_import: 2,
    text_current_price: i18n.t('message.common.currentPrice'),
    text_common_price: i18n.t('message.PriceListDetailIndex.price'),
    text_last_price: i18n.t('message.common.lastPurchasePrice'),
    sale_mode_fast: 1,
    sale_mode_trans: 2,
    shipping_mode_default: 2,
    shipping_mode_fast: 1,
    shipping_mode_in_day: 3,
    weightUnits: [
        {id: 1, name: 'Gram'},
        {id: 2, name: 'Kg'},
    ],
    weightUnitsIdGram: 1,
    weightUnitsIdKg: 2,
    transporters: {
        status_active: 1,
        status_in_active: 0
    },
    max_transporter_integration_id: 20,
    transporter_integration: 1,    
    transporter_other: 2,    
    transporter_tab_info: 1,    
    transporter_tab_history: 2,    
    transporter_tab_control_history: 3,    
    record_checked: 'checked',    
    status_using_price_cod: 1,
    status_cod_all: 2,
    typeTranPersonal: 0,
    typeTranCompany: 1,
    receivePayment: 'CASH_BY_RECIPIENT',
    sendPayment: 'CASH',
    appPayment: 'BALANCE',
    level_1: 'TIER_1',
    level_2: 'TIER_2',
    level_3: 'TIER_3',
    level_4: 'TIER_4',
    level_5: 'TIER_MAX',
    addressBranchCreate: 'isCreate',
    addressBranchUpdate: 'isUpdate',
    listDeliveryStatus: [
        {id: 1, name: i18n.t('message.common.waitingForProgressing')},
        {id: 2, name: i18n.t('message.common.delivering')},
    ], 
    typePrintSingle: 'single',
    typePrintMulti: 'multi',
    typeLocationCustomer: 'customer',
    typeLocationBranch: 'branch',
    typePrintTitle: i18n.t('message.common.selectTypePaper'), 
    typePrintPreviewPrintTitle: i18n.t('message.common.previewTemplate'),
    lstExportTranporterIndex: [2],    
    customer: {
        values: {
            info: "info",
            address: "addressGetOrder",
            historyOrder: "historyOrder",
            historySale: "historySale"
        }
    },
    priceListTab: {
        orders: "orders",
        invoices: "invoices",
        returns: "returns",
    },
    supplier: {
        values: {
            info: "info",
            paymentForSupplier: "plan",
            historyOrder: "historyOrder",
            historyImport: "historyImport"
        }
    },
    type_payment_total: 1,
    type_payment_cod: 2,
    type_payment_fee_ship: 3,
    service_type_default: 2,
    delivery_status_default: 1,
    LIST_STATUS_DELIVERY_CAN_CANCEL: ['ASSIGNING', 'ACCEPTED', 'CONFIRMING', 'PAYING', 'IDLE', 'ready_to_pick', 'picking', "100", "101", "102", "103", "104", ],
    LIST_STATUS_DELIVERY_HANDMADE_CAN_CANCEL: [1, 6],
    STATUS_DELEIVEY_CANCEL: ['CANCELLED', 'cancel', "107", "503"],

    paid_by_receiver: 2,
    paid_by_sender: 1,
    paid_by_app: 3,
    ahamoveId: 1,
    status_update_delivery_1: 1,
    status_update_delivery_2: 2,
    status_update_delivery_3: 3,
    status_update_delivery_4: 4,
    status_delivery_complete: 3,
    status_invoice_complete: 1,
    status_invoice_in_process: 3,
    status_invoice_not_delivery: 5,
    option_payment_1: 1,
    option_payment_2: 2,
    option_payment_3: 3,
    multiple_option: i18n.t('message.common.multipleOptions'),
    all_option: i18n.t('message.common.all'),
    plan_status_draft: 1,
    plan_status_wait: 2,
    plan_status_complete: 3,
    plan_status_cancel: 4,
    listPlanStatus: [
        {id: 1, name: i18n.t('message.common.draft')},
        {id: 2, name: i18n.t('message.common.waitingForProgressing')},
    ], 
    plan_status_success: 3,
    delete: "delete",
    plan_purchase_status_draft: 1,
    plan_purchase_status_complete: 2,
    plan_purchase_status_complete_order: 3,
    type_variant: 1,
    type_print_multiple: 1,
    type_print_single: 0,
    receiver: i18n.t('message.common.receiver'),
    accountant: i18n.t('message.common.accountant'),
    seller: i18n.t('message.customerIndex.sellerName'),   
    option_time_today: 1, 
    option_time_week: 2, 
    option_time_month: 3, 
    option_time_3_month_ago: 4, 
    option_time_other: 5, 
    is_company: 2,
    not_company: 1,

    // TabOrderPDF: 'order', 
    // TabInvoicePDF: 'invoice', 
    // TabTransferPDF: 'transfer', 
    TabRequestPDF: 'TabRequest', 
    // TabReturnPDF: 'return', 
    // TabOrderSupplierPDF: 'orderSupplier', 
    // TabImportSupplierPDF: 'importSupplier', 
    // TabReturnSupplierPDF: 'returnSupplier', 
    // TabRefundPDF: 'refund', 
    // TabReceiptTicketPDF: 'receiptTicket', 
    // TabPaymentTicketPDF: 'paymentTicket', 
    // TabDeliveryPDF: 'delivery', 
    // TabPlanDetailPDF: 'planDetail', 
    // TabPlanPurchasePDF: 'planPurchase', 
    // TabSettlementAdvancePDF: 'settlementAdvance', 
    // TabImportLocationTicketPDF: 'importLocationTicket', 
    // TabExportLocationTicketPDF: 'exportLocationTicket', 
    // TabTransferLocationTicketPDF: 'transferLocationTicket', 
    TabEditPdfTemplate: 1,
    TabShowKeyPdfTemplate: 2,
    typeImportProducts: 1,
    typeSellProducts: 2,
    typeTransferProducts: 3,
    statusTransporterAll: 2,
    isChangeInformationInvoice: 1,
    msgErrorStop: 0, //Báo lỗi và dừng import
    updateProduct: 1, // Cập nhật hàng hóa khi import
    createProductNew: 2, // Thêm mới hàng hóa khi import
    updateCreateProduct: 3, // Cập nhật và Thêm mới hàng hóa    
    msgUpEmployee:1, // Thay thế thông tin nhân viên cũ bằng thông tin nhân viên mới
    TYPE_PRODUCT: 'product',
    TYPE_INVENTORY_PLAN: 'inventory_plan',
    discount_bas_status_draft: 0, // Phiếu tạm
    discount_bas_status_success_payment: 2, // Phiếu Đã quyết toán
    discount_bas_status_no_payment: 1, // PHiếu chưa quyết toán
    discount_bas_status_cancel: 3, // PHiếu đã hủy
    cancelDiscountBas: 'cancel',
    deleteDiscountBas: 'delete',
    searchDiscountBas: 'search',
    branchDiscountBas: 'branch',
    printUpdateTab: 0,
    printInfoTab: 1,
    active: 1,
    inactive: 0,
    statusOrderNotAllow:[3,4,5,6],
    fieldOrderDetail : [
        {
            name: i18n.t('message.DetailOrdersCard.prodCode'),
            field: 'code',
            selected: true,
        },
        {
            name: i18n.t('message.DetailOrdersCard.prodName'),
            field: 'code',
            selected: true,
        },
        {
            name: i18n.t('message.DetailOrdersCard.dvt'),
            field: 'unitName',
            selected: true,
        },
        {
            name: i18n.t('message.DetailOrdersCard.attribute'),
            field: 'attributeName',
            selected: true,
        },
        {
            name: i18n.t('message.ModalProductFormTabMain.hs_code'),
            field: 'hsCode',
            selected: true,
        },
        {
            name: i18n.t('message.ModalProductFormTabMain.tax_rate'),
            field: 'tax',
            selected: true,
        },
        {
            name: i18n.t('message.ProductIndex.batch'),
            field: 'consignment',
            selected: true,
        },
        {
            name: i18n.t('message.common.stamp'),
            field: 'stamp',
            selected: true,
        },
        {
            name: i18n.t('message.ProductIndex.quantity'),
            field: 'quantity',
            style: 'text-align: right !important; ',
            selected: true,
        },
        {
            name: i18n.t('message.DetailOrdersCard.prodPrice'),
            field: 'price',
            style: 'text-align: right !important; ',
            selected: true,
        },
        {
            name: i18n.t('message.DetailOrdersCard.prodDiscount'),
            field: 'discount',
            style: 'text-align: right !important; ',
            selected: true,
        },
        {
            name: i18n.t('message.ProductIndex.price'),
            field: 'sendPrice',
            style: 'text-align: right !important; ',
            selected: true,
        },
        {
            name: i18n.t('message.DetailOrdersCard.totalPrice'),
            field: 'subTotal',
            style: 'text-align: right !important; ',
            selected: true,
        },
    ],
    filterById: "id",
    filterByCode: "code",
    typePrintTemplateAdd: "add",
    typePrintTemplateUpdate: "update",
    printTemplateActive: 1,
    printTemplateDefault: [1,2,3,4,5,6,7,8,9,10,14],
    defaultOrderTemplateId: 1,
    defaultInvoicesTemplateId: 2,
    orderStatusDelivery: 3,
    defaultReturnTemplateId: 4,
    defaultOrderSupplierTemplateId: 5,
    defaultImportSupplierTemplateId: 6,
    defaultReturnSupplierTemplateId: 7,
    defaultTransferTemplateId: 8,
    defaultReceiptTemplateId: 9,
    defaultPaymentTemplateId: 10,
    defaultRefundTemplateId: 11,
    defaultSettlementAdvanceTemplateId: 12,
    defaultTicketImportTemplateId: 13,
    defaultTicketExportTemplateId: 14,
    defaultTicketTransferTemplateId: 15,
    defaultPlanDetailTemplateId: 16,
    defaultPlanImportBuyTemplateId: 17,
    defaultDiscountBasTemplateId: 18,
    typeAhaMove: 'ahamove',
    typeGHN: 'ghn',
    typeVTP: 'vtp',
    giao_hanh_nhanh_transposter: 2,
    vtp_transposter: 3,
    ahamove_transposter: 1,
    ahamove_option_id: 36,
    ghn_option_id: 37,
    vtp_option_id: 39,

    ahamove_transposter_id: 1,
    ghn_transporter_id: 2,
    priceListIdField: 'priceListId',
    typeForDay: 1, 
    typeForHour: 2,
    typeForDayOfWeek: 3,
    typeNoticeProduct: 'productConsignment',
    typeNoticeCoupon: 'coupon',
    typeNoticeVoucher: 'voucher',
    typeNoticePromotion: 'promotions',

    //Phân quyền báo cáo
    reports: [ 'SalesReports', 'PurchaseReports', 'ProductReports', 'ReportSaleDetails', 'ReportSaleChannel', 'ReportSaleEmployee', 'ReportSaleBranch', 'ReportRevenueProduct', 'ReportRevenueInBranch', 'ReportRevenuePriceList', 'ReportRevenueCustomerGroup', 'ReportPurchaseDetail', 'ReportStockDetail', 'ReportInventories', 'ReportInventoriesDetail', 'ReportValueBranch', 'ReportConsignment', 'ReportStockLocation', 'ReportStockLocationConsignment'],
    salesReports: ["ReportSaleDetails","ReportSaleChannel","ReportSaleEmployee","ReportSaleBranch","ReportRevenueProduct","ReportRevenueInBranch","ReportRevenuePriceList","ReportRevenueCustomerGroup"],
    purchaseReports: ['ReportPurchaseDetail'],
    productReports: ["ReportStockDetail","ReportInventories","ReportInventoriesDetail","ReportValueBranch","ReportConsignment","ReportStockLocation","ReportStockLocationConsignment"],
    settingStore: {
        setting_time_return_id: 18,
        id_setting_order: 13,
        can_Order_out_Stock_detail_Id: 4,
    },
    settingNotActive: [ 14, 15, 20],
    settingReturnTimeTypeInvoice: 'invoice',
    settingReturnTimeTypeRefund: 'refund',
    typePermissionDashboard: 6,
    permissionsDasboard: "Dashboard",
    routerName: [  
        {code:'Dashboard',name:'Home'},
        {code:'Products',name:'ProductIndex'},
        {code:'Medicine',name:'MedicineIndex'},
        {code:'Customer',name:'CustomerIndex'},
        {code:'Supplier',name:'SupplierIndex'},
        {code:'User',name:'UserIndex'},
        {code:'Transporter',name:'TransporterIndex'},
        {code:'SaleChannel',name:'SaleChannelIndex'},
        {code:'PriceList',name:'PriceListIndex'},
        {code:'CreateOrder',name:'CreateOrder'},
        {code:'Categories',name:'CategoryIndex'},
    ],
    typeSendZaloGroup: 0,
    typeSendZaloZNS: 1,
    languages: ['vi', 'en', 'ja'],
    // languages: ['vi', 'en'],
    productTypes: [
        { "id": 1, "name": i18n.t("message.ProductIndex.title"), "language": true },
        { "id": 2, "name": i18n.t("message.common.service"), "language": true },
        { "id": 3, "name": i18n.t("message.common.combo"), "language": true }
    ],
    textInvoice: "Hóa đơn",
    textReturn: "Trả hàng bán",
    textBuy: "Mua hàng",
    textReturnImport: "Trả hàng nhập",
    textSale: "Bán hàng",
    logo_image: process.env.VUE_APP_V1_API_URL+'/images/logo-v2.png',
    statusStopSupplier: 2,
};

export default constant;
