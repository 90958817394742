<template>
    <div class="row mr-1">
        <div class="pl-4" :class="[(entry.products && entry.products.length ? 'col-left' : ''), (entry.saleMode == this.$constant.sale_mode_trans ? 'col-lg-6 cart-container-content d-flex flex-column justify-content-between' : 'col-8 col-xl-9'), (isExtend ? '' : 'col-8 col-xl-9')]">            
            <table class="table table-index table-striped">
                <thead class="text-center">
                <tr>
                    <th scope="col" style="width: 2%;" class="checkbox-table"></th>
                    <th scope="col" style="width: 2%;">
                        {{$t('message.common.STT')}}
                    </th>
                    <th scope="col" style="width: 10%;">
                        {{$t('message.DetailOrdersCard.prodCode')}}
                    </th>
                    <th scope="col" style="width: 15%; min-width: 100px !important">
                        {{$t('message.DetailOrdersCard.prodName')}}
                    </th>
                    <th scope="col" style="width: 10%;">
                        {{$t('message.DetailOrdersCard.dvt')}}
                    </th>
                    <th scope="col" style="width: 8%;">
                        {{$t('message.DetailOrdersCard.attribute')}}
                    </th>

                    <th scope="col" style="width: 7%;"  v-if="this.isHsCode">
                        {{$t('message.ModalProductFormTabMain.hs_code')}}
                    </th>
                    <th scope="col" style="width: 7%;"  v-if="this.isTaxRate">
                        {{$t('message.ModalProductFormTabMain.tax_rate')}}
                    </th>
                    <th scope="col" style="width: 10%;"  v-if="this.hasConsignment">
                        {{$t('message.ProductIndex.batch')}}
                    </th>
                    <th scope="col" style="width: 10%;" v-if="this.hasStamp">
                        {{$t('message.common.stamp')}}
                    </th>

                    <th scope="col" style="width: 10%" v-if="entry.type == TYPE_RETURN">
                        {{$t('message.common.returnQuantity')}}
                    </th>
                    <th scope="col" style="width: 10%" v-else-if="entry.type == TYPE_INVOICE">
                        {{$t('message.common.shortQuantity')}}
                    </th>
                    <th scope="col" style="width: 10%" v-else>
                        {{$t('message.common.orderQuantity')}}
                    </th>
                    
                    <th scope="col" style="width: 2%" v-if="entry.type == TYPE_RETURN && entry.action == ACTION_RETURN_INVOICE"></th>

                    <th scope="col" style="width: 10%;" v-if="entry.type == TYPE_RETURN">
                        {{$t('message.ProductIndex.price')}}
                    </th>
                    <th scope="col" style="width: 10%;" v-else>
                        {{$t('message.DetailOrdersCard.prodPrice')}}
                    </th>

                    <th scope="col" style="width: 10%;">
                        {{$t('message.DetailOrdersCard.prodDiscount')}}
                    </th>

                    <th scope="col" style="width: 10%;" v-if="entry.type == TYPE_RETURN">
                        {{$t('message.ReturnPurchaseOrder.returnPrice')}}
                    </th>
                    <th scope="col" style="width: 10%;" v-else>
                        {{$t('message.DetailOrdersCard.prodPrice')}}
                    </th>

                    <th scope="col" style="width: 10%;">
                        {{$t('message.DetailOrdersCard.totalPrice')}}
                    </th>                    
                    <th scope="col" style="width: 4%;">
                        <div class="dropdown">
                            <button class="btn" type="button" id="dropdownMenuButton " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class='fas fa-plus-circle'></i> 
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">  
                                <div class="mx-2 my-2">
                                    <input type="checkbox" id="hsCode" :checked="this.isHsCode ? true : false" @click="showHsCode(this)"> 
                                    {{$t('message.common.displayHSCode')}}
                                </div>
                                <div class="mx-2 my-2">
                                    <input type="checkbox" id="taxRate" :checked="this.isTaxRate  ? true : false" @click="showTaxRate(this)"> 
                                    {{$t('message.common.displayTax')}}
                                </div>
                                <div class="mx-2 my-2" v-if="entry.type == TYPE_ORDER">
                                    <input type="checkbox" v-model="this.hasConsignment" :checked="this.hasConsignment ? true : false" @click="changeOptionConsignment()"> 
                                    {{$t('message.ProductIndex.batch')}}
                                </div>
                                <div class="mx-2" v-if="entry.type == TYPE_ORDER">
                                    <input type="checkbox" v-model="this.hasStamp" :checked="this.hasStamp ? true : false" @click="changeOptionStamp()"> 
                                    {{$t('message.common.stamp')}}
                                </div>                               
                            </div>
                        </div>
                    </th>
                    <th v-if="entry.type == TYPE_RETURN"></th>
                </tr>
                </thead>
                <tbody class="font-weight-bold">
                    
                    <template v-if="entry.products && entry.products.length">
                        <template v-for="(product, index) in entry.products" :key="product.code">
                            <!-- Hiển thị thông tin sản phẩm chính -->
                            <tr>
                                <td @click="removeProduct(index)" class="checkbox-table text-center">
                                    <i class="fas fa-trash-alt"></i>
                                </td>
                                <td class="text-center" v-text="index + 1"></td>
                                <td class="text-center">
                                    <div class="row m-0 d-flex">
                                        <div class="col-2 p-0">
                                            <i class="fa fa-chevron-down" v-if="product.combo_products && product.combo_products.length > 0 && !product.showCombo" @click.stop="showCombo(product)"></i>
                                            <i class="fa fa-chevron-up" v-if="product.combo_products && product.combo_products.length > 0 && product.showCombo" @click.stop="showCombo(product)"></i>
                                        </div>
                                        <div class="col-10 p-0">
                                            {{ product.code }}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    {{ product.name }}
                                    <span v-if="this.isUsePromotion && entry.promotionsProducts && entry.promotionsProducts.length && (checkPromotionActive(product.productUnitId, product.product_category, product.quantity))" 
                                        @click="showPromotionProduct(product.productUnitId, product.product_category, product.quantity)" class="btn-icon-gift"><i class="fa fa-gift"></i></span>
                                    <div class="position-relative">
                                        <input type="text" v-model.trim="product.description" class="format__input bg-transparent text-left" :placeholder="$t('message.OrderSupplierIndex.note')">
                                    </div>
                                </td>
                                <!-- Đơn vị - Sản phẩm chính -->
                                <td style="padding-top: 18px" class="text-center">                                    
                                    <InputSelect v-model="product.productUnitId"
                                        :placeholder="$t('message.DetailOrdersCard.chooseUnit')"
                                        :options="product.product_units"
                                        v-if="product.product_units && product.product_units.length && entry.type != TYPE_RETURN"
                                        option-label="unitName"
                                        :readonly="product.isUpdated"
                                        @update:modelValue="updateProductUnit(product)"
                                        :cardTransaction="true"
                                    ></InputSelect>

                                    <InputSelect v-model="product.productUnitId"
                                        :placeholder="$t('message.DetailOrdersCard.chooseUnit')"
                                        :options="product.product_units"
                                        v-else-if="product.product_units && product.product_units.length && entry.type === TYPE_RETURN"
                                        option-label="unitName"
                                        :readonly="product.isUpdated"
                                        @update:modelValue="updateProductUnit(product)"
                                        :cardTransaction="true"
                                    ></InputSelect>
                                    <!-- <span v-else>{{ product.unitText }}</span> -->
                                </td>
                                <!-- Thuộc tính -->
                                <td class="pt-3 block__quantity" >
                                    
                                    <template v-if="entry.isLink">                                                                                
                                        <span v-text="product.productUnit && product.variant ? product.variant.name : null"></span>
                                    </template>
                                    <template v-else>     
                                        <!-- :options="product.attributesActive" -->                                        
                                        <InputSelect v-model="product.variantId"
                                        :placeholder="$t('message.DetailOrdersCard.chooseAttribute')"
                                        :options="product.attributesActive"
                                        v-if="product?.attributesActive && product.attributesActive.length"
                                        option-label="name"
                                        :readonly="product.isUpdated"                                        
                                        @update:modelValue="updateAttributes(product, product.variantId)" 
                                        :cardTransaction="true">
                                        </InputSelect>                                                                             
                                        <!-- v-if="product.variant && product.variant.length" -->
                                    </template>                                    
                                </td>                                                        
                                <td class="pl-4" v-if="this.isHsCode">{{product.hsCode}}</td>
                                <td class="pl-4" v-if="this.isTaxRate">{{product.taxRate? product.taxRate + '%' : ''}}</td>
                                <td class="pl-4" style="padding-top: 18px" v-if="this.hasConsignment && entry.type == TYPE_ORDER">
                                    <input type="text" v-model.trim="product.consignment" place="Nhập mô tả" class="format__input bg-transparent text-left">
                                </td>
                                <td class="pl-4" style="padding-top: 18px" v-if="this.hasStamp && entry.type == TYPE_ORDER">
                                    <InputSelect v-model="product.stamp"
                                        :options="this.$constant.listOptionStamps"
                                        :cardTransaction="true"
                                    ></InputSelect>
                                </td>
                                <!-- Số lượng đặt - Sản phẩm chính-->
                                <td class="position-relative">
                                    <div class="d-flex justify-content-center">
                                        <div class="position-relative" data-toggle="dropdown" id="dropdownMenuButtonDepartment" aria-haspopup="true" aria-expanded="false" >                                            
                                            <InputNumberFormat :pxNone="true" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true"                                                
                                            :readonly="((entry.type == TYPE_RETURN && (entry.valueCoupon || entry.valueVoucher || entry.promotionId)) || ((entry.type == TYPE_INVOICE || (entry.type == TYPE_RETURN && product.isConsignment) || (entry.type == TYPE_ORDER && entry.action == $constant.actionUpdateOrder)) && product.isConsignment))"             
                                            v-model="product.quantity"
                                            v-if="product.quantityRemaining" :min="1"
                                            >
                                            </InputNumberFormat>                                                                                       
                                            <InputNumberFormat :pxNone="true" v-else
                                            :readonly="((entry.type == TYPE_RETURN && (entry.valueCoupon || entry.valueVoucher || entry.promotionId)) || ((entry.type == TYPE_INVOICE || (entry.type == TYPE_RETURN && product.isConsignment) || (entry.type == TYPE_ORDER && entry.action == $constant.actionUpdateOrder)) && product.isConsignment))"             
                                            class="sale-input-number mb-0"  v-model="product.quantity"
                                            :class="product.inventories < product.quantity ? 'danger_fail' : ''" :cardTransaction="true" :bgTransparent="true" 
                                            ></InputNumberFormat>
                                            <AppPermission :permission="this.$permissions.invoices.getInventory?? '' ">
                                                <div class="dropdown-menu dropdownSupplier p-2" aria-labelledby="dropdownMenuButtonDepartment">
                                                    <div>{{ branchName }}</div>
                                                    <div class="font-weight-normal">
                                                        {{$t('message.ProductUnitSearch.inventory')}} :
                                                        <template v-for="(item, index) in product.product_branch_meta" :key="index">
                                                            <template v-if="index < product.product_branch_meta.length">
                                                                {{  item.inventories + ' ' +  item.product_unit?.unitName + ', '}}
                                                            </template>
                                                            <template v-else>
                                                                {{  (item.inventories > 0) ? item.inventories : '__'  + ' ' +  item.product_unit?.unitName }}
                                                            </template>
                                                        </template>
                                                    </div>
                                                    <div class="font-weight-normal">{{$t('message.ProductUnitSearch.orderSupplier')}}: {{ (product.order_quantity && product.order_quantity.length) ? (product.order_quantity[0]?.totalQuantity + ' ' + product.order_quantity[0].unit.unitName) : 0 }}</div>
                                                    <div class="font-weight-normal">
                                                        {{$t('message.ProductUnitSearch.orderFromCustomer')}}:
                                                        <template v-for="(item, index) in product.product_branch_meta" :key="index">
                                                            <template v-if="index < product.product_branch_meta.length">
                                                                {{ item.quantityOrder + ' ' +  item.product_unit?.unitName + ', '}}
                                                            </template>
                                                            <template v-else>
                                                                {{ item.quantityOrder + ' ' +  item.product_unit?.unitName}}
                                                            </template>
                                                        </template>
                                                    </div>
                                                </div>  
                                            </AppPermission>
                                        </div>                            
                                    </div>
                                    <div class="text-center quantity-remaining" v-if="entry.action == ACTION_ORDER_PROCESSING">
                                        <span> / </span>
                                        <span v-text="product.quantityRemaining"></span>
                                    </div>
                                </td>
                                <td v-if="entry.type == TYPE_RETURN && entry.action == ACTION_RETURN_INVOICE" class="p-0 text-gray">
                                    <!-- <div class="text-left">
                                        <span>{{  '/' + product.quantityRemaining }}</span>
                                    </div> -->
                                </td>
                                <!-- Đơn giá -->
                                <td class="text-center popover-wrap">  
                                    <InputNumberFormat :pxNone="true" v-model="product.price" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true"
                                    :readonly="entry.type == TYPE_RETURN && entry.action == ACTION_RETURN_INVOICE"></InputNumberFormat>                                    
                                </td>
                                <!-- Giảm giá sản phẩm   -->                                                                 
                                <td class="text-center position-relative" style="padding-top: 25px">                                                                                                       
                                    <ProductDiscount :modelValue="product" :price="product.price" @update:modelValue="updateDiscount" 
                                    :readonly="entry.type == TYPE_INVOICE && (entry.action == ACTION_UPDATE_INVOICE)"
                                    />                                    
                                </td>                                
                                <td class="text-center">
                                    {{ $filters.formatPrice(product.amount) }}
                                </td>
                                <td class="text-center">
                                    {{ $filters.formatPrice(product.amount * product.quantity) }}
                                </td>
                                <td class="text-center">
                                    <div class="d-flex align-items-center">
                                        <i class="fas fa-plus mr-2" @click="cloneProduct(product.id)" v-if="entry.type != TYPE_RETURN"></i>
                                        <i class="fa fa-ellipsis-v" @click="showDetailsProduct(product)" v-if="entry.type != TYPE_RETURN"></i>
                                    </div>
                                </td>

                                <td v-if="entry.type == TYPE_RETURN">
                                    <i class="fa fa-plus" @click="addProductPlusUnitReturn(index, product)"></i>
                                </td>
                            </tr>
                            <!-- Show hàng hóa combo -->
                            <template v-if="product.combo_products && product.combo_products.length > 0 && product.showCombo" >
                                <template v-for="(item,indexCombo) in product.combo_products" :key="indexCombo" >
                                    <tr style="padding: 50px;">
                                        <td></td>                                        
                                        <td class="text-center"></td>
                                        <td class="text-center p-2 pl-3">
                                            <div class="row m-0 d-flex">
                                                <div class="col-2 p-0">
                                                    <span v-text="(indexCombo + 1) + '. '"></span>
                                                </div>
                                                <div class="col-10 p-0">
                                                    {{ item.product.code }}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {{ item.product.name }}
                                        </td>

                                        <td class="text-left">
                                            <span>{{ item.product_unit ? item.product_unit.unitName : null }}</span>
                                        </td>                                        
                                        <td v-if="this.isHsCode"></td>
                                        <td v-if="this.isTaxRate"></td>  
                                        <td class="pl-4" v-if="this.hasConsignment && entry.type == TYPE_ORDER">
                                        </td>
                                        <td class="pl-4" v-if="this.hasStamp && entry.type == TYPE_ORDER">
                                        </td>                                   
                                        <td class="text-right colQuantity" :class="this.isTaxRate ? 'colQuantityTax' : '' ">{{ item.quantity }}</td>
                                        <td :colspan="entry.type != TYPE_RETURN ? 5 : 7"></td>                                        
                                   </tr>
                                    <template v-if="item.product.consignmentActive && item.product.consignmentActive.length && (entry.type != TYPE_ORDER || entry.type == TYPE_ORDER && entry.action == $constant.actionUpdateOrder)">
                                            <tr v-for="(consignment, key) in item.product.consignmentActive" :key="key">
                                                <td>
                                                </td>
                                                <td class="checkbox-table text-center">
                                                    <i v-if="key > 0 && entry.type != TYPE_RETURN" @click="key != 0 ? removeConsignmentProductInCombo(index, indexCombo, key): null" class="fas fa-trash-alt"></i>
                                                </td>
                                                <td colspan="3" :id="'consignmentComboProduct_' + key">
                                                    <ChooseConsignment :indexComboProducts="key" :isReturn="(entry.type == TYPE_RETURN && item.product.isConsignment)" :hideOption="(entry.type == TYPE_RETURN && item.product.isConsignment)" />
                                                    <!-- :readonly="entry.type == TYPE_RETURN && item.product.isConsignment"  :object="item.product" @update:value="updateConsignmentComboProducts" :isSaleScreen="true" :defaultValue="consignment" :index="index" :plusIndex="indexCombo" :isUpdate="entry.action == ACTION_UPDATE_INVOICE" -->
                                                </td>
                                               <td v-if="this.isHsCode"></td>
                                                <td v-if="this.isTaxRate"></td>
                                                <td class="position-relative" :id="'consignmentComboProduct_quantity_' + key">
                                                    <div class="d-flex justify-content-center">
                                                        <div v-if="entry.type == TYPE_RETURN" class="d-flex align-items-center">
                                                            <InputNumberFormat :pxNone="true" :readonly="(entry.type == TYPE_RETURN && (entry.valueCoupon || entry.valueVoucher || entry.promotionId)) && item.product.isConsignment" v-model="consignment.quantity" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true"></InputNumberFormat>
                                                            <!-- <div class="text-left">
                                                                <span>{{  '/' + consignment.maxQuantity }}</span>
                                                            </div> -->
                                                        </div>
                                                        <div v-else >
                                                            <InputNumberFormat :pxNone="true" :readonly="readonlyCombo" v-model="consignment.quantity" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true"></InputNumberFormat>
                                                        </div>
                                                    </div>
                                                </td>                                               
                                                <td :colspan="entry.type != TYPE_RETURN ? 5 : 7">
                                                    <div class="flex-end" v-if="key == 0 && entry.type != TYPE_RETURN">
                                                        <i class="fas fa-plus mr-2" @click="addConsignmentCombo(index, indexCombo, null)"></i>
                                                    </div>
                                                </td>
                                                
                                            </tr>
                                    </template> 
                                </template >
                            </template>

                            <template v-if="product.consignmentActive && product.consignmentActive.length &&  (entry.type != TYPE_ORDER || entry.type == TYPE_ORDER && entry.action == $constant.actionUpdateOrder)">
                                <tr v-for="(consignment, key) in product.consignmentActive" :key="key">
                                    <td></td>
                                    <td></td>
                                    <td class="checkbox-table text-center">
                                        <i v-if="key > 0" @click="key != 0 ? removeConsignment(index, key): null" class="fas fa-trash-alt"></i>
                                    </td>
                                    <td colspan="3" :id="'consignment_' + key">
                                        <!-- <ChooseConsignment
                                        :isReturn="(entry.type == TYPE_RETURN && product.isConsignment)" 
                                        :hideOption="(entry.type == TYPE_RETURN && product.isConsignment)" 
                                        :readonly="entry.type == TYPE_RETURN && product.isConsignment"  
                                        :object="product" @update:value="updateConsignment"
                                        :isSaleScreen="true"
                                        :defaultValue="consignment"
                                        :index="key"/> -->
                                        <ChooseConsignment
                                        :isReturn="(entry.type == TYPE_RETURN && product.isConsignment)" 
                                        :object="product" @update:value="updateConsignment"
                                        :isSaleScreen="true"
                                        :defaultValue="consignment"
                                        :index="key"/>
                                    </td>
                                    <td class="position-relative" :id="'consignment_quantity_' + key">
                                        <div class="d-flex justify-content-center">
                                            <InputNumberFormat :pxNone="true" :readonly="(entry.type == TYPE_RETURN && (entry.valueCoupon || entry.valueVoucher || entry.promotionId)) && product.isConsignment" v-model="consignment.quantity" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true"></InputNumberFormat>
                                        </div>
                                    </td>
                                    <td :colspan="entry.type != TYPE_RETURN ? 6 : 7">
                                        <div class="flex-end" v-if="key == 0">
                                            <i class="fas fa-plus mr-2" @click="addConsignment(index)"></i>                                         
                                        </div>
                                    </td>
                                </tr>
                            </template>

                            <!-- Mua thêm sản phẩm-->
                            <template v-if="product.plusOrder && product.plusOrder.length">
                                <template v-for="(productPlus, key) in product.plusOrder" :key="productPlus.key">
                                    <tr>
                                        <td colspan="2"></td>
                                        <td class="text-center">
                                            <div class="row m-0 d-flex">
                                                <div class="col-2 p-0">
                                                    <i class="fa fa-chevron-down" v-if="productPlus.combo_products && productPlus.combo_products.length > 0 && !productPlus.showCombo" @click.stop="showCombo(productPlus)"></i>
                                                    <i class="fa fa-chevron-up" v-if="productPlus.combo_products && productPlus.combo_products.length > 0 && productPlus.showCombo" @click.stop="showCombo(productPlus)"></i>
                                                </div>
                                                <div class="col-10 p-0">
                                                </div>
                                            </div>
                                        </td>
                                        <td></td>
                                        <td style="padding-top: 18px" class="text-center">
                                            <InputSelect v-model="productPlus.productUnitId"
                                                :placeholder="$t('message.DetailOrdersCard.chooseUnit')"
                                                :options="product.product_units"
                                                v-if="product.product_units && product.product_units.length"
                                                option-label="unitName"
                                                :readonly="product.isUpdated"
                                                @update:modelValue="updateProductUnit(productPlus)"
                                                :cardTransaction="true">
                                            </InputSelect>
                                            <span v-else>
                                                {{ productPlus.productUnitText }}
                                            </span>
                                        </td>
                                        <td class="pt-3 block__quantity">
                                            <template v-if="entry.isLink">
                                                    <span v-text="product.productUnit && product.variant ? product.variant.name : null"></span>
                                            </template>
                                            <template v-else>                                                
                                                <InputSelect v-model="productPlus.variantId"
                                                :placeholder="$t('message.DetailOrdersCard.chooseAttribute')"
                                                :options="productPlus.attributesActive"
                                                v-if="productPlus.attributesActive && productPlus.attributesActive.length"
                                                option-label="name"
                                                :readonly="productPlus.isUpdated"    
                                                @update:modelValue="updateAttributes(productPlus, productPlus.variantId)"                                             
                                                :cardTransaction="true">
                                                </InputSelect>
                                            </template>
                                        </td> 
                                        <td class="pl-4" v-if="this.isHsCode">{{product.hsCode}}</td>
                                        <td class="pl-4" v-if="this.isTaxRate">{{product.taxRate}}%</td>
                                        <td class="pl-4" v-if="this.hasConsignment && entry.type == TYPE_ORDER">
                                        </td>
                                        <td class="pl-4" v-if="this.hasStamp && entry.type == TYPE_ORDER">
                                        </td>
                                        <td class="position-relative">
                                            <div class="d-flex justify-content-center">
                                                <InputNumberFormat :pxNone="true" v-model="productPlus.quantity" :readonly="product.isConsignment && (entry.type == TYPE_INVOICE || (entry.type == TYPE_RETURN && product.isConsignment) || (entry.type == TYPE_ORDER && entry.action == $constant.actionUpdateOrder))" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true" v-if="productPlus.quantityRemaining" :max="productPlus.quantityRemaining"></InputNumberFormat>
                                                <InputNumberFormat :pxNone="true" v-model="productPlus.quantity" :readonly="product.isConsignment && (entry.type == TYPE_INVOICE || (entry.type == TYPE_RETURN && product.isConsignment) || (entry.type == TYPE_ORDER && entry.action == $constant.actionUpdateOrder))" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true" v-else></InputNumberFormat>
                                            </div>
    
                                            <div class="text-center quantity-remaining" v-if="entry.action == ACTION_ORDER_PROCESSING">
                                                <span> / </span>
                                                <span v-text="productPlus.quantityRemaining"></span>
                                            </div>
                                        </td>
                                        <td v-if="entry.type == TYPE_RETURN && entry.action == ACTION_RETURN_INVOICE" class="p-0 text-gray">
                                        </td>
    
                                        <td class="text-center popover-wrap">
                                            <InputNumberFormat :pxNone="true" v-model="productPlus.price" :cardTransaction="true" :bgTransparent="true"
                                            class="sale-input-number mb-0" 
                                            :readonly="entry.type == TYPE_RETURN && entry.action == ACTION_RETURN_INVOICE ? true : false"
                                            ></InputNumberFormat>
                                        </td>
    
                                        <td class="text-center position-relative" style="padding-top: 25px">
                                            <ProductDiscount :modelValue="productPlus" :price="productPlus.price" @update:modelValue="updateDiscount"
                                            :readonly="entry.type == TYPE_INVOICE &&entry.action == ACTION_UPDATE_INVOICE"
                                            />
                                        </td>
                                        <td class="text-center">
                                            {{ $filters.formatPrice(productPlus.amount) }}
                                        </td>
                                        <td class="text-center">
                                            {{ $filters.formatPrice(productPlus.amount * productPlus.quantity) }}
                                        </td>
                                        <td v-if="entry.type == TYPE_RETURN"></td>  
                                        <td>
                                            <i class="fas fa-trash-alt" @click="removeProductOrderPlus(product.id, key)"></i>
                                        </td>
                                    </tr>
                                   
                                    <template v-if="productPlus.combo_products && productPlus.combo_products.length > 0 && productPlus.showCombo" >
                                        <template v-for="(itemCombo, indexCombo) in productPlus.combo_products" :key="indexCombo" >
                                            <tr style="padding: 50px;">
                                                    <td></td>
                                                <td class="text-center"></td>
                                                <td class="text-center">
                                                    <div class="row m-0 d-flex">
                                                        <div class="col-2 p-0">
                                                            <span v-text="(indexCombo + 1) + '. '"></span>
                                                        </div>
                                                        <div class="col-10 p-0">
                                                            {{ itemCombo.product.code }}
                                                        </div>
                                                    </div>
                                                </td>

                                                <td>
                                                    {{ itemCombo.product.name }}
                                                </td>

                                                <td style="padding-top: 18px" class="text-center">
                                                    <span>{{ itemCombo.product_unit.unitName }}</span>
                                                </td>    
                                                <td v-if="this.isHsCode"></td>
                                                <td v-if="this.isTaxRate"></td>                                                     
                                                <td class="text-right colQuantity" :class="this.isTaxRate ? 'colQuantityTax' : '' ">
                                                    {{ itemCombo.quantity }}
                                                </td>
                                                <td colspan="5"></td>
                                            </tr>

                                            <template v-if="itemCombo.product.consignmentActive && itemCombo.product.consignmentActive.length && (entry.type == TYPE_INVOICE || entry.type == TYPE_RETURN || (entry.type == TYPE_ORDER && entry.action == $constant.actionUpdateOrder))">
                                                <tr v-for="(consignmentPlus, ind) in itemCombo.product.consignmentActive" :key="ind">
                                                    <td></td>
                                                    <td class="checkbox-table text-center">
                                                        <div>
                                                            <i v-if="ind > 0 && entry.type != TYPE_RETURN" @click="removeConsignmentProductInComboPlus(index, key, indexCombo, ind)" class="fas fa-trash-alt"></i>
                                                        </div>
                                                        <!-- <div>
                                                            {{ itemCombo.product.code }}
                                                        </div> -->
                                                    </td>
                                                    <td colspan="3" :id="'consignmentPlus_' + key">
                                                        <ChooseConsignment :hideOption="(entry.type == TYPE_RETURN)" :object="itemCombo.product" @update:value="updateConsignmentProductPlusProduct" :isSaleScreen="true" :defaultValue="consignmentPlus" :index="index" :plusIndex="key" :indexComboProducts="indexCombo" :indexConsigmentActive="ind"/>
                                                    </td>
                                                    <td v-if="this.isHsCode"></td>
                                                    <td v-if="this.isTaxRate"></td>       
                                                    <td class="position-relative" :id="'consignmentPlus_input_' + key">
                                                        <div class="d-flex justify-content-center">
                                                            <div v-if="entry.type == TYPE_RETURN" class="d-flex align-items-center">
                                                                <InputNumberFormat :pxNone="true" :max="consignmentPlus.maxQuantity" :readonly="(entry.type == TYPE_RETURN && (entry.valueCoupon || entry.valueVoucher || entry.promotionId))" v-model="consignmentPlus.quantity" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true"></InputNumberFormat>
                                                                <!-- <div class="text-left">
                                                                    <span>{{  '/' + consignmentPlus.maxQuantity }}</span>
                                                                </div> -->
                                                            </div>
                                                            <div v-else>
                                                                <InputNumberFormat :pxNone="true" :readonly="(entry.type == TYPE_RETURN && (entry.valueCoupon || entry.valueVoucher || entry.promotionId))" v-model="consignmentPlus.quantity" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true"></InputNumberFormat>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td colspan="5">
                                                        <div class="flex-end" v-if="ind == 0">
                                                            <i class="fas fa-plus mr-2" @click="addConsignmentCombo(index, indexCombo, key)"></i>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>
                                        </template >
                                    </template>

                                    <template v-if="productPlus.consignmentActive && productPlus.consignmentActive.length && (entry.type == TYPE_INVOICE || entry.type == TYPE_RETURN || (entry.type == TYPE_ORDER && entry.action == $constant.actionUpdateOrder))">
                                        <tr v-for="(consignmentPlus, ind) in productPlus.consignmentActive" :key="ind">
                                            <td></td>
                                            <td></td>
                                            <td class="checkbox-table text-center">
                                                <div>
                                                    <i v-if="ind > 0" @click="removeConsignmentProductPlus(index, key, ind)" class="fas fa-trash-alt"></i>
                                                </div>
                                            </td>
                                            <td colspan="3" :id="'consignmentPlus_' + key">
                                                <!-- <ChooseConsignment :hideOption="(entry.type == TYPE_RETURN)" :object="productPlus" @update:value="updateConsignmentProductPlus" :isSaleScreen="true" :defaultValue="consignmentPlus" :index="ind" :plusIndex="key"/> -->
                                                <ChooseConsignment :object="productPlus" @update:value="updateConsignmentProductPlus" :isSaleScreen="true" :defaultValue="consignmentPlus" :index="ind" :plusIndex="key"/>
                                            </td>
                                            <td class="position-relative" :id="'consignmentPlus_input_' + key">
                                                <div class="d-flex justify-content-center">
                                                    <InputNumberFormat :pxNone="true" :readonly="(entry.type == TYPE_RETURN && (entry.valueCoupon || entry.valueVoucher || entry.promotionId))" v-model="consignmentPlus.quantity" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true"></InputNumberFormat>
                                                </div>
                                            </td>
                                            <td colspan="7">
                                                <div class="flex-end" v-if="ind == 0">
                                                    <i class="fas fa-plus mr-2" @click="addConsignment(index, key)"></i>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </template>
                            </template>

                            <!-- Trả hàng break nhiều đơn vị -->
                            <template v-if="entry.type == TYPE_RETURN && product.plusReturn">
                                <!-- Chi tiết của hàng hóa khi đã được break -->
                                <template v-for="(plusReturnItem, plusReturnIndex) in product.plusReturn" :key="plusReturnIndex">
                                    <!-- Detail -->
                                    <tr>
                                        <td class="checkbox-table text-center"></td>
                                        <td class="text-center"></td>
                                        <td class="text-center"></td>
                                        <td></td>
                                        <td style="padding-top: 18px" class="text-center">
                                            <span>
                                                <InputSelect v-model="plusReturnItem.productUnitId"
                                                    :placeholder="$t('message.DetailOrdersCard.chooseUnit')"
                                                    :options="plusReturnItem.product_units"
                                                    v-if="plusReturnItem.product_units && plusReturnItem.product_units.length"
                                                    option-label="unitName"
                                                    :readonly="product.isUpdated"
                                                    @update:modelValue="updateUnitProductReturn(plusReturnItem)"
                                                    :cardTransaction="true">
                                                </InputSelect>
                                            </span>
                                        </td>  
                                        <!-- Bổ sung variant -->
                                        <td class="pt-3 block__quantity" >
                                            <template v-if="entry.isLink">
                                                <span v-text="product.productUnit && product.variant ? product.variant.name : null"></span>
                                            </template>
                                            <template v-else>
                                                <!-- <InputSelect v-model="product.variantId"
                                                :placeholder="$t('message.DetailOrdersCard.chooseAttribute')"
                                                :options="product.attributesActive"
                                                v-if="product.attributesActive && product.attributesActive.length"
                                                option-label="name"
                                                :readonly="product.isUpdated"
                                                @update:modelValue="updateProductUnit(product)"
                                                :cardTransaction="true">
                                                </InputSelect> -->

                                                <InputSelect v-model="plusReturnItem.variantId"
                                                    :placeholder="$t('message.DetailOrdersCard.chooseAttribute')"
                                                    :options="plusReturnItem.attributesActive"
                                                    v-if="plusReturnItem.attributesActive && plusReturnItem.attributesActive.length"
                                                    option-label="name"
                                                    :readonly="plusReturnItem.isUpdated"    
                                                    @update:modelValue="updateAttributes(plusReturnItem, plusReturnItem.variantId)"                                             
                                                    :cardTransaction="true">
                                                </InputSelect>
                                            </template>
                                        </td>
                                        <td class="pl-4" v-if="this.isHsCode">{{product.hsCode}}</td>
                                        <td class="pl-4" v-if="this.isTaxRate">{{product.taxRate? product.taxRate + '%' : ''}}</td>

                                        <td class="pl-4" style="padding-top: 18px" v-if="this.hasConsignment && entry.type == TYPE_ORDER">
                                            <input type="text" v-model.trim="product.consignment" place="Nhập mô tả" class="format__input bg-transparent text-left">
                                        </td>
                                        <td class="position-relative">
                                            <div class="d-flex justify-content-center">
                                                <div class="position-relative">
                                                    <InputNumberFormat :pxNone="true"
                                                        :readonly="(entry.type == TYPE_RETURN && (entry.valueCoupon || entry.valueVoucher || entry.promotionId)) || ((entry.type == TYPE_INVOICE || (entry.type == TYPE_RETURN && plusReturnItem.isConsignment) || (entry.type == TYPE_ORDER && entry.action == $constant.actionUpdateOrder)) && plusReturnItem.isConsignment)" 
                                                        v-model="plusReturnItem.quantity" 
                                                        class="sale-input-number mb-0" 
                                                        :cardTransaction="true" 
                                                        :bgTransparent="true" 
                                                        :min="1">
                                                    </InputNumberFormat>
                                                   
                                                </div>                            
                                            </div>
                                        </td>
                                        <td v-if="entry.type == TYPE_RETURN && entry.action == ACTION_RETURN_INVOICE" class="p-0 text-gray">
                                            <!-- <div class="text-left">
                                                <span>{{  '/' + plusReturnItem.quantityRemaining }}</span>
                                            </div> -->
                                        </td>
                                
                                        <td class="text-center popover-wrap">
                                            <InputNumberFormat :pxNone="true" 
                                                v-model="plusReturnItem.price" 
                                                class="sale-input-number mb-0" 
                                                :cardTransaction="true" 
                                                :bgTransparent="true" 
                                                :readonly="entry.type == TYPE_RETURN && entry.action == ACTION_RETURN_INVOICE">
                                            </InputNumberFormat>
                                        </td>
                                        <!-- <td> <div>{{ plusReturnItem  }}</div></td> -->
                                        <!-- // Giảm giá hóa đơn   -->
                                        <td class="text-center position-relative" style="padding-top: 25px">                                    
                                            <ProductDiscount :modelValue="plusReturnItem" :price="plusReturnItem.price" @update:modelValue="updateDiscount"/>
                                            <!-- :readonly="this.hasPermission(this.$permissions.invoices.updateDiscountInvoice) && entry.products && entry.products.length" -->
                                        </td>
                                        <td class="text-center">
                                            {{ $filters.formatPrice(plusReturnItem.amount) }}
                                        </td>
                                        <td class="text-center">
                                            {{ $filters.formatPrice(plusReturnItem.amount * plusReturnItem.quantity) }}
                                        </td>
                                        <td class="text-center">
                                            <div class="d-flex align-items-center">
                                                <i class="fas fa-plus mr-2" @click="cloneProduct(product.id)" v-if="entry.type != TYPE_RETURN"></i>
                                                <i class="fa fa-ellipsis-v" @click="showDetailsProduct(product)" v-if="entry.type != TYPE_RETURN"></i>
                                            </div>
                                        </td>

                                        <td v-if="entry.type == TYPE_RETURN">
                                            <i class="fas fa-trash-alt" @click="removeReturnProductPlus(index, plusReturnIndex)"></i>
                                        </td>
                                    </tr>
                                    <!-- Nếu có consignment -->
                                    <template v-if="plusReturnItem.consignmentActive && plusReturnItem.consignmentActive.length && (entry.type == TYPE_INVOICE || entry.type == TYPE_RETURN || (entry.type == TYPE_ORDER && entry.action == $constant.actionUpdateOrder))">
                                        <tr v-for="(consignmentPlusReturn, indReturn) in plusReturnItem.consignmentActive" :key="indReturn">
                                            <td></td>
                                            <td></td>
                                            <td class="checkbox-table text-center">
                                                <div>
                                                    <i v-if="indReturn > 0" @click="removeConsignmentProductPlusReturn(index, plusReturnIndex, indReturn)" class="fas fa-trash-alt"></i>
                                                </div>
                                            </td>
                                            <td colspan="3" :id="'consignmentPlusReturn_' + key">
                                                    <ChooseConsignment 
                                                    :object="plusReturnItem" 
                                                    @update:value="updateConsignmentProductReturn"
                                                    :isSaleScreen="true" 
                                                    :defaultValue="consignmentPlusReturn" 
                                                    :index="index" 
                                                    :indexConsigmentActive="indReturn"
                                                    :plusIndex="plusReturnIndex"/>
                                            </td>
                                            <td class="position-relative" :id="'consignmentPlusReturn_input_' + indReturn">
                                                <div class="d-flex justify-content-center">
                                                    <div v-if="entry.type == TYPE_RETURN" class="d-flex align-items-center">
                                                        <InputNumberFormat :pxNone="true" 
                                                            :readonly="(entry.type == TYPE_RETURN && (entry.valueCoupon || entry.valueVoucher || entry.promotionId))" 
                                                            v-model="consignmentPlusReturn.quantity"
                                                            class="sale-input-number mb-0"
                                                            :cardTransaction="true"
                                                            :bgTransparent="true">
                                                        </InputNumberFormat>
                                                        <div class="text-left">
                                                            <!-- <span>{{  '/' + consignmentPlusReturn.maxQuantity }}</span> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td colspan="7">
                                                <div class="flex-end" v-if="indReturn == 0">
                                                    <i class="fas fa-plus mr-2" @click="addConsignmentPlusReturn(index, plusReturnIndex)"></i>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>

                                </template>
                            </template>

                        </template>
                    </template>

                    <template v-if="this.isUsePromotion && ((entry.productGifts && entry.productGifts.length) || (entry.promotion && (entry.promotion.discountRatio || entry.promotion.discountValue)))">
                        <tr class="bg-gift">
                            <td class="checkbox-table text-center border-none">
                            </td>
                            <td colspan="10" class="border-none">
                                <h6 class="label_gift text-bold">
                                    {{$t('message.common.promotionInvoice')}}
                                </h6>
                            </td>
                        </tr>
                        <tr class="bg-gift" v-if="entry.productGifts && entry.productGifts.length">
                            <td class="checkbox-table text-center py-3 border-0">
                            </td>
                            <td class="text-center py-3 border-0"></td>
                            <td class="py-3 text-primary  border-top-dotted" :colspan="9">
                                {{ this.entry.promotion.name }}
                            </td>
                        </tr>
                        <template v-for="(product, productGiftIndex) in entry.productGifts" :key="product.id">
                            <tr class="bg-gift">
                                <td class="checkbox-table text-center py-3 border-0">
                                </td>
                                <td class="text-center py-3 border-0"></td>
                                <td class="text-center py-3 border-top-dotted">
                                    {{ product.productCode }}
                                </td>
                                <td class="py-3 border-top-dotted">
                                    {{ product.productName }}
                                </td>
                                <td class="text-center py-3 border-top-dotted">
                                    <span >{{ product.productUnitText }}</span>
                                </td>
                                <td class="text-center py-3 border-top-dotted">
                                    {{ n(product.quantity) + ' / ' + n(product.maxQuantity) }}
                                </td>
                                <td class="text-center py-3 border-top-dotted">
                                    {{ n(product.priceOrigin) }}
                                </td>
                                <td class="text-center py-3 border-top-dotted">
                                    0
                                </td>
                                <td class="text-center py-3 border-top-dotted">
                                    0
                                </td>
                                <td class="text-center py-3 border-top-dotted">
                                    0
                                </td>
                                <td class="text-center py-3 border-top-dotted">
                                </td>
                            </tr>
                            <template v-if="(entry.type == TYPE_ORDER && entry.action == $constant.actionUpdateOrder) || (entry.type != TYPE_ORDER && entry.action != $constant.actionUpdateOrder)">
                                <tr v-for="(consignment, k) in product.consignmentActive" :key="k" class="bg-gift">
                                    <td>
                                    </td>
                                    <td @click="k > 0 ? removeConsignmentProductGift(productGiftIndex, k) : null" class="checkbox-table text-center">
                                        <i class="fas fa-trash-alt" v-if="k > 0"></i>
                                    </td>
                                    <td colspan="3" :id="'consignmentGift_' + key">
                                        <ChooseConsignment :object="product" @update:value="updateConsignmentProductGift" :isSaleScreen="true" :defaultValue="consignment"/>
                                    </td>
                                    <td class="position-relative">
                                        <div class="d-flex justify-content-center">
                                            <InputNumberFormat :pxNone="true" v-model="consignment.quantity" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true" :max="consignment.maxQuantity ? consignment.maxQuantity : null" @change="setMaxQuantityProductGift(productGiftIndex, this.entry.productGifts[productGiftIndex].maxQuantity)"></InputNumberFormat>
                                        </div>
                                    </td>
                                    <td colspan="4">
                                    </td>
                                    <td>
                                        <div class="flex-end" v-if="k == 0">
                                            <i class="fas fa-plus mr-2" @click="addConsignment(null, null, productGiftIndex)"></i>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </template>

                    <template v-if="this.isUsePromotion && entry.productDiscount && entry.productDiscount.length">
                        <tr class="bg-gift">
                            <td class="checkbox-table text-center border-none">
                            </td>
                            <td colspan="10" class="border-none">
                                <h6 class="label_gift text-bold">
                                    {{$t('message.common.discountProduct')}}
                                </h6>
                            </td>
                        </tr>
                        <tr class="bg-gift">
                            <td class="checkbox-table text-center py-3 border-0">
                            </td>
                            <td class="text-center py-3 border-0"></td>
                            <td class="py-3 text-primary  border-top-dotted" :colspan="9">
                                {{ this.entry.promotion.name }}
                            </td>
                        </tr>
                        <template v-for="(product, productDiscountIndex) in entry.productDiscount" :key="productDiscountIndex">
                            <tr class="bg-gift">
                                <td class="checkbox-table text-center py-3 border-0">
                                </td>
                                <td class="text-center py-3 border-0"></td>
                                <td class="text-center py-3 border-top-dotted">
                                    {{ product.productCode }}
                                </td>
                                <td class="py-3 border-top-dotted">
                                    {{ product.productName }}
                                </td>
                                <td class="text-center py-3 border-top-dotted">
                                    <span >{{ product.productUnitText }}</span>
                                </td>
                                <td class="text-center py-3 border-top-dotted">
                                    {{ n(product.quantity) + ' / ' + n(product.maxQuantity) }}
                                </td>

                                <td class="text-center py-3 border-top-dotted">
                                    {{ n(product.priceOrigin) }}
                                </td>
                                <td class="text-center py-3 border-top-dotted text-danger">
                                    {{ n(product.discountValue) }} <span v-if="product.discountRatio">({{ product.discountRatio }}%)</span>
                                </td>
                                <td class="text-center py-3 border-top-dotted">
                                    {{ (product.priceOrigin - product.discountValue) > 0 ? n(product.priceOrigin - product.discountValue) : 0  }}
                                </td>
                                <td class="text-center py-3 border-top-dotted">
                                    {{ n(product.amount) }}
                                </td>
                                <td class="text-center py-3 border-top-dotted">
                                </td>
                            </tr>

                            <tr v-for="(consignmentDiscount, productDiscountInd) in product.consignmentActive" :key="productDiscountInd" class="bg-gift">
                                <td>
                                </td>
                                <td @click="productDiscountInd > 0 ? removeConsignmentProductDiscount(productDiscountIndex, productDiscountInd) : null" class="checkbox-table text-center">
                                    <i class="fas fa-trash-alt" v-if="productDiscountInd > 0"></i>
                                </td>
                                <td colspan="3" :id="'consignmentDiscount_' + key">
                                    <ChooseConsignment :object="product" @update:value="updateConsignmentProductDiscount" :isSaleScreen="true" :defaultValue="consignmentDiscount"/>
                                </td>
                                <td class="position-relative">
                                    <div class="d-flex justify-content-center">
                                        <InputNumberFormat :pxNone="true" v-model="consignmentDiscount.quantity" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true" :max="consignmentDiscount.maxQuantity ? consignmentDiscount.maxQuantity : null"
                                         @change="setMaxQuantityProductDiscount(productDiscountIndex, this.entry.productDiscount[productDiscountIndex].maxQuantity)"></InputNumberFormat>
                                    </div>
                                </td>
                                <td colspan="4">
                                </td>
                                <td>
                                    <div class="flex-end" v-if="productDiscountInd == 0">
                                        <i class="fas fa-plus mr-2" @click="addConsignment(null, null, null, productDiscountIndex)"></i>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </template>

                    <!-- Không có data -->
                    <template v-if="this.isUsePromotion && ((!entry.products || !entry.products.length) && (!entry.productGifts || !entry.productGifts.length) && (!entry.productDiscount || !entry.productDiscount.length))">
                        <tr>
                            <td :colspan="entry.type == TYPE_RETURN && entry.action == ACTION_RETURN_INVOICE ? 12 : 12" class="text-center p-4">
                                {{$t('message.common.noProduct')}}
                            </td>                                                        
                            <td v-if="this.isHsCode"></td>
                            <td v-if="this.isTaxRate"></td>
                            <td v-if="this.hasConsignment"></td>
                            <td v-if="this.hasStamp"></td>
                        </tr>
                    </template>
                </tbody>
            </table>

            <div class="row cart-footer-info" v-if="entry.saleMode == this.$constant.sale_mode_trans">
                <div class="cart-footer-left col-lg-6">
                    <InputTextArea :placeholder="$t('message.DetailOrdersCard.note')" v-model.trim="entry.description" class="input__textArea mb-0 pb-2"/>
                    <InputFileManager :smallSize="true" max="5" v-model="entry.image" :multiple="true" v-if="entry.type == TYPE_ORDER"/>
                </div>
                <div class="cart-footer-right col-lg-6">
                    <div class="row">
                        <div class="col-12">
                            <div class="d-flex justify-content-between">
                                <div class="mb-0 d-flex align-items-end">
                                    <label class="mb-0">
                                        {{$t('message.OrderIndex.total_origin')}}
                                    </label>
                                    <span class="total-quantity" v-text="entry.totalQuantity"></span>
                                    <span v-if="this.isUsePromotion && entry.promotionsInvoice && entry.promotionsInvoice.length && entry.products && entry.products.length" 
                                        class="total-quantity border-0 btn-icon-gift"
                                        @click="showPromotionListInvoice()">
                                        <i class="fa fa-gift"></i>
                                    </span>
                                </div>
                                <div class="text-bold" style="padding-right: .75rem">
                                    <div v-text="n(entry.totalPrice)"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-2">
                            <div class="d-flex justify-content-between align-items-center m-0">
                                <label>
                                    {{$t('message.DetailOrdersCard.prodDiscount')}}
                                </label>
                                <div style="width: 100px;">                                    
                                    <ProductDiscount :modelValue="entry" :price="entry.totalPrice" @update:modelValue="updateDiscount" />                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-2">
                            <div class="d-flex justify-content-between">
                                <div class="form-group">
                                    <label v-text="entry.type == TYPE_RETURN ? $t('message.ReturnIndex.returnCustomer') : $t('message.InvoiceIndex.total')"></label>
                                </div>
                                <div class="text-bold text-omi" style="padding-right: .75rem">
                                    <template v-if="+entry.totalPriceAfterDiscount > 0">
                                        <div v-text="n(entry.totalPriceAfterDiscount)"></div>
                                    </template>
                                    <template v-else>
                                        <div>0</div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4 col-xl-3 col-right" v-if="entry.saleMode !== this.$constant.sale_mode_trans">
            <div class="col-right__content">
                <div class="row overflow-a-part">
                    <div class="col-6 d-flex align-items-center mt-2 pl-4">
                        {{ user.name }}
                    </div>
                    <div class="col-6 d-flex align-items-center mt-2">
                        <div class="form-control" readonly>{{ entry.purchaseDate }}</div>
                    </div>
                    <div class="col-6 d-flex align-items-center mt-4">
                        <!-- Là Admin -->
                        <SelectSearch v-if="user.id == this.$constant.isAdmin || entry.action != this.$constant.actionUpdateInvoice" :placeholder="$t('message.common.businessMan')" 
                        :modelValue="entry.soldById" :defaultOption="branchAdmins" 
                        @update:modelValue="selectSoldBy" :label="'soldById1'" >
                        </SelectSearch>
                        <!-- Là User -->
                        <SelectSearch v-else :placeholder="$t('message.common.businessMan')" :modelValue="entry.soldById" :defaultOption="branchAdmins" @update:modelValue="selectSoldBy" :label="'soldById1'" :disabled="user.id != this.$constant.isAdmin || entry.type == TYPE_RETURN || !this.hasPermission(this.$permissions.invoices.updateSoldBy)  ? true : false"></SelectSearch>
                    </div>
                    <div class="col-6 d-flex align-items-center mt-4">
                        <SelectSearch :placeholder="$t('message.common.selectPriceBook')" :modelValue="entry.priceListId" :defaultOption="priceLists" @update:modelValue="selectPriceList" :label="'priceListId'" :disabled="entry.type == TYPE_RETURN ? true : false"></SelectSearch>
                    </div>
                    <!-- Input khách hàng -->
                    <div class="col-6 d-flex align-items-center mt-4">
                        <div class="form-group">
                            <div class="d-flex">
                                <CustomerSearch class="form-control" @selectCustomer="selectCustomer" :customerName="entry.customerName" @clearCustomer="clearCustomer" :readCustomer="(entry.type == TYPE_RETURN && entry.invoiceId) ? true : false"></CustomerSearch>
                            </div>

                        </div>
                    </div>
                    <div class="col-6 d-flex align-items-center mt-4">
                        <div class="form-group">
                            <div class="d-flex">
                                <SelectSearch :placeholder="$t('message.OrderIndex.filter.placeholder.saleChannel')" :modelValue="entry.channelId" :defaultOption="saleChannels" @update:modelValue="selectSaleChannel" :label="'channelId'" :disabled="(entry.type == TYPE_RETURN && entry.invoiceId) ? true : false"></SelectSearch>
                                <a href="#" type="button" class="m-auto" :title="$t('message.SaleChannelIndex.titleCreate')" @click="onCreateSaleChannel" v-if="entry.type != TYPE_RETURN">
                                    <i class="fas fa-plus" style="color: teal"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-1" v-if="entry.type == TYPE_RETURN">
                        <div class="d-flex justify-content-between">
                            <div class="form-group mb-0">
                                <span class="text-omi">
                                    <b>
                                        {{$t('message.InvoiceIndex.button.return')}}/ 
                                        <a :href="'/invoices/index?code=' + entry.invoiceCode" target="_blank" class="text-decoration-none">{{ entry.invoiceCode }}</a>
                                    </b>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2" v-if="entry.type == TYPE_RETURN">
                        <div class="d-flex justify-content-between">
                            <div class="form-group mb-0">
                                <label>
                                    {{$t('message.common.totalCostPrice')}}
                                </label>
                                <span class="total-quantity" v-text="entry.totalQuantity"></span>
                            </div>
                            <div class="" style="padding-right: .75rem">
                                <div v-text="n(entry.totalBasePrice)"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2">
                        <div class="d-flex justify-content-between">
                            <div class="form-group mb-0 d-flex align-items-end">
                                <label class="mb-0">
                                    {{$t('message.DetailOrdersCard.total')}}
                                </label>
                                <span class="total-quantity" v-text="entry.totalQuantity"></span>
                                <span v-if="this.isUsePromotion && entry.promotionsInvoice && entry.promotionsInvoice.length && entry.products && entry.products.length" 
                                    class="total-quantity border-0 btn-icon-gift"
                                    @click="showPromotionListInvoice()">
                                    <i class="fa fa-gift"></i>
                                </span>
                            </div>
                            <div class="" style="padding-right: .75rem">
                                <div v-text="n(entry.totalPrice)"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2">
                        <div class="form-group d-flex justify-content-between align-items-center m-0">
                            <!-- Bên ngoài -->
                            <label>
                                {{$t('message.DetailOrdersCard.prodDiscount')}}
                            </label> 
                            <div style="width: 100px;">   
                                <div class="border-bottom-1 form-discount text-right" @click.stop="showDiscountForm">{{ n(this.entry.discountValue ?? 0 ) }}</div>                                 
                                <!-- <ProductDiscount :modelValue="entry" :price="entry.totalPrice" @update:modelValue="updateDiscount" />                                 -->
                            </div>
                            <!-- :readonly="  (entry.type == TYPE_INVOICE && (entry.action == ACTION_UPDATE_INVOICE))" -->
                        </div>
                    </div>                                    
                    <div class="col-12 mt-2" v-if="this.isUsePromotion && (((entry.promotion && entry.promotion.discountValue) || (entry.type == TYPE_RETURN && entry.promotionValue)))">
                        <div class="row m-0">
                            <div class="col-4 p-0 form-group">
                                <label>
                                    {{$t('message.DetailOrdersCard.promotion')}}
                                </label>
                            </div>
                            <div class="col-4">
                                <span v-if="entry.promotion && entry.promotion.discountRatio" class="text-primary">({{ entry.promotion.discountRatio }}%) </span><span class="bg-text-promotion p-1 text-bold">
                                    {{$t('message.common.KM')}}
                                </span>
                            </div>
                            <div v-if="entry.type == TYPE_RETURN" class="col-4 text-right form-group" style="padding-right: 0.75rem">
                                {{ $filters.formatPrice(+entry.promotionValue) }}
                            </div>
                            <div v-else class="col-4 text-right form-group" style="padding-right: 0.75rem">
                                {{ $filters.formatPrice(+entry.promotion.discountValue) }}
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-0">
                        <div class="form-group d-flex justify-content-between align-items-center m-0">
                            <label v-text="entry.type == TYPE_RETURN ? $t('message.ReturnIndex.returnFee') : $t('message.OrderIndex.surcharge')"></label>
                            <div class="sale-input-number">
                                <InputNumberFormat v-model="entry.surchargeAmount"
                                    class="sale-input-number mb-0"
                                ></InputNumberFormat>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mt-3" v-if="isUseCoupon">
                        <div class="row m-0">
                            <div class="col-4 p-0 form-group">
                                <label>
                                    {{$t('message.CouponIndex.title')}}
                                </label>
                            </div>
                            <div class="col-4">                                
                                <input v-if="(!entry.isShowCodeActive && entry.type != TYPE_RETURN)" 
                                    type="text" v-model.trim="entry.couponCode" 
                                    v-on:keyup.enter="getCoupon()" 
                                    class="format__input bg-transparent text-left custom_format min-w-100" 
                                    :placeholder="$t('message.common.couponCode')"
                                >
                                <div v-if="entry.isShowCodeActive" class="cate_selected d-flex align-items-center px-2 my-1">
                                    <span v-if="entry.couponCodes">{{ this.entry.couponCodes }}</span>
                                    <span v-else>{{ this.entry.couponCode }}</span>
                                    <i v-if="entry.type != TYPE_RETURN" class="fa fa-times ml-2 text-danger" aria-hidden="true" @click="removeCoupon"></i>
                                </div>
                            </div>
                            <div class="col-4 text-right form-group" style="padding-right: 0.75rem" v-text="entry.valueCoupon > 0 ? n(entry.valueCoupon) : null"></div>
                        </div>
                    </div>

                    <div class="col-12 mt-2" v-if="isUseVoucher">
                        <div class="row m-0">
                            <div class="col-4 p-0 form-group">
                                <label>
                                    {{$t('message.VoucherIndex.title')}}
                                </label>
                            </div>
                            <div class="col-4" v-if="entry.type != TYPE_RETURN">
                                <input type="text" v-model.trim="entry.voucherCode" v-on:keyup.enter="getVoucher()" 
                                    class="format__input bg-transparent text-left custom_format min-w-100" 
                                    :placeholder="$t('message.common.voucherCode')"
                                >
                            </div>
                            <div class="col-4 text-right form-group" style="padding-right: 0.75rem">
                            </div>
                        </div>                        
                        <div v-if="entry.infoVouchers && entry.infoVouchers.length">
                            <div v-for="(voucher, index) in entry.infoVouchers" :key="index">
                                <div class="row m-0">
                                    <div class="col-4">
                                    </div>
                                    <div class="col-4">
                                        <div class="cate_selected d-flex align-items-center px-2 my-1">{{ voucher.code }}
                                            <i v-if="entry.type != TYPE_RETURN" class="fa fa-times ml-2 text-danger" aria-hidden="true" @click="removeVoucher(index, voucher.value, voucher.id)"></i>
                                        </div>
                                    </div>
                                    <div class="col-4 text-right form-group" style="padding-right: 0.75rem">
                                        <span v-if="voucher.coupon_voucher">{{ $filters.formatPrice(voucher.coupon_voucher.value) }}</span>
                                        <span v-else>{{ $filters.formatPrice(voucher.value) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mt-2" v-if="entry.paymentsInfo && entry.paymentsInfo.length">
                        <div class="row m-0">
                            <div class="col-8 py-2 px-0 form-group">
                                <label>
                                    {{$t('message.DetailOrdersCard.paymentForSupplier')}}
                                </label>
                            </div>
                            <div class="col-4 text-right form-group py-2 bg-light cursor-pointer" @click="showModalCustomerPaid" style="padding-right: 0.75rem">
                                <span v-text="n(entry.totalCustomerPaid)"></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2">
                        <div class="d-flex justify-content-between">
                            <div class="form-group">
                                <label v-text="entry.type == TYPE_RETURN ? $t('message.ReturnIndex.returnCustomer') : $t('message.InvoiceIndex.total')"></label>
                            </div>
                            <div class="form-group" style="padding-right: .75rem">
                                <template v-if="+entry.totalPriceAfterDiscount > 0">
                                    <div v-text="n(entry.totalPriceAfterDiscount)"></div>
                                </template>
                                <template v-else>
                                    <div>0</div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2" v-if="!entry.totalCustomerPaid || (0 < (entry.totalPriceAfterDiscount))">
                        <div class="d-flex justify-content-between">
                            <div class="form-group">
                                <label v-text="entry.type == TYPE_RETURN ? $t('message.common.returnCustomer') : $t('message.common.customerPays')"></label>
                            </div>
                            <div class="form-group" style="padding-right: .75rem">
                                <div>
                                    <span v-if="entry.customerPayments">{{ $filters.formatPrice(entry.customerPayments) }}</span>
                                    <span v-else>{{ $filters.formatPrice(entry.customerPayment) }}</span>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 d-flex" v-if="!entry.totalCustomerPaid || (0 < (entry.totalPriceAfterDiscount))">
                        <div class="col-4 pl-0 pr-0">
                            <label class="container-checkbox">{{$t('message.common.cash')}}
                                <input type="checkbox" :value="$constant.paymentChannelCashMoney" v-model="paymentChannel">
                                <span class="checkmark-checkbox"></span>
                            </label>
                        </div>
                        <div class="col-4 pl-0 pr-0">
                            <label class="container-checkbox">{{$t('message.common.card')}}
                                <input type="checkbox" :value="$constant.paymentChannelCardMoney" v-model="paymentChannel">
                                <span class="checkmark-checkbox"></span>
                            </label>
                        </div>
                        <div class="col-4 pl-0 pr-0">
                            <label class="container-checkbox">{{$t('message.common.transferPayment')}}
                                <input type="checkbox" :value="$constant.paymentChannelTransferMoney" v-model="paymentChannel">
                                <span class="checkmark-checkbox"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-12 d-flex" v-if="!entry.totalCustomerPaid || (0 < (entry.totalPriceAfterDiscount))">
                        <div class="col-4 pl-0 pr-0">
                            <div class="input__order">
                                <InputNumberFormat :cardTransaction="true" v-model="entry.cash" :readonly="!paymentChannel.includes($constant.paymentChannelCashMoney)" class="mb-0" 
                                @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)"/>
                            </div>
                        </div>
                        <div class="col-4 pl-1 pr-0">
                            <div class="input__order">
                                <InputNumberFormat :cardTransaction="true" v-model="entry.card" :readonly="!paymentChannel.includes($constant.paymentChannelCardMoney)"
                                 @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)" class="mb-0"/>
                            </div>
                        </div>
                        <div class="col-4 pl-1 pr-0">
                            <div class="input__order">
                                <InputNumberFormat :cardTransaction="true" v-model="entry.transfer" :readonly="!paymentChannel.includes($constant.paymentChannelTransferMoney)" 
                                @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)" class="mb-0"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <div class="d-flex justify-content-between">
                            <div class="form-group">
                                <label v-text="entry.type == TYPE_RETURN ? $t('message.common.ExcessMoneyPaidByCustomer') : $t('message.common.ExcessMoneyReturnedToCustomers')"></label>
                            </div>
                            <div class="form-group" style="padding-right: .75rem">
                                <div v-if="(+entry.customerPayment - +entry.totalPriceAfterDiscount > 0) && (+entry.totalPrice > +(entry.promotion ? entry.promotion.discountValue : 0 ))">
                                    {{ $filters.formatPrice(+entry.customerPayment - +entry.totalPriceAfterDiscount) }}
                                </div>
                                <div v-else-if="entry.totalCustomerPaid && +entry.totalCustomerPaid - +entry.totalPrice - +entry.discount > 0 && entry.action == ACTION_UPDATE_ORDER">
                                    {{n(entry.totalCustomerPaid - entry.totalPrice - +entry.discount)}}
                                </div>
                                <div v-else>
                                    0
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2" v-if="entry.customerId && entry.type != TYPE_RETURN">
                        <div class="d-flex justify-content-between">
                            <div class="form-group">
                                <label>
                                    {{$t('message.common.RemainingUnpaid')}}
                                </label>
                            </div>
                            <div class="form-group" style="padding-right: .75rem">
                                <div v-if="+entry.customerPayment - +entry.totalPriceAfterDiscount < 0">
                                    <!-- {{ $filters.formatPrice(+entry.totalPriceAfterDiscount - +entry.customerPayment) }}-->
                                       {{ $filters.formatPrice(+entry.totalPriceAfterDiscount - +this.entry.totalCustomerPaid - +this.entry.customerPayment) }}
                                </div>
                                <div v-else>
                                    0
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2" v-if="entry.customerId && entry.type != TYPE_RETURN">
                        <div class="d-flex justify-content-between align-items-end" v-if="(+entry.totalPriceAfterDiscount - +entry.customerPayment) > 0 && entry.debtTerm">
                            <div class="form-group">
                                <label>{{$t('message.customerIndex.debtDate')}}</label>
                            </div>
                            <div class="sale-input-number">
                                <InputNumberFormat v-model="entry.period" @input="calculateDebtTerm"
                                    class="sale-input-number mb-0"
                                ></InputNumberFormat>
                            </div>
                            <div>
                                {{$t('message.TransporterIndex.modal.modelSelectInvoicePayment.end_date')}} : {{ $filters.formatDate(entry.debtTerm) }}
                            </div>
                        </div>
                        <div class="row p-0 m-0" v-else-if="(+entry.totalPriceAfterDiscount - +entry.customerPayment) > 0 && entry.debtDate && !entry.debtTerm">
                            <div class="col-6 text-bold p-0 m-0">
                                <label>{{$t('message.customerIndex.debtDate')}}</label>
                            </div>
                            <div class="col-6 border-bottom" v-text="this.entry.debtDate"></div>
                        </div>
                    </div>
                    <div class="col-12 mt-2" v-if="entry.type == TYPE_ORDER">
                        <div class="d-flex justify-content-between">
                            <div class="form-group">
                                <label>
                                    {{$t('message.common.ExpectedDelivery')}}
                                </label>
                            </div>
                            <div class="form-group" style="padding-right: .75rem">
                                <div class="w-100 p-0 m-0 border-bottom">
                                    <input type="date" v-model="entry.ExpectedDateOfTransfer" class="w-100 border-none">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-12 mt-2" v-if="entry.type == TYPE_ORDER">
                        <div class="row p-0 m-0">
                            <div class="col-6 text-bold p-0 m-0">
                                <label>{{$t('message.customerIndex.debtDate')}}</label>
                            </div>
                            <div class="col-6 border-bottom" v-text="this.entry.debtDate"></div>
                        </div>
                    </div> -->
                    <div class="col-md-12 d-flex mt-2" v-if="entry.type == TYPE_ORDER || entry.type == TYPE_INVOICE || entry.action == ACTION_UPDATE_ORDER">
                        <label class="container-checkbox text-bold">{{$t('message.common.changeInforInvoice')}}
                            <input type="checkbox" :value="$constant.isChangeInformationInvoice" v-model="entry.isChangeInformationInvoice">
                            <span class="checkmark-checkbox"></span>
                        </label>
                    </div>
                    <div class="col-md-12 d-flex align-items-end mb-3" v-if="(entry.type == TYPE_ORDER || entry.type == TYPE_INVOICE || entry.action == ACTION_UPDATE_ORDER) && entry.isChangeInformationInvoice">
                        <div class="col-lg-4 pl-0 text-bold">{{$t('message.common.soldByName')}}:</div>
                        <div class="col-lg-8">
                            <input type="text" class="border-bottom-1 w-100" :placeholder="$t('message.common.enterSoldByName')" v-model="entry.soldByName">
                        </div>
                    </div>
                    <div class="col-md-12 d-flex align-items-end mb-3" v-if="(entry.type == TYPE_ORDER || entry.type == TYPE_INVOICE || entry.action == ACTION_UPDATE_ORDER) && entry.isChangeInformationInvoice">
                        <div class="col-lg-4 pl-0 text-bold">{{$t('message.modalCreateUpdateUnit.unit_name')}}:</div>
                        <div class="col-lg-8">
                            <input type="text" class="border-bottom-1 w-100" :placeholder="$t('message.common.enterUnitName')" v-model="entry.soldByCompany">
                        </div>
                    </div>
                    <div class="col-md-12 d-flex align-items-end mb-3" v-if="(entry.type == TYPE_ORDER || entry.type == TYPE_INVOICE || entry.action == ACTION_UPDATE_ORDER) && entry.isChangeInformationInvoice">
                        <div class="col-lg-4 pl-0 text-bold">{{$t('message.customerIndex.taxCode')}}:</div>
                        <div class="col-lg-8">
                            <input type="text" class="border-bottom-1 w-100" :placeholder="$t('message.customerIndex.modal.enterTaxCode')" v-model="entry.soldByTaxCode">
                        </div>
                    </div>
                    <div class="col-md-12 d-flex align-items-end" v-if="(entry.type == TYPE_ORDER || entry.type == TYPE_INVOICE || entry.action == ACTION_UPDATE_ORDER) && entry.isChangeInformationInvoice">
                        <div class="col-lg-4 pl-0 text-bold">{{$t('message.customerIndex.address')}}:<span class="text-danger">*</span></div>
                        <div class="col-lg-8">
                            <input type="text" class="border-bottom-1 w-100" :placeholder="$t('message.customerIndex.modal.enterAddress')" v-model="entry.soldByAddress">
                        </div>
                    </div>
                    <div class="col-12 mt-3" v-if="entry">
                        <InputTextArea :placeholder="$t('message.OrderSupplierIndex.note')" v-model.trim="entry.description" class="input__textArea mb-0"/>
                    </div>
                    <div class="col-12 mt-3">
                        <InputFileManager :smallSize="true" max="5" v-model="entry.image" :multiple="true" v-if="entry.type == TYPE_ORDER"/>
                    </div>
                </div>
            </div>
            <!-- Xử lý phiếu -->
            <!------------------------------------------------------------>
            <div class="col-right__save row justify-content-center">
                <div v-if="entry.type != TYPE_RETURN" class="col-3 text-center">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <button type="button" class="btn btn-save btn-secondary text-white" :disabled="isCallApi" @click="onPrintTicketCurrent()">
                                {{$t('message.button.print.name')}}
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="entry.action == ACTION_UPDATE_ORDER" class="col-9 text-center">
                    <div class="row justify-content-center">                                                
                        <AppPermission :permission="this.$permissions.invoices.create?? '' ">
                            <div v-if="entry.isCreateInvoice" class="col-12">
                                <button type="button" class="btn btn-save" :disabled="isCallApi" @click="onOrderProcessing">
                                    {{$t('message.button.invoiceAdd')}}
                                </button>                             
                            </div>
                        </AppPermission>
                        <AppPermission :permission="this.$permissions.orders.update?? '' ">   
                            <div v-if="entry.status != $constant.status_delivery_order && entry.isUpdateOrder" class="col-12">                                
                                    <button type="button" class="btn btn-save" :disabled="isCallApi" @click="save">
                                        {{$t('message.button.save')}}
                                    </button>                                
                            </div>
                        </AppPermission>
                    </div>
                </div>
                
                <div v-else-if="entry.action == ACTION_UPDATE_INVOICE" class="col-9 text-center">
                    <div class="row">
                        <div class="col-12">
                            <AppPermission :permission="this.$permissions.invoices.update?? '' ">
                                <button type="button" :disabled="isCallApi" class="btn btn-save" @click="save">
                                    {{$t('message.button.save')}}
                                </button>
                            </AppPermission>
                        </div>
                    </div>
                </div>
                <div v-else class="col-9 text-center">                    
                    <button type="button" :disabled="isCallApi" class="btn btn-save" @click="saveReturn" v-if="entry.type == TYPE_RETURN && (this.hasPermission(this.$permissions.returns.create) || user.id == 1)">
                        {{$t('message.InvoiceIndex.button.return')}}
                    </button>  
                    
                    <button type="button" :disabled="isCallApi" class="btn btn-save" @click="save" v-else-if="entry.type == TYPE_INVOICE && (this.hasPermission(this.$permissions.invoices.create) || user.id == 1)">
                        {{$t('message.TransporterIndex.button.payment')}}
                    </button>
                    
                    <button type="button" :disabled="isCallApi" class="btn btn-save" @click="save" v-else v-text="$t('message.OrderIndex.button.order')"></button>
                </div>
            </div>
            <!------------------------------------------------------------>
        </div>
        <!-- sale mode is transfer -->
        <div class="col-lg-6 col-right px-0 d-flex" :class="isExtend ? '' : 'col-4 col-xl-3'" v-if="entry.saleMode == this.$constant.sale_mode_trans">
            <div class="p-0 m-0 px-3 col-lg-6 position-relative" :class="isExtend ? '' : 'col-lg-12'">
                <div class="col-right__content">
                    <div class="row" :class="isExtend ? 'overflow-all' : 'overflow-a-part'">
                        <div class="col-6 d-flex align-items-center mt-2 pr-4">
                            {{ user.name }}
                        </div>
                        <div class="col-6 d-flex align-items-center mt-2">
                            <div class="form-control" readonly>{{ entry.purchaseDate }}</div>
                        </div>
                        <div class="col-6 d-flex align-items-center mt-4">
                            <!-- Là Admin -->
                            <SelectSearch v-if="user.id == this.$constant.isAdmin || entry.action != this.$constant.actionUpdateInvoice" :placeholder="$t('message.common.businessMan')" 
                            :modelValue="entry.soldById" :defaultOption="branchAdmins" 
                            @update:modelValue="selectSoldBy" :label="'soldById1'" >
                            </SelectSearch>
                            <!-- Là User -->
                            <SelectSearch v-else :placeholder="$t('message.common.businessMan')" :modelValue="entry.soldById" :defaultOption="branchAdmins" @update:modelValue="selectSoldBy" :label="'soldById1'" :disabled="user.id != this.$constant.isAdmin || entry.type == TYPE_RETURN || !this.hasPermission(this.$permissions.invoices.updateSoldBy)  ? true : false"></SelectSearch>
                        </div>
                        <div class="col-6 d-flex align-items-center mt-4">
                            <SelectSearch :placeholder="$t('message.common.selectPriceBook')" :modelValue="entry.priceListId" :defaultOption="priceLists" @update:modelValue="selectPriceList" :label="'priceListId'" :disabled="entry.type == TYPE_RETURN ? true : false"></SelectSearch>
                        </div>
                        <div class="col-6 d-flex align-items-center mt-4">
                            <div class="form-group">
                                <div class="d-flex">
                                    <CustomerSearch class="form-control" @selectCustomer="selectCustomer" :customerName="entry.customerName" @clearCustomer="clearCustomer" :readCustomer="entry.type == TYPE_RETURN ? true : false"></CustomerSearch>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 d-flex align-items-center mt-4">
                            <div class="form-group">
                                <div class="d-flex">
                                    <SelectSearch :placeholder="$t('message.OrderIndex.filter.placeholder.saleChannel')" :modelValue="entry.channelId" :defaultOption="saleChannels" @update:modelValue="selectSaleChannel" :label="'channelId'" :disabled="entry.type == TYPE_RETURN ? true : false"></SelectSearch>
                                    <a href="#" type="button" class="m-auto" :title="$t('message.SaleChannelIndex.titleCreate')" @click="onCreateSaleChannel" v-if="entry.type != TYPE_RETURN">
                                        <i class="fas fa-plus" style="color: teal"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2" v-if="!entry.totalCustomerPaid || (0 < (entry.totalPriceAfterDiscount))">
                            <div class="d-flex justify-content-between">
                                <div class="form-group">
                                    <label v-text="entry.type == TYPE_RETURN ? $t('message.common.returnCustomer') : $t('message.common.customerPays')"></label>
                                </div>
                                <div class="form-group" style="padding-right: .75rem">
                                    <div>
                                        {{ $filters.formatPrice(entry.customerPayment) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 d-flex" v-if="!entry.totalCustomerPaid || (0 < (entry.totalPriceAfterDiscount))">
                            <div class="col-4 pl-0 pr-0">
                                <div class="p-0 m-0 h-100 d-flex flex-wrap">
                                    <div class="m-0 p-0">
                                        <div class="col-lg-12 p-0 m-0">
                                            <label class="container-checkbox">{{$t('message.common.cash')}}
                                                <input type="checkbox" :value="$constant.paymentChannelCashMoney" v-model="paymentChannel">
                                                <span class="checkmark-checkbox"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="m-0 p-0 d-flex align-items-end">
                                        <div class="col-lg-12 p-0 pr-1 m-0">
                                            <div class="input__order">
                                                <InputNumberFormat :cardTransaction="true" v-model="entry.cash" :readonly="!paymentChannel.includes($constant.paymentChannelCashMoney)" class="mb-0" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)"/>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            <div class="col-4 pl-0 pr-0">
                                <div class="p-0 m-0 h-100 d-flex flex-wrap">
                                    <div class="col-lg-12 p-0 m-0">
                                        <label class="container-checkbox">{{$t('message.common.card')}}
                                            <input type="checkbox" :value="$constant.paymentChannelCardMoney" v-model="paymentChannel">
                                            <span class="checkmark-checkbox"></span>
                                        </label>
                                    </div>
                                    <div class="col-lg-12 p-0 pr-1 m-0 d-flex align-items-end">
                                            <div class="input__order">
                                                <InputNumberFormat :cardTransaction="true" v-model="entry.card" :readonly="!paymentChannel.includes($constant.paymentChannelCardMoney)" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)" class="mb-0"/>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4 pl-0 pr-0">
                                <div class="p-0 m-0 h-100 d-flex flex-wrap">
                                    <div class="col-lg-12 p-0 m-0">
                                        <label class="container-checkbox">{{$t('message.common.transferPayment')}}
                                            <input type="checkbox" :value="$constant.paymentChannelTransferMoney" v-model="paymentChannel">
                                            <span class="checkmark-checkbox"></span>
                                        </label>
                                    </div>
                                    <div class="col-lg-12 pr-1 p-0 m-0 d-flex align-items-end">
                                        <div class="input__order">
                                            <InputNumberFormat :cardTransaction="true" v-model="entry.transfer" :readonly="!paymentChannel.includes($constant.paymentChannelTransferMoney)" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)" class="mb-0"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-3 pl-0 pr-0">
                                <div class="p-0 m-0 h-100 d-flex flex-wrap">
                                    <div class="col-lg-12 p-0 m-0">
                                        <label class="container-checkbox">Voucher
                                            <input type="checkbox" :value="$constant.paymentChannelVoucher" v-model="paymentChannel">
                                            <span class="checkmark-checkbox"></span>
                                        </label>
                                    </div>
                                    <div class="col-lg-12 p-0 pr-1 m-0 d-flex align-items-end">
                                        <div class="input__order">
                                            <InputNumberFormat :cardTransaction="true" v-model="entry.voucher" :readonly="!paymentChannel.includes($constant.paymentChannelVoucher)" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)" class="mb-0"/>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class="col-12 mt-2">
                            <div class="row p-0 m-0 d-flex align-items-center">
                                <div class="col-lg-3 form-group px-0">
                                    <label>
                                        {{$t('message.common.collection')}}
                                    </label>
                                </div>
                                <div class="col-lg-4">
                                    <InputSwitchButton v-model="entry.usingPriceCod"/>
                                </div>
                                <div class="col-lg-5 p-0 text-right">
                                    <div class="form-group" style="padding-right: .75rem">
                                        <template v-if="+entry.totalPriceAfterDiscount > 0 && entry.usingPriceCod">
                                            <span >{{ n(entry.amountCod) }}</span>
                                        </template>
                                        <template v-else>
                                            <span>0</span>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-3" v-if=isUseCoupon>
                            <div class="row m-0">
                                <div class="col-4 p-0 form-group">
                                    <label>
                                        {{$t('message.CouponIndex.title')}}
                                    </label>
                                </div>
                                <div class="col-4">                                
                                    <input v-if="(!entry.isShowCodeActive && entry.type != TYPE_RETURN)" 
                                        type="text" v-model.trim="entry.couponCode" 
                                        v-on:keyup.enter="getCoupon()" 
                                        class="format__input bg-transparent text-left custom_format min-w-100" 
                                        :placeholder="$t('message.common.couponCode')"
                                    >
                                    <div v-if="entry.isShowCodeActive" class="cate_selected d-flex align-items-center px-2 my-1">
                                        <span v-if="entry.couponCodes">{{ this.entry.couponCodes }}</span>
                                        <span v-else>{{ this.entry.couponCode }}</span>
                                        <i v-if="entry.type != TYPE_RETURN" class="fa fa-times ml-2 text-danger" aria-hidden="true" @click="removeCoupon"></i>
                                    </div>
                                </div>
                                <div class="col-4 text-right form-group" style="padding-right: 0.75rem" v-text="entry.valueCoupon > 0 ? n(entry.valueCoupon) : null"></div>
                            </div>
                        </div>
                       
                        <div class="col-12 mt-2" v-if="isUseVoucher">
                            <div class="row m-0">
                                <div class="col-4 p-0 form-group">
                                    <label>
                                        {{$t('message.VoucherIndex.title')}}
                                    </label>
                                </div>
                                <div class="col-4" v-if="entry.type != TYPE_RETURN">
                                    <input type="text" v-model.trim="entry.voucherCode" v-on:keyup.enter="getVoucher()" 
                                        class="format__input bg-transparent text-left custom_format min-w-100" 
                                        :placeholder="$t('message.common.voucherCode')"
                                    >
                                </div>
                                <div class="col-4 text-right form-group" style="padding-right: 0.75rem">
                                </div>
                            </div>                        
                            <div v-if="entry.infoVouchers && entry.infoVouchers.length">
                                <div v-for="(voucher, index) in entry.infoVouchers" :key="index">
                                    <div class="row m-0">
                                        <div class="col-4">
                                        </div>
                                        <div class="col-4">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1">{{ voucher.code }}
                                                <i v-if="entry.type != TYPE_RETURN" class="fa fa-times ml-2 text-danger" aria-hidden="true" @click="removeVoucher(index, voucher.value, voucher.id)"></i>
                                            </div>
                                        </div>
                                        <div class="col-4 text-right form-group" style="padding-right: 0.75rem">
                                            <span v-if="voucher.coupon_voucher">{{ $filters.formatPrice(voucher.coupon_voucher.value) }}</span>
                                            <span v-else>{{ $filters.formatPrice(voucher.value) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="d-flex justify-content-between border-bottom-dotted">
                                <div class="form-group">
                                    <label v-text="entry.type == TYPE_RETURN ? $t('message.common.ExcessMoneyPaidByCustomer') : $t('message.common.ExcessMoneyReturnedToCustomers')"></label>
                                </div>
                                <div class="form-group" style="padding-right: .75rem">
                                    <div v-if="(+entry.customerPayment - +entry.totalPriceAfterDiscount > 0) && (+entry.totalPrice > +(entry.promotion ? entry.promotion.discountValue : 0 ))">
                                        {{ $filters.formatPrice(+entry.customerPayment - +entry.totalPriceAfterDiscount) }}
                                    </div>
                                    <div v-else-if="entry.totalCustomerPaid && +entry.totalCustomerPaid - +entry.totalPrice - +entry.discount > 0 && entry.action == ACTION_UPDATE_ORDER">
                                        {{n(entry.totalCustomerPaid - entry.totalPrice - +entry.discount)}}
                                    </div>
                                    <div v-else>
                                        0
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="row p-0 m-0">
                                <div class="col-lg-1 col-2 transport-address-from">
                                    <i class="fas fa-circle"></i>
                                </div>
                                <div class="col-lg-11 col-10 p-0">
                                    <div class="form-group mb-0">
                                        <!-- <input type="text" placeholder="Tên chương trình khuyến mại" class="form-control pl-0 input-custom" autocomplete="off"> -->
                                        <SelectPickupAddress @update:modelValue="getFromAddress" :placeholder="$t('message.InvoiceIndex.tabs.delivery.address_receive')" :defaultOption="this.pickupAddress" @reloadData="getDataPickupAddress" :defaultValue="this.entry.from_address_id"></SelectPickupAddress>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="row p-0 m-0">
                                <div class="col-lg-1 col-2 transport-address-to">
                                    <i class="fas fa-map-marker-alt"></i>
                                </div>

                                <div class="col-lg-11 col-10 p-0">
                                    <div class="form-group mb-0 position-relative">
                                        <input type="text" v-model="entry.to_name" :placeholder="$t('message.ModalCreateUpdateDelivery.name')" class="form-control pl-0 input-custom" autocomplete="off"
                                        :readonly="(entry.type == TYPE_INVOICE || entry.type == TYPE_ORDER) ? true : false">
                                        <i class="far fa-address-book" v-if="this.entry.to_name" @click="showInformationCustomer"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="row p-0 m-0">
                                <!-- <div class="col-lg-1"></div> -->
                                <div class="col-lg-12 p-0">
                                    <div class="form-group mb-0">
                                        <input type="text" v-model="entry.to_phone" :placeholder="$t('message.supplierIndex.phone')" class="form-control pl-0 input-custom" autocomplete="off"
                                        :readonly="(entry.type == TYPE_INVOICE || entry.type == TYPE_ORDER) ? true : false">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="row p-0 m-0">
                                <!-- <div class="col-lg-1"></div> -->
                                <div class="col-lg-12 p-0">
                                    <div class="form-group mb-0">
                                        <!-- <input type="text" :placeholder="$t('message.ModalCreateUpdatePickup.placeholder_address')" class="form-control pl-0 input-custom" autocomplete="off"> -->
                                        <SearchLocation @select="onSelectLocation" :addressValue="entry.customerAddressValue" 
                                        :readonly="(entry.type == TYPE_INVOICE || entry.type == TYPE_ORDER) ? true : false"></SearchLocation>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="row p-0 m-0">
                                <!-- <div class="col-lg-1"></div> -->
                                <div class="col-lg-12 p-0">
                                    <div class="form-group mb-0">
                                        <SelectSearch :defaultOption="provinces" :modelValue="entry.customerProvinceId" @update:modelValue="changeProvince"
                                        :disabled="(entry.type == TYPE_INVOICE || entry.type == TYPE_ORDER) ? true : false"></SelectSearch>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="row p-0 m-0">
                                <!-- <div class="col-lg-1"></div> -->
                                <div class="col-lg-12 p-0">
                                    <div class="form-group mb-0">
                                        <SelectSearch :defaultOption="districts" :modelValue="entry.customerDistrictId" @update:modelValue="changeDistrict"
                                        :disabled="(entry.type == TYPE_INVOICE || entry.type == TYPE_ORDER) ? true : false"></SelectSearch>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="row p-0 m-0">
                                <!-- <div class="col-lg-1"></div> -->
                                <div class="col-lg-12 p-0">
                                    <div class="form-group mb-0">
                                        <SelectSearch :defaultOption="wards" :modelValue="entry.customerWardId"
                                        :disabled="(entry.type == TYPE_INVOICE || entry.type == TYPE_ORDER) ? true : false"></SelectSearch>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="row p-0 m-0">
                                <!-- <div class="col-lg-1"></div> -->
                                <div class="col-lg-2 pl-0">
                                    <div class="form-group mb-0">
                                        <InputNumberFormat v-model="entry.weight" :cardTransaction="true" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)" class="mb-0"/>
                                    </div>
                                </div>
                                <div class="col-lg-2 px-0">
                                    <SelectSearch :defaultOption="weightUnits" :readonly="true" :label="'weightUnit'" :modelValue="entry.weightUnit" @update:modelValue="selectWeightUnits"></SelectSearch>
                                </div>
                                <div class="col-lg-2 pr-0">
                                    <div class="form-group mb-0">
                                        <InputNumberFormat v-model="entry.long" :cardTransaction="true" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)" class="mb-0"/>
                                    </div>
                                </div>
                                <div class="col-lg-2 pr-0">
                                    <div class="form-group mb-0">
                                        <InputNumberFormat v-model="entry.width" :cardTransaction="true" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)" class="mb-0"/>
                                    </div>
                                </div>
                                <div class="col-lg-2 pr-0">
                                    <div class="form-group mb-0">
                                        <InputNumberFormat v-model="entry.hight" :cardTransaction="true" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)" class="mb-0"/>
                                    </div>
                                </div>
                                <div class="col-lg-2 d-flex align-items-center">
                                    {{$t('message.common.cm')}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 d-flex mt-3" v-if="(entry.type == TYPE_ORDER || entry.type == TYPE_INVOICE || entry.action == ACTION_UPDATE_ORDER) && !isExtend">
                            <label class="container-checkbox text-bold">{{$t('message.common.changeInforInvoice')}}
                                <input type="checkbox" :value="$constant.isChangeInformationInvoice" v-model="entry.isChangeInformationInvoice">
                                <span class="checkmark-checkbox"></span>
                            </label>
                        </div>
                        <div class="col-md-12 d-flex align-items-end mb-3" v-if="(entry.type == TYPE_ORDER || entry.type == TYPE_INVOICE || entry.action == ACTION_UPDATE_ORDER) && !isExtend && entry.isChangeInformationInvoice">
                            <div class="col-lg-4 pl-0 text-bold">{{$t('message.common.soldByName')}}:</div>
                            <div class="col-lg-8">
                                <input type="text" class="border-bottom-1 w-100" :placeholder="$t('message.common.enterSoldByName')" v-model="entry.soldByName">
                            </div>
                        </div>
                        <div class="col-md-12 d-flex align-items-end mb-3" v-if="(entry.type == TYPE_ORDER || entry.type == TYPE_INVOICE || entry.action == ACTION_UPDATE_ORDER) && !isExtend && entry.isChangeInformationInvoice">
                            <div class="col-lg-4 pl-0 text-bold">{{$t('message.modalCreateUpdateUnit.unit_name')}}:</div>
                            <div class="col-lg-8">
                                <input type="text" class="border-bottom-1 w-100" :placeholder="$t('message.common.enterUnitName')" v-model="entry.soldByCompany">
                            </div>
                        </div>
                        <div class="col-md-12 d-flex align-items-end mb-3" v-if="(entry.type == TYPE_ORDER || entry.type == TYPE_INVOICE || entry.action == ACTION_UPDATE_ORDER) && !isExtend && entry.isChangeInformationInvoice">
                            <div class="col-lg-4 pl-0 text-bold">{{$t('message.customerIndex.taxCode')}}:</div>
                            <div class="col-lg-8">
                                <input type="text" class="border-bottom-1 w-100" :placeholder="$t('message.customerIndex.modal.enterTaxCode')" v-model="entry.soldByTaxCode">
                            </div>
                        </div>
                        <div class="col-md-12 d-flex align-items-end" v-if="(entry.type == TYPE_ORDER || entry.type == TYPE_INVOICE || entry.action == ACTION_UPDATE_ORDER) && !isExtend && entry.isChangeInformationInvoice">
                            <div class="col-lg-4 pl-0 text-bold">{{$t('message.customerIndex.address')}}:<span class="text-danger">*</span></div>
                            <div class="col-lg-8">
                                <input type="text" class="border-bottom-1 w-100" :placeholder="$t('message.customerIndex.modal.enterAddress')" v-model="entry.soldByAddress">
                            </div>
                        </div>
                        <div class="col-12 my-3">
                            <InputTextArea :placeholder="$t('message.common.noteForDriver')" v-model.trim="entry.shippingNote" class="input__textArea mb-0"/>
                        </div>
                    </div>
                </div>
                <div class="col-right__save row justify-content-center" :class="isExtend ? 'd-none' : ''">
                    <div v-if="entry.action == ACTION_UPDATE_ORDER" class="col-12 text-center">
                        <div class="row justify-content-center">                                                
                            <AppPermission :permission="this.$permissions.invoices.create?? '' ">
                                <div v-if="entry.isCreateInvoice" class="col-12">
                                    <button type="button" class="btn btn-save"  :disabled="isCallApi"  @click="onOrderProcessing">
                                        {{$t('message.button.invoiceAdd')}}
                                    </button>                             
                                </div>
                            </AppPermission>
                            <AppPermission :permission="this.$permissions.orders.update?? '' ">   
                                <div v-if="entry.status != $constant.status_delivery_order && entry.isUpdateOrder" class="col-12">                                
                                        <button type="button" :disabled="isCallApi" class="btn btn-save" @click="save">
                                            {{$t('message.button.save')}}
                                        </button>                                
                                </div>
                            </AppPermission>
                        </div>
                    </div>
                    
                    <div v-else-if="entry.action == ACTION_UPDATE_INVOICE" class="col-12 text-center">
                        <div class="row">
                            <div class="col-12">
                                <AppPermission :permission="this.$permissions.invoices.update?? '' ">
                                    <button type="button" :disabled="isCallApi" class="btn btn-save" @click="save">
                                        {{$t('message.button.save')}}
                                    </button>
                                </AppPermission>
                            </div>
                        </div>
                    </div>
                    <div v-else class="col-12 text-center">                    
                        <button type="button" class="btn btn-save" @click="saveReturn" v-if="entry.type == TYPE_RETURN && (this.hasPermission(this.$permissions.returns.create) || user.id == 1)">
                            {{$t('message.InvoiceIndex.button.return')}}
                        </button>  
                        
                        <button type="button" class="btn btn-save" @click="save" v-else-if="entry.type == TYPE_INVOICE && (this.hasPermission(this.$permissions.invoices.create) || user.id == 1)">
                            {{$t('message.TransporterIndex.button.payment')}}
                        </button>
                        
                        <button type="button" class="btn btn-save" @click="save" v-else v-text="$t('message.OrderIndex.button.order')"></button>
                    </div>
                </div>
                <i class="fa fa-chevron-right icon-extend cursor-pointer" @click="toggleExtendScreen" :class="isExtend ? '' : 'd-none'"></i>
                <i class="fa fa-chevron-left icon-un-extend cursor-pointer" @click="toggleExtendScreen" :class="isExtend ? 'd-none' : ''"></i>
            </div>
            <div class="p-0 m-0 pl-0 pr-3 col-lg-6" :class="isExtend ? '' : 'd-none'">
                <div class="col-right__content" :class="isExtend ? 'overflow-a-part' : 'overflow-all'">
                    <div class="row m-0 p-0">
                        <div class="col-6 d-flex align-items-center mt-2 pr-4">
                            <b>
                                {{$t('message.TransporterIndex.title')}}
                            </b>
                        </div>
                        <div class="col-6 d-flex align-items-center mt-2">
                            <div class="d-flex">
                                <TransportersSearch class="form-control" v-model="entry.transporterName"
                                @selectValue="selectTransporter" @createNewValue="createTransporter" @clearValue="clearTransporter" :readonly="false">
                                </TransportersSearch>
                            </div>
                        </div>
                        <div class="col-12 mt-2 d-flex cursor-pointer" v-if="this.entry.isTransporterIntegration">
                            <span class="shipping-mode">
                                <a class="d-flex align-items-center justify-content-center" :class="[(entry.shippingMode == this.$constant.shipping_mode_default ? 'active' : '')]" @click="toggleShippingMode(this.$constant.shipping_mode_default)">
                                    <span class="p-2">
                                        {{$t('message.common.normal')}}
                                    </span>
                                    <i class="fa fa-truck d-flex align-items-center justify-content-center pr-2"></i>
                                </a>
                            </span>
                            <span class="shipping-mode">
                                <a class="d-flex align-items-center justify-content-center" :class="[(entry.shippingMode == this.$constant.shipping_mode_fast ? 'active' : '')]" @click="toggleShippingMode(this.$constant.shipping_mode_fast)">
                                    <span class="p-2">
                                        {{$t('message.common.fast')}}
                                    </span>
                                    <i class="fa fa-plane d-flex align-items-center justify-content-center pr-2"></i>
                                </a>
                            </span>
                            <span class="shipping-mode">
                                <a class="d-flex align-items-center justify-content-center" :class="[(entry.shippingMode == this.$constant.shipping_mode_in_day ? 'active' : '')]" @click="toggleShippingMode(this.$constant.shipping_mode_in_day)">
                                    <span class="p-2">
                                        {{$t('message.common.duringTheDay')}}
                                    </span>
                                    <i class="fa fa-clock d-flex align-items-center justify-content-center pr-2"></i>
                                </a>
                            </span>
                        </div>
                        <div class="col-12 mt-2 cursor-pointer border px-0" v-if="this.entry.isTransporterIntegration">
                            <div class="p-2 bg-warning w-100" v-if="!this.entry.from_address">
                                <i class="fas fa-info-circle pr-2"></i>
                                <span>{{$t('message.orderFromTab.message_create_address')}}</span>
                            </div>
                            <div class="transpoter-options" v-if="this.isConnectTransporter">
                                <!-- Giao hàng nhanh -->
                                <template v-if="entry.shippingMode == this.$constant.shipping_mode_default && (this.statusOption.ghn)">
                                    <div v-if="!this.isLoginGHN">                                        
                                        <template v-for="(item, index) in this.deliveries" :key="index">
                                            <div class="row px-0 py-2 m-0 border  bg-light" @click="chooseSerivce(item)">                                                
                                                <div class="col-1 p-0">
                                                    <label class="container-radio ml-2 w-100">
                                                        <input type="radio" name="transporter" :checked="this.entry.serviceId == item._id ? true : false">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div class="col-3">
                                                    <img src="../../../public/img/GHN.png" class="w-100 bg-transparent">
                                                </div>
                                                <div class="col-6">
                                                    <div class="transpoter-name" v-text="item.infoService.name"></div>
                                                    <div class="transpoter-description" v-text="item.infoService.description"></div>
                                                </div>
                                                <div class="col-2 text-primary text-right p-0" v-text="n(item.total_price)"></div>
                                            </div>
                                        </template>
                                    </div>
                                    <div v-else>
                                        <div class="row px-0 py-2 m-0 border bg-light">
                                            <div class="col-3">
                                                <img src="../../../public/img/GHN.png" class="w-100">
                                            </div>
                                            <div class="col-9">
                                                <div class="transpoter-name">
                                                    <span class="text-danger text-bold">
                                                        {{$t('message.common.linkToGHN')}}
                                                    </span>
                                                    <span class="text-primary text-bold" @click="connectTransporter($constant.typeGHN)">
                                                        {{$t('message.common.loginNow')}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <!-- Ahamove -->
                                <template v-else-if="entry.shippingMode == this.$constant.shipping_mode_in_day && (this.statusOption.ahamove)">
                                    <div v-if="!this.isLoginAhamove">
                                        <template v-for="(item, index) in this.deliveries" :key="index">
                                            <div class="row px-0 py-2 m-0 border  bg-light" @click="chooseSerivce(item)">
                                                <div class="col-1 p-0">
                                                    <label class="container-radio ml-2 w-100">
                                                        <input type="radio" name="transporter" :checked="this.entry.serviceId == item._id ? true : false">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div class="col-3">
                                                    <img src="../../../public/img/ahamove.png" class="w-100 bg-transparent">
                                                </div>
                                                <div class="col-6">
                                                    <div class="transpoter-name" v-text="item.infoService.name"></div>
                                                    <div class="transpoter-description" v-text="item.infoService.description"></div>
                                                </div>
                                                <div class="col-2 text-primary text-right p-0" v-text="n(item.total_price)"></div>
                                            </div>
                                        </template>
                                    </div>
                                    <div v-else>
                                        <div class="row px-0 py-2 m-0 border bg-light">
                                            <div class="col-3">
                                                <img src="../../../public/img/ahamove.png" class="w-100">
                                            </div>
                                            <div class="col-9">
                                                <div class="transpoter-name">
                                                    <span class="text-danger text-bold">
                                                        {{$t('message.common.linkToAhaMove')}}
                                                    </span>
                                                    <span class="text-primary text-bold" @click="connectTransporter($constant.typeAhaMove)">
                                                        {{$t('message.common.loginNow')}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <!-- ViettelPost -->
                                <template v-if="entry.shippingMode == this.$constant.shipping_mode_default && (this.statusOption.vtp)">
                                    <div v-if="!this.isLoginViettelPost">
                                        <template v-for="(item, index) in this.deliveriesViettelPost" :key="index">
                                            <div class="row px-0 py-2 m-0 border  bg-light" @click="chooseSerivce(item)">
                                                <div class="col-1 p-0">
                                                    <label class="container-radio ml-2 w-100">
                                                        <input type="radio" name="transporter" :checked="this.entry.serviceId == item.infoService.code ? true : false">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div class="col-3">
                                                    <img src="../../../public/img/viettelpost.png" class="w-100 bg-transparent">
                                                </div>
                                                <div class="col-6">
                                                    <div class="transpoter-name" v-text="item.infoService.code"></div>
                                                    <div class="transpoter-description">{{ item.infoService.name}}</div>
                                                </div>
                                                <div class="col-2 text-primary text-right p-0" v-text="n(item.infoService.total_price)"></div>
                                            </div>
                                        </template>
                                    </div>
                                    <div v-else>
                                        <div class="row px-0 py-2 m-0 border bg-light">
                                            <div class="col-3">
                                                <img src="../../../public/img/viettelpost.png" class="w-100">
                                            </div>
                                            <div class="col-9">
                                                <div class="transpoter-name">
                                                    <span class="text-danger text-bold">
                                                        {{$t('message.common.linkToViettelPost')}}
                                                    </span>
                                                    <span class="text-primary text-bold" @click="connectTransporter($constant.typeVTP)">
                                                        {{$t('message.common.loginNow')}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>



                                <!-- start comment old code -->
                                <!-- <template v-if="!this.isLoginAhamove">
                                    <template v-for="(item, index) in this.deliveries" :key="index">
                                        <div class="row px-0 py-2 m-0 border  bg-light" @click="chooseSerivce(item)">
                                            <div class="col-1 p-0">
                                                <label class="container ml-2 w-100">
                                                    <input type="radio" name="transporter" :checked="this.entry.serviceId == item._id ? true : false">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div class="col-3">
                                                <img src="../../../public/img/ahamove.png" class="w-100 bg-transparent">
                                            </div>
                                            <div class="col-6">
                                                <div class="transpoter-name" v-text="item.infoService.name"></div>
                                                <div class="transpoter-description" v-text="item.infoService.description"></div>
                                            </div>
                                            <div class="col-2 text-primary text-right" v-text="n(item.total_price)"></div>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>
                                    <div class="row px-0 py-2 m-0 border bg-light">
                                        <div class="col-3">
                                            <img src="../../../public/img/ahamove.png" class="w-100">
                                        </div>
                                        <div class="col-9">
                                            <div class="transpoter-name">
                                                <span class="text-danger text-bold">{{$t('message.common.linkToAhaMove')}}</span>
                                                <span class="text-primary text-bold" @click="connectTransporter">
                                                    {{$t('message.common.loginNow')}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </template> -->
                                <!-- end comment old code -->
                            </div>
                        </div>
                        <div class="col-12 mt-2 py-2 cursor-pointer bg-light" v-if="this.entry.isTransporterIntegration">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="">
                                    <span class="btn-icon-gift pr-2"><i class="fa fa-gift"></i></span>
                                    <label class="text-bold">
                                        {{$t('message.common.deliveryPromotion')}}
                                    </label>
                                </div>
                                <div class="" style="padding-right: .75rem">
                                    <span class="px-2 py-1 m-2 border-active">4 mã</span><i class="fa fa-chevron-right"></i>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-12 mt-2 py-2 cursor-pointer">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="">
                                    <label class="text-bold">{{$t('message.common.theSenderPaysTheFee')}}</label>
                                </div>
                                <div class="" style="padding-right: .75rem">
                                    <span>0</span>
                                </div>
                            </div>
                        </div> -->

                        <div class="col-12 mt-2 pt-2 cursor-pointer d-flex justify-content-between" v-if="this.entry.isTransporterIntegration">
                            <div>
                                <label class="container-radio ml-0">
                                    <b>
                                        {{$t('message.common.theSenderPaysTheFee')}}
                                    </b>
                                    <input type="radio" name="fee" v-model="entry.type_payment" :value="$constant.sendPayment">
                                    <span class="checkmark"></span>
                                </label>
                                <!-- <input type="radio" v-model="entry.type_payment" :value="$constant.sendPayment">
                                <label class="text-bold">{{$t('message.common.theSenderPaysTheFee')}}</label> -->
                            </div>
                            <div v-if="this.entry.transporterId != this.$constant.giao_hanh_nhanh_transposter && this.entry.transporterId != this.$constant.vtp_transposter">
                                <label class="container-radio ml-0">
                                    <b>
                                        {{$t('message.common.paymentViaApp')}}
                                    </b>
                                    <input type="radio" name="fee" v-model="entry.type_payment" :value="$constant.appPayment">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div>
                                <label class="container-radio ml-0">
                                    <b>
                                        {{$t('message.InvoiceIndex.tabs.delivery.paid_by_receiver_value')}}
                                    </b>
                                    <input type="radio" name="fee" v-model="entry.type_payment" :value="$constant.receivePayment">
                                    <span class="checkmark"></span>
                                </label>
                                <!-- <input type="radio" v-model="entry.type_payment" :value="$constant.receivePayment">
                                <label class="text-bold">{{$t('message.InvoiceIndex.tabs.delivery.paid_by_receiver_value')}}</label> -->
                            </div>
                        </div>

                        <div class="col-12 pt-2 cursor-pointer" v-if="this.entry.isTransporterIntegration && this.entry.transporterId != this.$constant.giao_hanh_nhanh_transposter &&  this.entry.transporterId != this.$constant.vtp_transposter">
                            <div >
                                <label class="container-checkbox">
                                    <b>{{$t('message.common.heavyThings')}} <span v-if="entry.levelName">{{ '( '+entry.levelName+ ' )' }}</span></b>
                                    <input type="checkbox" :readonly="true" :disabled="true" :checked="entry.is_bulky ? true : false">
                                    <span class="checkmark-checkbox"></span>
                                </label>
                            </div>
                        </div>
                        <div class="col-12 cursor-pointer d-flex justify-content-between" v-if="this.entry.isTransporterIntegration">
                            <label class="container-checkbox">
                                <b v-if="this.entry.transporterId != this.$constant.vtp_transposter">
                                    {{$t('message.common.declarePrice')}}
                                    (
                                        {{$t('message.common.maximumDeclaredValue')}}
                                        <span v-text="entry.shippingMode == this.$constant.shipping_mode_in_day ? '30' : (entry.shippingMode == this.$constant.shipping_mode_default ? '5' : '0')"></span> 
                                        {{$t('message.common.million')}}
                                    )
                                </b>
                                <b v-else>
                                    {{$t('message.common.declarePrice')}}
                                </b>
                                <input type="checkbox" v-model="entry.is_insurance" :checked="entry.is_insurance ? true : false">
                                <span class="checkmark-checkbox"></span>
                            </label>
                            <InputNumberFormat :max="entry.shippingMode == this.$constant.shipping_mode_in_day ? 30000000 : (entry.shippingMode == this.$constant.shipping_mode_default ? 5000000 : 0)" v-if="entry.is_insurance && this.entry.transporterId != this.$constant.vtp_transposter" v-model="entry.item_value" class="mb-0" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)"/>
                            <InputNumberFormat  v-if="entry.is_insurance && this.entry.transporterId == this.$constant.vtp_transposter" v-model="entry.item_value" class="mb-0" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)"/>

                        </div>
                        <div class="col-12 cursor-pointer d-flex justify-content-between" v-if="(this.entry.isTransporterIntegration && this.entry.transporterId != this.$constant.giao_hanh_nhanh_transposter) && (this.entry.isTransporterIntegration && this.entry.transporterId != this.$constant.vtp_transposter)">
                            <label class="container-checkbox">
                                <b>
                                    {{$t('message.common.sendSMS')}}
                                </b>
                                <input type="checkbox" v-model="entry.sendSms" :checked="entry.sendSms ? true : false">
                                <span class="checkmark-checkbox" ></span>
                            </label>
                        </div>
                        <div class="col-12 cursor-pointer d-flex justify-content-between" v-if="(this.entry.isTransporterIntegration && this.entry.transporterId != this.$constant.giao_hanh_nhanh_transposter) && this.entry.isTransporterIntegration && this.entry.transporterId != this.$constant.vtp_transposter">
                            <label class="container-checkbox">
                                <b>
                                    {{$t('message.common.fragile')}}
                                </b>
                                <input type="checkbox" v-model="entry.is_fragile" :checked="entry.is_fragile ? true : false">
                                <span class="checkmark-checkbox" ></span>
                            </label>
                        </div>

                        <!-- ViettelPost OptionZ -->
                        <div class="col-12 cursor-pointer d-flex justify-content-between" v-if="this.entry.isTransporterIntegration && this.entry.transporterId == this.$constant.vtp_transposter">
                            <label class="container-checkbox">
                                <b>
                                    {{$t('message.common.atThePostOffice')}}
                                </b>
                                <input type="checkbox" v-model="entry.isSend" :checked="entry.isSend ? true : false">
                                <span class="checkmark-checkbox" ></span>
                            </label>
                        </div>
                        <div class="col-6 py-2" v-if="this.entry.isTransporterIntegration && this.entry.transporterId == this.$constant.vtp_transposter">
                            <SelectSearch :modelValue="entry.serviceVTP" 
                            :defaultOption="optionServiceVTP" @update:modelValue="selectServiceVTP" 
                            :label="'serviceVTP'" :readonly="true"></SelectSearch>
                        </div>
                        <div class="col-6 py-2" v-if="this.entry.isTransporterIntegration && this.entry.transporterId == this.$constant.vtp_transposter">
                        </div>
                        <!-- <div class="col-12 cursor-pointer d-flex justify-content-between" v-if="this.entry.isTransporterIntegration && this.entry.transporterId == this.$constant.vtp_transposter">
                            <label class="container-checkbox">
                                <b>Khai giá</b>
                                <input type="checkbox" v-model="entry.isSend" :checked="entry.is_fragile ? true : false">
                                <span class="checkmark-checkbox" ></span>
                            </label>
                        </div> -->

                        <div class="col-12 py-2 cursor-pointer" v-if="this.entry.isTransporterIntegration">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="">
                                    <label class="text-bold">{{ entry.type_payment == this.$constant.receivePayment ? $t('message.InvoiceIndex.tabs.delivery.paid_by_receiver_value') : $t('message.common.theSenderPaysTheFee')}}</label>
                                </div>
                                <div class="" style="padding-right: .75rem">
                                    <div v-text="n(this.entry.total_price ?? 0)"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 py-2 cursor-pointer" v-if="this.entry.isTransporterIntegration && entry.usingPriceCod">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="">
                                    <label class="text-bold">
                                        {{$t('message.InvoiceIndex.tabs.delivery.total_receive')}}
                                    </label>
                                    <a href="javascript:void(0);" class="text-dark ml-2" id="linkIcon" @click="showHide()">
                                        <i class="fa fa-chevron-down" v-if="this.hiddenArrowUp"></i>
                                        <i class="fa fa-chevron-up" v-if="!this.hiddenArrowUp"></i>
                                    </a>
                                </div>
                                <div class="" style="padding-right: .75rem">
                                    <div v-text="n(totalAmountReceiver)"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 py-2 cursor-pointer" v-if="this.entry.isTransporterIntegration && entry.usingPriceCod && !this.hiddenArrowUp">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="pl-3">
                                    <label>
                                        {{$t('message.common.cod')}}
                                    </label>
                                </div>
                                <div style="padding-right: .75rem">
                                    <div v-text="n(entry.amountCod)"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 py-2 cursor-pointer" v-if="this.entry.isTransporterIntegration && entry.usingPriceCod && !this.hiddenArrowUp">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="pl-3">
                                    <label>
                                        {{$t('message.OrderIndex.shipping_fee_collected_by_customer')}}
                                    </label>
                                </div>
                                <div style="padding-right: .75rem">
                                    <div v-text="n(entry.shippingFeeByCustomer ?? 0)"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 my-2" v-if="entry.customerId && entry.type != TYPE_RETURN">
                            <div class="d-flex justify-content-between align-items-end" v-if="(+entry.totalPriceAfterDiscount - +entry.customerPayment) > 0 && entry.debtTerm">
                                <div class="form-group">
                                    <label>{{$t('message.customerIndex.debtDate')}}</label>
                                </div>
                                <div class="sale-input-number">
                                    <InputNumberFormat v-model="entry.period" @input="calculateDebtTerm"
                                        class="sale-input-number mb-0"
                                    ></InputNumberFormat>
                                </div>
                                <div>
                                    {{$t('message.TransporterIndex.modal.modelSelectInvoicePayment.end_date')}} : {{ $filters.formatDate(entry.debtTerm) }}
                                </div>
                            </div>
                            <div class="row p-0 m-0" v-else-if="(+entry.totalPriceAfterDiscount - +entry.customerPayment) > 0 && entry.debtDate && !entry.debtTerm">
                                <div class="col-6 text-bold p-0 m-0">
                                    <label>{{$t('message.customerIndex.debtDate')}}</label>
                                </div>
                                <div class="col-6 border-bottom" v-text="this.entry.debtDate"></div>
                            </div>
                        </div>
                        <!-- <div class="col-12 mt-2" v-if="entry.type == TYPE_ORDER">
                            <div class="row p-0 m-0">
                                <div class="col-6 text-bold p-0 m-0">
                                    <label>{{$t('message.customerIndex.debtDate')}}</label>
                                </div>
                                <div class="col-6 border-bottom" v-text="this.entry.debtDate"></div>
                            </div>
                        </div> -->

                        <div class="col-12 mt-2 py-2 cursor-pointer bg-light rounded" v-if="!this.entry.isTransporterIntegration">
                            <div class="row m-0 p-0">
                                <div class="col-lg-5 py-2 px-0">{{$t('message.orderFromTab.service_type')}}</div>
                                <div class="col-lg-7 py-2 px-0">
                                    <SelectSearch :defaultOption="listServiceType" :borderActive="true" :modelValue="entry.serviceTypeId" @update:modelValue="changeServiceType" :readonly="true" :label="'serviceTypeId'"></SelectSearch>
                                </div>
                            </div>
                            <div class="row m-0 p-0">
                                <div class="col-lg-5 py-2 px-0">{{$t('message.orderFromTab.shipping_fee')}}<i class="fa fa-info-circle ml-1"></i></div>
                                <div class="col-lg-7 py-2 px-0">
                                    <InputNumberFormat v-model="entry.shipping_price" :borderActive="true" :bgTransparent="true" :cardTransaction="true" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)" class="mb-0"/>
                                </div>
                            </div>
                            <div class="row m-0 p-0">
                                <div class="col-lg-5 py-2 px-0">{{$t('message.orderFromTab.delivery_code')}}</div>
                                <div class="col-lg-7 py-2 px-0">
                                    <div class="form-group mb-0">
                                        <input type="text" v-model="entry.deliveryCode" :placeholder="$t('message.orderFromTab.placeholder.delivery_code')" class="form-control pl-0 input-custom bg-transparent border-bottom-focus-active" autocomplete="off">
                                    </div>
                                </div>
                            </div>
                            <div class="row m-0 p-0">
                                <div class="col-lg-5 py-2 px-0">{{$t('message.orderFromTab.delivery_time')}}</div>
                                <div class="col-lg-7 py-2 px-0">
                                    <SelectDateTimePicker :modelValue="entry.deliveryTime" @update:modelValue="changeDeliveryTime" :borderActive="true" :backgroundColor="'bg-light'"/>
                                </div>
                            </div>
                            <div class="row m-0 p-0" v-if="entry.type != TYPE_ORDER">
                                <div class="col-lg-5 py-2 px-0">{{$t('message.orderFromTab.delivery_status')}}</div>
                                <div class="col-lg-7 py-2 px-0">
                                    <SelectSearch :defaultOption="this.$constant.listDeliveryStatus" :borderActive="true" :modelValue="entry.deliveryStatus" @update:modelValue="changeDeliveryStatus" :readonly="true" :label="'deliveryStatus'"></SelectSearch>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 d-flex mt-2" v-if="(entry.type == TYPE_ORDER || entry.type == TYPE_INVOICE || entry.action == ACTION_UPDATE_ORDER) && isExtend">
                            <label class="container-checkbox text-bold">{{$t('message.common.changeInforInvoice')}}
                                <input type="checkbox" :value="$constant.isChangeInformationInvoice" v-model="entry.isChangeInformationInvoice">
                                <span class="checkmark-checkbox"></span>
                            </label>
                        </div>
                        <div class="col-md-12 d-flex align-items-end mb-3" v-if="(entry.type == TYPE_ORDER || entry.type == TYPE_INVOICE || entry.action == ACTION_UPDATE_ORDER) && isExtend && entry.isChangeInformationInvoice">
                            <div class="col-lg-4 pl-0 text-bold">{{$t('message.common.soldByName')}}:</div>
                            <div class="col-lg-8">
                                <input type="text" class="border-bottom-1 w-100" :placeholder="$t('message.common.enterSoldByName')" v-model="entry.soldByName">
                            </div>
                        </div>
                        <div class="col-md-12 d-flex align-items-end mb-3" v-if="(entry.type == TYPE_ORDER || entry.type == TYPE_INVOICE || entry.action == ACTION_UPDATE_ORDER) && isExtend && entry.isChangeInformationInvoice">
                            <div class="col-lg-4 pl-0 text-bold">{{$t('message.modalCreateUpdateUnit.unit_name')}}:</div>
                            <div class="col-lg-8">
                                <input type="text" class="border-bottom-1 w-100" :placeholder="$t('message.common.enterUnitName')" v-model="entry.soldByCompany">
                            </div>
                        </div>
                        <div class="col-md-12 d-flex align-items-end mb-3" v-if="(entry.type == TYPE_ORDER || entry.type == TYPE_INVOICE || entry.action == ACTION_UPDATE_ORDER) && isExtend && entry.isChangeInformationInvoice">
                            <div class="col-lg-4 pl-0 text-bold">{{$t('message.customerIndex.taxCode')}}:</div>
                            <div class="col-lg-8">
                                <input type="text" class="border-bottom-1 w-100" :placeholder="$t('message.customerIndex.modal.enterTaxCode')" v-model="entry.soldByTaxCode">
                            </div>
                        </div>
                        <div class="col-md-12 d-flex align-items-end" v-if="(entry.type == TYPE_ORDER || entry.type == TYPE_INVOICE || entry.action == ACTION_UPDATE_ORDER) && isExtend && entry.isChangeInformationInvoice">
                            <div class="col-lg-4 pl-0 text-bold">{{$t('message.customerIndex.address')}}:<span class="text-danger">*</span></div>
                            <div class="col-lg-8">
                                <input type="text" class="border-bottom-1 w-100" :placeholder="$t('message.customerIndex.modal.enterAddress')" v-model="entry.soldByAddress">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-right__save row justify-content-center ml-1" :class="isExtend ? '' : 'd-none'">
                    <div v-if="entry.type != TYPE_RETURN" class="col-3 text-center">
                        <div class="row justify-content-center">
                            <div class="col-12">
                                <button type="button" class="btn btn-save btn-secondary text-white" :disabled="isCallApi" @click="onPrintTicketCurrent()">
                                    {{$t('message.button.print.name')}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-if="entry.action == ACTION_UPDATE_ORDER" class="col-9 text-center">
                        <div class="row justify-content-center">                                                
                            <AppPermission :permission="this.$permissions.invoices.create?? '' ">
                                <div v-if="entry.isCreateInvoice" class="col-12">
                                    <button type="button" class="btn btn-save" :disabled="isCallApi" @click="onOrderProcessing">
                                        {{$t('message.button.invoiceAdd')}}
                                    </button>                             
                                </div>
                            </AppPermission>
                            <AppPermission :permission="this.$permissions.orders.update?? '' ">   
                                <div v-if="entry.status != $constant.status_delivery_order && entry.isUpdateOrder" class="col-12">                                
                                        <button type="button" :disabled="isCallApi" class="btn btn-save" @click="save">
                                            {{$t('message.button.save')}}
                                        </button>                                
                                </div>
                            </AppPermission>
                        </div>
                    </div>
                    <div v-else-if="entry.action == ACTION_UPDATE_INVOICE" class="col-9 text-center">
                        <div class="row">
                            <div class="col-12">
                                <AppPermission :permission="this.$permissions.invoices.update?? '' ">
                                    <button type="button" :disabled="isCallApi" class="btn btn-save" @click="save">
                                        {{$t('message.button.save')}}
                                    </button>
                                </AppPermission>
                            </div>
                        </div>
                    </div>
                    <div v-else class="col-9 text-center">                    
                        <button type="button" class="btn btn-save" :disabled="isCallApi" @click="saveReturn" v-if="entry.type == TYPE_RETURN && (this.hasPermission(this.$permissions.returns.create) || user.id == 1)">
                            {{$t('message.InvoiceIndex.button.return')}}
                        </button>  
                        
                        <button type="button" class="btn btn-save" :disabled="isCallApi" @click="save" v-else-if="entry.type == TYPE_INVOICE && (this.hasPermission(this.$permissions.invoices.create) || user.id == 1)">
                            {{$t('message.TransporterIndex.button.payment')}}
                        </button>
                        
                        <button type="button" class="btn btn-save" :disabled="isCallApi" @click="save" v-else v-text="$t('message.OrderIndex.button.order')"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="">
        <!-- <ModalAddInfoCustomer ref="ModalAddInfoCustomer" :action="'Thêm mới khách hàng'" @updated="getCustomers" @selectCustomer="selectCustomer"/> -->
        <ModalDetailProduct ref="modalDetailProduct" @updateQuantity="updateQuantity" v-model="entry"></ModalDetailProduct>
        <ModalPromotionInvoiceList ref="modalPromotionInvoiceList" @choosePromotion="choosePromotion"></ModalPromotionInvoiceList>
        <ModalAddInfoSaleChannels ref="ModalAddInfoSaleChannels" @inputData="getSaleChannels()" :typeEditAdd="'ADD'"/>
        <ModalPromotionWarning ref="ModalPromotionWarning" @outputData="promotionWarning"/>
        <ModalCustomerPaid ref="ModalCustomerPaid" />
        <ModalConnectTransporter ref="ModalConnectTransporter" @reload="countAhamove"/>
        <ModalConnectGHN ref="ModalConnectGHN" @reload="countGHN"/>
        <ModalInformationCustomer ref="ModalInformationCustomer" @changeData="changeDataAddress"/>
        <ModalAddTransporters ref="ModalAddTransporters"/>        
        <OrderDiscount ref="orderDiscount" :modelValue="entry" :price="entry.totalPrice" @update:modelValue="updateDiscount" />
        <ModalConnectVTP ref="ModalConnectVTP" @reload="countViettelPost"/>
        <ModalSelectPrintTemplate ref="ModalSelectPrintTemplate" @updateContent="updateContent"/>
        <PreviewTemplate ref="PreviewTemplate" :editorDataClone="this.editorDataClone"/>
    </div>
</template>

<script>
    import InputSelect from "../../components/forms/InputSelect";
    import store from "../../store";
    import ModalAddInfoCustomer from '@/components/customers/ModalAddInfoCustomer';
    import ModalAddInfoSaleChannels from "@/components/sale_channels/ModalAddInfoSaleChannels";
    import {$alert, $get, $post, cloneObject, parseIntEx, clone, debounce} from "../../lib/utils";
    import toastr from 'toastr';
    import InputNumberFormat from "../../components/forms/InputNumberFormat";
    import {DISCOUNT_TYPE_PERCENT, DISCOUNT_TYPE_VND, TYPE_INVOICE, TYPE_ORDER, ACTION_ORDER_PROCESSING, ACTION_UPDATE_ORDER, ACTION_UPDATE_INVOICE, TYPE_RETURN, ACTION_RETURN_INVOICE} from "../../lib/utils";
    import InputTextArea from "../../components/forms/InputTextArea";
    import storage from "../../lib/storage";
    import moment, { locales } from "moment";
    import currencyMixin from "@/currencyMixin";
    import SelectSearch from "@/components/SelectSearch.vue";
    import ProductDiscount from "@/components/ProductDiscount.vue";
    import OrderDiscount from "@/components/OrderDiscount.vue";
    import ModalDetailProduct from './ModalDetailProduct';
    import ModalPromotionWarning from './ModalPromotionWarning';
    import ModalPromotionInvoiceList from './ModalPromotionInvoiceList'
    import emitter from "@/lib/emitter";
    import ChooseConsignment from "@/components/ChooseConsignment.vue";    
    import AppPermission from '@/components/AppPermission.vue';
    import permissionMixin from '@/permissionMixin';
    import ModalCustomerPaid from './ModalCustomerPaid.vue';
    import ModalConnectTransporter from './ModalConnectTransporter.vue';
    import ModalConnectGHN from './ModalConnectGHN.vue';
    import ModalConnectVTP from './ModalConnectVTP.vue';
    import ModalInformationCustomer from './ModalInformationCustomer.vue';
    import CustomerSearch from '@/components/CustomerSearch';
    import TransportersSearch from '@/components/TransportersSearch';
    import InputSwitchButton from "../../components/forms/InputSwitchButtonCustom";
    import ModalAddTransporters from '../../views/transporters/tabs/ModalAddTransporters.vue';
    // SelectPickupAddress
    import SelectPickupAddress from "@/components/SelectPickupAddress";
    import SearchLocation from '@/components/SearchLocation';
    import SelectDateTimePicker from "@/components/SelectDateTimePicker";
    import attributesMixin from '@/attributesMixin';
    import { mapGetters } from "vuex";
    import InputFileManager from "@/components/forms/InputFileManager";
    import ModalSelectPrintTemplate from '@/components/ModalSelectPrintTemplate';
    import printOrdersMixin from '@/printOrdersMixin';
    import PreviewTemplate from '@/components/printTemplate/PreviewTemplate.vue';
    let app;
    export default {
        name: "SaleFormTab",
        emits: ['removeTab', 'orderProcessing', 'print', 'SetPriceListId', 'setSaleChannelId', 'setSoldById', 
        'setCustomerId', 'setDescription', 'setCashMoney', 'setCashMoney', 'setCardMoney','setTransferMoney', 'setVoucher', 
        'setDebtTerm', 'setProductGift' ],
        mixins: [currencyMixin, permissionMixin, attributesMixin, printOrdersMixin],
        components: {
            ProductDiscount,
            ModalDetailProduct,
            InputTextArea,
            InputNumberFormat,
            ModalAddInfoCustomer,
            InputSelect,
            SelectSearch,
            ModalAddInfoSaleChannels,
            ModalPromotionInvoiceList,
            ModalPromotionWarning,
            ChooseConsignment, AppPermission,
            ModalCustomerPaid,
            CustomerSearch,
            InputSwitchButton,
            SelectPickupAddress,
            ModalConnectTransporter,
            SearchLocation,
            TransportersSearch,
            ModalInformationCustomer,
            ModalAddTransporters,
            SelectDateTimePicker,
            OrderDiscount,
            ModalConnectGHN,
            ModalConnectVTP,
            InputFileManager,
            ModalSelectPrintTemplate,
            PreviewTemplate,
        },
        props: ['modelValue', 'action'],
        data() {
            // const user = store.state.Auth ? store.state.Auth.user : {};
            app = this;
            return {
                user: store.state.Auth.user?? null,
                isTaxRate: false,
                isHsCode: false,
                addPlusOder: 'addPlusOder',
                changePlusOder: 'changePlusOder',
                change: 'change',
                entry: this.modelValue,
                saleChannels: store.state.GlobalConfig.saleChannels,
                listServiceType: store.state.GlobalConfig.listServiceType,
                priceLists: [],
                customers: [],
                productIds: [],
                    productDetail: null,
                paymentChannel: [this.$constant.paymentChannelCashMoney],
                branchAdmins: null,
                weightUnits: this.$constant.weightUnits,
                branchName: store.state.CurrentBranch.name ?? null,
               
                TYPE_INVOICE,
                TYPE_ORDER,
                TYPE_RETURN,
                mapTypeLabel: {
                    [TYPE_INVOICE]: this.$t('message.headers.invoices') + " ",
                    [TYPE_ORDER]: this.$t('message.OrderIndex.button.order')
                },
                ACTION_UPDATE_ORDER,
                ACTION_UPDATE_INVOICE,
                ACTION_ORDER_PROCESSING,
                ACTION_RETURN_INVOICE,
                DISCOUNT_TYPE_VND,
                DISCOUNT_TYPE_PERCENT,
                productUnitIds: null,
                categoriesIds: null,
                disablePromotionInvoiceIds: [],
                disablePromotionProductIds: [],
                totalQuantityMax: 0,
                isChangeCustomer: false,
                readonlyCombo: true,
                hasConsignment: false,
                hasStamp: false,
                pickupAddress: [],
                provinces: [],
                districts: [],
                wards: [],
                info_delivery: {
                    is_bulky: false,
                    is_insurance: false,
                    level: this.$constant.level_1,
                    type_payment: this.$constant.sendPayment,
                    from_address: "",
                    from_name: store.state.CurrentBranch.name,
                    from_phone: "",
                    from_note: "",
                    to_address: "",
                    to_name: "",
                    to_phone: "",
                    to_note: "",
                    serviceId: "",
                    requests: [],
                    item_value: 0,
                    sendSms: false,
                    is_fragile: false,
                    levelName: null,
                },
                deliveries: [],
                deliveriesViettelPost: [],
                informationCustomer: [],
                isTransporterIntegration: true,
                isLoginAhamove: false,
                isLoginGHN: false,
                isLoginViettelPost: false,
                isExtend: true,
                isCallApi: false,
                statusOption: {
                    ahamove: false,
                    ghn: false,
                    vtp: false
                },
                hiddenArrowUp: false,
                optionServiceVTP: [
                    {id: 'GDK', name: this.$t('message.common.showProduct')},
                    {id: '1', name: this.$t('message.common.notShowProduct')},  
                ]
            }
        },
        async mounted() {
            if(this.entry.type == TYPE_RETURN){
                document.title = this.$t('message.InvoiceIndex.button.return');       
            }else{
                document.title = this.$t('message.OrderIndex.button.order');       
            }
            if(!this.entry.shippingMode){
                this.entry.shippingMode = this.$constant.shipping_mode_default;
            }
            if(!this.entry.is_bulky) this.entry.is_bulky = false;
            if(!this.entry.is_insurance) this.entry.is_insurance = false;
            if(!this.entry.level) this.entry.level = this.$constant.level_1;
            if(!this.entry.type_payment) this.entry.type_payment = this.$constant.sendPayment;
            if(this.entry.isTransporterIntegration != false) this.entry.isTransporterIntegration = true;
            if (this.entry.width && this.entry.hight && this.entry.long) {
                this.onGetLevelBulkyUseAhamove();
            } else {
                this.clearLevel();
            }
            if (this.entry.hasShipping) {
                if (this.entry.shippingMode == this.$constant.shipping_mode_default) {
                    this.onEstimatedFeesGiaoHangNhanh();
                    this.onEstimatedFeesViettelPost();
                }
                else if (this.entry.shippingMode == this.$constant.shipping_mode_in_day) {
                    this.onEstimatedFeesAhamove();
                }
                this.informationCustomer = (this.entry && this.entry.informationCustomer) ? this.entry.informationCustomer : [];
            }

            if(!this.entry.soldById){
                this.entry.soldById = this.user.id ?? null;
            }
            if(this.entry.customer && !this.entry.customerId && this.entry.type == TYPE_RETURN){
                this.entry.customerId = this.entry.customer.id ?? null;
            }
            if(!this.entry.serviceVTP){
                this.entry.serviceVTP = '1';
            }
        },
        created() {
            this.getPriceList();
            // this.getCustomers();
            this.setValue();
            this.getUserActive();
            this.getDataPickupAddress();
            this.loadDataLocation();
            this.countAhamove();
            this.countGHN();
            this.countViettelPost();
            this.loadSetting();
        },
        computed: {
            permissionInfo: () => store.state.PermissionInfo,   
            authData: () => store.state.Auth,
            authAhamove: () => store.state.authAhamove,
            totalAmountReceiver() {
                if (this.entry.type_payment == this.$constant.sendPayment || this.entry.type_payment == this.$constant.appPayment) {
                    return (this.entry.amountCod ? +this.entry.amountCod : 0) + (this.entry.shippingFeeByCustomer ? +this.entry.shippingFeeByCustomer : 0);
                } else {
                    return (this.entry.amountCod ? +this.entry.amountCod : 0) + (+this.entry.total_price ?? 0) + (this.entry.shippingFeeByCustomer ? +this.entry.shippingFeeByCustomer : 0); 
                }
            },
            propertyADeliveries() {
                return `${this.entry.from_address}|${this.entry.to_address}|${this.entry.is_bulky}|${this.entry.level}|${this.entry.is_insurance}|
                    ${this.entry.type_payment}|${this.entry.item_value}|${this.entry.sendSms}|${this.entry.is_fragile}|${this.entry.usingPriceCod}|${this.entry.amountCod}`;
            },
            ...mapGetters({
                isConnectTransporter: 'settingStore/isConnectTransporter',
                isUseVoucher: 'settingStore/isUseVoucher',
                isUseCoupon: 'settingStore/isUseCoupon',
                isUsePromotion: 'settingStore/isUsePromotion',
            })
        },
        methods: {
            async onPrintTicketCurrent() {
                if (!this.entry.products || !this.entry.products.length) {
                    $alert({code: 500, message: this.$t('message.common.emptyOrder')});
                    return;
                }
                if (this.isCallApi) {
                    return;
                }
                this.isCallApi = true;
                let type_print = null;
                if (this.entry.type == TYPE_INVOICE) {
                    type_print = this.$constant.TabInvoices;
                } else if (this.entry.type == TYPE_ORDER) {
                    type_print = this.$constant.TabOrder;
                } else if (this.entry.type == TYPE_RETURN) {
                    type_print = this.$constant.TabReturns;
                }
                if (type_print) {
                    const res = await $get(`/v1/print-templates/get-content-print-cli`, {action: type_print});    
                    if(res.result) {    
                        this.entry.typePrint = type_print;                
                        this.$refs.ModalSelectPrintTemplate.show(res.result, this.entry);
                    }           
                }
                this.isCallApi = false;
            },

            async updateContent(data){
                if (!data || !data.content || !data.dataPrint || !data.dataPrint.typePrint) {
                    return;
                }
                this.content = data.content;
                let dataPrint = null;
                if (data.dataPrint.typePrint == this.$constant.TabOrder) {
                    dataPrint = this.formatDataPrintTypeOrder(data.dataPrint);
                } else if (data.dataPrint.typePrint == this.$constant.TabInvoices) {
                    dataPrint = this.formatDataPrintTypeInvoice(data.dataPrint);
                }
                if (dataPrint) {
                    await this.print(dataPrint);
                }
            },

            formatDataPrintTypeInvoice(invoice) {
                let promotion = null;
                if (invoice.promotion) {
                    promotion = invoice.promotion;
                    promotion.promotionValue = invoice.promotion.discountValue;
                }
                let voucher_detail = [];
                if (invoice.voucherDetailIds && invoice.voucherDetailIds.length) {
                    for (let index = 0; index < invoice.voucherDetailIds.length; index++) {
                        const voucherId = invoice.voucherDetailIds[index];
                        voucher_detail.push({
                            code: invoice.infoVouchers.find(voc => voc.id == voucherId)?.code
                        })
                    }
                }
                let coupon_detail = '';
                if (invoice.couponCode) {
                    coupon_detail = {
                        code: invoice.couponCode
                    }
                }
                let customerGroups = '';
                if (invoice.customerOrigin && invoice.customerOrigin.customer_group && invoice.customerOrigin.customer_group.length) {
                    customerGroups = this.formatCustomerGroups(invoice.customerOrigin.customer_group);
                }
                const {listProducts, countQuantity} = this.formatProductsPrint(invoice.products, 'invoice');
                let currentInvoice = {
                    customerGroups: customerGroups,
                    code: invoice.code ? invoice.code : '',
                    branch: store.state.CurrentBranch,
                    countItems: invoice.products.length,
                    createdAt: moment(),
                    sold_by: this.branchAdmins.find(user => user.id == invoice.soldById),
                    description: invoice.description,
                    customer: invoice.customerOrigin,
                    priceListName: this.priceLists.find(priceList => priceList.id == invoice.priceListId)?.name,
                    saleChannelName: this.saleChannels.find(channel => channel.id == invoice.channelId)?.name,
                    invoice_items: listProducts,
                    totalProduct: countQuantity,
                    totalOrigin: invoice.totalPrice,
                    discountValue: invoice.discountValue,
                    surchargeAmount: invoice.surchargeAmount,
                    promotion: promotion,
                    total: invoice.totalPriceAfterDiscount,
                    cash: invoice.cash,
                    card: invoice.card,
                    transfer: invoice.transfer,
                    discountRatio: invoice.discountRatio,
                    user_created: store.state.Auth.user,
                    typePrint: this.$constant.TypePrintInvoice,
                    promotionValue: (promotion && promotion.promotionValue) ? promotion.promotionValue : 0,
                    coupon_detail: coupon_detail,
                    voucher_detail: voucher_detail,
                    valueVoucher: invoice.valueVoucher,
                    valueCoupon: invoice.valueCoupon,
                }
                return currentInvoice;
            },

            formatDataPrintTypeOrder(order) {
                let promotion = null;
                let promotionValue = 0;
                if (order.promotion) {
                    promotion = order.promotion;
                    promotion.promotionValue = order.promotion.discountValue;
                    promotionValue = order.promotion.discountValue;
                }
                let customerGroups = '';
                if (order.customerOrigin && order.customerOrigin.customer_group && order.customerOrigin.customer_group.length) {
                    customerGroups = this.formatCustomerGroups(order.customerOrigin.customer_group);
                }
                let voucher_detail = [];
                if (order.voucherDetailIds && order.voucherDetailIds.length) {
                    for (let index = 0; index < order.voucherDetailIds.length; index++) {
                        const voucherId = order.voucherDetailIds[index];
                        voucher_detail.push({
                            code: order.infoVouchers.find(voc => voc.id == voucherId)?.code
                        })
                    }
                }
                let coupon_detail = '';
                if (order.couponCode) {
                    coupon_detail = {
                        code: order.couponCode
                    }
                }
                let totalPaymentOrigin = +order.cash + +order.card + +order.transfer;
                const {listProducts, countQuantity} = this.formatProductsPrint(order.products, 'order');
                let currentOrder = {
                    code: order.code ? order.code : '',
                    countItems: order.products.length,
                    customerGroups: customerGroups,
                    totalProduct: countQuantity,
                    branch: store.state.CurrentBranch,
                    createdAt: moment(),
                    sold_by: this.branchAdmins.find(user => user.id == order.soldById),
                    description: order.description,
                    customer: order.customerOrigin ? order.customerOrigin : null,
                    priceListName: this.priceLists.find(priceList => priceList.id == order.priceListId)?.name,
                    saleChannelName: this.saleChannels.find(channel => channel.id == order.channelId)?.name,
                    order_items: listProducts,
                    totalOrigin: order.totalPrice,
                    discountValue: order.discountValue,
                    surchargeAmount: order.surchargeAmount,
                    promotion: promotion,
                    total: order.totalPriceAfterDiscount,
                    cash: order.cash,
                    card: order.card,
                    transfer: order.transfer,
                    discountRatio: order.discountRatio,
                    user_created: store.state.Auth.user,
                    typePrint: this.$constant.TypePrintOrder,
                    promotionValue: promotionValue,
                    voucher_detail: voucher_detail,
                    coupon_detail: coupon_detail,
                    totalPaymentOrigin: totalPaymentOrigin,
                    valueVoucher: order.valueVoucher,
                    valueCoupon: order.valueCoupon
                }
                console.log('currentOrder', currentOrder);
                return currentOrder;
            },
            formatCustomerGroups(groups) {
                let groupName = null;
                for (let index = 0; index < groups.length; index++) {
                    const group = groups[index];
                    groupName =  groupName ? groupName + ', ' + group.name : group.name ;
                }
                return groupName;
            },
            formatProductsPrint(products, type) {
                let newProducts = [];
                let countProduct = 0;
                for (let index = 0; index < products.length; index++) {
                    const product = products[index];
                    product.product_unit = product.productUnit;
                    product.attributeName = (product.attributesActive && product.attributesActive.length) ? (product.attributesActive.find(attribute => attribute.id == product.variantId)?.name) : '';
                    product.amount = product.amount * product.quantity;
                    product.subTotal = product.price * product.quantity;
                    if (type == 'invoice') {
                        product.consignments = product.consignmentActive;
                    } else  {
                        product.consignments = null;
                    }
                    countProduct = countProduct + +product.quantity;
                    newProducts.push(product);
                    if (product.plusOrder && product.plusOrder.length) {
                        for (let index = 0; index < product.plusOrder.length; index++) {
                            const productPlus = product.plusOrder[index];
                            let consignments = null;
                            if (type == 'invoice') {
                                consignments = productPlus.consignmentActive;
                            }
                            countProduct = countProduct + +productPlus.quantity;
                            newProducts.push({
                                name: product.name,
                                code: product.code,
                                subTotal: productPlus.price * productPlus.quantity,
                                product_unit: product.product_units.find(productUnit => productUnit.id == productPlus.productUnitId),
                                discountValue: productPlus.discountValue,
                                price: productPlus.price,
                                amount: productPlus.amount * productPlus.quantity,
                                quantity: productPlus.quantity,
                                discountRatio: productPlus.discountRatio ? productPlus.discountRatio : 0,
                                hsCode: product.hsCode,
                                // product_unit_main: product.product_units ? product.product_units[0]: null,
                                activeIngredient: product.activeIngredient,
                                categories: product.categories,
                                attributeName: (productPlus.attributesActive && productPlus.attributesActive.length) ? (productPlus.attributesActive.find(attribute => attribute.id == productPlus.variantId)?.name) : '',
                                consignments: consignments,
                                manufacturer: product.manufacturer,
                                country: product.country,
                                unit: product.unit,
                                description: product.description,
                                taxRate: product.taxRate,
                            })
                        }
                    }
                }
                return {
                    listProducts: newProducts, 
                    countQuantity: countProduct,
                };
            },
            async print(entry) {
                let dataPrint = await this.dataPrint(entry);  
                this.editorDataClone = await this.renderData(dataPrint, entry.typePrint);
                this.$refs.PreviewTemplate.multiplePrint(this.editorDataClone);   
            },
            showHide(){
                this.hiddenArrowUp = !this.hiddenArrowUp;
            },
            async loadSetting(){
                const res = await $get('/v1/setting-store/get-options', {action: 'transporter'});
                Object.keys(this.statusOption).forEach(key => {
                    this.statusOption[key] = false;
                });
                res.result.forEach(element => {
                    if (element.id == this.$constant.ahamove_option_id && element.status == this.$constant.active) {
                        this.statusOption.ahamove = true;
                    }
                    if (element.id == this.$constant.ghn_option_id && element.status == this.$constant.active) {
                        this.statusOption.ghn = true;
                    }
                    if (element.id == this.$constant.vtp_option_id && element.status == this.$constant.active) {
                        this.statusOption.vtp = true;
                    }
                });
            },
            updateAttributes(product, variantId) {
                if (!product.attributesActive || !product.attributesActive.length || !variantId) return;
                let variant = product.attributes.find(el => (el.id == variantId));
                product.price = variant ? variant.price : 0;
                if (product.consignmentActive) {
                    product.consignmentActive.forEach(element => {
                        product.consignments.forEach(consignment => {
                            let consignmentProductBranchMeta = consignment.consignment_product_branch_metas.find(item => (item.product_branch_meta.productUnitId == variantId && item.consignmentId == element.consignmentId));
                            if (consignmentProductBranchMeta) {
                                element.total_sales = consignmentProductBranchMeta.total_sales;
                            }
                        });
                    });
                }
            },
            showDiscountForm($event){
                let top = $event.clientY ?? 0;
                this.$refs.orderDiscount.showDiscountForm(top, true);
            },
            onEstimatedFeesAhamove: debounce(async () => {
                app.getETAhamove();
            }),
            onEstimatedFeesGiaoHangNhanh: debounce(async () => {
                app.getETGiaoHangNhanh();
            }),
            onEstimatedFeesViettelPost: debounce (async () => {
                app.getETViettelPost();
            }),
            onGetLevelBulkyUseAhamove: debounce(async () => {
                app.getLevelBulky();
            }),
            addAmountShipping(amount) {
                this.entry.total_price = amount;
            },
            clearInfosShipping(){
                this.entry.serviceId = "";
                this.entry.requests = [];
                // this.entry.item_value = 0;
                this.entry.total_price = 0;
                this.entry.transporterId = null;
            },
            toggleShippingMode(mode){
                this.entry.shippingMode = mode;
            },
            toggleExtendScreen(){
                this.isExtend = !this.isExtend;
            },
            showModalCustomerPaid(){
                this.$refs.ModalCustomerPaid.show(this.entry);
            },
            connectTransporter(type){
                switch (type) {
                    case this.$constant.typeAhaMove:
                        this.$refs.ModalConnectTransporter.show();
                        break;
                    case this.$constant.typeGHN:
                        this.$refs.ModalConnectGHN.show();
                        break;
                    case this.$constant.typeVTP:
                        this.$refs.ModalConnectVTP.show();
                        break;
                }
            },
            showTaxRate(){                
                this.isTaxRate = !this.isTaxRate;
            },
            showHsCode(){                
                this.isHsCode = !this.isHsCode;
            },
            async changeOptionConsignment(){
                this.hasConsignment = !this.hasConsignment;
                this.entry.consignment = this.hasConsignment;
            },
            async changeOptionStamp(){
                this.hasStamp = !this.hasStamp;
                if(this.hasStamp && this.entry){
                    this.entry.products.forEach(product => {
                        if(!product.stamp){
                            product.stamp = this.$constant.statusHasStamp;
                        }
                        if(product.plusOrder && product.plusOrder.length){
                            product.plusOrder.forEach(plusItem => {
                                if(!plusItem.stamp){
                                    plusItem.stamp = this.$constant.statusHasStamp;
                                }
                            });
                        }
                    });
                }
                this.entry.stamp = this.hasStamp;
            },
            async updateConsignment(data){
                if(this.entry.products){
                    let productIndex = this.entry.products.findIndex(prod => prod.id == data.object.id);
                    if(data.data?.length == 0 && data.defaultValue){
                        this.entry.products[productIndex].consignmentActive[data.index] = [];
                        return;
                    }
                    data.data.forEach(consignment => {
                        
                        let totalQuantity = 0;
                        this.entry.products[productIndex].consignmentActive.forEach(el => {
                            if(el.consignmentId == consignment.id){
                                totalQuantity += el.quantity;
                            }
                        })
                        let productUnitId = data.object.productUnitId;
                        let consignmentProductBranchMeta = consignment.consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == productUnitId);
                        
                        let newConsignment = {
                            consignmentProductBranchMetaId: consignmentProductBranchMeta.id,
                            consignmentId: consignmentProductBranchMeta.consignmentId,
                            quantity: consignment.quantity,
                            name: consignment.name,
                            expried: consignment.expried,
                            key: data.defaultValue.key,
                            productUnitId: productUnitId,
                            total_sales: consignment.total_sales ?? null
                            // quantityRemaining: 
                        };
                        if(this.entry.products[productIndex].consignmentActive?.length == 1 && this.entry.products[productIndex].consignmentActive[0].consignmentId == null){
                            this.entry.products[productIndex].consignmentActive[0] = newConsignment;
                        }else{
                            this.entry.products[productIndex].consignmentActive[data.index] = newConsignment;
                        }
                    });
                }
            },
            async updateConsignmentProductPlus(data){
                let productIndex = this.entry.products.findIndex(prod => prod.id == data.object.parentId);
                if(data.data?.length == 0 && data.defaultValue){
                    // const consignmentIndex = this.entry.products[productIndex].plusOrder[data.plusIndex].consignmentActive.findIndex(v => v.consignmentId == data.defaultValue.consignmentId);
                    this.entry.products[productIndex].plusOrder[data.plusIndex].consignmentActive[data.index].consignmentId = null;
                    return;
                }
                data.data.forEach(consignment => {
                    let totalQuantity = 0;
                    this.entry.products[productIndex].plusOrder[data.plusIndex].consignmentActive.forEach(el => {
                        if(el.consignmentId == consignment.id){
                            totalQuantity += el.quantity;
                        }
                    })
                    let productUnitId = data.object.productUnitId;
                    let consignmentProductBranchMeta = consignment.consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == productUnitId);
                    let newConsignment = {
                        consignmentProductBranchMetaId: consignmentProductBranchMeta.id,
                        consignmentId: consignmentProductBranchMeta.consignmentId,
                        quantity: totalQuantity < consignmentProductBranchMeta.inventories ? consignment.quantity : consignmentProductBranchMeta.inventories,
                        name: consignment.name,
                        expried: consignment.expried,
                        productUnitId: productUnitId,
                        total_sales: consignment.total_sales
                    };
                    if(this.entry.products[productIndex].plusOrder[data.plusIndex].consignmentActive?.length == 1 && this.entry.products[productIndex].plusOrder[data.plusIndex].consignmentActive[0].consignmentId == null){
                        this.entry.products[productIndex].plusOrder[data.plusIndex].consignmentActive[0] = newConsignment;

                    }else{
                        let consignmentIndex = this.entry.products[productIndex].plusOrder[data.plusIndex].consignmentActive.findIndex(consignmentActive => consignmentActive.consignmentId == data.defaultValue.consignmentId);
                        if(consignmentIndex > -1){
                            this.entry.products[productIndex].plusOrder[data.plusIndex].consignmentActive[data.index] = newConsignment;
                        }
                    }
                });
            },

            // Cập nhật lại lô date của sản phẩm được break để trả
            async updateConsignmentProductReturn(data){
                if(data.data?.length == 0 && data.defaultValue){
                    this.entry.products[data.index].plusReturn[data.plusIndex].consignmentActive[data.indexConsigmentActive].consignmentId = null;
                    return;
                }
                data.data.forEach(consignment => {
                    let productUnitId = data.object.productUnitId;
                    let consignmentProductBranchMeta = consignment.consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == productUnitId);
                    let newConsignment = {
                        consignmentProductBranchMetaId: consignmentProductBranchMeta.id,
                        consignmentId: consignmentProductBranchMeta.consignmentId,
                        quantity: consignment.quantity,
                        name: consignment.name,
                        productUnitId: productUnitId,
                    };
                    this.entry.products[data.index].plusReturn[data.plusIndex].consignmentActive[data.indexConsigmentActive] = newConsignment;
                });
                // this.entry.products[data.index].plusReturn[data.plusIndex].consignmentActive[data.indexConsigmentActive] = data.data;
            },

            async updateConsignmentProductPlusProduct (data) {
                // if(this.entry.products){
                    if(data.data?.length == 0 && data.defaultValue){
                        this.entry.products[data.index].plusOrder[data.plusIndex].combo_products[data.indexComboProducts].product.consignmentActive[0].consignmentId = null;
                        return;
                    }
                    data.data.forEach(consignment => {
                        let productUnitId = data.object.productUnitId;
                        let consignmentProductBranchMeta = consignment.consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == productUnitId);
                        let newConsignment = {
                            consignmentProductBranchMetaId: consignmentProductBranchMeta.id,
                            consignmentId: consignmentProductBranchMeta.consignmentId,
                            quantity: consignment.quantity,
                            name: consignment.name,
                            expried: consignment.expried,
                            key: data.defaultValue.key,
                            productUnitId: productUnitId,
                        };
                        if(this.entry.products[data.index].plusOrder[data.plusIndex].combo_products[data.indexComboProducts].product.consignmentActive?.length == 1 && this.entry.products[data.index].plusOrder[data.plusIndex].combo_products[data.indexComboProducts].product.consignmentActive[0].consignmentId == null){
                            this.entry.products[data.index].plusOrder[data.plusIndex].combo_products[data.indexComboProducts].product.consignmentActive[0] = newConsignment;
                        }else{
                            this.entry.products[data.index].plusOrder[data.plusIndex].combo_products[data.indexComboProducts].product.consignmentActive[data.indexConsigmentActive] = newConsignment;
                        }
                    });
                // }
            },
            async updateConsignmentProductGift(data){
                let productIndex = this.entry.productGifts.findIndex(prod => prod.productUnitId == data.object.productUnitId);
                if(data.data?.length == 0 && data.defaultValue){
                    const consignmentIndex = this.entry.productGifts[productIndex].consignmentActive.findIndex(v => v.key == data.defaultValue.key);
                    this.entry.productGifts[productIndex].consignmentActive[consignmentIndex].consignmentId = null;
                    this.entry.productGifts[productIndex].consignmentActive[consignmentIndex].quantity = 0;
                    this.setMaxQuantityProductGift(productIndex, this.entry.productGifts[productIndex].maxQuantity);
                    return;
                }
                data.data.forEach(consignment => {
                    let totalQuantity = 0;
                    if (!this.entry.productGifts[productIndex].consignmentActive) {
                        this.entry.productGifts[productIndex].consignmentActive = [];
                    }
                    let maxQuantity = this.entry.productGifts[productIndex].maxQuantity;
                    let quantityActive = 0
                    this.entry.productGifts[productIndex].consignmentActive.forEach((el , index) => {
                        if(el.consignmentId == consignment.id){
                            totalQuantity += el.quantity;
                        } else {
                            quantityActive = +quantityActive + (el.quantity ?? 0);
                        }
                    })
                    let productUnitId = data.object.productUnitId;
                    let consignmentProductBranchMeta = consignment.consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == productUnitId);
                    let newConsignment = {
                        consignmentProductBranchMetaId: consignmentProductBranchMeta.id,
                        consignmentId: consignmentProductBranchMeta.consignmentId,
                        quantity: totalQuantity < consignmentProductBranchMeta.inventories ? consignment.quantity : consignmentProductBranchMeta.inventories,
                        name: consignment.name,
                        expried: consignment.expried,
                        key: data.defaultValue.key,
                        productUnitId: productUnitId,
                    };
                    if(this.entry.productGifts[productIndex].consignmentActive?.length == 1 && this.entry.productGifts[productIndex].consignmentActive[0].consignmentId == null){
                        this.entry.productGifts[productIndex].consignmentActive[0] = newConsignment;
                    }else{
                        let consignmentIndex = this.entry.productGifts[productIndex].consignmentActive.findIndex(consignmentActive => consignmentActive.key == data.defaultValue.key);
                        if(consignmentIndex > -1){
                            this.entry.productGifts[productIndex].consignmentActive[consignmentIndex] = newConsignment;
                        }
                    }
                    this.setMaxQuantityProductGift(productIndex, maxQuantity);
                });
            },
            async updateConsignmentProductDiscount(data){
                let productIndex = this.entry.productDiscount.findIndex(prod => prod.productUnitId == data.object.productUnitId);
                if(data.data?.length == 0 && data.defaultValue){
                    const consignmentIndex = this.entry.productDiscount[productIndex].consignmentActive.findIndex(v => v.key == data.defaultValue.key);
                    this.entry.productDiscount[productIndex].consignmentActive[consignmentIndex].consignmentId = null;
                    this.entry.productDiscount[productIndex].consignmentActive[consignmentIndex].quantity = 0;
                    this.setMaxQuantityProductDiscount(productIndex, this.entry.productDiscount[productIndex].maxQuantity);
                    return;
                }
                data.data.forEach(consignment => {
                    let totalQuantity = 0;
                    if (!this.entry.productDiscount[productIndex].consignmentActive) {
                        this.entry.productDiscount[productIndex].consignmentActive = [];
                    }

                    let maxQuantity = this.entry.productDiscount[productIndex].maxQuantity;
                    let quantityActive = 0
                    this.entry.productDiscount[productIndex].consignmentActive.forEach((el , index) => {
                        if(el.consignmentId == consignment.id){
                            totalQuantity += el.quantity;
                        } else {
                            quantityActive = +quantityActive + (el.quantity ?? 0);
                        }
                    })
                    let productUnitId = data.object.productUnitId;
                    let consignmentProductBranchMeta = consignment.consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == productUnitId);
                    let newConsignment = {
                        consignmentProductBranchMetaId: consignmentProductBranchMeta.id,
                        consignmentId: consignmentProductBranchMeta.consignmentId,
                        quantity: totalQuantity < consignmentProductBranchMeta.inventories ? consignment.quantity : consignmentProductBranchMeta.inventories,
                        name: consignment.name,
                        expried: consignment.expried,
                        key: data.defaultValue.key,
                        productUnitId: productUnitId,
                    };
                    if(this.entry.productDiscount[productIndex].consignmentActive?.length == 1 && this.entry.productDiscount[productIndex].consignmentActive[0].consignmentId == null){
                        this.entry.productDiscount[productIndex].consignmentActive[0] = newConsignment;
                    }else{
                        let consignmentIndex = this.entry.productDiscount[productIndex].consignmentActive.findIndex(consignmentActive => consignmentActive.key == data.defaultValue.key);
                        if(consignmentIndex > -1){
                            this.entry.productDiscount[productIndex].consignmentActive[consignmentIndex] = newConsignment;
                        }
                    }
                    this.setMaxQuantityProductDiscount(productIndex, maxQuantity);
                });
            },
            async updateConsignmentComboProducts(data){
                if(this.entry.products){
                    if(data.data?.length == 0 && data.defaultValue){
                        this.entry.products[data.index].combo_products[data.plusIndex].product.consignmentActive[0].consignmentId = null;
                        return;
                    }
                    data.data.forEach(consignment => {
                        let productUnitId = data.object.productUnitId;
                        let consignmentProductBranchMeta = consignment.consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == productUnitId);
                        let newConsignment = {
                            consignmentProductBranchMetaId: consignmentProductBranchMeta.id,
                            consignmentId: consignmentProductBranchMeta.consignmentId,
                            quantity: consignment.quantity,
                            name: consignment.name,
                            expried: consignment.expried,
                            key: data.defaultValue.key,
                            productUnitId: productUnitId,
                        };
                        if(this.entry.products[data.index].combo_products[data.plusIndex].product.consignmentActive?.length == 1 && this.entry.products[data.index].combo_products[data.plusIndex].product.consignmentActive[0].consignmentId == null){
                            this.entry.products[data.index].combo_products[data.plusIndex].product.consignmentActive[0] = newConsignment;
                        }else{
                            this.entry.products[data.index].combo_products[data.plusIndex].product.consignmentActive[data.indexComboProducts] = newConsignment;
                        }
                    });
                }
            },
            setMaxQuantityProductGift(productIndex, maxQuantity){
                if (this.entry.productGifts[productIndex] && this.entry.productGifts[productIndex].consignmentActive && this.entry.productGifts[productIndex].consignmentActive.length) {
                    this.entry.productGifts[productIndex].consignmentActive.forEach(el => {
                        const getQuantity = this.entry.productGifts[productIndex].consignmentActive.reduce(function(sum, record) {
                            return (record.key != el.key) ? sum + (record.quantity ? record.quantity : 0) : sum;
                        }, 0);
                        el.maxQuantity = (maxQuantity - getQuantity) < 0 ? 0 : (maxQuantity - getQuantity);
                    })
                }
            },
            setMaxQuantityProductDiscount(productIndex, maxQuantity) {
                if (this.entry.productDiscount[productIndex] && this.entry.productDiscount[productIndex].consignmentActive && this.entry.productDiscount[productIndex].consignmentActive.length) {
                    this.entry.productDiscount[productIndex].consignmentActive.forEach(el => {
                        const getQuantity = this.entry.productDiscount[productIndex].consignmentActive.reduce(function(sum, record) {
                            return (record.key != el.key) ? sum + (record.quantity ? record.quantity : 0) : sum;
                        }, 0);
                        el.maxQuantity = (maxQuantity - getQuantity) < 0 ? 0 : (maxQuantity - getQuantity);
                    })
                }
            },
            addConsignment(productIndex, productPlusIndex = null, productGiftIndex = null, productDiscountIndex = null){
                if(productPlusIndex !== null){
                    var maxKey = this.entry.products[productIndex].plusOrder[productPlusIndex].consignmentActive.reduce((acc, value) => {
                        return (acc = acc > (value.key ? value.key : 1) ? acc : (value.key ? value.key : 1));
                    }, 0);
                    if(this.entry.products[productIndex].plusOrder[productPlusIndex].consignmentActive == undefined){
                        this.entry.products[productIndex].plusOrder[productPlusIndex].consignmentActive = [];
                    }
                    this.entry.products[productIndex].plusOrder[productPlusIndex].consignmentActive.push({
                        key: maxKey + 1,
                        consignmentId: null,
                    });
                } else if (productGiftIndex !== null) {
                    if(this.entry.productGifts[productGiftIndex].consignmentActive == undefined){
                        this.entry.productGifts[productGiftIndex].consignmentActive = [];
                    }
                    var maxKey1 = this.entry.productGifts[productGiftIndex].consignmentActive.reduce((acc, value) => {
                        return (acc = acc > (value.key ? value.key : 1) ? acc : (value.key ? value.key : 1));
                    }, 0);
                    let maxQuantity = this.entry.productGifts[productGiftIndex].quantity;
                    let totalQuantity = 0;
                    this.entry.productGifts[productGiftIndex].consignmentActive.forEach(consignment => {
                        totalQuantity += consignment.quantity ? consignment.quantity : 0;
                    })
                    this.entry.productGifts[productGiftIndex].consignmentActive.push({
                        key: maxKey1 + 1,
                        consignmentId: null,
                        quantity: 0,
                        maxQuantity: (maxQuantity - totalQuantity) < 0 ? 0 : (maxQuantity - totalQuantity),
                    });
                }
                else if (productDiscountIndex !== null) {
                    if(this.entry.productDiscount[productDiscountIndex].consignmentActive == undefined){
                        this.entry.productDiscount[productDiscountIndex].consignmentActive = [];
                    }
                    var maxKey3 = this.entry.productDiscount[productDiscountIndex].consignmentActive.reduce((acc, value) => {
                        return (acc = acc > (value.key ? value.key : 1) ? acc : (value.key ? value.key : 1));
                    }, 0);
                    let maxQuantity = this.entry.productDiscount[productDiscountIndex].quantity;
                    let totalQuantity = 0;
                    this.entry.productDiscount[productDiscountIndex].consignmentActive.forEach(consignment => {
                        totalQuantity += consignment.quantity ? consignment.quantity : 0;
                    })
                    this.entry.productDiscount[productDiscountIndex].consignmentActive.push({
                        key: maxKey3 + 1,
                        consignmentId: null,
                        quantity: 0,
                        maxQuantity: (maxQuantity - totalQuantity) < 0 ? 0 : (maxQuantity - totalQuantity),
                    });
                }
                else{
                    var maxKey2 = this.entry.products[productIndex].consignmentActive.reduce((acc, value) => {
                        return (acc = acc > (value.key ? value.key : 1) ? acc : (value.key ? value.key : 1));
                    }, 0);
                    this.entry.products[productIndex].consignmentActive.push({
                        key: maxKey2 + 1,
                        consignmentId: null,
                    });
                }
            },
            addConsignmentPlusReturn(productIndex, productPlusReturnIndex){
                if(this.entry.products[productIndex].plusReturn[productPlusReturnIndex].consignmentActive == undefined){
                    this.entry.products[productIndex].plusReturn[productPlusReturnIndex].consignmentActive = [];
                }
                this.entry.products[productIndex].plusReturn[productPlusReturnIndex].consignmentActive.push({
                    consignmentId: null,
                    quantity: null,
                });
            },
            addConsignmentCombo (productIndex, comboIndex, plusComboIndex = null) {          
                if (plusComboIndex != null) {
                    var maxKey = this.entry.products[productIndex].plusOrder[plusComboIndex].combo_products[comboIndex].product.consignmentActive.reduce((acc, value) => {
                        return (acc = acc > (value.key ? value.key : 1) ? acc : (value.key ? value.key : 1));
                    }, 0);
                    if(this.entry.products[productIndex].plusOrder[plusComboIndex].combo_products[comboIndex].product.consignmentActive == undefined){
                        this.entry.products[productIndex].plusOrder[plusComboIndex].combo_products[comboIndex].product.consignmentActive = [];
                    }
                    else{
                        this.entry.products[productIndex].plusOrder[plusComboIndex].combo_products[comboIndex].product.consignmentActive.push({
                            key: maxKey + 1,
                            consignmentId: null,
                        });
                    }
                } else {
                    var maxKey1 = this.entry.products[productIndex].combo_products[comboIndex].product.consignmentActive.reduce((acc, value) => {
                        return (acc = acc > (value.key ? value.key : 1) ? acc : (value.key ? value.key : 1));
                    }, 0);
                    if(this.entry.products[productIndex].combo_products[comboIndex].product.consignmentActive == undefined){
                        this.entry.products[productIndex].combo_products[comboIndex].product.consignmentActive = [];
                    }
                    else{
                        this.entry.products[productIndex].combo_products[comboIndex].product.consignmentActive.push({
                            key: maxKey1 + 1,
                            consignmentId: null,
                        });
                    }
                }
            },
            choosePromotion(promotion) {
                if (!promotion) {
                    if (this.entry.promotion && this.entry.promotion.promotionById == this.$constant.promotionProductId){
                        this.clearDataProduct();
                    }
                    this.clearPromotion();
                    return;
                }
                this.clearPromotion();
                if (promotion && promotion.promotionById == this.$constant.promotionInvoiceId) {
                    this.applyPromotionForInvoice(promotion);    
                }
                if (promotion && promotion.promotionById == this.$constant.promotionProductId) {
                    this.applyPromotionForProduct(promotion);
                }
                if (promotion && promotion.promotionById == this.$constant.promotionAllId) {
                    this.applyPromotionForInvoiceAndProduct(promotion);
                }
            },
            clearDataProduct() {
                this.getPriceProductForPiceList();
            },
            applyPromotionForInvoiceAndProduct(promotion) {
                this.entry.promotion = {
                    id: promotion.id,
                    name: promotion.name,
                    promotionOptionId: promotion.promotion_options.id,
                    promotionOptionChildId: promotion.promotionOptionChildId ?? null,
                    promotionById : promotion.promotionById ?? null,
                    receiveProductUnit: promotion.receiveProductUnit,
                };
                if (promotion.promotionFromId == this.$constant.promotion_all_invoice_discount) {
                    this.setDiscountPrice(promotion);
                } else if (promotion.promotionFromId == this.$constant.promotion_all_donate_product) {
                    this.setProductGift(promotion)
                } else if (promotion.promotionFromId == this.$constant.promotion_all_product_discount) {
                    this.setProductDiscount(promotion);
                }  
            },
            setProductGift(promotion) {
                this.entry.productGifts = [];
                promotion.receiveProductUnit.forEach(productUnit => {
                    if(productUnit.quantity){
                        let productGift = {
                            productId: productUnit.product.id,
                            productName: productUnit.product.name,
                            productCode: productUnit.product.code,
                            productUnitId: productUnit.productUnitId ?? productUnit.id,
                            productUnitText: productUnit.unitName,
                            quantity: productUnit.quantity,
                            discount: null,
                            priceOrigin: productUnit.price,
                            discountType: null,
                            discountValue: null,
                            maxQuantity: productUnit.quantity,
                            amount: 0,
                            consignments: productUnit.product.consignments ?? productUnit.consignments,
                            isConsignment: productUnit.product.isConsignment,
                        };
                        if (productGift.isConsignment) {
                            if (productGift.consignments && productGift.consignments.length) {
                                productGift.consignmentActive = [];
                                let newConsignment = {
                                    key: 1,
                                    consignmentProductBranchMetaId: productGift.consignments[0].consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == productGift.productUnitId).id,
                                    consignmentId: productGift.consignments[0].id,
                                    quantity: productUnit.quantity,
                                    maxQuantity: productUnit.quantity,
                                    name: productGift.consignments[0].name,
                                    expried: productGift.consignments[0].expried,
                                    productUnitId: productUnit.id,
                                };
                                productGift.consignmentActive[0] = newConsignment;
                                this.entry.productGifts.push(productGift);
                            } else {
                                toastr.error(this.$t('message.common.notEnoughInventory', {name: productGift.productName}));
                            }
                        }
                    }
                });
                if (!this.entry.productGifts.length) {               
                    this.entry.promotion = null;
                }
            },
            setDiscountPrice(promotion) {
                if (promotion.promotion_options.discountValue && promotion.promotion_options.discountValue != this.$constant.priceZero) {
                    this.entry.promotion.discountValue = promotion.promotion_options.discountValue;
                } else if (promotion.promotion_options.discountRatio) {
                    this.entry.promotion.discountRatio = promotion.promotion_options.discountRatio;
                }
            },
            setProductDiscount(promotion) {
                this.entry.productDiscount = [];
                promotion.receiveProductUnit.forEach(productUnit => {
                    if(productUnit.quantity){
                        let discountValue = 0;
                        let discountRatio = null;
                        if (promotion.promotion_options.discountValue != this.$constant.priceZero && promotion.promotion_options.discountValue) {
                            discountValue = promotion.promotion_options.discountValue;
                        } else if (promotion.promotion_options.discountRatio) {
                            discountValue = promotion.promotion_options.discountRatio*productUnit.price/100;
                            discountRatio = promotion.promotion_options.discountRatio;
                        }
                        let productDiscount = {
                            productId: productUnit.product.id,
                            productName: productUnit.product.name,
                            productCode: productUnit.product.code,
                            productUnitId: productUnit.id,
                            productUnitText: productUnit.unitName,
                            quantity: productUnit.quantity,
                            maxQuantity: productUnit.quantity,
                            discount: null,
                            priceOrigin: productUnit.price,
                            discountType: null,
                            discountValue: discountValue,
                            discountRatio: discountRatio,
                            amount: (+productUnit.price - +discountValue) > 0 ? (+productUnit.price - +discountValue)*productUnit.quantity : 0,
                            valuePercent: promotion.promotion_options.discountRatio,
                            isConsignment: productUnit.product.isConsignment,
                        };
                        if (productDiscount.isConsignment) {
                            if (productUnit.product.consignments && productUnit.product.consignments.length) {
                                let newConsignment = {
                                    key: 1,
                                    consignmentProductBranchMetaId: productUnit.product.consignments[0].consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == productUnit.id).id,
                                    consignmentId: productUnit.product.consignments[0].id,
                                    quantity: productUnit.quantity,
                                    maxQuantity: productUnit.quantity,
                                    name: productUnit.product.consignments[0].name,
                                    expried: productUnit.product.consignments[0].expried,
                                    productUnitId: productUnit.id,
                                };
                                productDiscount.consignmentActive = [newConsignment];
                                productDiscount.consignments = productUnit.product.consignments;
                                this.entry.productDiscount.push(productDiscount);
                            } else {
                                toastr.error(this.$t('message.common.notEnoughInventory', {name: productDiscount.productName}));
                                return false;
                            }
                        }
                    }
                });
                if (!this.entry.productDiscount.length) {                    
                    this.entry.promotion = null;
                }
            },
            applyPromotionForInvoice(promotion) {
                this.entry.promotion = {
                    id: promotion.id,
                    name: promotion.name,
                    promotionOptionId: promotion.promotion_options.id,
                    promotionOptionChildId: promotion.promotionOptionChildId ?? null,
                    promotionById: promotion.promotionById ?? null,
                    receiveProductUnit: promotion.receiveProductUnit,
                };
                if (promotion.promotionFromId == this.$constant.promotion_invoice_discount) {
                    if (promotion.promotion_options.discountValue && !promotion.promotion_options.discountRatio) {
                        this.entry.promotion.discountValue = promotion.promotion_options.discountValue;
                    } else if (promotion.promotion_options.discountRatio) {
                        this.entry.promotion.discountRatio = promotion.promotion_options.discountRatio;
                    }
                } else if (promotion.promotionFromId == this.$constant.promotion_invoice_donate_product) {
                    this.setProductGift(promotion);
                } else if (promotion.promotionFromId == this.$constant.promotion_invoice_discount_on_product) {
                    this.setProductDiscount(promotion);
                }
            },

            // Quân làm hàng hóa khuyến mại
            applyPromotionForProduct (promotion) {
                this.entry.promotion = {
                    id: promotion.id,
                    name: promotion.name,
                    promotionOptionId: promotion.promotion_options.id,
                    promotionOptionChildId: promotion.promotionOptionChildId ?? null,
                    promotionById: promotion.promotionById ?? null,
                    receiveProductUnit: promotion.receiveProductUnit,
                    clonePromotion: promotion,
                };
                if (promotion.promotionFromId == this.$constant.promotion_product_discount) {
                    this.setProductDiscount(promotion);
                } 
                else if (promotion.promotionFromId == this.$constant.promotion_product_donate_product) {
                    this.setProductGift(promotion);
                } else if (promotion.promotionFromId == this.$constant.promotion_product_allow_quantity) {
                    this.entry.products.forEach(product => {
                        if (product.productUnitId == promotion.productUnitId) {
                            let originPrice = !product.originPrice ? product.price : product.originPrice; 
                            product.originPrice = originPrice;
                            if (promotion.promotion_options.whenBuying.discountBy == 1) {
                                product.price = promotion.promotion_options.whenBuying.discountValue;
                            }
                            else {
                                if (promotion.promotion_options.whenBuying.discountValue && !promotion.promotion_options.whenBuying.discountRatio) {
                                    product.price = product.originPrice - promotion.promotion_options.whenBuying.discountValue;
                                } 
                                if (promotion.promotion_options.whenBuying.discountRatio) {
                                    product.price = product.originPrice - (product.originPrice * (promotion.promotion_options.whenBuying.discountRatio/100));
                                }
                            }
                        }
                    });
                }
            },
            removeCoupon() {
                this.entry.couponCode = null;
                this.entry.isShowCodeActive = false;
                this.entry.valueCoupon = 0;
                this.entry.couponDetailId = null;
            },
            removeVoucher(index, value, id) {
                this.entry.infoVouchers.splice(index, 1);
                this.entry.valueVoucher = +this.entry.valueVoucher - +value;
                var i = this.entry.voucherDetailIds.indexOf(id);
                if (i !== -1) {
                    this.entry.voucherDetailIds.splice(i, 1);
                }
            },
            removeAllVoucher(){
                this.entry.infoVouchers = [];
                this.entry.valueVoucher = 0;
                this.entry.voucherDetailIds = [];
            },
            async getVoucher() {
                if (!this.entry.products || !this.entry.products.length) {
                    $alert({code: 500, message: this.$t('message.common.emptyOrder')});
                    return;
                }
                const res = await $post('/v1/vouchers/get-voucher-active', {
                    totalPrice: this.entry.totalPrice,
                    voucherCode: this.entry.voucherCode,
                    productUnitIds: this.productUnitIds,
                    categoriesIds: this.categoriesIds,
                    orderId: this.entry.orderId,
                    invoiceId: this.entry.invoiceId,
                    branchId: store.state.CurrentBranch.id,
                    userId: this.entry.soldById,
                    customerGroupId: this.groupIds,
                });
                this.entry.voucherCode = null;
                if (res.result && !res.result.status) {
                    $alert({code: 500, message: this.$t('message.common.cannotApplyVoucher')});
                    return;
                }
                if (!Array.isArray(this.entry.infoVouchers)) {
                    this.entry.infoVouchers = [];
                }
                if (!Array.isArray(this.entry.voucherDetailIds)) {
                    this.entry.voucherDetailIds = [];
                }
                if (this.entry.voucherDetailIds.includes(res.result.voucherActive.id)) {
                    $alert({code: 500, message: this.$t('message.common.usedVoucher')});
                    return
                }
                
                if (!res.result.voucherActive.coupon_voucher.applyMultipleVoucher) {
                    for (let index = 0; index < this.entry.infoVouchers.length; index++) {
                        const info = this.entry.infoVouchers[index];
                        if (info.voucherId == res.result.voucherActive.coupon_voucher.id) {
                            $alert({code: 500, message: this.$t('message.common.cannotApplyVouchers')});
                            return;
                        }
                    }
                }
                this.entry.infoVouchers.push({
                    code: res.result.voucherActive.code,
                    value: +res.result.voucherActive.coupon_voucher.value,
                    id: res.result.voucherActive.id,
                    voucherId: res.result.voucherActive.coupon_voucher.id,
                    applyMultipleVoucher: res.result.voucherActive.coupon_voucher.applyMultipleVoucher,
                });
                this.entry.valueVoucher = (this.entry.valueVoucher ?? 0) + +res.result.voucherActive.coupon_voucher.value;
                this.entry.voucherDetailIds.push(res.result.voucherActive.id);
            },
            async getCoupon() {
                if (!this.isUseCoupon) {
                    await this.removeCoupon();
                    return;
                }
                if (!this.entry.products || !this.entry.products.length) {
                    $alert({code: 500, message: this.$t('message.common.emptyOrder')});
                    return;
                }
                const res = await $post('/v1/coupons/get-coupon-active', {
                    totalPrice: this.entry.totalPrice,
                    couponCode: this.entry.couponCode,
                    productUnitIds: this.productUnitIds,
                    categoriesIds: this.categoriesIds,
                    orderId: this.entry.orderId,
                });
                if (res.result && !res.result.status) {
                    $alert({code: 500, message: this.$t('message.common.cannotApplyCoupon')});
                    return;
                }
                this.setEntry(res);
            },
            async checkCouponActive() {
                if (!this.entry.couponDetailId) return;
                if (this.entry.type == TYPE_RETURN) return;
                if (!this.isUseCoupon) {
                    await this.removeCoupon();
                    return;
                }
                const res = await $post('/v1/coupons/get-coupon-active', {
                    totalPrice: this.entry.totalPrice,
                    productUnitIds: this.productUnitIds,
                    categoriesIds: this.categoriesIds,
                    orderId: this.entry.orderId,
                    invoiceId: this.entry.invoiceId,
                    couponId: this.entry.couponDetailId,
                });
                if (res.result && !res.result.status) {
                    await this.removeCoupon();
                    return;
                }
                this.setEntry(res);
            },
            async checkVoucherActive () {
                if (this.entry.type == TYPE_RETURN) return;
                if (!this.entry.valueVoucher) {
                    return;
                }
                const res = await $post('/v1/vouchers/get-voucher-active', {
                    totalPrice: this.entry.totalPrice,
                    productUnitIds: this.productUnitIds,
                    categoriesIds: this.categoriesIds,
                    orderId: this.entry.orderId,
                    invoiceId: this.entry.invoiceId,
                    voucherDetailIds: this.entry.voucherDetailIds,
                    branchId: store.state.CurrentBranch.id,
                });
                this.entry.infoVouchers = [];
                this.entry.valueVoucher = 0;
                this.entry.voucherDetailIds = [];
                if (res.result.status) {
                    if (!Array.isArray(this.entry.infoVouchers)) {
                        this.entry.infoVouchers = [];
                    }
                    if (!Array.isArray(this.entry.voucherDetailIds)) {
                        this.entry.voucherDetailIds = [];
                    }
                    res.result.voucherActive.forEach(element => {
                        this.entry.infoVouchers.push({
                            code: element.code,
                            value: +element.coupon_voucher.value,
                            id: element.id,
                            voucherId: element.coupon_voucher.id,
                            applyMultipleVoucher: element.coupon_voucher.applyMultipleVoucher,
                        });
                        this.entry.valueVoucher = (this.entry.valueVoucher ?? 0) + +element.coupon_voucher.value;
                        this.entry.voucherDetailIds.push(element.id);
                    });
                }
            },

            setEntry(res) {
                let valueCouponCovert = (+res.result.couponActive.coupon_voucher.value * +this.entry.totalPrice)/100;
                if ((res.result.couponActive.coupon_voucher.maxPrice != this.$constant.priceZero && res.result.couponActive.coupon_voucher.maxPrice)  && valueCouponCovert > +res.result.couponActive.coupon_voucher.maxPrice) {
                    this.entry.valueCoupon = +res.result.couponActive.coupon_voucher.maxPrice;
                } else { 
                    this.entry.valueCoupon = valueCouponCovert;
                }
                this.entry.isShowCodeActive = true;
                this.entry.couponDetailId = res.result.couponActive.id;
                this.entry.couponCode = res.result.couponActive.code;
            },
            calculateDebtTerm() {
                this.entry.debtTerm = moment().add(this.entry.period, 'days');
            },
            removeProductOrderPlus(productId, key) {
                const index = this.entry.products.findIndex(el => (el.id == productId));
                if (index != -1) {
                    this.entry.products[index].plusOrder.splice(key, 1);
                    localStorage.setItem('details', this.entry.products);
                }
            },
            cloneProduct(id) {                
                this.entry.products.forEach(product => {                    
                    if (product.id == id) {
                        if (!product.plusOrder || !product.plusOrder.length) {
                            product.plusOrder = [];
                        }
                        let oldCombo = null;
                        if (product.combo_products) {
                            oldCombo = clone(product.combo_products);
                            oldCombo.forEach(element => {
                                if(element.oldQuantity) {
                                    element.quantity = element.oldQuantity
                                }
                            });
                        }
                        let plusOrder = {
                            key: product.plusOrder.length,
                            parentId: product.id,
                            productUnitId: product.productUnitId,
                            productUnitText: (product.product_units.find(el => (el.id == product.productUnitId))).unitName,
                            quantity: 1,
                            discount: null,
                            price: product.price,
                            discountType: null,
                            discountValue: null,
                            amount: product.price,
                            consignments: product.consignments,
                            combo_products: oldCombo ? oldCombo : product.combo_products ,
                            // Bổ sung attribute plus
                            attributes: clone(product.attributes),
                            attributesActive: clone(product.attributesActive),
                            product_units: product.product_units,
                        };
                        
                        if (product.isConsignment) {
                            if (!product.consignments || !product.consignments.length) {
                                return;
                            }
                            plusOrder.consignmentActive = [];
                            let consignmentProductBranchMeta = product.consignments[0].consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == product.productUnitId);
                            let newConsignment = {
                                consignmentProductBranchMetaId: consignmentProductBranchMeta.id,
                                consignmentId: consignmentProductBranchMeta.consignmentId,
                                quantity: 1,
                                name: product.consignments[0].name,
                                expried: product.consignments[0].expried,
                                key: 1,
                                productUnitId: product.productUnitId
                            };
                            plusOrder.consignmentActive.push(newConsignment);
                        }
                        product.plusOrder.unshift(plusOrder);
                    }
                });
            },
            updateQuantity(quantity, productId) {
                this.entry.products.forEach(product => {
                    if (product.id == productId) {
                        product.quantity = quantity;
                    }
                });
            },
            showDetailsProduct(product) {
                this.$refs.modalDetailProduct.show(product);
            },
            setValue() {
                this.entry.purchaseDate = moment().format('DD/MM/YYYY HH:mm');
                if (!this.entry.priceListId) {
                    this.entry.priceListId = this.$constant.priceListIdCommon;
                }
                emitter.emit('clearSelectSearchValue');
                this.paymentChannel = [];
                if (+this.entry.cash) {
                    this.paymentChannel.push(this.$constant.paymentChannelCashMoney);
                } 
                if (+this.entry.card) {
                    this.paymentChannel.push(this.$constant.paymentChannelCardMoney);
                } 
                if (+this.entry.transfer) {
                    this.paymentChannel.push(this.$constant.paymentChannelTransferMoney);
                } 
                if (+this.entry.voucher) {
                    this.paymentChannel.push(this.$constant.paymentChannelVoucher);
                }
                if (!this.paymentChannel.length) {
                    this.paymentChannel.push(this.$constant.paymentChannelCashMoney);
                }
                if (this.entry.type != TYPE_RETURN) {
                    this.entry.couponCode = null;
                    this.entry.voucherCode = null;
                }
            },
            async getPriceProductForPiceList() {
                const res = await $post('/v1/orders/get-price-for-price-list-id', {
                    productIds: this.productIds,
                    priceListId: this.entry.priceListId,
                });
                const resultFormat = res.result;
                this.entry.products?.forEach(product => {
                    let item = resultFormat.find(el => (el.productId == product.id && el.productUnitId == product.productUnitId));
                    if (item) {
                        product.price = item.price;
                    }else{    
                        product.product_units.forEach((pu) => {        
                            // thiết lập giá cho hàng hóa bằng giá đơn vị hàng hóa hiện tại được chọn
                            if (this.entry.type == TYPE_RETURN && pu.id == product.productUnitId && product.baseProductUnitId && product.productUnitId == product.baseProductUnitId) {
                                product.price = product.basePrice ?? null;
                            }else if(pu.id == product.productUnitId){
                                product.price = pu.price;
                            }
                            
                        });
                    }
                });
            },
            async getUserActive() {
                const res = await $get('/v1/orders/get-user-active-price-list', {
                    priceListId: this.entry.priceListId,
                });
                this.branchAdmins = res.result;
                for (let index = 0; index < this.branchAdmins.length; index++) {
                    const user = this.branchAdmins[index];
                    if (user.id == this.entry.soldById) {
                        return;
                    }
                }
                this.entry.soldById = null;
            },
            // async getCustomers() {
            //     const res = await $get('/v1/orders/get-customer', {
            //         priceListId: this.entry.priceListId,
            //     });
            //     if (res.status.code != 200) {
            //         $alert({code: 500, message: this.$t('message.common.errorServer')});
            //         return;
            //     } 
            //     this.customers = res.result;
            //     for (let index = 0; index < this.customers.length; index++) {
            //         const customer = this.customers[index];
            //         if (customer.id == this.entry.customerId) {
            //             return;
            //         }
            //     }
            //     this.entry.customerId = null;
            // },
            async getPriceList() {
                const res = await $get('/v1/orders/get-price-lists');
                if (res.status.code != 200) {
                    $alert({code: 500, message: this.$t('message.common.errorServer')});
                    return;
                } 
                this.priceLists = res.result;
                this.priceLists.unshift(this.$constant.priceListCommon);
            },
            async getSaleChannels(){
                const res = await $post('/v1/configs/index', {} , false);
                if (res.code === 200) {
                    this.saleChannels = store.state.GlobalConfig.saleChannels = res.data.saleChannels;
                }
            },

            selectPriceList(v) {
                this.entry.priceListId = v.model;
            },
            selectSaleChannel(v) {
                this.entry.channelId = v.model;
            },
            selectSoldBy(v) {
                this.entry.soldById = v.model;
            },
            selectCustomer(v) {   
                this.entry.customerId = v.id;
                this.entry.customerName = v.name;
                this.entry.debtDate = '';
                this.entry.debtTerm = null;
                this.entry.period = null;
                this.entry.customerOrigin = v;
                if(v.numberDebtDate){
                    this.entry.period = +v.numberDebtDate;
                    this.entry.debtTerm = moment().add((+v.numberDebtDate), 'days');
                    this.entry.debtDate = moment().add((+v.numberDebtDate), 'days').format('YYYY/MM/DD');
                }else if(v.recurringPaymentDate){
                    this.entry.debtDate = v.recurringPaymentDate;
                }
                this.disablePromotionInvoiceIds = [];
                this.disablePromotionProductIds = [];
                if(this.entry.saleMode == this.$constant.sale_mode_trans){
                    this.entry.toDistrictName = v.address2 && v.address2.districts ? v.address2.districts.name : null;
                    this.entry.toWardName = v.address2 && v.address2.wards ? v.address2.wards.name : null;
                    this.entry.to_name = v.name ?? null;
                    this.entry.to_phone = v.address2 ? v.address2.phone : null;
                    this.entry.customerCode = v.customer_code ?? null
                    this.entry.customerAddressMain = v.address2 ? v.address2.addressValue : null,
                    this.entry.customerAddressId = v.address2 && v.address2.provinceId ? v.address2.id : null;
                    this.entry.customerProvinceId = v.address2 && v.address2.provinceId ? v.address2.provinceId : null;
                    this.entry.customerDistrictId = v.address2 && v.address2.districtId ? v.address2.districtId : null;
                    this.entry.customerWardId = v.address2 && v.address2.wardId ? v.address2.wardId : null;
                    this.informationCustomer = v.address_has_many ? v.address_has_many : [];
                    this.entry.customerAddressValue = v.address2 ? this.formatAddress(v.address2) : null;
                    this.entry.to_address = this.entry.customerAddressValue;
                    this.entry.toProvinceName = (v && v.address2 && v.address2.provinces) ? v.address2.provinces.name : '';                    
                    this.loadDataLocation();
                }
                this.getDataCustomer(v);
            },
            updateDiscount(value){
                if (value.dEntry && value.dEntry.parentId) {
                    this.entry.products.forEach(product => {    
                        if (product.id == value.dEntry.parentId) {
                            if(this.entry.type == TYPE_RETURN && product.plusReturn){
                                product.plusReturn.forEach(item => {
                                    if(item.key == value.dEntry.key) {
                                        item.discount = value.model.discountValue;
                                        item.discountValue = value.model.discountValue;
                                        item.valuePercent = value.model.valuePercent;
                                        item.discountRatio = value.model.valuePercent;
                                        item.discountType = value.model.discountType;
                                    }
                                });
                            }else{
                                product.plusOrder.forEach(item => {
                                    if(item.key == value.dEntry.key) {
                                        item.discount = value.model.discountValue;
                                        item.discountValue = value.model.discountValue;
                                        item.valuePercent = value.model.valuePercent;
                                        item.discountRatio = value.model.valuePercent;
                                        item.discountType = value.model.discountType;
                                    }
                                });
                            }
                        }
                    })
                }else if(value.dEntry.products){                    
                    this.entry.discountValue = value.model.discountValue;
                    this.entry.valuePercent = value.model.valuePercent;
                    this.entry.discountRatio = value.model.valuePercent;
                    this.entry.discountType = value.model.discountType;
                }else{
                    if (this.entry && this.entry.products) {
                        this.entry.products.forEach(product => {
                            if(product.id === value.dEntry?.id) {
                                product.discount = value.model.discountValue;
                                product.discountValue = value.model.discountValue;
                                product.valuePercent = value.model.valuePercent;
                                product.discountRatio = value.model.valuePercent;
                                product.discountType = value.model.discountType;
                            }
                        })
                    }
                }
            },
            updateProductUnit(product, productParent = null) {                
                if(productParent && productParent.product_units && productParent.product_units.length){
                    product.productUnit = productParent.product_units.find(el => (el.id == product.productUnitId));
                    product.quantity = 1;
                    product.discountType = DISCOUNT_TYPE_VND;
                    product.discountValue = 0;
                    if (product.baseProductUnitId && product.basePrice && product.productUnitId == product.baseProductUnitId) {
                        product.price = product.basePrice;
                    }else{
                        product.price = product.productUnit && product.productUnit.price ? product.productUnit.price : null;
                    }
                }else if (product.product_units && product.product_units.length) {
                    product.productUnit = product.product_units.find(el => (el.id == product.productUnitId));
                    product.quantity = 1;
                    product.discountType = DISCOUNT_TYPE_VND;
                    product.discountValue = 0;
                    // product.quantityRemaining = product.quantityRemaining * product.productUnit.conversionValue;
                    if (product.baseProductUnitId && product.basePrice && product.productUnitId == product.baseProductUnitId) {
                        product.price = product.basePrice;
                    }else{
                        product.price = product.productUnit && product.productUnit.price ? product.productUnit.price : null;
                    }
                    this.getPriceProductForPiceList();
                }
                if(product.consignmentActive && product.consignmentActive.length) {
                    for (let index = 0; index < product.consignmentActive.length; index++) {
                        const consignmentActive = product.consignmentActive[index];
                        consignmentActive.productUnitId = product.productUnitId;
                        product.consignments.forEach(consignment => {
                            let consignmentProductBranchMeta = consignment.consignment_product_branch_metas.find(item => (item.product_branch_meta.productUnitId == product.productUnitId && item.consignmentId == consignmentActive.consignmentId));
                            if (consignmentProductBranchMeta) {
                                consignmentActive.total_sales = consignmentProductBranchMeta.total_sales;
                            }
                        });
                    }
                }       
                this.getListAttributeForUnit(product);     

            },          
            async onOrderProcessing() {
                this.isCallApi = true;
                this.entry.branchId = store.state.CurrentBranch.id;                      
                const tabData = cloneObject(this.entry);
                tabData.branchId = store.state.CurrentBranch.id;

                if (!this.entry.customerId) {
                    if (!window.confirm(this.$t('message.common.confirmPayment'))) {
                        this.isCallApi = false;  
                        return false;
                    }
                    if (this.entry.type == this.$constant.typeInvoice && this.entry.customerPayment < this.entry.totalPriceAfterDiscount) {
                        this.isCallApi = false;  
                        toastr.error(this.$t('message.common.notEnoughMoney'));
                        return;
                    }
                }
                if (!this.entry.products || this.entry.products?.length <= 0){
                    this.isCallApi = false;  
                    toastr.error(this.$t('message.common.emptyInvoice'));
                    return;
                } else {
                    let confirm = true;
                    // this.entry.products.forEach(el => {
                    //     if(el.quantityOrigin && el.quantityOrigin < el.quantity){
                    //         toastr.error('Số lượng hàng hóa ' + el.name + ' không được lớn hơn số lượng đặt');
                    //         confirm = false;
                    //         return;
                    //     }
                    //     if(el.plusOrder && el.plusOrder.length){
                    //         el.plusOrder.forEach(el1 => {
                    //             if(el1.quantityOrigin && el1.quantityOrigin < el1.quantity){
                    //                 toastr.error('Số lượng hàng hóa ' + el1.name + ' không được lớn hơn số lượng đặt');
                    //                 confirm = false;
                    //                 return;
                    //             }
                    //         });
                    //     }
                    // });

                    // Fix lỗi không chọn đơn vị trong lượng
                    if(this.entry.weight && this.entry.weight > 0 && !this.entry.weightUnit){
                            toastr.error(this.$t('message.common.selectWeightProduct'));
                            return;
                    }    
                    // Fix lỗi chọn đối tác giao hàng
                    if((!this.entry.transporterId || this.entry.transporterId == null) && (this.entry._transporterId && this.entry._transporterId != null)){   
                        this.entry.transporterId = this.entry._transporterId;
                    }
                    if(confirm){
                        let form = this.formatConsignmentActive();
                        form.code = null;
                        form.isLink = true;
                        this.entry.isLink = true;
                        form.type = TYPE_INVOICE;
                        form.hasShipping = 0;
                        if(form.saleMode == this.$constant.sale_mode_trans){
                            form.hasShipping = 1;
                            form.paymentMethod = form.type_payment ?? null;
                            form.serviceTypeId = form.isTransporterIntegration ? form.shippingMode : form.serviceTypeId;
                            form.totalAmountReceiver = form.isTransporterIntegration ? this.totalAmountReceiver : 0;
                            if (form.transporterId == this.$constant.giao_hanh_nhanh_transposter) {
                                if (+form.weight > 300000) {
                                    $alert({code: 500, message: this.$t('message.common.notExceed30Kg')});
                                    this.isCallApi = false; 
                                    return;
                                }
                                if (form.usingPriceCod && +form.amountCod > 10000000) {
                                    $alert({code: 500, message: this.$t('message.common.notExceed10Million')});
                                    this.isCallApi = false; 
                                    return;
                                }
                                if (form.usingPriceCod && +form.amountCod < 0 ) {
                                    $alert({code: 500, message: this.$t('message.common.cannotLessThan0')});
                                    this.isCallApi = false; 
                                    return;
                                }
                                if (+form.item_value > 5000000) {
                                    $alert({code: 500, message: this.$t('message.common.notExceed5Million')});
                                    this.isCallApi = false; 
                                    return;
                                }
                                if (+form.hight > 150 || +form.long > 150 || +form.width > 150) {
                                    $alert({code: 500, message: this.$t('message.common.notExceed150Cm')});
                                    this.isCallApi = false; 
                                    return;
                                }
                            }
                        }                        
                        const res = await $post('/v1/orders/create-invoice', {...form});
                        if (res.code === 422) {
                            const errors = res.messages.errors;
                            Object.keys(errors).forEach(function(key) {
                                $alert({code: res.code, message: errors[key][0]});
                            });
                            this.isCallApi = false;
                        } else if (res.status.code == 200 && res.result.status) {
                            toastr.success(this.$t('message.common.addInvoiceSuccess'));
                            this.$emit('removeTab');                                                 
                            if(!this.entry.method || this.entry.method != this.$constant.typeMultiOrder) this.$router.push({name: 'InvoiceIndex', query: {orderCode: res.result.orderCode, orderId: res.result.orderId, action: this.$constant.confim, statusOrder: res.result.statusOrder}});
                            
                        } else {
                            $alert({code: 500, message: this.$t('message.common.errorServer')});
                            this.isCallApi = false;
                        }
                        this.entry.isLink =  false;
                    }
                }
            },
            async save() {                
                this.isCallApi = true;                             
                this.entry.branchId = store.state.CurrentBranch.id;                               
                if(this.isTaxRate == true) this.entry.isTaxRate = 1;                
                if(this.isHsCode == true) this.entry.isHsCode = 1;
                if (!this.entry.customerId) {
                    if (!window.confirm(this.$t('message.common.confirmPayment'))) {
                        this.isCallApi = false;  
                        return false;
                    }
                    if (this.entry.type == this.$constant.typeInvoice && this.entry.customerPayment < this.entry.totalPriceAfterDiscount) {
                        toastr.error(this.$t('message.common.notEnoughMoney'));
                        this.isCallApi = false;  
                        return;
                    }
                }
                if(!this.entry.products || this.entry.products?.length <= 0){
                    let message = this.entry.type == TYPE_RETURN ? this.$t('message.common.emptyReturn') : (this.entry.type == TYPE_INVOICE ? this.$t('message.common.emptyInvoice') : this.$t('message.common.emptyOrder'));
                    toastr.error(message);
                    this.isCallApi = false;  
                    return;
                }
                let res;
                this.entry.hasShipping = 0;
                if(this.entry.saleMode == this.$constant.sale_mode_trans){
                    this.entry.hasShipping = 1;
                    // this.entry.shippingNote = this.entry.to_note ?? null;
                    this.entry.paymentMethod = this.entry.type_payment ?? null;
                    this.entry.serviceTypeId = this.entry.isTransporterIntegration ? this.entry.shippingMode : this.entry.serviceTypeId;
                    this.entry.totalAmountReceiver = this.entry.isTransporterIntegration ? this.totalAmountReceiver : 0;
                }                
                
                // Kiểm tra nếu nhập số kg thì phải có đơn vị
                if(this.entry.weight && this.entry.weight > 0 && !this.entry.weightUnit){
                     toastr.error(this.$t('message.common.selectWeightProduct'));
                     this.isCallApi = false;
                     return;
                }               
                if((!this.entry.transporterId || this.entry.transporterId == null) && (this.entry._transporterId && this.entry._transporterId != null)){                    
                    this.entry.transporterId = this.entry._transporterId;
                }                
                if (this.entry.type == this.$constant.typeOrder) {
                    if (!this.entry.isUpdated) {
                        res = await $post('/v1/orders/create', {
                            ...this.entry
                        });
                    } else {                        
                        res = await $post('/v1/orders/update', {                            
                            ...this.entry
                        });
                    }
                } else if (this.entry.type == this.$constant.typeInvoice) {
                    
                    let form = this.formatConsignmentActive();
                    if (form.transporterId == this.$constant.giao_hanh_nhanh_transposter) {
                        if (+form.weight > 30000) {
                            $alert({code: 500, message: this.$t('message.common.notExceed30Kg')});
                            this.isCallApi = false; 
                            return;
                        }
                        if (form.usingPriceCod && +form.amountCod > 10000000) {
                            $alert({code: 500, message: this.$t('message.common.notExceed10Million')});
                            this.isCallApi = false; 
                            return;
                        }
                        if (form.usingPriceCod && +form.amountCod < 0 ) {
                            $alert({code: 500, message: this.$t('message.common.cannotLessThan0')});
                            this.isCallApi = false; 
                            return;
                        }
                        if (+form.item_value > 5000000) {
                            $alert({code: 500, message: this.$t('message.common.notExceed5Million')});
                            this.isCallApi = false; 
                            return;
                        }
                        if (+form.hight > 150 || +form.long > 150 || +form.width > 150) {
                            $alert({code: 500, message: this.$t('message.common.notExceed150Cm')});
                            this.isCallApi = false; 
                            return;
                        }
                    }               
                    if (!this.entry.isUpdated) {
                        res = await $post('/v1/orders/create-invoice', {
                            ...form
                        });
                    } else {
                        res = await $post('/v1/orders/update-invoice', {
                            ...form
                        });
                    }
                }
                if (res.code === 422) {
                    const errors = res.messages.errors;
                    Object.keys(errors).forEach(function(key) {
                        $alert({code: res.code, message: errors[key][0]});
                    });
                    this.isCallApi = false;
                } else if (res.status.code == 200 && res.result.status) {
                    if (this.entry.type == this.$constant.typeOrder) {
                        if (!this.entry.isUpdated) {
                            toastr.success(this.$t('message.common.addOrderSuccess'));
                        } else {
                            toastr.success(this.$t('message.common.updateOrderSuccess'));
                        }
                        this.$emit('removeTab');
                        if(!this.entry.method || this.entry.method != this.$constant.typeMultiOrder) this.$router.push({name: 'OrderIndex'});
                    } else if (this.entry.type == this.$constant.typeInvoice) {
                        if (!this.entry.isUpdated) {
                            toastr.success(this.$t('message.common.addInvoiceSuccess'));
                        } else {
                            toastr.success(this.$t('message.common.updateInvoiceSuccess'));
                        }
                        this.$emit('removeTab');
                        if(!this.entry.method || this.entry.method != this.$constant.typeMultiOrder) this.$router.push({name: 'InvoiceIndex', query: {print_id: res.result.id}});
                    }
                } else {
                    if(res.result && res.result.inValidCod){
                        $alert({code: 500, message: this.$t('message.common.notExceed10Million')});
                    }else{
                        $alert({code: 500, message: this.$t('message.common.errorServer')});
                    }
                    this.isCallApi = false; 
                }

            },
            formatConsignmentActive() {
                let form = JSON.parse(JSON.stringify(this.entry));
                form.products.forEach(product => {
                    let consignments = [];
                    if (product.isConsignment && product.consignmentActive && product.consignmentActive.length) {
                        product.consignmentActive.forEach(consignment => {
                            let index = consignments.findIndex(item => (item.consignmentId == consignment.consignmentId && item.productUnitId == consignment.productUnitId));
                            if (index > -1) {
                                consignments[index].quantity = consignments[index].quantity + consignment.quantity;
                            } else {
                                consignments.push(JSON.parse(JSON.stringify(consignment)));
                            }
                        });

                        if (product.plusOrder && product.plusOrder.length) {
                            product.plusOrder.forEach(plus => {
                                plus.consignmentActive.forEach(consignment => {
                                    let index = consignments.findIndex(item => (item.consignmentId == consignment.consignmentId && item.productUnitId == consignment.productUnitId));
                                    if (index > -1) {
                                        consignments[index].quantity = consignments[index].quantity + consignment.quantity;
                                    } else {
                                        consignments.push(JSON.parse(JSON.stringify(consignment)));
                                    }
                                });
                            });
                        }
                        product.consignmentActiveClone = consignments;
                    }
                });
                let weight = 0;
                if (this.$constant.weightUnitsIdGram == this.entry.weightUnit) {
                    weight = form.weight;
                } else {
                    weight = form.weight * 1000;
                }
                form.weight = weight;
                return form;
            },
            async saveReturn() {
                if(this.isTaxRate == true) this.entry.isTaxRate = 1;                
                if(this.isHsCode == true) this.entry.isHsCode = 1;                
                const data = cloneObject(this.entry);                
                let canSave = false;
                if (!data.customerId) {
                    if (!window.confirm(this.$t('message.common.confirmReturn'))) {
                        return false;
                    }
                }
                if(!this.entry.products || this.entry.products?.length <= 0){
                    toastr.error(this.$t('message.common.emptyReturn'));
                    return;
                }else{
                    this.entry.products.forEach(product => {
                        if(product.quantity > 0){
                            canSave = true;
                        }
                        if (product.plusOrder && product.plusOrder.length) {
                            product.plusOrder.forEach(item => {
                                if(item.quantity > 0){
                                    canSave = true;
                                }
                            });
                        }
                    })
                }
                if(!canSave){
                    toastr.error(this.$t('message.common.quantityReturnGreaterThan0'));
                    return;
                }
                this.isCallApi = true;
                const res = await $post('/v1/returns/save', data);
                if (res.code === 422) {
                    const errors = res.messages.errors;
                    Object.keys(errors).forEach(function(key) {
                        $alert({code: res.code, message: errors[key][0]});
                    });
                    this.isCallApi = false;
                } else if (res.status.code == 200 && res.result.status) {
                    if (!this.entry.isUpdated) {
                        toastr.success(this.$t('message.common.addReturnSuccess'));
                    } else {
                        toastr.success(this.$t('message.common.updateReturnPOSuccess'));
                    }
                    this.$emit('removeTab');
                    this.$router.push({name: 'ReturnIndex'});
                } else {
                    $alert({code: 500, message: this.$t('message.common.errorServer')});
                    this.isCallApi = false;
                }
            },
            removeProduct(index) {                
                this.entry.products.splice(index, 1);
                if(this.entry.products?.length == 0){
                    this.removeAllVoucher();
                    this.removeCoupon();
                    this.entry.promotion = null;
                    this.entry.productDiscount = [];
                    this.entry.productGifts = [];
                    this.entry.promotionsInvoice = [];
                    this.entry.promotionsProducts = [];
                }
                localStorage.setItem('details', this.entry.products);
            },
            onSaveProductBatch(data) {
                const product = this.entry.products.find(p => (p.id == data.productId));
                const quantityOrigin = product.productUnit ? product.productUnit.quantityOrigin : 0;

                if (product) {
                    if (data.quantity > quantityOrigin) {
                        product.quantity = quantityOrigin;
                        toastr.error(this.$t('message.common.notEnoughInventorySystem'));
                    } else {
                        product.product_batches = data.product_batches;
                        product.quantity = data.quantity;
                    }
                }
            },
            onCreateCustomer() {
                this.$refs.ModalAddInfoCustomer.show();
            },
            onCreateSaleChannel() {
                this.$refs.ModalAddInfoSaleChannels.show();
            },
            createdCustomer(customer) {
                this.entry.customerId = customer.id;
                this.onChangeCustomer();
            },
            onShowCustomer() {
                this.$refs.modalAddInfoCustomer.show(this.entry.customerId);
            },
            clearCustomer() {
                this.entry.customerId = null;
                this.entry.customerName = null;
                this.entry.customerOrigin = null;
                this.entry.to_phone = null;
                if(this.entry.saleMode == this.$constant.sale_mode_trans){
                    this.entry.to_name = null;
                    this.entry.customerProvinceId = null;
                    this.entry.customerDistrictId = null;
                    this.entry.customerWardId = null;
                    this.entry.customerAddressValue = null;
                    this.entry.to_address = null;
                }
                this.groupIds = [];
            },
            onSavePayment(cashFlows, totalPayment) {
                this.entry.cash_flows = cashFlows;
                this.entry.totalPayment = totalPayment;
            },
            async onChangeCustomer() {
                let customer = null;
                let address = null;

                if (this.entry.customerId) {
                    const res = await $get('/v1/sale/getCustomer', {customer_id: this.entry.customerId});
                    if (res.code === 200) {
                        customer = res.data;
                        address = res.address;
                        this.entry.customer = customer;
                    }
                }

                const userInfo = {};
                userInfo.receiverName = customer ? customer.name : null;
                userInfo.receiverPhone = customer ? customer.contactNumber : null;
                userInfo.receiverAddress = address ? address.addressValue : null;
                userInfo.receiverProvinceId = address ? address.provinceId : null;
                userInfo.receiverDistrictId = address ? address.districtId : null;
                userInfo.receiverWardId = address ? address.wardId : null;

                if (typeof this.entry.delivery === 'object') {
                    this.entry.delivery = {...this.entry.delivery, ...userInfo};
                } else {
                    this.entry.delivery = userInfo;
                }
            },
            getDataCustomer(customer) {
                this.groupIds = [];
                if (customer) {
                    customer.customer_group_details.forEach(detail => {
                        this.groupIds.push(detail.customerGroupId);
                    });
                }
            },
            async getPromotion() {
                let maxQuantity = 0;
                if(!Array.isArray(this.quantityOrder)) {
                    this.quantityOrder = [];
                }
               for (let index = 0; index < this.quantityOrder.length; index++) {
                    const element = this.quantityOrder[index];
                    if (element.maxQuantity > maxQuantity) {
                        maxQuantity = element.maxQuantity;
                    }
               }
               var max = 0 
               this.quantityOrder.forEach(element => {
                    if (element.categoryIds && element.categoryIds.length) {
                        max += parseInt(element.maxQuantity)
                    }
               });
               
               this.totalQuantityMax = max;

                const res = await $post('/v1/promotions/get-promotion-active', {
                    branchId: store.state.CurrentBranch.id,
                    totalPrice: this.entry.totalPrice,
                    categoryIds: this.categoriesIds,
                    productUnitId: this.productUnitIds,
                    userId: this.entry.soldById,
                    customerId: this.entry.customerId,
                    groupId: this.groupIds,
                    maxQuantity: maxQuantity,
                    productUnits: this.quantityOrder,
                    categories: this.categories,
                    currentPromotionId: this.entry.promotionId,
                    totalQuantityMax: this.totalQuantityMax
                });
                this.entry.promotionsInvoice = [];
                this.entry.promotionsProducts = [];
                if(res.result) {
                    this.entry.promotionsInvoice = res.result.promotionsInvoice;
                    this.entry.promotionsProducts = res.result.promotionsProducts;
                }
                if(this.disablePromotionInvoiceIds){
                    this.disablePromotionInvoiceIds.forEach(id => {
                        let index = this.entry.promotionsInvoice.findIndex(v => v.id == id);
                        if(index > -1){
                            this.entry.promotionsInvoice.splice(index, 1)
                        }
                    });
                }
                if(this.disablePromotionProductIds){
                    this.disablePromotionProductIds.forEach(id => {
                        let index = this.entry.promotionsProducts.findIndex(v => v.id == id);
                        if(index > -1){
                            this.entry.promotionsProducts.splice(index, 1)
                        }
                    });
                }
                if(!this.entry.customerId && !this.entry.soldById && !this.entry.promotionsInvoice.length && !this.entry.promotionsProducts.length){
                    this.clearPromotion();
                }
                if (!this.entry.promotion) {
                    return;
                }
                this.clearCurrentPromotion();
            },
            clearCurrentPromotion(){
                let statusDeletePromotionInvoice = true;
                for (let index = 0; index < this.entry.promotionsInvoice.length; index++) {
                    const promotion = this.entry.promotionsInvoice[index];
                    if (this.entry.promotion?.id == promotion.id) {
                        for (let index = 0; index < promotion.promotion_options.length; index++) {
                            const option = promotion.promotion_options[index];
                            if (option.id == this.entry.promotion.promotionOptionId) {
                                statusDeletePromotionInvoice = false;
                                break;
                            }
                        }
                    }
                    if (!statusDeletePromotionInvoice) {
                        break;
                    }
                }

                let statusDeletePromotionProduct = true;
                for (let index = 0; index < this.entry.promotionsProducts.length; index++) {
                    const promotion = this.entry.promotionsProducts[index];
                    if (this.entry.promotion?.id == promotion.id) {
                        for (let index = 0; index < promotion.promotion_options.length; index++) {
                            const option = promotion.promotion_options[index];
                            if (option.whenBuying) {
                                const buying = JSON.parse(option.whenBuying);
                                if (buying.length) {
                                    for (let ind = 0; ind < buying.length; ind++) {
                                        if ((option.id + '_' + ind) == this.entry.promotion.promotionOptionId) {
                                            statusDeletePromotionProduct = false;
                                            break;
                                        }else if(option.id == this.entry.promotion.promotionOptionId && this.entry.promotion.promotionOptionChildId && buying[ind].id && this.entry.promotion.promotionOptionChildId == buying[ind].id){
                                            this.entry.promotion.promotionOptionId = (option.id + '_' + ind);
                                            statusDeletePromotionProduct = false;
                                            break;
                                        }
                                        if (!statusDeletePromotionProduct) {
                                            break;
                                        }
                                    }
                                }
                            }
                            else if(option.id == this.entry.promotion.promotionOptionId) {
                                statusDeletePromotionProduct = false;
                                break;
                            }
                        }
                    }
                    if (!statusDeletePromotionProduct) {
                        break;
                    }
                }
                if (statusDeletePromotionInvoice && statusDeletePromotionProduct) {
                    if(!this.entry.isFirst){
                        this.clearPromotion();
                    }else{
                        this.entry.isFirst = false;
                    }
                }
            },
            showPromotionList(promotions) {
                let promotionOptionId = this.entry.promotion ? this.entry.promotion.promotionOptionId : null;
                let noCheckExistPromotion = true;
                if(this.entry.promotion){
                    noCheckExistPromotion = false;
                    promotions.forEach(promotion => {
                        if(promotion.promotionById == this.entry.promotion.promotionById){
                            noCheckExistPromotion = true;
                        }
                    })
                }
                this.$refs.modalPromotionInvoiceList.show(promotions, promotionOptionId, noCheckExistPromotion);
            },
            clearPromotion() {                
                this.entry.promotion = null;
                this.entry.productDiscount = [];
                this.entry.productGifts = [];
            },
            promotionWarning(value){
                switch (value) {
                    case this.$constant.confirmPromotionWarning:
                        if(!this.isChangeCustomer){
                            this.showPromotionListInvoice();
                        }
                        this.isChangeCustomer = false;
                        break;
                    case this.$constant.cancelPromotionWarning:
                        if(this.disablePromotionInvoiceIds){
                            this.disablePromotionInvoiceIds.forEach(id => {
                                let index = this.entry.promotionsInvoice.findIndex(v => v.id == id);
                                if(index > -1){
                                    this.entry.promotionsInvoice.splice(index, 1)
                                }
                            });
                        }
                        if(this.disablePromotionProductIds){
                            this.disablePromotionProductIds.forEach(id => {
                                let index = this.entry.promotionsProducts.findIndex(v => v.id == id);
                                if(index > -1){
                                    this.entry.promotionsProducts.splice(index, 1)
                                }
                            });
                        }
                        if(!this.isChangeCustomer){
                            this.showPromotionListInvoice();
                        }
                        this.isChangeCustomer = false;
                        this.clearCurrentPromotion();
                        break;
                }
            },
            showPromotionListInvoice(){
                let promotions = [];
                for (let index = 0; index < this.entry.promotionsInvoice.length; index++) {
                    const promotion = this.entry.promotionsInvoice[index];
                    for (let index = 0; index < promotion.promotion_options.length; index++) {
                        const option = promotion.promotion_options[index];
                        var newItem = {
                            id: promotion.id,
                            code: promotion.code,
                            name: promotion.name,
                            promotionFromId: promotion.promotionFromId,
                            promotionById: promotion.promotionById,
                            promotion_from: promotion.promotion_from,
                            promotionOptionId: option.id,
                            promotion_options: {
                                id: option.id,
                                promotionFromId: option.promotionFromId,
                                promotionId: option.promotionId,
                                totalAmount: option.totalAmount,
                                totalGift: option.totalGift ?? null,
                                totalBuyQuantity: option.totalBuyQuantity ?? null,
                                totalVoucher: option.totalVoucher ?? null,
                                discountValue: option.discountValue ?? null,
                                discountRatio: option.discountRatio ?? null,
                                totalQuantityDiscount: option.totalQuantityDiscount ?? null,
                                whenBuying: option.whenBuying ?? null,
                                attachQuantity: option.attachQuantity ?? null,
                                receive_categories: option.receive_categories ?? null,
                                receive_products: option.receive_products ?? null,
                                receiveProductUnitIds: option.receiveProductUnitIds ?? null,
                                receiveCategoriesIds: option.receiveCategoriesIds ?? null,
                                isFullCategories: option.isFullCategories,
                            }
                        };
                        if (this.entry.promotion && this.entry.promotion.id == promotion.id && option.id == this.entry.promotion.promotionOptionId) {
                            newItem.receiveProductUnit = this.entry.promotion.receiveProductUnit;
                        }
                        promotions.push(newItem);
                    }
                }
                this.showPromotionList(promotions);
            },
            checkPromotionActive(productUnitId, infoCategory, productQuantity) {
                let categoresId = [];
                infoCategory.forEach(category => {
                    categoresId.push(category.categoryId);
                });
                let status = false;
                let statusQuantity = false;
                let statusQuantityWhenBuying = false;
                for (let index = 0; index < this.entry.promotionsProducts.length; index++) {
                    const promotion = this.entry.promotionsProducts[index];
                    for (let index = 0; index < promotion.promotion_options.length; index++) {
                        const element = promotion.promotion_options[index];

                        if (element.totalBuyQuantity && element.totalBuyQuantity <= productQuantity) {
                            statusQuantity = true;
                        }
                        if (element.totalBuyQuantity && element.totalBuyQuantity <= this.totalQuantityMax) {
                            statusQuantity = true;
                        }
                        if (element.productUnitId && element.productUnitId.length && element.productUnitId.includes(productUnitId)) {
                            status = true;
                        }
                        if (element.isFullCategoriesCondition || categoresId.length) {
                            status = true;
                        }
                        if (element.categoriesIds && element.categoriesIds.length && element.categoriesIds.some(r=> categoresId.indexOf(r) >= 0)) {
                            status = true;
                        } 
                    }
                    for (let index = 0; index < promotion.promotion_options.length; index++) {
                        const element = promotion.promotion_options[index];
                        if (element.whenBuying) {
                            const buying = JSON.parse(element.whenBuying);
                            buying.forEach(item => {
                                if(element.isFullCategoriesCondition){
                                    if(item.quantity <= this.totalQuantityMax){
                                        statusQuantityWhenBuying = true;
                                    }
                                }else{
                                    if(statusQuantityWhenBuying != true){
                                        statusQuantityWhenBuying = this.checkConditionPromotion(element, categoresId, productQuantity, item.quantity, productUnitId);
                                    }
                                }
                            });
                        }
                    }

                } 
                return ((status && statusQuantity) || statusQuantityWhenBuying); 
                
            },
            showPromotionProduct(productUnitId, infoCategory, quantityOrder) {
                let categoresId = [];
                let promotions = [];
                let product = this.entry.products.find(product => product.productUnitId == productUnitId);
                if(product){
                    quantityOrder = 0;
                    quantityOrder += product.quantity;
                    if(product.plusOrder && product.plusOrder.length){
                        product.plusOrder.forEach(element => {
                            quantityOrder += element.quantity;
                        });
                    }
                }
                infoCategory.forEach(category => {
                    categoresId.push(category.categoryId);
                });
                for (let index = 0; index < this.entry.promotionsProducts.length; index++) {
                    const promotion = this.entry.promotionsProducts[index];
                    for (let index = 0; index < promotion.promotion_options.length; index++) {
                        const element = promotion.promotion_options[index];
                        if (element.productUnitId.includes(productUnitId)) {
                            promotions.push(promotion);
                            break;
                        }
                        if (element.isFullCategoriesCondition || categoresId.length) {
                            promotions.push(promotion);
                            break;
                        }  
                        if (element.categoriesIds && element.categoriesIds.length && element.categoriesIds.some(r=> categoresId.indexOf(r) >= 0)) {
                            promotions.push(promotion);
                            break;
                        }      
                    }
                }
                let newPromotionOptions = [];
                var newItem = {};
                for (let index = 0; index < promotions.length; index++) {
                    const promotion = promotions[index];
                    for (let index = 0; index < promotion.promotion_options.length; index++) {
                        const option = promotion.promotion_options[index];
                        if (option.whenBuying) {
                            const buying = JSON.parse(option.whenBuying);
                            if (buying.length) {
                                buying.forEach((element, ind) => {
                                    let append = false;
                                    if(option.isFullCategoriesCondition){
                                        if(element.quantity <= this.totalQuantityMax){
                                            append = true;
                                        }
                                    }else{
                                        append = this.checkConditionPromotion(option, categoresId, quantityOrder, element.quantity, productUnitId);
                                    }
                                    if(append){
                                        newItem = {
                                            id: promotion.id,
                                            code: promotion.code,
                                            name: promotion.name,
                                            productUnitId: productUnitId,
                                            quantityOrder: quantityOrder,
                                            duplicateProduct: promotion.duplicateProduct,
                                            promotionFromId: promotion.promotionFromId,
                                            promotionById: promotion.promotionById,
                                            promotion_from: promotion.promotion_from,
                                            promotionOptionId: option.id + '_' + ind,
                                            promotionOptionChildId: element.id,
                                            promotion_options: {
                                                id: option.id + '_' + ind,
                                                promotionFromId: option.promotionFromId,
                                                promotionId: option.promotionId,
                                                totalAmount: option.totalAmount,
                                                totalGift: option.totalGift,
                                                totalBuyQuantity: option.totalBuyQuantity,
                                                totalVoucher: option.totalVoucher,
                                                discountValue: option.discountValue,
                                                discountRatio: option.discountRatio,
                                                isFullCategories: option.isFullCategories,
                                                totalQuantityDiscount: option.totalQuantityDiscount,
                                                whenBuying: element,
                                                attachQuantity: option.attachQuantity,
                                                receive_categories: option.receive_categories,
                                                receive_products: option.receive_products,
                                                receiveProductUnitIds: option.receiveProductUnitIds,
                                                receiveCategoriesIds: option.receiveCategoriesIds,
                                            }
                                        };
                                        newPromotionOptions.push(newItem);
                                    }
                                });
                            }
                        }
                        if (!option.whenBuying) {
                                if (quantityOrder >= option.totalBuyQuantity || this.totalQuantityMax >= option.totalBuyQuantity) {
                                    newItem = {
                                        id: promotion.id,
                                        code: promotion.code,
                                        name: promotion.name,
                                        productUnitId: productUnitId,
                                        quantityOrder: quantityOrder,
                                        promotionFromId: promotion.promotionFromId,
                                        duplicateProduct: promotion.duplicateProduct,
                                        promotionById: promotion.promotionById,
                                        promotion_from: promotion.promotion_from,
                                        promotionOptionId: option.id,
                                        promotion_options: {
                                            id: option.id,
                                            promotionFromId: option.promotionFromId,
                                            promotionId: option.promotionId,
                                            totalAmount: option.totalAmount,
                                            totalGift: option.totalGift,
                                            totalBuyQuantity: option.totalBuyQuantity,
                                            totalVoucher: option.totalVoucher,
                                            discountValue: option.discountValue,
                                            isFullCategories: option.isFullCategories,
                                            discountRatio: option.discountRatio,
                                            totalQuantityDiscount: option.totalQuantityDiscount,
                                            whenBuying: '',
                                            attachQuantity: option.attachQuantity,
                                            receive_categories: option.receive_categories,
                                            receive_products: option.receive_products,
                                            receiveProductUnitIds: option.receiveProductUnitIds,
                                            receiveCategoriesIds: option.receiveCategoriesIds,
                                    }
                                }   
                                newPromotionOptions.push(newItem);
                            }
                        }
                        if (this.entry.promotion && this.entry.promotion.id == promotion.id && option.id == this.entry.promotion.promotionOptionId) {
                            newItem.receiveProductUnit = this.entry.promotion.receiveProductUnit;
                        }
                    }
                }
                this.showPromotionList(newPromotionOptions);
            },
            checkConditionPromotion(promotion, productCategories, quantityProduct, conditionQuantity, productUnitId){
                if(promotion.condition_categories && promotion.condition_categories?.length > 0 && promotion.condition_products && promotion.condition_products?.length > 0){
                    let append = false;
                    if(this.categories){
                        promotion.condition_categories.forEach(category => {
                            const result = this.categories.filter(cate => cate.id == category.categoryId);
                            let totalQuantityProduct = 0;
                            this.quantityOrder.forEach(prod => {
                                let isExistProduct = promotion.condition_products.filter(product => product.productUnitId == prod.id);
                                if(isExistProduct && isExistProduct?.length > 0){
                                    let isExistCategory = promotion.condition_categories.some(r=> prod.categoryIds.indexOf(r.categoryId) >= 0) ? true : false;
                                    if(!isExistCategory){
                                        totalQuantityProduct += prod.maxQuantity;
                                    }
                                }
                            })
                            if(result[0] && (result[0].maxQuantity + totalQuantityProduct >= conditionQuantity)){
                                append = true;
                            }
                        })
                    }
                    return append;

                }else if(promotion.condition_categories && promotion.condition_categories?.length > 0 && promotion.condition_products && promotion.condition_products?.length == 0){
                    let isExistCategory = promotion.condition_categories.some(r=> productCategories.indexOf(r.categoryId) >= 0) ? true : false;
                    if(!isExistCategory){
                        return false;
                    }else{
                        let status = false;
                        if(this.categories){
                            promotion.condition_categories.forEach(category => {
                                const result = this.categories.filter(cate => cate.id == category.categoryId);
                                if(result[0] && result[0].maxQuantity >= conditionQuantity){
                                    status = true;
                                }
                            })
                        }
                        return status;
                    }
                }else if(promotion.condition_categories && promotion.condition_categories?.length == 0 && promotion.condition_products && promotion.condition_products?.length > 0){
                    let isExistProduct = promotion.condition_products.filter(prod => prod.productUnitId == productUnitId);
                    if(isExistProduct && isExistProduct?.length > 0){
                        if(quantityProduct >= conditionQuantity){
                            return true;
                        }
                        return false;
                    }else{
                        return false;
                    }
                }
            },
            async checkPromotionForCustomer() {
                this.disablePromotionInvoiceIds = [];
                this.disablePromotionProductIds = [];
                await this.getPromotion();
                let promotionIds = [];
                this.entry.promotionsInvoice.forEach(promotion => {
                    promotionIds.push(promotion.id);
                });
                this.entry.promotionsProducts.forEach(promotion => {
                    promotionIds.push(promotion.id);
                });
                const res = await $post('/v1/promotions/check-promotion-for-customer', {
                    customerId: this.entry.customerId,
                    promotionIds: promotionIds,
                });
                if (res && res.result && res.result.length) {
                    let namePromotion = '';
                    let indexPromotionInvoices = [];
                    let indexPromotionProducts = [];
                    res.result.forEach(promotionId => {
                        let indexInvoice = this.entry.promotionsInvoice.findIndex(p => (p.id == promotionId));
                        let indInvoice = indexPromotionInvoices.findIndex(i => i == promotionId);
                        if (indexInvoice > -1 && indInvoice < 0) {
                            indexPromotionInvoices.push(this.entry.promotionsInvoice[indexInvoice].id);
                            namePromotion = (namePromotion ? (namePromotion + ', ') : '' ) + this.entry.promotionsInvoice[indexInvoice].name;
                        }
                        let indexProduct = this.entry.promotionsProducts.findIndex(p => (p.id == promotionId));
                        let indProduct = indexPromotionProducts.findIndex(p => p == promotionId);
                        if (indexProduct > -1 && indProduct < 0) {
                            indexPromotionProducts.push(this.entry.promotionsProducts[indexProduct].id);
                            namePromotion = (namePromotion ? (namePromotion + ', ') : '' ) + this.entry.promotionsProducts[indexProduct].name;
                        }
                    });
                    this.disablePromotionInvoiceIds = indexPromotionInvoices;
                    this.disablePromotionProductIds = indexPromotionProducts;
                    this.isChangeCustomer = true;
                    this.$refs.ModalPromotionWarning.show(namePromotion);
                    return;
                }else{
                    this.disablePromotionInvoiceIds = [];
                    this.disablePromotionProductIds = [];
                }
            },
            removeConsignment(productIndex, consignmentIndex) {
                this.entry.products[productIndex].consignmentActive.splice(consignmentIndex, 1);
            },
            removeConsignmentProductPlus(productIndex, productPlusIndex, consignmentIndex) {
                this.entry.products[productIndex].plusOrder[productPlusIndex].consignmentActive.splice(consignmentIndex, 1);
            },
            removeConsignmentProductPlusReturn(productIndex, productPlusReturnIndex, consignmentIndex) {
                this.entry.products[productIndex].plusReturn[productPlusReturnIndex].consignmentActive.splice(consignmentIndex, 1);
            },
            removeConsignmentProductGift(productIndex, consignmentIndex) {
                this.entry.productGifts[productIndex].consignmentActive.splice(consignmentIndex, 1);
                this.setMaxQuantityProductGift(productIndex, this.entry.productGifts[productIndex].maxQuantity);
            },
            removeConsignmentProductDiscount(productIndex, consignmentIndex){
                this.entry.productDiscount[productIndex].consignmentActive.splice(consignmentIndex, 1);
                this.setMaxQuantityProductDiscount(productIndex, this.entry.productDiscount[productIndex].maxQuantity);
            },
            countQuantityProduct(productIndex, consignmentIndex) {
                this.entry.products[productIndex].consignmentActive;
            },

            // Remove consigment of product in combo
            removeConsignmentProductInCombo(productComboIndex, productOfComboIndex, consigmentIndex) {
                this.entry.products[productComboIndex].combo_products[productOfComboIndex].product.consignmentActive.splice(consigmentIndex, 1)
            },
            removeConsignmentProductInComboPlus (productComboIndex, productPlusComboIndex, productOfComboPlusIndex, consignmentIndex) {                
                this.entry.products[productComboIndex].plusOrder[productPlusComboIndex].combo_products[productOfComboPlusIndex].product.consignmentActive.splice(consignmentIndex, 1)
            },
            showCombo(entry){
                if(!entry.showCombo){
                    entry.showCombo = true;
                }else{
                    entry.showCombo = !entry.showCombo;
                }
            },

            // Xử lí task liên quan đến break đơn vị để trả hàng
            addProductPlusUnitReturn(productIndex, productOrigin) {
                let product = clone(productOrigin);
                let plusReturn = {
                    productUnitId: product.product_units[0]['id'],
                    productUnit: product.product_units[0],
                    quantity: 0,
                    price: product.product_units[0].price,
                    product_units: product.product_units,
                    id: product.id,
                    isPlus: true,
                    isConsignment: product.isConsignment,
                    consignments: clone(product.consignments),
                    consignmentActive: clone(product.consignmentActive),
                    parentId: product.productId ? product.productId : (product.id ? product.id : null),
                    attributes: clone(product.attributes),
                    attributesActive: clone(product.attributesActive),
                    hasVariant: product.hasVariant
                    
                }
                if (!this.entry.products[productIndex].plusReturn) {
                    this.entry.products[productIndex].plusReturn = [];
                }
                plusReturn.index = this.entry.products[productIndex].plusReturn.length;                
                this.entry.products[productIndex].plusReturn.push(plusReturn);
            }, 

            removeReturnProductPlus(productIdex, plusIndex) {
                this.entry.products[productIdex].plusReturn.splice(plusIndex, 1);
            },

            updateUnitProductReturn (plusReturn) {
                if (plusReturn.product_units && plusReturn.product_units.length) {
                    plusReturn.productUnit = plusReturn.product_units.find(el => (el.id == plusReturn.productUnitId));
                    plusReturn.quantity = 0;
                    plusReturn.discountType = DISCOUNT_TYPE_VND;
                    plusReturn.discountValue = 0;

                    if (plusReturn.consignments && plusReturn.consignments?.length > 0) {
                        plusReturn.consignments.forEach(consignment => {
                            let consignmentProductBranchMeta = consignment.consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == plusReturn.productUnitId);
                            
                            if (consignmentProductBranchMeta && consignmentProductBranchMeta.id && plusReturn.consignmentActive) {
                                plusReturn.consignmentActive.forEach(consigmentActive => {
                                    if (consignment.id == consigmentActive.consignmentId) {
                                        consigmentActive.consignmentProductBranchMetaId = consignmentProductBranchMeta.id;
                                    }
                                });
                            }
                       });
                    }
                   
                    // plusReturn.quantityRemaining = plusReturn.quantityRemaining * plusReturn.productUnit.conversionValue;                    
                    this.getPriceProductForPiceListReturn();
                    this.getListAttributeForUnit(plusReturn);
                }
            },
            async getPriceProductForPiceListReturn() {
                const res = await $post('/v1/orders/get-price-for-price-list-id', {
                    productIds: this.productIds,
                    priceListId: this.entry.priceListId,
                });
                const resultFormat = res.result;
                this.entry.products?.forEach(product => {
                    let item = resultFormat.find(el => (el.productId == product.id && el.productUnitId == product.productUnitId));
                    if (item) {
                        product.price = item.price;
                    }else{    
                        product.product_units.forEach((pu) => {        
                            // thiết lập giá cho hàng hóa bằng giá đơn vị hàng hóa hiện tại được chọn
                            if(pu.id == product.productUnitId){
                                product.price = pu.price;
                            }
                            
                        });
                    }

                    if (product.plusReturn && product.plusReturn.length > 0) {
                        product.plusReturn.forEach(itemPls => {
                            itemPls.price = itemPls.productUnit.price;
                           
                        });
                    }
                });
            },
            async getDataPickupAddress ()
            {
                const result = await $post('/v1/pickup-address/data', {
                    branchId: store.state.CurrentBranch.id
                });
                this.pickupAddress = result.result;
                
            },
            async onSelectLocation(location) {
               const result = await $post('/v1/pickup-address/search-location', {
                        province: location.compound.province,
                        district: location.compound.district,
                        commune: location.compound.commune
                });
                this.entry.customerProvinceId = result.result.provinceId
                this.entry.customerDistrictId = result.result.districtId;
                this.entry.customerWardId = result.result.wardId;
                this.entry.to_address = location.description;
                this.entry.customerAddressValue = location.description;
                this.loadDataLocation();     
            },
            async getETAhamove() {
                if (this.entry.shippingMode != this.$constant.shipping_mode_in_day || this.isLoginAhamove || !this.entry.to_address || !this.entry.from_address) {
                    this.deliveries = [];
                    return;
                }
                const result = await $post('/v1/ahamove/estimate-multiple-service-fee', {...this.entry});
                this.deliveries = result.result;
            },
            async getETGiaoHangNhanh() {
                if (this.entry.shippingMode != this.$constant.shipping_mode_default || this.isLoginGHN) {
                    this.deliveries = [];
                    return;
                }
                if (!this.entry.width || !this.entry.hight || !this.entry.long || !this.entry.weightUnit || !this.entry.weight) {
                    this.deliveries = [];
                    return;
                }
                let dataEt = await this.formatDataShipForGiaoHangNhanh();
                const result = await $post('/v1/giao-hang-nhanh/estimate-order-fee', {...dataEt});
                this.deliveries = [result.result];
            },
            async getETViettelPost(){
                if (this.entry.shippingMode != this.$constant.shipping_mode_default || this.isLoginViettelPost) {
                    this.deliveriesViettelPost = [];
                    return;
                }
                if (!this.entry.width || !this.entry.hight || !this.entry.long || !this.entry.weightUnit || !this.entry.weight) {
                    this.deliveriesViettelPost = [];
                    return;
                }
               
                let dataEtVTP = await this.formatDataShipForViettelPost();
                const result = await $post('/v1/viettel-post/estimate-order-fee', {...dataEtVTP});
                this.deliveriesViettelPost = result.result;
            },
            async formatDataShipForViettelPost () {
                let items = await this.formartItems();
                let weight = 0;
                if (this.$constant.weightUnitsIdGram == this.entry.weightUnit) {
                    weight = this.entry.weight;
                } else {
                    weight = this.entry.weight * 1000;
                }
                const dataEt = {
                    'formDistrictName': this.entry.formDistrictName ?? '',
                    'toDistrictName': this.entry.toDistrictName ?? '',
                    'toWardName': this.entry.toWardName ?? '',
                    'width': this.entry.width,
                    'height': this.entry.hight ?? '',
                    'weight': weight,
                    'length': this.entry.long ?? '',
                    'amount': this.entry.totalPrice ?? '',
                    'items': items,
                    'amountCod': this.entry.usingPriceCod ? this.entry.amountCod : 0,
                    'itemValue': this.entry.is_insurance ? this.entry.item_value : 0,
                    'isSend': this.entry.isSend,
                    'is_declare_price': this.entry.is_insurance,
                    'serviceVTP': this.entry.serviceVTP,
                }
                return dataEt;
            },
            async formatDataShipForGiaoHangNhanh() {
                let items = await this.formartItems();
                let weight = 0;
                if (this.$constant.weightUnitsIdGram == this.entry.weightUnit) {
                    weight = this.entry.weight;
                } else {
                    weight = this.entry.weight * 1000;
                }
                const dataEt = {
                    'formDistrictName': this.entry.formDistrictName ?? '',
                    'toDistrictName': this.entry.toDistrictName ?? '',
                    'toWardName': this.entry.toWardName ?? '',
                    'width': this.entry.width,
                    'height': this.entry.hight ?? '',
                    'weight': weight,
                    'length': this.entry.long ?? '',
                    'amount': this.entry.totalPrice ?? '',
                    'items': items,
                    'amountCod': this.entry.usingPriceCod ? this.entry.amountCod : 0,
                    'itemValue': this.entry.is_insurance ? this.entry.item_value : 0,
                }
                return dataEt;
            },
            formartItems() {
                let items = [];
                if (!this.entry.products) return [];
                for (let index = 0; index < this.entry.products.length; index++) {
                    const product = clone(this.entry.products[index]);
                    items.push({
                        'name': product.name,
                        'quantity': product.quantity,
                    })
                }
                return items;
            },
            async loadDataLocation(){
                const res = await $get('/v1/locations/provinces', {
                    provinceId: this.entry.customerProvinceId,
                    districtId: this.entry.customerDistrictId
                });
                this.provinces = res.data.provinces ?? null;
                this.districts = res.data.districts ?? null;
                this.wards = res.data.wards ?? null;
            },
            async changeDistrict(value = null) {
                this.entry.customerDistrictId = value && value.model ? value.model : null;
                this.entry.customerWardId = null;
                const res = await $get('/v1/locations/wards', {districtId: this.entry.customerDistrictId});
                this.wards = res.data;
            },
            async changeProvince(value = null) {
                this.entry.customerProvinceId = value && value.model ? value.model : null;
                this.entry.customerDistrictId = null;
                this.entry.customerWardId = null;
                const res = await $get('/v1/locations/districts', {provinceId: this.entry.customerProvinceId});
                this.districts = res.data;
                this.changeDistrict();
            },
            getFromAddress (value) {
                this.entry.from_address = value.currentOption ? value.currentOption.address : null;
                this.entry.from_address_id = value.model ?? null;
                this.entry.from_phone = value.currentOption ? value.currentOption.phone : null;
                this.entry.branchAddressId = value.currentOption ? value.currentOption.id : null;
                this.entry.formDistrictName = value.currentOption ? value.currentOption.formDistrictName : null;
            },
            chooseSerivce(item){
                this.entry.serviceId = item._id ?? null;
                this.entry.transporterId = item.transporterId ?? null;
                this.entry.requests = item.requests ?? null;
                this.entry.currentServiceId = item.infoService ? item.infoService.id : null;
                this.totalAmountReceiver = this.entry.totalPriceAfterDiscount;
                this.entry.total_price = item.total_price ?? null;
                if (this.entry.transporterId == this.$constant.giao_hanh_nhanh_transposter) {
                    if (this.entry.type_payment == this.$constant.appPayment) {
                        this.entry.type_payment = this.$constant.sendPayment;
                    }
                    this.entry.is_insurance = true;
                }

                if (this.entry.transporterId == this.$constant.vtp_transposter) {
                    this.entry.kpi_ht = item.kpi_ht;
                    this.entry.is_insurance = false;
                    this.entry.isSend = false;
                    this.entry.serviceVTP = "1";
                }
            },
            showInformationCustomer(){
                this.$refs.ModalInformationCustomer.show(this.informationCustomer, this.entry.customerId, this.entry.customerCode);
            },
            changeDataAddress(value){
                if(this.entry.saleMode == this.$constant.sale_mode_trans){
                    this.entry.toDistrictName = value.districts ? value.districts.name : null;
                    this.entry.toWardName = value.wards ? value.wards.name : null;
                    this.entry.to_name = value.name ?? null;
                    this.entry.to_phone = value.phone ?? null;
                    this.entry.customerAddressId = value.id ?? null;
                    this.entry.customerProvinceId = value.provinceId ? value.provinceId : null;
                    this.entry.customerDistrictId = value.districtId ? value.districtId : null;
                    this.entry.customerWardId = value.wardId ? value.wardId : null;
                    this.entry.customerAddressValue = this.formatAddress(value);
                    this.entry.customerAddressMain = value.addressValue;
                    this.entry.to_address = this.entry.customerAddressValue;
                    // this.entry.customerName = value.name ?? null;
                    this.entry.toProvinceName = value.provinces.name;
                    this.loadDataLocation();
                } 
            },
            formatAddress(value){
                let address = [value.addressValue];
                address.push(value && value.wards ? value.wards.name : null);
                address.push(value && value.districts ? value.districts.name : null);
                address.push(value && value.provinces ? value.provinces.name : null);
                return address.join(' ,');
            },
            selectWeightUnits(value){
                this.entry.weightUnit = value.model ?? null;
            },
            createTransporter(){
                this.$refs.ModalAddTransporters.show();
            },
            selectTransporter(data){
                this.entry.transporterId = data && data.id ? data.id : null;
                this.entry.isTransporterIntegration = data ? data.isIntegration : true;
                if(!this.entry.isTransporterIntegration){
                    this.entry.serviceTypeId = this.$constant.service_type_default;
                    this.entry.deliveryStatus = this.$constant.delivery_status_default;
                }
            },
            changeServiceType(data){
                this.entry.serviceTypeId = data && data.model ? data.model : null;
            },
            changeDeliveryStatus(data){
                this.entry.deliveryStatus = data && data.model ? data.model : null;
            },
            changeDeliveryTime(date){
                this.entry.deliveryTime = date;
            },
            async countAhamove (){
                const result = await $get('/v1/ahamove/count-account-trans');
                if (result.result === false) {
                    this.isLoginAhamove = false;
                }else {
                    this.isLoginAhamove = true;
                }
            },
            async countGHN (){
                const result = await $get('/v1/giao-hang-nhanh/count-account-ghn');
                if (result.result === false) {
                    this.isLoginGHN = false;
                }else {
                    this.isLoginGHN = true;
                }
            },

            async countViettelPost (){
                const result = await $get('/v1/viettel-post/count-account-vtp');
                if (result.result === false) {
                    this.isLoginViettelPost = false;
                    this.onEstimatedFeesViettelPost();
                }else {
                    this.isLoginViettelPost = true;   
                }
            },
            async getLevelBulky() {
                if (!this.entry.width || !this.entry.hight || !this.entry.long) {
                    this.clearLevel();
                }
                const res = await $get('/v1/ahamove/convert-level-bulky', {
                    width: this.entry.width,
                    hight: this.entry.hight,
                    long: this.entry.long,
                    weightUnit: this.entry.weightUnit,
                });
                if (res && res['result'] && res['result']['is_bulky']) {
                    this.entry.is_bulky = res['result']['is_bulky'];
                    this.entry.level = res['result']['level'];
                    this.entry.levelName = res['result']['name'];
                } else {
                    this.clearLevel();
                }              
            }, 
            clearLevel() {
                this.entry.is_bulky = false;
                this.entry.level = this.$constant.level_1;
                this.entry.levelName = null;
            },
            async getProductForPiceList() {
                if(!this.entry.priceListId && this.entry.priceListId != this.$constant.priceListIdCommon){
                    this.entry.products = [];
                    return;
                }else if(this.entry.priceListId == this.$constant.priceListIdCommon){
                    return;
                }

                const res = await $post('/v1/setting-price/get-product-for-price-list-id', {
                    productIds: this.productIds,
                    priceListId: this.entry.priceListId,
                });
                const resultFormat = res.result;
                this.entry.products?.forEach((product, index) => {
                    let item = resultFormat.find(el => (el == product.id || el == product.productId));
                    if (!item) {
                        this.entry.products.splice(index, 1);
                    }
                });
            },
            selectServiceVTP(o){
                if (!o) {
                    return;
                }
                this.entry.serviceVTP = o.model;   
            }
        },
        watch: {           
            'entry.priceListId'(newValue) {
                this.$emit('SetPriceListId', newValue);
                // this.$emit('SetPriceListId', newValue.totalPrice);
                this.getUserActive();
                // this.getCustomers();
                this.clearCustomer();
                this.getPriceProductForPiceList();
                this.getProductForPiceList();
            },
            'entry.channelId'(newValue) {
                this.$emit('setSaleChannelId', newValue);
            },
            'entry.soldById'(newValue) {
                this.$emit('setSoldById', newValue);
                this.getPromotion();
            },
            'entry.customerId'(newValue) {
                if(!newValue){
                    this.entry.debtDate = '';
                    this.entry.debtTerm = '';
                    this.entry.period = '';
                }
                this.$emit('setCustomerId', newValue);
                this.checkPromotionForCustomer();
            },
            'entry.cash'(newValue) {
                this.$emit('setCashMoney', newValue);
            },
            'entry.card'(newValue) {
                this.$emit('setCardMoney', newValue);
            },
            'entry.transfer'(newValue) {
                this.$emit('setTransferMoney', newValue);
            },
            'entry.voucher'(newValue) {
                this.$emit('setVoucher', newValue);
            },
            'entry.debtTerm'(newValue) {
                this.$emit('setDebtTerm', newValue);
            },
            'entry.is_insurance'() {
                this.entry.item_value = 0;
            },
            'entry.isSend' () {
                if (this.entry.shippingMode == this.$constant.shipping_mode_default) {
                    if (this.entry.width && this.entry.hight && this.entry.long && this.entry.weightUnit) {
                        this.onEstimatedFeesViettelPost();
                    }
                }
            },
            'entry.serviceVTP' () {
                if (this.entry.shippingMode == this.$constant.shipping_mode_default) {
                    if (this.entry.width && this.entry.hight && this.entry.long && this.entry.weightUnit) {
                        this.onEstimatedFeesViettelPost();
                    }
                }
            },
            paymentChannel: {
                handler(){
                    if (!this.paymentChannel.includes(this.$constant.paymentChannelCashMoney)) {
                        this.entry.cash = 0;
                    }
                    if (!this.paymentChannel.includes(this.$constant.paymentChannelCardMoney)) {
                        this.entry.card = 0;
                    }
                    if (!this.paymentChannel.includes(this.$constant.paymentChannelTransferMoney)) {
                        this.entry.transfer = 0;
                    }
                    if (!this.paymentChannel.includes(this.$constant.paymentChannelVoucher)) {
                        this.entry.voucher = 0;
                    }
                },
                deep: true
            },            
            entry: {
                handler: function(dataNew) {                          
                    let totalPrice = 0;
                    let totalBasePrice = 0;
                    let totalQuantity = 0;
                    this.productIds = [];
                    this.productUnitIds = [];
                    this.categoriesIds = [];
                    this.quantityOrder = [];
                    this.productUnits = [];
                    this.categories = [];
                    if(dataNew.isHsCode) {
                        this.isHsCode = true;
                    }
                    if(dataNew.isTaxRate) {
                        this.isTaxRate = true;
                    }
                    if(dataNew.consignment) {
                        this.hasConsignment = true;
                    }
                    if(dataNew.stamp) {
                        this.hasStamp = true;
                    }
                    if (dataNew.products) {
                        dataNew.products.forEach(product => {  
                            if(product.product?.hsCode){
                                product.hsCode = product.product.hsCode
                            }
                            if(product.product?.taxRate){
                                product.taxRate = product.product.taxRate
                            }
                            if (dataNew.type == TYPE_RETURN) {
                                if(product.consignmentActive && product.consignmentActive?.length > 0){
                                    let quantityProduct = 0;
                                    product.consignmentActive.forEach(element => {
                                        quantityProduct += (element.quantity ? element.quantity : 0);
                                    });
                                    product.quantity = quantityProduct;
                                }
                                if (product.plusOrder) {
                                    product.plusOrder.forEach(plus => {
                                        if (plus.consignmentActive && plus.consignmentActive.length) {
                                            let quantityProductPlus = 0;
                                            plus.consignmentActive.forEach(consignmentPlus => {
                                                quantityProductPlus += +(consignmentPlus.quantity ?? 0);
                                            });
                                            plus.quantity = quantityProductPlus;
                                        }
                                    })
                                }
                                if (product.plusReturn) {
                                    product.plusReturn.forEach(plusItem => {
                                        plusItem.amount = plusItem.price - (!isNaN(plusItem.discount) ? plusItem.discount : 0);
                                        if (plusItem.consignmentActive && plusItem.consignmentActive.length > 0) {
                                            let quantityProductPlus = 0;
                                            plusItem.consignmentActive.forEach(consignmentPlus => {
                                                quantityProductPlus += +(consignmentPlus.quantity ?? 0);
                                            });
                                            plusItem.quantity = quantityProductPlus;
                                        }
                                        totalQuantity = totalQuantity + +plusItem.quantity;
                                        totalPrice = totalPrice + plusItem.amount * plusItem.quantity;
                                        totalBasePrice = totalBasePrice + (plusItem.price * plusItem.quantity);
                                    });
                                }
                            }
                            if (dataNew.type == TYPE_INVOICE || (dataNew.type == TYPE_ORDER && dataNew.action == this.$constant.actionUpdateOrder)) {
                                if(product.consignmentActive && product.consignmentActive?.length > 0){
                                    let quantityProduct = 0;
                                    product.consignmentActive.forEach(element => {
                                        quantityProduct += (element.quantity ? element.quantity : 0);
                                    });
                                    product.quantity = quantityProduct;
                                }
                            }
                            let currentProductCategories = [];
                            if(product.product_category && product.product_category?.length){
                                product.product_category.forEach(children => {
                                    currentProductCategories.push(children.categoryId);
                                    let index = this.categories.findIndex(v => v.id == children.categoryId);
                                    let sumQuantityChild = 0;
                                    if (product.plusOrder && product.plusOrder.length){
                                        sumQuantityChild = product.plusOrder.reduce((accumulator, object) => {
                                            return accumulator + object.quantity;
                                        }, 0);
                                    }
                                    if(index < 0){
                                        this.categoriesIds.push(children.categoryId);
                                        this.categories.push({
                                            id: children.categoryId,
                                            maxQuantity: product.quantity + sumQuantityChild,
                                        })
                                    }else{
                                        this.categories[index].maxQuantity += (product.quantity + sumQuantityChild);
                                    }
                                });
                            }
                            let index = this.quantityOrder.findIndex((item) => item.id == product.productUnitId);
                            if (index > -1) {
                                this.quantityOrder[index].maxQuantity += product.quantity;
                            } else {
                                this.quantityOrder.push({
                                    id: product.productUnitId,
                                    maxQuantity: product.quantity,
                                    categoryIds: currentProductCategories
                                })
                            }
                            if (product.variantId) {
                                this.productUnitIds.push(product.variantId);
                            } else {
                                this.productUnitIds.push(product.productUnitId);
                            }
                            
                            if (product.discountType == this.$constant.discountTypePercent) {
                                product.discount = product.price * product.valuePercent/100;
                            }
                            product.amount = product.price - (!isNaN(product.discount) ? product.discount : 0);
                            totalPrice = totalPrice + product.amount * product.quantity;
                            totalQuantity = totalQuantity + +product.quantity;
                            if(product.basePrice){
                                totalBasePrice = totalBasePrice + (product.basePrice * product.quantity);
                            }
                            product.productUnit = product.product_units.find(el => (el.id == product.productUnitId));
                            this.productIds.push(product.id);
                            if (product.plusOrder && product.plusOrder.length) {
                                product.plusOrder.forEach(item => {
                                    if (dataNew.type == TYPE_INVOICE || (dataNew.type == TYPE_ORDER && dataNew.action == this.$constant.actionUpdateOrder)) {
                                        if(item.consignmentActive && item.consignmentActive.length > 0){
                                            let quantityProduct = 0;
                                            item.consignmentActive.forEach(element => {
                                                quantityProduct += (element.quantity ? element.quantity : 0);
                                            });
                                            item.quantity = quantityProduct;
                                        }
                                    }
                                    if (item.variantId) {
                                        this.productUnitIds.push(item.variantId);
                                    } else {
                                        this.productUnitIds.push(item.productUnitId);
                                    }
                                    totalQuantity = totalQuantity + +item.quantity;
                                    totalPrice = totalPrice + item.amount * item.quantity;
                                    if (item.discountType == this.$constant.discountTypePercent) {
                                        item.discount = item.price * item.valuePercent/100;
                                    }
                                    item.amount = +item.price - +item.discount;
                                    if(item.basePrice){
                                        totalBasePrice = totalBasePrice + (item.basePrice * item.quantity);
                                    }

                                    let key = this.quantityOrder.findIndex((i) => i.id == item.productUnitId);
                                    if (key > -1) {
                                        this.quantityOrder[key].maxQuantity += item.quantity;
                                    } else {
                                        this.quantityOrder.push({
                                            id: item.productUnitId,
                                            maxQuantity: item.quantity,
                                            categoryIds: currentProductCategories
                                        })
                                    }
                                });
                            }
                            this.entry.amountCod = this.entry.totalPriceAfterDiscount - this.entry.customerPayment;
                        });
                    }
                    
                    this.entry.customerPayment = +(this.entry.cash ?? 0) + +(this.entry.card ?? 0) + +(this.entry.transfer ?? 0) + +(this.entry.voucher ?? 0);
                    let amountDiscountProduct = 0;
                    let quantityPromotion = Number(0);
                    if (dataNew && dataNew.productDiscount && dataNew.productDiscount.length) {
                        dataNew.productDiscount.forEach((promtion, index)=> {
                            this.setMaxQuantityProductDiscount(index, promtion.maxQuantity);
                            amountDiscountProduct = Number(amountDiscountProduct) +  Number(promtion.amount);
                            quantityPromotion = Number(quantityPromotion) + Number(promtion.quantity);
                        });
                    }
                    if (dataNew && dataNew.productGifts && dataNew.productGifts.length) {
                        dataNew.productGifts.forEach((promtion, index) => {
                            this.setMaxQuantityProductGift(index, promtion.maxQuantity);
                            quantityPromotion = +quantityPromotion + promtion.quantity;
                            if (promtion.isConsignment && promtion.consignmentActive && promtion.consignmentActive.length) {

                                let totalQuantity = 0;
                                promtion.consignmentActive.forEach(item => {
                                    totalQuantity += +item.quantity;
                                });
                                promtion.quantity = totalQuantity;
                            }
                        });
                    }                    
                    if (dataNew && dataNew.productDiscount && dataNew.productDiscount.length) {                        
                        dataNew.productDiscount.forEach((promtion, index) => {
                            this.setMaxQuantityProductGift(index, promtion.maxQuantity);
                            quantityPromotion = +quantityPromotion + promtion.quantity;
                            if (promtion.isConsignment && promtion.consignmentActive && promtion.consignmentActive.length) {
                                let totalQuantity = 0;
                                promtion.consignmentActive.forEach(item => {
                                    totalQuantity += +item.quantity;
                                });
                                promtion.quantity = totalQuantity;
                            }
                        });
                    }
                    this.entry.totalPrice =  Number(totalPrice) + Number(amountDiscountProduct);
                    this.entry.totalBasePrice = totalBasePrice;
                    this.entry.totalQuantity = +totalQuantity + +quantityPromotion;                     
                    if (this.entry.discountType == this.$constant.discountTypePercent) {                          
                        this.entry.discountValue = totalPrice * this.entry.valuePercent/100;                        
                    }                    
                    if(!dataNew.promotion && dataNew.promotions && dataNew.promotions.id){
                        dataNew.promotion = dataNew.promotions;
                        this.entry.promotion.discountValue = dataNew.promotionValue;
                    }
                    if (dataNew.promotion && dataNew.promotion.id) {
                        if (dataNew.promotion.discountRatio) {
                            this.entry.promotion.discountValue = dataNew.totalPrice*dataNew.promotion.discountRatio/100;
                        }
                    }                          
             
                    let totalPriceEnd = 0;                    
                    if(this.entry.type == TYPE_RETURN){                        
                        totalPriceEnd = +totalPrice - (!isNaN(this.entry.discountValue) ? this.entry.discountValue : 0) - +(this.entry.surchargeAmount ?? 0) - (this.entry.valueCoupon ?? 0) - (this.entry.valueVoucher ?? 0) - (this.entry.promotionValue ?? 0);
                        
                        if(+totalPriceEnd < 0){
                            totalPriceEnd = 0;
                        }
                        this.entry.discount = +this.entry.discountValue;
                    }else{     
                        totalPriceEnd = Number(this.entry.totalPrice) - (!isNaN(this.entry.discountValue) ? this.entry.discountValue : 0) + +(this.entry.surchargeAmount ?? 0) - (this.entry.valueCoupon ?? 0) - (this.entry.valueVoucher ?? 0) - ((this.entry.promotion && this.entry.promotion.discountValue) ? this.entry.promotion.discountValue : 0);
                        if(+totalPriceEnd < 0){
                            totalPriceEnd = 0;
                        }
                    }
                    
                    // this.entry.totalPriceAfterDiscount = (totalPriceEnd - (this.entry.totalCustomerPaid ?? 0)) < 0 ? 0 : (totalPriceEnd - (this.entry.totalCustomerPaid ?? 0));
                    this.entry.totalPriceAfterDiscount = totalPriceEnd;
                    this.entry.priceCodPayment = this.entry.totalPriceAfterDiscount;                    
                },
                deep: true
            },
            'entry.products': {
                handler: function(newProducts) {
                    newProducts.forEach(product => {                    
                        if (product.combo_products) {
                            product.combo_products.forEach(element => {
                                if (element.oldQuantity) {
                                    element.quantity = product.quantity * element.oldQuantity;
                                }
                                if (element.product.isConsignment && (this.entry.type == TYPE_INVOICE || (this.entry.type == TYPE_ORDER && this.entry.action == this.$constant.actionUpdateOrder))) {
                                    if (!element.product.consignmentActive && element.product.consignments && element.product.consignments.length) {
                                        let comboConsignment = element.product.consignments[0].consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == element.productUnitId);
                                        let consignmentActive1 = {
                                            consignmentProductBranchMetaId: comboConsignment.id,
                                            consignmentId: comboConsignment.consignmentId,
                                            quantity: element.quantity,
                                            key: 1,
                                            productUnitId: element.productUnitId,
                                            name: element.product.consignments[0].name,
                                        }
                                        element.product.consignmentActive = [consignmentActive1];

                                    }
                                }
                            });
                        }

                        if (product.plusOrder) {
                            product.plusOrder.forEach(plusItem => {
                                if (plusItem.combo_products) {
                                    plusItem.combo_products.forEach(element => {
                                        if (element.oldQuantity) {
                                            element.quantity = plusItem.quantity * element.oldQuantity;
                                        }
                                        if (element.product.isConsignment && (this.entry.type == TYPE_INVOICE || (this.entry.type == TYPE_ORDER && this.entry.action == this.$constant.actionUpdateOrder))) {
                                            if (!element.product.consignmentActive && element.product.consignments && element.product.consignments.length) {
                                                let comboConsignment = element.product.consignments[0].consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == element.productUnitId);
                                                let consignmentActive2 = {
                                                    consignmentProductBranchMetaId: comboConsignment.id,
                                                    consignmentId: comboConsignment.consignmentId,
                                                    quantity: element.quantity,
                                                    key: 1,
                                                    productUnitId: element.productUnitId,
                                                    name: element.product.consignments[0].name,
                                                }
                                                element.product.consignmentActive = [consignmentActive2];
                                            }
                                        }
                                    });
                                }
                            });
                        }
                
                        if (product.isConsignment && (this.entry.type == TYPE_INVOICE  || this.entry.type == TYPE_RETURN  || (this.entry.type == TYPE_ORDER && this.entry.action == this.$constant.actionUpdateOrder))) {
                            if (!product.consignmentActive && product.consignments && product.consignments.length) {
                                let consignmentProductBranchMeta = product.consignments[0].consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == product.productUnitId);
                                let consignmentActive = {
                                    consignmentProductBranchMetaId: consignmentProductBranchMeta.id,
                                    consignmentId: consignmentProductBranchMeta.consignmentId,
                                    quantity: product.quantity,
                                    key: 1,
                                    productUnitId: product.productUnitId,
                                    name: product.consignments[0].name,
                                    total_sales: consignmentProductBranchMeta.total_sales
                                }
                                product.consignmentActive = [consignmentActive];
                            }

                            if (product.plusOrder && product.plusOrder.length) {
                                product.plusOrder.forEach(plus => {
                                    if (!plus.consignmentActive) { 
                                        let consignmentProductBranchMetaPlus = plus.consignments[0].consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == plus.productUnitId);
                                        let consignmentActivePlus = {
                                            consignmentProductBranchMetaId: consignmentProductBranchMetaPlus.id,
                                            consignmentId: consignmentProductBranchMetaPlus.consignmentId,
                                            quantity: plus.quantity,
                                            key: 1,
                                            productUnitId: plus.productUnitId,
                                            name: plus.consignments[0].name
                                        }
                                        plus.consignmentActive = [consignmentActivePlus];
                                    }
                                });
                            }
                        }                        
                        if(!this.entry.promotion && this.entry.promotions){                            
                            this.entry.promotion = this.entry.promotions;                            
                        }
                        if(this.entry.promotion && this.entry.promotion.clonePromotion){    
                            
                            if (this.entry.promotion.clonePromotion.promotionFromId == this.$constant.promotion_product_allow_quantity) {
                                if (product.productUnitId == this.entry.promotion.clonePromotion.productUnitId) {
                                    let originPrice = !product.originPrice ? product.price : product.originPrice; 
                                    product.originPrice = originPrice;
                                    if (this.entry.promotion.clonePromotion.promotion_options.whenBuying.discountBy == 1) {
                                        this.entry.promotion.promotionProductValue = (product.originPrice - this.entry.promotion.clonePromotion.promotion_options.whenBuying.discountValue) < 0 ? 0 : (product.originPrice - this.entry.promotion.clonePromotion.promotion_options.whenBuying.discountValue);
                                    }
                                    else {
                                        if (this.entry.promotion.clonePromotion.promotion_options.whenBuying.discountValue && !this.entry.promotion.clonePromotion.promotion_options.whenBuying.discountRatio) {
                                            this.entry.promotion.promotionProductValue = this.entry.promotion.clonePromotion.promotion_options.whenBuying.discountValue;
                                        } 
                                        if (this.entry.promotion.clonePromotion.promotion_options.whenBuying.discountRatio) {
                                            this.entry.promotion.promotionProductValue = (product.originPrice * (this.entry.promotion.clonePromotion.promotion_options.whenBuying.discountRatio/100));
                                        }
                                    }
                                    this.entry.promotion.promotionProductValue = this.entry.promotion.promotionProductValue * product.quantity;
                                }
                            }
                        }
                       
                    });
                    if (this.entry.type != TYPE_RETURN) {
                        setTimeout(() => {
                            this.checkCouponActive();
                            this.checkVoucherActive();
                            this.getPromotion();
                        }, 30);
                    }
                },
                deep: true
            },
            deliveries: {
                handler(newData){
                    if (!newData) {
                        this.clearInfosShipping(); 
                        return;
                    }
                    let item = newData.find(item => item._id == this.entry.serviceId);
                    if (item && item.total_price) {
                        this.addAmountShipping(item.total_price);
                        this.entry.requests = item.requests;
                    } else if ((!item || !this.entry.serviceId) && this.entry.transporterId != this.$constant.vtp_transposter) {
                        this.clearInfosShipping();
                    }
                },
                deep: true
            },

            deliveriesViettelPost: {
                handler(newData){
                    let item = newData.find(item => item._id == this.entry.serviceId);
                    if (item && item.total_price) {
                        this.addAmountShipping(item.total_price);
                        this.entry.requests = item.requests;
                    } else if ((!item || !this.entry.serviceId) && this.entry.transporterId == this.$constant.vtp_transposter) {
                        this.clearInfosShipping();
                    }
                },
                deep: true
            },
            'entry.width'() {
                if (this.entry.shippingMode == this.$constant.shipping_mode_in_day) {
                    if (this.entry.width && this.entry.hight && this.entry.long) {
                        this.onGetLevelBulkyUseAhamove();
                    } else {
                        this.clearLevel();
                    }
                } else if (this.entry.shippingMode == this.$constant.shipping_mode_default) {
                    if (this.entry.width && this.entry.hight && this.entry.long && this.entry.weightUnit) {
                        this.onEstimatedFeesGiaoHangNhanh();
                        this.onEstimatedFeesViettelPost();
                    }
                }
            },
            'entry.hight'() {
                if (this.entry.shippingMode == this.$constant.shipping_mode_in_day) {
                    if (this.entry.width && this.entry.hight && this.entry.long) {
                        this.onGetLevelBulkyUseAhamove();
                    } else {
                        this.clearLevel();
                    }
                } else if (this.entry.shippingMode == this.$constant.shipping_mode_default) {
                    if (this.entry.width && this.entry.hight && this.entry.long && this.entry.weightUnit) {
                        this.onEstimatedFeesGiaoHangNhanh();
                        this.onEstimatedFeesViettelPost();
                    }
                }
            },
            'entry.long'() {
                if (this.entry.shippingMode == this.$constant.shipping_mode_in_day) {
                    if (this.entry.width && this.entry.hight && this.entry.long) {
                        this.onGetLevelBulkyUseAhamove();
                        this.onEstimatedFeesViettelPost();
                    } else {
                        this.clearLevel();
                    }
                } else if (this.entry.shippingMode == this.$constant.shipping_mode_default) {
                    if (this.entry.width && this.entry.hight && this.entry.long && this.entry.weightUnit) {
                        this.onEstimatedFeesGiaoHangNhanh();
                        this.onEstimatedFeesViettelPost();
                    }
                }
            },
            'entry.weightUnit'($value) {
                if (this.entry.shippingMode == this.$constant.shipping_mode_default) {
                    if (this.entry.width && this.entry.hight && this.entry.long && this.entry.weightUnit) {
                        this.onEstimatedFeesGiaoHangNhanh();
                        this.onEstimatedFeesViettelPost();
                    }
                }
            },
            propertyADeliveries() {
                if (this.entry.from_address && this.entry.to_address) {
                    if (this.entry.shippingMode == this.$constant.shipping_mode_default) {
                        this.onEstimatedFeesGiaoHangNhanh();
                        this.onEstimatedFeesViettelPost();
                    }
                    else if (this.entry.shippingMode == this.$constant.shipping_mode_in_day) {
                        this.onEstimatedFeesAhamove();
                    }
                }
            },
            'entry.shippingMode'(newValue) {
                this.deliveries = [];
                if(newValue == this.$constant.shipping_mode_default){
                    this.onEstimatedFeesGiaoHangNhanh();
                    this.onEstimatedFeesViettelPost();
                }else if(newValue == this.$constant.shipping_mode_in_day){
                    this.onEstimatedFeesAhamove();
                }
            },
            'entry.weight'($value) {
                if (this.entry.shippingMode == this.$constant.shipping_mode_default) {
                    if (this.entry.width && this.entry.hight && this.entry.long && this.entry.weightUnit) {
                        this.onEstimatedFeesGiaoHangNhanh();
                        this.onEstimatedFeesViettelPost();
                    }
                }
            },
            'entry.to_address'($value) {
                if(this.entry.shippingMode == this.$constant.shipping_mode_in_day){
                    this.onEstimatedFeesAhamove();
                } else if (this.entry.shippingMode == this.$constant.shipping_mode_default) {
                    if (this.entry.width && this.entry.hight && this.entry.long && this.entry.weightUnit) {
                        this.onEstimatedFeesGiaoHangNhanh();
                        this.onEstimatedFeesViettelPost();
                    }
                }
            },
            'entry.from_address'($value) {
                if(this.entry.shippingMode == this.$constant.shipping_mode_in_day){
                    this.onEstimatedFeesAhamove();
                } else if (this.entry.shippingMode == this.$constant.shipping_mode_default) {
                    if (this.entry.width && this.entry.hight && this.entry.long && this.entry.weightUnit) {
                        this.onEstimatedFeesGiaoHangNhanh();
                        this.onEstimatedFeesViettelPost();
                    }
                }
            },
        },
    }
</script>

<style scoped lang="scss">
@import "../../assets/css/vars.scss";
    .btn-icon-gift, .label_gift{
        color: #FF2F86 !important;
    }
    .btn-icon-gift:hover, .btn-icon-gift.active{
        border-radius: 999px !important;
        background-color: rgba(255,47,134,0.1);
    }
    .bg-text-promotion{
        background-color: rgba(255,47,134,0.1);
        color: #FF2F86 !important;
    }
    .bg-gift{
        background-color: #fff !important;
        padding: 10px 20px 10px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        // border: 1px solid #d7d7d7;
        -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.15);
        -moz-box-shadow: 0 5px 10px rgba(0,0,0,.15);
        box-shadow: 0 5px 10px rgba(0,0,0,.15);
    }
    .border-top-dotted{
        border: none;
        border-top: 1px dashed #D6D7E1;
    }
    .format__input {
        /* height: 100%; */
        border-bottom: 1px solid #C7C5C5!important;
        margin-bottom: 10px;
        text-align: right;
        border: none;
        background: none;
        width: 100%;
    }
    .noteOrder{
        position: absolute;
        top: 0px;
        left: 60px;
    }
    .col-left {
        background-color: #fff;

        table {
            height: calc(100vh - 150px);
            // overflow: hidden auto;
            overflow: auto;
            display: block;
            margin-bottom: 0;

            .popover-wrap {
                font-weight: 400;
                margin-bottom: 5px;
                position: relative;

                .popover-wrap__content {
                    padding-left: 10rem;
                    .arrow-up {
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 10px solid teal;

                        position: absolute;
                        top: -10px;
                        left: calc(10rem + 50px);

                        &:after {
                            margin-left: -10px;
                            content: " ";
                            top: 1px;
                            border-width: 10px;
                            border-top-width: 0;
                            border-color: transparent;
                            border-bottom-color: #fff;
                            position: absolute;
                            display: block;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            z-index: 11;
                        }
                    }

                    .content-tooltip {
                        border: 1px solid teal;
                        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
                        width: max-content;
                        padding: 10px;
                        background-color: white;
                        font-weight: 600;

                        .price-item {
                            padding: 0 5px;
                        }
                    }

                    .inventory-by-branch {
                        border: 1px solid teal;
                        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
                        padding: 10px;
                        background-color: white;
                        font-weight: 600;
                        min-width: 500px;

                        table {
                            height: auto;
                            width: 100%;
                            display: table;
                        }
                    }

                    textarea {
                        border-color: teal;
                        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
                        font-size: 13px;

                        height: auto;
                        min-height: 50px;
                        max-height: 112px;
                        width: 300px;

                        &:focus {
                            outline: none;
                        }
                    }
                }
            }
        }

        .btn-icon {
            border: 1px solid rgba(0,0,0,0.15);
            text-align: center;
            height: 24px;
            width: 24px;
            margin: auto 5px auto;
            outline: none;
        }

        .product-batch-selected  {
            margin-bottom: 7px;

            &>span {
                background-color: #2fa1a1;
                color: white;
                font-weight: 600;

                padding: 1px 2px 1px 6px;
                display: inline-block;
                border-radius: 3px;

                .remove-product-batch {
                    padding: 0 5px;
                    margin-left: 2px;
                    font-size: 16px;

                    &:hover {
                        background-color: teal;
                    }
                }
            }
        }

        .input-product-price {
            cursor: text;
            font-weight: 600;
            background-color: transparent;

            &:hover {
                border-bottom: 2px solid teal;
                font-weight: bold;
            }
        }
    }

    .col-right {
        height: calc(100vh - 150px);
        background: $background-white;
        overflow: hidden auto;
        box-shadow: 0 5px 25px rgb(0 0 0 / 25%);

        .col-right__content {
            min-height: calc(100% - 100px);

            .total-quantity {
                min-width: 24px;
                height: 24px;
                line-height: 24px;
                margin: 0 5px;
                border: 1px solid #e1e1e1;
                border-radius: 3px;
                font-size: 14px;
                display: inline-block;
                padding: 0 5px;
                text-align: center;
            }

            .form-group {
                label {
                    font-weight: 600;
                }
            }

            .btn-icon-payment {
                font-size: 24px;
                color: teal;
                font-weight: 400;
                background-color: transparent;
                border: none;
                padding: 0;
                line-height: 3.1rem;
            }
        }

        .col-right__save {
            // margin: 0 12px;
            padding-top: 20px;
            height: 100px;

            .btn-save {
                width: 100%;
                font-size: 20px;
                height: 65px;
                margin-left: 0;
                font-weight: 600;

                &:hover {
                    background-color: #168080 !important;
                }
            }
        }
    }

    .col-left table, .col-right {
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        &::-webkit-scrollbar-thumb {
            width: 8px;
            border-radius: 4px;
            background-color: #c1c1c1;
            border: 1px solid #fff;
        }

        &::-webkit-scrollbar-track {
            padding: 6px;
        }
    }
    .quantity-remaining{
        position: absolute;
        top: 35%;
        right: 0;
        color: gray;
    }
    .show_coupon {
        padding: 5px;
        border-radius: 5px;
        margin-bottom: 5px;
        background-color: #e9ecef;
    }
    .custom_format {
        width: 50% !important;
    }
    .label_gift {
        height: 50px;
        display: flex;
        align-items: center;
    }
    .cate_selected{
        background: #e5e5e5 !important;
        min-height: 30px !important;
        width:fit-content !important;
    }
    .colQuantity {
        padding-right: 33px !important;
    }
    .colQuantityTax {
        padding-right: 24px !important;
    }
    .container input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .transpoter-options{
        overflow: auto;
        max-height: 300px;
    }
    .transpoter-options .disable{
        cursor: not-allowed !important;
        pointer-events: all !important;
    }
    .border-active{
        border: 1px solid $base-color;
    }

    .cart-footer-info {
        padding: 10px;
        background-color: $background-white;
        margin: 5px 0px;
        border-radius: 5px;
        box-shadow: 0 5px 25px rgba(0, 0, 0, 0.25);
        max-height: 135px !important;
        overflow: auto;
    }

    .cart-footer-info .total-quantity {
        min-width: 24px;
        height: 24px;
        line-height: 24px;
        margin: 0 5px;
        border: 1px solid #e1e1e1;
        border-radius: 3px;
        font-size: 14px;
        display: inline-block;
        padding: 0 5px;
        text-align: center;
    }

    .cart-container-content table{
        height: calc(100vh - 300px) !important;
    }
    .position-relative .fa-address-book{
        position: absolute;
        top: 35%;
        right: 0px;
    }
    .container-radio input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .icon-un-extend, .icon-extend{
        position: absolute;
        top: calc(100vh - 100px - 60%);
        left: 5px;
    }
    .overflow-a-part{
        max-height: calc(100vh - 300px);
        overflow-y: auto;
        overflow-x: hidden;
    }
    .overflow-all{
        max-height: calc(100vh - 150px);
        overflow-y: auto;
        overflow-x: hidden;
    }
</style>
